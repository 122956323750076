export function hash(str: string) {
  return (
    Math.abs(
      Array.from(str).reduce(
        (s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0,
        0
      )
    ) % 5
  );
}
