import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import Text from "components/Text";
import * as s from "pages/WorldDomination/DefenseOffenceSummary/Summary.styled";
import { WDModalStyles } from "pages/WorldDomination/modalStyles";
import React from "react";
import Modal from "react-modal";
import {
  useGetClanLeaderboardQuery,
  useGetEventQuery,
  useGetEventTurnQuery,
} from "redux/rtkQuery/rtkApi";

type GridRow = {
  city: string;
  clan: string;
  successfulStrikes: number;
  failedStrikes: number;
  totalStrikes: number;
  totalStolen: number;
  pointsStolen: number;
};
type GridBlock = { turn: number; rows: GridRow[] };

type AllCitiesItem = {
  cityId: string;
  cityName: string;
  clanName: string;
};

export function OffenseSummary({ onClose }: { onClose: any }) {
  const { data: event } = useGetEventQuery();
  const { data: turn } = useGetEventTurnQuery(event?.id ?? skipToken);
  const { data: clanLb } = useGetClanLeaderboardQuery(
    event ? { event, params: { clanSlots: true, allItems: true } } : skipToken
  );

  if (!turn || !clanLb) return null;

  const allCities = clanLb.reduce((acc, clan) => {
    clan.worldDomination.slotsList.forEach((city) => {
      if (city.kind === 2) {
        const record = {
          cityId: city.id,
          cityName: city.name,
          clanName: clan.name,
        };
      acc.push(record);
      }
    });
    return acc;
  }, [] as AllCitiesItem[]);

  let grid: GridBlock[] = turn.battleSummaryList.reduce((acc, summaryItem) => {
    let gridBlock = {} as GridBlock;

    if (summaryItem.offence.citiesList.length > 0) {
      gridBlock.turn = summaryItem.round;
      gridBlock.rows = [];
      for (let record of summaryItem.offence.citiesList) {
        let gridRow: GridRow = {
          city: allCities.find(i=>i.cityId===record.cityId)?.cityName || "",
          clan: allCities.find(i=>i.cityId===record.cityId)?.clanName || "",
          successfulStrikes: record.myNukes.successful,
          failedStrikes: record.myNukes.failed,
          totalStrikes: record.nukes.successful + record.nukes.failed,
          totalStolen: record.nukes.vampirePoints,
          pointsStolen: record.myNukes.vampirePoints,
        };
        gridBlock.rows.push(gridRow);
      }
      gridBlock.rows = gridBlock.rows.sort((a,b)=> a.clan.localeCompare(b.clan) || a.city.localeCompare(b.city))
      return acc.concat(gridBlock);
    } else return acc;
  }, [] as GridBlock[]);
  grid = grid.sort((a, b) => b.turn - a.turn);

  return (
    <Modal
      isOpen
      style={WDModalStyles}
      shouldCloseOnEsc
      preventScroll={true}
      onRequestClose={onClose}
    >
      <s.Content>
        <s.Close onClick={onClose}>
          <img src={closeIcon} />
        </s.Close>
        <Text s={22} mb={16}>
          Offense Summary
        </Text>

        <s.Scroll>
          {grid.map((gridBlock) => (
            <div key={gridBlock.turn}>
              <s.OffenseYellow>
                Turn {gridBlock.turn}{" "}
                {turn.round - 1 === gridBlock.turn && "(Last Turn)"}
              </s.OffenseYellow>
              <s.OffenseGrid key={gridBlock.turn}>
                <s.Title>City</s.Title>
                <s.Title>Clan</s.Title>
                <s.Title>Your Successful Strikes</s.Title>
                <s.Title>Your Failed Strikes</s.Title>
                {/*<s.Title>*/}
                {/*  Total Strikes Launched <br />*/}
                {/*  (including Others)*/}
                {/*</s.Title>*/}
                {/*<s.Title>Total Points Stolen (including Other Clans)</s.Title>*/}
                <s.Title>Total Points Stolen by Your Clan</s.Title>
                {gridBlock.rows.map((row, idx) => (
                  <React.Fragment key={idx}>
                    <s.OffenceWhite>{row.city}</s.OffenceWhite>
                    <s.OffenceWhite>{row.clan}</s.OffenceWhite>
                    <s.Green val={row.successfulStrikes} />
                    <s.Red val={row.failedStrikes} />
                    {/*<s.Grey val={row.totalStrikes} />*/}
                    {/*<s.Grey val={row.totalStolen} />*/}
                    <s.PointsStolen val={row.pointsStolen} />
                  </React.Fragment>
                ))}
              </s.OffenseGrid>
            </div>
          ))}
        </s.Scroll>
      </s.Content>
    </Modal>
  );
}
