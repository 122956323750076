import { useEffect, useState } from "react";
import { 
    SPolygon,
    SCupIcon,
    HeaderIconBlock,
    ContainerHeader,
    HeaderItem,
    ListContainer,
    ContainerBlock,
    LeftConteinerItem,
    ContainerItem,
    SelectBtn,
    SubtitleText,
    ScrollContainer,
    TitleContainer,
    CloseBtn,
} from "components/Transfer/ClanMembers/ClanMembers.style";
import { TransferModal } from 'components/Transfer/TransferModal/transferModal';
import { useIsTablet, useIsMobile } from "hooks/useMediaQuery";
import { getClanMembers, getLastEventpoints, getAccounts } from 'api';
import { IMembersTableType, Imember } from 'types/transferTypes';


export const ClanMembersTable = ({ setMembersOpen, currentItem, setGdImage, setUpdate, setGdname }: IMembersTableType) => {

  const isTablet = useIsTablet();
  const isMobile = useIsMobile()
  const [selectedUser, setUser] = useState({})
  const [open, setOpen] = useState(false)
  const [users, setUsers] = useState<Imember[]>([])
  const [accounts, setAccounts]  = useState<(IProfileFull | undefined)[]>([]);

  useEffect(() => {
    Promise.all([getClanMembers(), getLastEventpoints()]).then((prom) => {
      const newUsersArr = prom[0].map((item) => ({...item, totalScore: prom?.[1]?.leaderboardList.find((lederboard) => lederboard?.id === item?.id)?.totalScore}))
      const usersId = newUsersArr.map((user) => user.id)

      getAccounts(usersId).then((res: (IProfileFull | undefined)[]) => setAccounts(res));
      setUsers(newUsersArr)
    })
  }, [])

  const openModal = (user: Imember) => {
    setUser(user)
    setOpen(true)
  }

  const getUserSuffix = (id: string) => {
    return id ? `#${accounts.find((item: IProfileFull | undefined) => item?.id === id)?.suffix}` : ''
  }

    return (
        <ListContainer>
          <TitleContainer>
            <SubtitleText>Clan Members</SubtitleText>
            {(isTablet || isMobile) && <CloseBtn onClick={() => setMembersOpen && setMembersOpen(false)}/>}
          </TitleContainer>
          <ContainerHeader>
            <HeaderItem style={{ opacity: '50%'}}>
              PLAYER NAME
              <SPolygon/>
            </HeaderItem>
            <HeaderItem>
              <HeaderIconBlock>
                {!isMobile && <SCupIcon />}
                <span style={{ color: '#FFB60C'}}>LAST EVENT POINTS</span>
              </HeaderIconBlock>
              <SPolygon color='#FFB60C'/>
            </HeaderItem>
          </ContainerHeader>
          <ScrollContainer isTablet={isTablet || isMobile}>
            {users?.map((item: Imember, i: number) => (<ContainerBlock key={i}>
              <LeftConteinerItem>{item?.name}<div style={{ opacity: '0.7'}}>{getUserSuffix(item?.id)}</div></LeftConteinerItem>
              <ContainerItem style={{ fontWeight: '400'}}>
                {item.totalScore || 0}
                <SelectBtn selected={item.selected} onClick={() => openModal(item)}>Send</SelectBtn>
              </ContainerItem>
            </ContainerBlock>))}
          </ScrollContainer>
          <TransferModal open={open} setOpen={setOpen} recipient={selectedUser} currentItem={currentItem} setGdImage={setGdImage} setUpdate={setUpdate} setGdname={setGdname} />
          </ListContainer>
    )
}