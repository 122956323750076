import * as s from "./staking.styled";

export function StakingLogin() {
  return (
    <s.StackingLogin
      onClick={() => {
        window.location.href = "https://miniroyale.io/";
      }}
    >
      <div>
        Login <span>& Start Collecting</span>
      </div>
    </s.StackingLogin>
  );
}
