import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { ICurrency } from "api/api.types";
import uranium from "assets/uranium_icon.png";
import Box from "components/Box";
import { Gap } from "components/Gap";
import GreenButton from "components/GreenButton";
import Img from "components/Img";
import { QuantityCounterFlex } from "components/QuantityCounterFlex/QuantityCounterFlex";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import { HorizontalLine } from "components/TableClans/CloseModal.styled";
import Text from "components/Text/Text";
import image from "pages/WorldDomination/assets/nukeBig.png";
import whiteCheck from "pages/WorldDomination/assets/whiteCheck.svg";
import { Close } from "pages/WorldDomination/WDStatsPanel/Close";
import * as s from "pages/WorldDomination/WDStatsPanel/ConstructionModal.styled";
import { Icon } from "pages/WorldDomination/WDStatsPanel/StatsPanel.styled";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import {
  useAddToQueueMutation,
  useGetClanAssetsQuery,
  useGetClanInfoQuery,
  useGetEventQuery,
  useGetEventTurnQuery,
  useGetRoundQueueQuery,
  useGetUserRestrictionsQuery,
  useRemoveFromQueueMutation,
} from "redux/rtkQuery/rtkApi";
import { addErrorMessage } from "redux/slices/messageSystemSlice";
import { selectStreamItemById2 } from "redux/slices/worldDominationSlice";
import { useAppDispatch, useAppSelector } from "store";
import { numberWithCommas } from "utils/numberWithComas";
import { toFixedWithoutZeros } from "utils/toFixedWithoutZeros";
import { v4 as uuidv4 } from "uuid";

export function NukeModalContent(props: {
  onClose: Function;
  player: IProfileFull;
}) {
  const dispatch = useAppDispatch();
  const { data: event } = useGetEventQuery();
  const { data: turn, refetch: refetchTurn } = useGetEventTurnQuery(
    event?.id ?? skipToken
  );
  const { data: queue } = useGetRoundQueueQuery();
  const [addToQueue] = useAddToQueueMutation();
  const [removeFromQueue] = useRemoveFromQueueMutation();
  const { data: assets } = useGetClanAssetsQuery(event?.id ?? skipToken);
  const { data: userRestrictions } = useGetUserRestrictionsQuery();
  const { data: info } = useGetClanInfoQuery();

  const [status, setStatus] = useState<IStatus>("idle");
  const [amount, setAmount] = useState(1);

  const [requestId, setRequestId] = useState(() => uuidv4());
  const transactionResult = useAppSelector(selectStreamItemById2(requestId));

  // Wait transaction to finish
  useEffect(() => {
    if (transactionResult === undefined) return;
    if (transactionResult) {
      if (transactionResult?.error) {
        if (transactionResult?.error?.code === 3) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Insufficient funds",
            })
          );
        } else if (transactionResult?.error?.code === 6) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Construction limit exceeded",
            })
          );
        } else if (transactionResult?.error?.code === 5) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Can not allocate space for construction",
            })
          );
        } else {
          dispatch(
            addErrorMessage({ title: "Error", text: "Something went wrong" })
          );
        }
        props.onClose();
      } else {
        setStatus("done");
        setRequestId(uuidv4());
      }
    }
  }, [transactionResult]);

  if (!event || !queue || !turn || !assets || !userRestrictions || !info)
    return null;

  const currency: ICurrency = "URANIUM";
  const priceData = turn.market.productsList
    .find((i) => i.productId === "BUILD_NUKE")!
    .pricesList.find((i) => i.currency === currency)!;
  const price = priceData.amount;

  const missileSiloCount = assets.missileSilosList.length;

  const balance = turn.clan.balancesList.find(
    (i) => i.currency === currency
  )!.amount;

  const nukesCount = assets.nukesList.length;
  const nukesPlannedCount = queue.nuke.reduce((acc, i) => acc + i.count, 0);
  const attacksCount = queue.attackCityByNuke.reduce(
    (acc, i) => acc + i.count,
    0
  );
  const nukeVampirePercent =
    event.extensions.worldDominationConfig.attacks.attackCityByNuke
      .nukeVampirePercent / 10000;
  const maxNukes = missileSiloCount - nukesCount - nukesPlannedCount;

  const nukeDestructionPercent =
    event.extensions.worldDominationConfig.attacks.attackCityByNuke
      .nukeDestructionPercent / 10000;

  let hintText = "";
  if (amount + nukesPlannedCount > missileSiloCount - nukesCount)
    hintText = "Insufficient Space";
  if (price * amount > balance) hintText = "Insufficient Uranium";
  if (
    price * amount > userRestrictions.queueRestrictions.uranium.limit &&
    !userRestrictions.queueRestrictions.uranium.unlimited
  )
    hintText = "The total cost is over your build limit";
  if (!["LEADER", "OFFICER"].includes(info.role))
    hintText = "You Must be a Clan Leader, or Officer in order to Build";

  const handleOrder = async () => {
    setStatus("pending");
    const resp = (await addToQueue({
      type: "nuke",
      amount,
      turnId: turn.turn.turnId,
      currency,
      requestId,
    })) as any;
    if (resp?.error) {
      props.onClose();
      dispatch(
        addErrorMessage({ title: "Error", text: "Something went wrong" })
      );
    }
    refetchTurn();
  };

  const handleRemove = async () => {
    setStatus("pending");
    const ids = queue.nuke.map((i) => i.id);
    const data = (await removeFromQueue({
      turnId: turn.turn.turnId,
      itemIds: ids,
    })) as any;
    if (data?.error) {
      props.onClose();
      dispatch(
        addErrorMessage({ title: "Error", text: "Something went wrong" })
      );
    }
    setStatus("idle");
    refetchTurn();
  };

  if (status === "idle" || status === "pending")
    return (
      <>
        <s.Close onClick={() => props.onClose()}>
          <img src={closeIcon} />
        </s.Close>
        <s.ContentNuke>
          <s.InfoNuke>
            <s.Img src={image} />
            <s.InfoContent>
              <s.Title>Nuke Construction</s.Title>
              <Gap h={9} />
              <s.DescriptionTitle>
                Each Nuke provides the following:
              </s.DescriptionTitle>
              <s.DescriptionNuke>
                <li>Ability to Attack an Enemy City</li>
                <li>
                  Ability to destroy up to{" "}
                  {toFixedWithoutZeros(nukeDestructionPercent * 100)}% of the
                  available points for each successful Nuke that gets through
                  the City Shields
                </li>
                <li>
                  Ability to steal{" "}
                  {toFixedWithoutZeros(nukeVampirePercent * 100)}% of the City’s
                  score points,
                  <br />
                  and reduce its population by 10, if your attack
                  <br />
                  is successful
                </li>
              </s.DescriptionNuke>
              <Box flex="1 1 auto" />
              <s.Hint>
                Nukes can not be destroyed by enemy attacks, but can be blocked
                by the Iron Dome or City Shields
              </s.Hint>
            </s.InfoContent>
          </s.InfoNuke>
          <HorizontalLine />
          <s.OrderBlock>
            <s.CurrentNuke>
              <div>Current Quantity:</div>
              <div className="count">{nukesCount}</div>
              <div>Cost:</div>
              <Icon src={uranium} height={18} />
              <div className="cost">{numberWithCommas(price)} Uranium</div>
            </s.CurrentNuke>
            <s.NukeBuild>
              <s.Stats>
                <Box>
                  <s.SiloCapacity>Silo Capacity:</s.SiloCapacity>
                  <s.SiloCapacityCount>
                    {missileSiloCount === 0 && (
                      <Text color="#EC2E00" w={400} s={16}>
                        0
                      </Text>
                    )}
                    {missileSiloCount > 0 && nukesCount < missileSiloCount && (
                      <Text green w={400} s={16}>
                        {nukesCount}/{missileSiloCount}
                      </Text>
                    )}
                    {missileSiloCount > 0 && nukesCount === missileSiloCount && (
                      <Text color="#EC2E00" w={400} s={16}>
                        {nukesCount}/{missileSiloCount}
                      </Text>
                    )}
                    {missileSiloCount > 0 && nukesCount > missileSiloCount && (
                      <Text color="#EC2E00" w={400} s={16}>
                        {nukesCount}/{missileSiloCount}
                      </Text>
                    )}
                  </s.SiloCapacityCount>
                </Box>
                <Box>
                  <s.SiloPlanned>
                    Planned for Next Turn:
                    {queue.nuke.length > 0 && (
                      <s.Remove onClick={handleRemove} />
                    )}
                  </s.SiloPlanned>
                  <s.PlannedCount count={nukesPlannedCount}></s.PlannedCount>
                </Box>
              </s.Stats>
              <Box flex="0 0 93px">Quantity:</Box>
              <QuantityCounterFlex
                amount={amount}
                setAmount={setAmount}
                max={maxNukes}
              />
            </s.NukeBuild>
          </s.OrderBlock>
          <Box w={278} mt={42} mb={10} alignSelf="center">
            <GreenButton
              h={60}
              onClick={handleOrder}
              disabled={
                !!hintText ||
                typeof amount !== "number" ||
                amount === 0 ||
                amount > 100
              }
              loading={status === "pending"}
            >
              Build
            </GreenButton>
          </Box>
          <s.ButtonHint>{hintText}</s.ButtonHint>
        </s.ContentNuke>
      </>
    );
  if (status === "done")
    return (
      <s.Content>
        <Close onClose={props.onClose} />
        <Gap h={28} />
        <Img src={image} w={160} center />
        <Gap h={12} />
        <Text s={22} center>
          You Built {pluralize("Nuke", amount, true)}
          <br />
          for <Img src={uranium} w={20} inline mb={-2} />{" "}
          {numberWithCommas(amount * price)} uranium!
        </Text>
        <Gap h={10} />
        <Text grey center w={350}>
          Clan Uranium Balance: {numberWithCommas(balance)}
        </Text>
        <Gap h={38} />
        <GreenButton onClick={() => props.onClose()} h={60} w={276} center>
          <Img src={whiteCheck} /> Done!
        </GreenButton>
      </s.Content>
    );
  return null;
}
