import * as s from "components/Profile/styled";
import kills from "assets/lastKills.svg";
import deaths from "assets/lastDeaths.svg";
import headshots from "assets/lastHeadshots.svg";
import rank from "assets/lastRank.svg";
import exp from "assets/lastExp.svg";
import { useIsMobile } from "hooks/useMediaQuery";

export function LastMatch({ lastMatch }: { lastMatch: IMatchHistory }) {
  const isMobile = useIsMobile();
  if (!lastMatch) return null;
  return (
    <s.Overall>
      <s.OverallHeader>LAST MATCH</s.OverallHeader>
      <s.OverallContent>
        <s.OverallBlock>
          <img src={kills} alt="kills" />
          <s.BlockCaption>Kills</s.BlockCaption>
          <s.BlockValue>{lastMatch.kills}</s.BlockValue>
        </s.OverallBlock>
        <s.VerticalLine />
        <s.OverallBlock>
          <img src={deaths} alt="deaths" />
          <s.BlockCaption>Deaths</s.BlockCaption>
          <s.BlockValue>{lastMatch.deaths}</s.BlockValue>
        </s.OverallBlock>
        {isMobile ? <s.HorizontalLine /> : <s.VerticalLine />}
        <s.OverallBlock>
          <img src={headshots} alt="headshots" />
          <s.BlockCaption>Headshots</s.BlockCaption>
          <s.BlockValue>{lastMatch.headshots}</s.BlockValue>
        </s.OverallBlock>
        {isMobile ? <s.VerticalLine /> : <s.HorizontalLine />}
        <s.OverallBlock>
          <img src={rank} alt="rank" />
          <s.BlockCaption>Rank</s.BlockCaption>
          <s.BlockValue>{lastMatch.rank}</s.BlockValue>
        </s.OverallBlock>
        {isMobile ? <s.HorizontalLine /> : <s.VerticalLine />}
        <s.OverallBlock>
          <img src={exp} alt="exp" />
          <s.BlockCaption>Experience</s.BlockCaption>
          <s.BlockValue>{lastMatch.experience}</s.BlockValue>
        </s.OverallBlock>
      </s.OverallContent>
    </s.Overall>
  );
}
