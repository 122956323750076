import { RedButton as GBWrap } from "components/Staking/StakeForEventPoints/Grid/Grid.styled";
import styled from "styled-components/macro";

export default function RedButton({
  children,
  disabled,
  h,
  loading,
  w,
  onClick,
  center,
}: {
  h?: number;
  w?: number;
  disabled?: boolean;
  children: React.ReactNode;
  loading?: boolean;
  onClick?: any;
  center?: boolean;
}) {
  return (
    <GBWrap
      w={w}
      h={h}
      disabled={disabled}
      onClick={!loading && onClick ? onClick : () => {}}
      $loading={loading}
    >
      {loading ? <Spinner /> : children}
    </GBWrap>
  );
}


export const Spinner = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%,#fefefe);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 5px),#000 0);
  animation: spinner-zp9dbg 1s infinite linear;
}

@keyframes spinner-zp9dbg {
  to {
    transform: rotate(1turn);
  }
`;
