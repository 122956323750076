import styled from "styled-components/macro";
import Arrow from 'assets/arrow';

export const Container = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        margin-top: 6px
  }
`;

export const LeftArrow = styled(Arrow)`
    padding: 17px 14px;
    background-color:#373737;
    box-sizing: content-box;
    cursor: pointer;
`;

export const RightArrow = styled(Arrow)`
    padding: 17px 14px;
    display: inline-block;
    background-color:#FFB60C;
    box-sizing: content-box;
    transform: rotate(180deg);
    cursor: pointer;
`;

export const NumberContiner = styled.input`
    padding: 13.5px;
    text-align: center;
    color: #fff;
    background-color: #171717;
    border: 2px solid #373737;
    width: 96px;
    margin: 0 2px;
`;