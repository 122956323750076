import { getAccountDetails, idp_GetAccount } from "api";
import { useSwrImmutable } from "hooks/useSwr";

export const useMyProfile = () => {
  const [auth] = useSwrImmutable("auth", idp_GetAccount);
  const [user] = useSwrImmutable(
    auth?.userId?.value ? [auth?.userId?.value, "user"] : null,
    getAccountDetails
  );
  return user;
};