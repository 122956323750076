import { useEffect, useState } from 'react';
import {
    Wrapper,
    Title, 
    StyleSuccesIcon,
    SubTitle,
    RecipientWrapper, 
    RecipientContainer, 
    GradientContainer,
    Border, WeaponContainer, 
    ButtonContainer, ConfirmBtn,
    RejectBtn,
    Content,
    StyledQuantity,
    modalStyles,
    WeaponItem,
    MoadlQuantityItem,
    ModalCloseBtn,
    SpinnerContainer,
} from 'components/Transfer/TransferModal/transferModal.style';
import Modal from "react-modal";
import { QuantityCounter } from 'components/QuantityCounter/quantityCounter';
import { useIsMobile } from "hooks/useMediaQuery";
import { sendTransaction, getAccounts } from 'api';
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from 'components/Spinner';
import { IModalProps, IgameData } from 'types/transferTypes';
import { TokenImage } from "components/TieredLeaderboard/componetns/TokenImage";
import { NameField } from 'components/Transfer/Currencies/Currencies.style';


export const TransferModal = ({open, setOpen, recipient, currentItem, setGdImage, setUpdate, setGdname }: IModalProps) => {
    const [isSuccess, setSuccess] = useState(false)
    const isMobile = useIsMobile()
    const [amount, setAmount] = useState(1)
    const [isLoading, setLoading] = useState(false)
    const [currentUser, setUser] = useState([] as any)
    console.log("AMOINT", amount)
    let reqId = uuidv4();
    useEffect(() => {
        recipient && getAccounts([recipient?.id]).then((res) => {
            setUser(res[0])})
    }, [recipient])

    const handleClose = () => {
        setSuccess(false)
        setOpen(false)
        setAmount(1)
    }

    const handleSubmit = () => {
        setLoading(true)
        sendTransaction(amount, recipient?.id, currentItem, reqId)
        .then(() => {
            reqId = uuidv4();
            setSuccess(true)
            setUpdate(uuidv4())
            setLoading(false)
        })
        .catch((error: Error) => console.log(error.message === 'duplicate', 'операция уже совершена', error))
    }

    const setStartAmount = () => {
        if((currentItem as IgameData)?.item?.itemId) return 1
        else return (currentItem as IgameData)?.total || currentItem as number
    }

    return (
        <Modal 
        style={modalStyles}
        isOpen={open}
        shouldCloseOnEsc
        preventScroll
        onRequestClose={handleClose}
        >
    <Wrapper>
        <Content>
        <Title isSuccess={isSuccess}>
            {isSuccess && <StyleSuccesIcon/>}
            Transfer Confirmation
        </Title>
        <ModalCloseBtn onClick={handleClose} />
       {!isSuccess && <SubTitle>Please select the quantity of the item that you want to send</SubTitle>}
        <RecipientContainer>
            Recipient:<RecipientWrapper>{recipient?.name}<div style={{ opacity: '0.7'}}>#{currentUser?.suffix}</div></RecipientWrapper>
        </RecipientContainer>
        {!isMobile && <GradientContainer isSuccess={isSuccess}></GradientContainer>}
        {!isMobile && <Border />}
        <WeaponContainer>
            <WeaponItem>
            <TokenImage info={currentItem} setItemImage={setGdImage} />
            <div>
                <NameField>{setGdname(currentItem as IgameData)}</NameField>
            </div>
            </WeaponItem>
            <MoadlQuantityItem>
                Quantity:
                {isSuccess ? 
                    <StyledQuantity>{amount}</StyledQuantity> : 
                    <QuantityCounter amount={setStartAmount()} setAmount={setAmount} 
                />}
            </MoadlQuantityItem>
        </WeaponContainer>
        </Content>
        {!isSuccess && <ButtonContainer>
            <RejectBtn onClick={handleClose}>Cancel</RejectBtn>
            <ConfirmBtn onClick={() => setStartAmount() && handleSubmit()}>Confirm & Send</ConfirmBtn>
        </ButtonContainer>}
        {isLoading && <SpinnerContainer><Spinner /></SpinnerContainer>}
    </Wrapper>
    </Modal>
    )
}