import * as s from "components/Staking/StakeForEventPoints/StakePage/StakeItemPage.styled";
import { useParams } from "react-router";
import { selectSlots } from "redux/slices/eventPointsSlice";
import { useAppSelector } from "store";
import infoGrey from "./assets/infoGrey.png";
import sol from "./assets/sol.png";

export function Info() {
  const { set, slot } = useParams();
  const sets = useAppSelector(selectSlots);
  const setNumber = sets.findIndex((i) => i.id === set);

  return (
    <s.InfoWrap>
      <s.RightTitle>
        Staking Slot {+slot! + 1} in set {setNumber + 1}
      </s.RightTitle>
      <s.Info>
        <img src={infoGrey} />
        <div>
          Connect your web3 wallet to turn items into NFTs and make them
          tradable
        </div>
        <img src={infoGrey} />
        <div>
          Blockchain commission fee for each item is <span>0.02 SOL</span>{" "}
          <img src={sol} className="sol" />
        </div>
        <img src={infoGrey} />
        <div>
          Staking can take up to <span>10 minutes</span>
        </div>
      </s.Info>
    </s.InfoWrap>
  );
}
