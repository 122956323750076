import styled from "styled-components/macro";

import search from "../assets/search.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  background: #1F1F1F url(${search}) no-repeat scroll 9px 10px;
  padding-left: 36px;
  font-weight: 350;
  font-size: 14px;
  color: #838383;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  height: 41px;
  width: 403px;
  &:focus-visible {
    outline: none;
  }
`;

export const Text = styled.div`
  font-weight: 350;
  font-size: 14px;
  color: #FFFFFF;
  margin-right: 6px;
`;

export const Button = styled.div<{active:boolean}>`
  padding: 12px 17px;
  font-weight: 350;
  font-size: 14px;
  color: ${({active})=> active ? '#FFB60C' : '#838383'};
  background: #000000;
  border: 1px solid ${({active})=> active ? '#FFB60C' : '#838383'};
  border-radius: 4px;
  margin-left: 6px;
  cursor: pointer;
`;




