import { useWallet } from "@solana/wallet-adapter-react";
import { staking_getSlotId, staking_setSlotStatus } from "api";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import { GridCtx } from "components/Staking/StakeForEventPoints/Grid/Grid";
import {
  GreenButton,
  RedButton,
} from "components/Staking/StakeForEventPoints/Grid/Grid.styled";
import { modalStyles } from "components/Staking/StakeForEventPoints/Grid/UnlockModal/UnlockModal";
import { WalletButton } from "components/WalletButton";
import React, { useContext } from "react";
import Modal from "react-modal";
import { fetchSlots } from "redux/slices/eventPointsSlice";
import { addErrorMessage } from "redux/slices/messageSystemSlice";
import { unStakeItem } from "stakeItemApi/stakeItemApi";
import { useAppDispatch } from "store";
import { ItemView } from "../ItemView";
import * as s from "./UnstakeModal.styled";

export function Unstake() {
  const wallet = useWallet();
  const dispatch = useAppDispatch();
  const [context, setField] = useContext(GridCtx);
  const handleClose = () => setField("showUnstakeModal", false);
  // Тут запрос на анстейк
  const item = context.selectedUnstakeItem!;
  const set = context.selectedUnstakeSet!;
  const slot = context.selectedUnstakeSlot!;
  const unstakeId = context.selectedUnstakeId!;

  // Запрос на анстейкинг
  const handleUnstake = async () => {
    console.log(`item: `, item);
    setField("selectedUnstakeStatus", "pending");
    try {
      const { slotId } = await staking_getSlotId(item.id, set, slot);
      console.log("unstake_slotId", slotId);
      const tx = await unStakeItem(wallet, slotId);
      console.warn("empty transaction");
      if (!tx) throw "empty transaction";
      await staking_setSlotStatus(unstakeId, tx, "unstaking");
      dispatch(fetchSlots());
    } catch (e: any) {
      if (e?.error?.message === "User rejected the request.") {
        dispatch(
          addErrorMessage({
            title: "Staking canceled",
            text: "The transaction was canceled by user",
          })
        );
      } else {
        dispatch(
          addErrorMessage({
            text: "An error occurred during the staking. Please, reload page and try again.",
          })
        );
      }
    } finally {
      setField("selectedUnstakeStatus", "idle");
      setField("showUnstakeModal", false);
    }
  };

  return (
    <Modal
      isOpen={context.showUnstakeModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      preventScroll
      onRequestClose={handleClose}
      style={modalStyles}
    >
      {wallet.connected ? (
        <s.Unstake>
          <img src={closeIcon} onClick={handleClose} />
          <div className="title">Unstake confirmation</div>
          <div className="titleHelp">
            Are you sure you want to unstake this item?
          </div>
          <ItemView
            className="item"
            currentItem={context.selectedUnstakeItem as any}
          />
          <div className="buttons">
            <RedButton onClick={handleClose}>Cancel</RedButton>
            <GreenButton onClick={handleUnstake}>Confirm</GreenButton>
          </div>
        </s.Unstake>
      ) : (
        <WalletButton />
      )}
    </Modal>
  );
}
