import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import Text from "components/Text";
import * as s from "pages/WorldDomination/DefenseOffenceSummary/Summary.styled";
import { WDModalStyles } from "pages/WorldDomination/modalStyles";
import React from "react";
import Modal from "react-modal";
import {
  useGetClanAssetsQuery,
  useGetEventQuery,
  useGetEventTurnQuery,
} from "redux/rtkQuery/rtkApi";

type CityItem = {
  cityName: string;
  strikesRepelled: number;
  strikesLost: number;
  pointsLost: number;
};
type GridBlock = { turn: number; cities: CityItem[] };

export function DefenseSummary({ onClose }: { onClose: any }) {
  const { data: event } = useGetEventQuery();
  const { data: turn } = useGetEventTurnQuery(event?.id ?? skipToken);
  const { data: assets } = useGetClanAssetsQuery(event?.id ?? skipToken);
  if (!turn) return null;

  const cities = turn.clan.slotsList.filter((i) => i.kind === 2);

  let grid: GridBlock[] = turn.battleSummaryList.reduce(
    (acc, summaryItem) => {
      let gridBlock = {} as GridBlock;

      if (summaryItem.defence.citiesList.length > 0) {
        gridBlock.turn = summaryItem.round;
        gridBlock.cities = [];
        for (let city of cities) {
          const record = summaryItem.defence.citiesList.find(
            (c) => c.cityId === city.id
          );
          let cityItem: CityItem = {
            cityName: city.name,
            strikesRepelled: record?.failedNukes || 0,
            strikesLost: record?.successfulNukes || 0,
            pointsLost: record?.damagePoints || 0,
          };
          gridBlock.cities.push(cityItem);
        }
        return acc.concat(gridBlock);
      } else return acc;
    },
    [] as GridBlock[]
  );
  grid = grid.sort((a,b) => b.turn - a.turn)
  const ironDomeCount = assets?.ironDomesList?.length || 0;
  
  return (
    <Modal
      isOpen
      style={WDModalStyles}
      shouldCloseOnEsc
      preventScroll={true}
      onRequestClose={onClose}
    >
      <s.Content>
        <s.Close onClick={onClose}>
          <img src={closeIcon} />
        </s.Close>
        <Text s={22} mb={6}>
          Defense Summary
        </Text>
        <Text s={16} grey mb={33}>
          Iron Dome: {ironDomeCount ? "Built": "Not Built"}
        </Text>
        <s.Scroll>
          {grid.map((gridBlock) => (
            <s.DefenseGrid key={gridBlock.turn}>
              <s.Yellow>
                Turn {gridBlock.turn} {turn.round-1===gridBlock.turn && "(Last Turn)"}
              </s.Yellow>
              <s.White>Inbound Strikes Repelled</s.White>
              <s.White>Failed Inbound Strikes</s.White>
              <s.White>Points Lost</s.White>
              {gridBlock.cities.map((city, idx) => (
                <React.Fragment key={idx}>
                  <s.CityName>{city.cityName}</s.CityName>
                  <s.Green val={city.strikesRepelled} />
                  <s.Red val={city.strikesLost} />
                  <s.PointsLost val={city.pointsLost} />
                </React.Fragment>
              ))}
            </s.DefenseGrid>
          ))}
        </s.Scroll>
      </s.Content>
    </Modal>
  );
}
