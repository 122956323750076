import * as s from "components/TopThree/styled";
import {
  SkeletonCircle,
  SkeletonRect,
  SkeletonIcon,
} from "components/Skeleton/index";

export function TopThreeCardSkeleton({ rank }: { rank: number }) {
  return (
    <s.Card rank={rank}>
      <s.CardHeaderRank rank={rank} />
      <s.CardHeader>
        <SkeletonRect w={200} h={15} />
      </s.CardHeader>

      <s.CardContent>
        <s.ScoreAndIcon>
          <s.ScoreAndIconInner>
            <SkeletonIcon />
            <s.ScoreWrapper>
              <SkeletonRect w={120} h={20} mt={12} />
              <SkeletonRect w={90} h={12} mt={6} />
            </s.ScoreWrapper>
          </s.ScoreAndIconInner>
        </s.ScoreAndIcon>
        <s.Separator />
        <s.Stats>
          <s.StatsItem>
            <SkeletonCircle r={12} ml={10} mr={4} />
            <SkeletonRect w={40} h={8} />
          </s.StatsItem>
          <s.StatsItem>
            <SkeletonCircle r={12} ml={10} mr={4} />
            <SkeletonRect w={40} h={8} />
          </s.StatsItem>
          <s.StatsItem fullWidth>
            <SkeletonCircle r={12} ml={10} mr={4} />
            <SkeletonRect w={40} h={8} />
          </s.StatsItem>
        </s.Stats>
      </s.CardContent>
    </s.Card>
  );
}
