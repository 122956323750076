import { ChangeEvent } from "react";
import styled from "styled-components/macro";

export function NumberInput({
  h = 50,
  value,
  w,
  setValue,
  undefinedText = "",
}: {
  value?: number;
  w?: number;
  h?: number;
  setValue: any;
  undefinedText?: string;
}) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      undefinedText !== "" &&
      e.target.value.includes(undefinedText) &&
      !isNaN(+e.target.value[3])
    ) {
      setValue(+e.target.value[3]);
    } else if (e.target.value === "") {
      setValue(undefined);
    } else if (+e.target.value < 0 || isNaN(+e.target.value)) {
      return;
    } else {
      setValue(+e.target.value);
    }
  };
  return (
    <Wrapper
      w={w}
      h={h}
      onChange={handleChange}
      value={value !== undefined ? value : undefinedText}
    ></Wrapper>
  );
}

export const Wrapper = styled.input<{
  w?: number;
  h?: number;
  value?: string | number;
}>`
  font-family: "Forza";
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #171717;
  border: 2px solid #373737;
  border-radius: 3px;
  color: ${({ value }) => (typeof value === "number" ? "#fff" : "#454545")};
  width: ${({ w }) => (w ? w + "px" : "100%")};
  height: ${({ h }) => h + "px"};
  &:focus-visible {
    outline: none;
    border-color: white;
  }
`;
