import * as s from "components/Header/styled";
import { useMediaQuery } from "react-responsive";

type IMenuItem = {
  text: string;
  active: boolean;
  img: string;
  activeImg: string;
  onClick: () => void;
  handleClose?: () => void;
};

export function MenuItem({
  active,
  img,
  activeImg,
  onClick,
  text,
  handleClose,
}: IMenuItem) {
  const isMobile = useMediaQuery({ maxWidth: 1170 });
  const handleClickMob = () => {
    onClick();
    if (handleClose) handleClose();
  };
  return isMobile ? (
    <s.MobMenuItemBg active={active}>
      <s.MobMenuItem active={active} onClick={handleClickMob}>
        <img src={active ? activeImg : img} alt={text} />
        <div>{text}</div>
      </s.MobMenuItem>
    </s.MobMenuItemBg>
  ) : (
    <s.MenuItemBg active={active}>
      <s.MenuItem active={active} onClick={onClick}>
        <s.MenuImg src={active ? activeImg : img} />
        <div>{text}</div>
      </s.MenuItem>
    </s.MenuItemBg>
  );
}
