import butterImg from "assets/butter_icon.png";
import bpIcon from "components/TieredLeaderboard/assets/bp.png";
import { RewardItem } from "components/TieredLeaderboard/componetns/RewardItem";
import { IItemsNormalized, IRewardItem } from "../../../api/api.types";
import { RewardToken } from "./RewardToken";
import * as s from "./Tablet.styled";

export function TabletRewards(props: {
  rewards: IRewardItem[];
  items: IItemsNormalized;
}) {
  const butter = props.rewards.find((item) => item.butter)?.butter?.amount || 0;
  const battlePassPoints =
    props.rewards.find((item) => item.battlePassPoints)?.battlePassPoints
      ?.amount || 0;
  const inventory = props.rewards.filter((item) => item.inventory);
  const tokens = props.rewards.filter((item) => item.token);
  return (
    <s.Rewards>
      <div>
        <div>
          <img src={butterImg} alt="butter" width={24} />
          {butter}
        </div>
        <div>
          <img src={bpIcon} alt="bp" />
          {battlePassPoints}
        </div>
      </div>
      <div>
        {inventory.map((item) => (
          <RewardItem
            item={item.inventory!}
            items={props.items}
            key={item.inventory?.itemId!}
          />
        ))}
        {tokens.map((item) => (
          <RewardToken token={item.token!} key={item.token!.configId} />
        ))}
      </div>
    </s.Rewards>
  );
}
