import styled from "styled-components/macro";

export const Wrapper = styled.div`
  margin: 20px;

  @media (min-width: 768px) {
    margin: 24px;
  }

  @media (min-width: 1280px) {
    max-width: 1170px;
    margin: 30px auto;
  }
`;
