export const WDModalStyles:any = {
  content: {
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    overflow: "visible",
    scrollbarWidth: "none",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: 0,
    background: "black",
    border: "1px solid #404040",
    boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.8)",
  },
  overlay: {
    zIndex: 30,
    backgroundColor: "rgba(16,16,16,0.8)",
  },
};