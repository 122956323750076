import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import * as s from "pages/WorldDomination/WDStatsPanel/ConstructionModal.styled";

export function Close(props: { onClose: any }) {
  return (
    <s.Close onClick={() => props.onClose()}>
      <img src={closeIcon} />
    </s.Close>
  );
}
