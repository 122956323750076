import styled from "styled-components/macro";
import { ContainerBlock, WeaponConteinerItem, WeaponQuantityItem } from 'components/Transfer/Currencies/Currencies.style';
import { TransferBtn, CloseBtn } from "components/Transfer/ClanMembers/ClanMembers.style";
// import { CloseBtn } from "components/Transfer/ClanMembers/ClanMembers.style";
import SuccessIcon from "assets/successIcon";


export const Wrapper = styled.div`
    position: relative;
    background-color: #000;
    max-width: 583px;
    margin: 258px auto 0;
    z-index: 100000;

    @media (max-width: 768px) {
        margin-top: 20px;
  }
`;

export const ModalCloseBtn = styled(CloseBtn)`
    position: absolute;
    right: 15px;
    top: 15px;
`;

export const MoadlQuantityItem = styled(WeaponQuantityItem)`
    flex-direction: column;
    font-size: 16px;
    justify-content: center;
`;

export const WeaponItem = styled(WeaponConteinerItem)`

@media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 7px;
        font-size: 16px;

        & > img {
            margin-right: 0;
            margin-bottom: 8px;
        }
        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &:first-child {
            margin-bottom: 14px;
        }
    }
`;

export const Title = styled.div<{ isSuccess: boolean }>`
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 5px;
    color: white;
    display: flex;
    align-items: center;
    color: ${({ isSuccess }) => isSuccess && '#59B015'};
    margin-bottom: ${({ isSuccess }) => isSuccess && '16px'};

    @media (max-width: 768px) {
        margin: 0 60px;
        text-align: center;
  }
`;

export const SubTitle = styled.div`
    text-align: center;
    font-weight: 350;
    color: #838383;
    margin-bottom: 16px;
`;

export const RecipientWrapper = styled.div`
    display: inline-flex;
    width: 100%;
    padding: 17px 10px 17px 33px;
    background-color: #282828;
    border: 2px solid #282828;
    max-width: 360px;
    margin-left: 10px;

    @media (max-width: 768px) {
        justify-content: center;
        margin-top: 6px;
        margin-left: 0;
        padding-left: 10px;
  }
`;

export const RecipientContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 13px;
  }
`;

export const GradientContainer = styled.div<{ isSuccess: boolean }>`
    height: 60px;
    width: 100%;
    background: radial-gradient(46.54% 100% at 50% 100%, #FFFFFF 0.52%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.2;
    position: absolute;
    top: ${({ isSuccess }) => isSuccess ? '128px' : '120px'};
`;

export const Border = styled.div`
    height: 1px;
    width: 500px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 47.92%, rgba(255, 255, 255, 0) 100%); 
    margin-top: 27px;
    opacity: 0.2;
    margin-bottom: 22px;
`;

export const WeaponContainer = styled(ContainerBlock)`
    width: 100%;
    grid-template-columns: 5fr 3fr;
    margin-bottom: 37px;
    
    &:hover {
        cursor: text;
        background-color: #1F1F1F;
        border: 1px solid #151515;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-bottom: 0;
  }
`;

export const ButtonContainer = styled.div`  
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 2px solid #1A1A1A;
    padding: 10px;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        padding-top: 0;
        border-top: none;
  }
`;

export const ConfirmBtn = styled(TransferBtn)`
    width: 100%;
    padding: 20px;
    background: rgba(138, 199, 0, 0.4);
    border: 1px solid #8AC700;
    box-shadow: 0px 10px 22px rgba(138, 199, 0, 0.4);

    &:hover {
        background: #75A703;
        cursor: pointer;
    }

    &:active {
        background: rgba(138, 199, 0, 0.25);
    }
`;

export const RejectBtn = styled(TransferBtn)`
    width: 100%;
    padding: 20px;
    background: rgba(255, 0, 0, 0.4);
    border: 1px solid #FF0000;
    box-shadow: 0px 10px 22px rgba(255, 0, 0, 0.4);
    margin-right: 10px;

    &:hover {
        background: #FF0000;
        cursor: pointer;
    }

    :active {
        background: rgba(255, 0, 0, 0.25);
    }

    @media (max-width: 768px) {
        margin-right: 0;
        margin-top: 5px;
  }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 29px 29px 10px;
`;

export const StyleSuccesIcon = styled(SuccessIcon)`
    box-sizing: content-box;
    padding: 16.5px 15px;
    background-color: #59B015;
    border: 4px solid #244608;
    border-radius: 50%;
    margin-right: 17px;
    color: white;
`;

export const StyledQuantity = styled.div`
    color: #59B015;
    font-size: 20px;
`;

export const modalStyles = {
    content: {
        background: 'none',
        overflow: 'hidden',
        inset: 0,
        border: 'none',
        color: 'white',
    },
    overlay: {
        backgroundColor: 'none'
    },
  };

  export const SpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: #000;
    opacity: 0.4;
  `;