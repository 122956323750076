const css1 = 'background: #444; color: #bada55; padding: 2px; border-radius:2px';
const css2 = 'background: #cd6a6a; color: black; padding: 2px; border-radius:2px';
export const greenLog = (data: any, title: string = '') => {
  console.log(`%c${title}%o`, css1, data);
};

export const yellowLog = (data: any, title: string = '') => {
  console.log(`%c${title}%o`, css2, data);
};

export const redLog = (data: any, title: string = '') => {
  console.log(`%c${title}%o`, css2, data);
};

