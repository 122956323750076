import { clanevents_setRestrictions } from "api";
import { QueueRestrictions } from "api/api.types";
import Box from "components/Box";
import { Checkbox } from "components/Checkbox/Checkbox";
import GreenButton from "components/GreenButton";
import Img from "components/Img";
import { NumberInput } from "components/NumberInput/NumberInput";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import Text from "components/Text/Text";
import { WDModalStyles } from "pages/WorldDomination/modalStyles";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { addErrorMessage } from "redux/slices/messageSystemSlice";
import { useAppDispatch } from "store";
import styled from "styled-components/macro";
import bp from "../assets/buildPointsBigIcon.png";
import butter from "../assets/butterBigIcon.png";
import uranium from "../assets/uraniumBigIcon.png";
import * as s from "./BuyUraniumModal.styled";

const max = 999999999;

export function OfficerLimitsModal({ onClose,restrictions,refetch }: { onClose: any,restrictions?:QueueRestrictions,refetch:any }) {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<"idle" | "done" | "pending">("idle");
  const [buildPointsLimit, setBuildPointsLimit] = useState<number>();
  const [butterLimit, setButterLimit] = useState<number >();
  const [uraniumLimit, setUraniumLimit] = useState<number >();
  const [canAttack, setCanAttack] = useState(false);

  const handleSave = () => {
    setStatus('pending')
    clanevents_setRestrictions({
      buildPointsLimit,
      uraniumLimit,
      butterLimit,
      canAttack,
    })
      .then(() => {
        onClose();
        refetch()
        dispatch(
          addErrorMessage({ title: "Success", text: "Limits successfully set" })
        );
        
      })
      .catch(() => {
        onClose();
        dispatch(
          addErrorMessage({ title: "Error", text: "Something went wrong" })
        );
      });
  };

  useEffect(()=>{
    if(!restrictions) return;
    setCanAttack(restrictions.canAttack)
    if(restrictions.uranium.unlimited) setUraniumLimit(undefined)
    else setUraniumLimit(restrictions.uranium.limit)
    
    if(restrictions.buildpoints.unlimited) setBuildPointsLimit(undefined)
    else setBuildPointsLimit(restrictions.buildpoints.limit)
  
    if(restrictions.butter.unlimited) setButterLimit(undefined)
    else setButterLimit(restrictions.butter.limit)
    
  },[restrictions])

  return (
    <Modal
      isOpen
      style={WDModalStyles}
      shouldCloseOnEsc
      preventScroll
      onRequestClose={() => onClose()}
    >
      <Content>
        <s.Close onClick={onClose}>
          <img src={closeIcon} />
        </s.Close>
        <Text white w={400} s={22} center>
          Officer spend limit
        </Text>
        <Text grey w={350} s={16} mt={20} center>
          As a Clan Leader you are able to set limits on how much Butter, Build
          Points, and Uranium each Clan Officer is allowed to spend per turn
          during a World Domination event
        </Text>
        <Limits>
          <Text>Build Points Limit:</Text>
          <Text>Butter Limit:</Text>
          <Text>Uranium Limit:</Text>
          <Box gap={6} alignItems="center">
            <NumberInput
              w={116}
              value={buildPointsLimit}
              setValue={setBuildPointsLimit}
              undefinedText="MAX"
            />
            <Img src={bp} w={40} h={41} />
          </Box>
          <Box gap={6} alignItems="center">
            <NumberInput
              w={116}
              value={butterLimit}
              setValue={setButterLimit}
              undefinedText="MAX"
            />
            <Img src={butter} w={40} h={40} />
          </Box>
          <Box gap={6} alignItems="center">
            <NumberInput
              w={116}
              value={uraniumLimit}
              setValue={setUraniumLimit}
              undefinedText="MAX"
            />
            <Img src={uranium} w={40} h={41} />
          </Box>
        </Limits>
        <Box mt={30} mb={40} alignItems="center" gap={10}>
          <Checkbox
            onClick={() => setCanAttack(!canAttack)}
            checked={canAttack}
          />
          <Text>Allow Officers to Attack Cities</Text>
        </Box>
        <GreenButton
          w={276}
          h={60}
          loading={status === "pending" || undefined}
          onClick={handleSave}
        >
          Save
        </GreenButton>
      </Content>
    </Modal>
  );
}

export const Content = styled.div`
  width: 822px;
  height: 464px;
  padding: 50px 74px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Limits = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  row-gap: 11px;
  column-gap: 50px;
`;
