import styled, { css } from "styled-components/macro";

export default function GreenButton({
  children,
  disabled,
  h,
  loading,
  w,
  onClick,
  center,
}: {
  h?: number;
  w?: number;
  disabled?: boolean;
  children: React.ReactNode;
  loading?: boolean;
  onClick?: any;
  center?: boolean;
}) {
  return (
    <GBWrap
      center={center}
      w={w}
      h={h}
      disabled={disabled}
      onClick={!loading && onClick ? onClick : () => {}}
      $loading={loading}
    >
      {loading ? <Spinner /> : children}
    </GBWrap>
  );
}


export const Spinner = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%,#fefefe);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 5px),#000 0);
  animation: spinner-zp9dbg 1s infinite linear;
}

@keyframes spinner-zp9dbg {
  to {
    transform: rotate(1turn);
  }
`;


const Button = styled.button`
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  height: 43px;
  cursor: pointer;
`;

export const GBWrap = styled(Button)<{
  h?: number;
  w?: number;
  disabled?: boolean;
  $loading?: boolean;
  center?: boolean;
}>`
  ${({ center }) => center && "margin: 0 auto;"}
  white-space: break-spaces;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(138, 199, 0, 0.4);
  border: 1px solid #8ac700;
  box-shadow: 0px 10px 22px rgba(138, 199, 0, 0.4),
    0px 5px 10px 2px rgba(34, 60, 80, 0.3) inset;
  ${({ h }) => h && "height:" + h + "px"};
  ${({ w }) => w && "width:" + w + "px"};
  &:hover:not(:active) {
    background: #8ac700;
    box-shadow: 0px 10px 22px rgba(138, 199, 0, 0.4);
  }

  ${({ disabled }) =>
  disabled &&
  css`
      background: rgba(61, 61, 61, 0.4);
      border: 1px solid #464646;
      box-shadow: none;
      &:hover:not(:active) {
        box-shadow: none;
        background: rgba(61, 61, 61, 0.4);
        cursor: not-allowed;
      }
    `}
  ${({ $loading }) =>
  $loading &&
  css`
      &:hover:not(:active) {
        background: rgba(138, 199, 0, 0.4);
        cursor: initial;
      }
    `}
  ${({ $loading, disabled }) =>
  (!$loading && !disabled) &&
  css`
      &:active {
        box-shadow: 0px 10px 22px rgba(138, 199, 0, 0.4),
          0px 5px 16px 5px rgba(0, 0, 0, 0.3) inset;
      }
    `}
`;
