import styled, { css } from "styled-components/macro";

export const DrawerContent = styled.div<{ open: boolean }>`
  position: fixed;
  right: 0;
  width: 280px;
  top: 60px;
  bottom: 0;
  background: black;
  overflow: auto;
  transition: transform 0.3s ease;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(101%)")};
  z-index: 1000;
`;

export const DrawerBg = styled.div<{ open: boolean }>`
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease, visibility 0.3s ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;

  ${({ open }) =>
    open &&
    css`
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
      z-index: 999;
    `};
`;
