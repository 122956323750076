import checkbox from "assets/checkbox.svg";
import checkboxActive from "assets/checkboxActive.svg";
import filterArrow from "assets/filterArrow.svg";
import mobFilterIcon from "assets/mobFilterIcon.svg";
import styled from "styled-components/macro";

export const MobFilterElementWrap = styled.div``;
export const MobFilterHeader = styled.div`
  height: 40px;
  background: rgb(55, 55, 55);
  margin-top: 10px;
  clip-path: polygon(3% 0%, 100% 0%, 97% 100%, 0% 100%);
  padding: 0 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const MobFilterTitle = styled.div<{ open: boolean }>`
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${({ open }) => (open ? 1 : 0.75)};
`;

export const MobFilterArrow = styled.img.attrs({
  src: filterArrow,
})<{ open: boolean }>`
  opacity: ${({ open }) => (open ? 1 : 0.75)};
  ${({ open }) => open && "transform: rotate(180deg);"}
`;
// export const MobFilterInner = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   flex: 1;
// `

export const MobFilterContent = styled.div<{ open: boolean }>`
  height: ${({ open }) => (open ? "200px" : "0px")};
  transition: all 0.1s;
  overflow-y: scroll;
  padding: ${({ open }) => (open ? "10px 0" : "0")};
  background: #121212;
  margin-top: ${({ open }) => (open ? "13px" : "0px")};
  &::-webkit-scrollbar-thumb {
    background-color: rgb(255, 138, 3);
    border-radius: 41px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(61, 74, 87);
    border-radius: 41px;
  }

  &::backdrop {
    background-color: rgb(7, 17, 27);
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
`;
export const FilterContentItem = styled.div<{ selected: boolean }>`
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  span {
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
  }
  background: ${({ selected }) =>
    selected
      ? "linear-gradient(90deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0) 98.46%), radial-gradient(86.15% 1839.23% at 0% 59.38%, rgba(255, 128, 12, 0.05) 0%, rgba(255, 128, 12, 0) 100%)"
      : "none"};
`;
export const FilterContentItemCheckbox = styled.div<{ selected: boolean }>`
  width: 20px;
  height: 20px;
  background: url(${({ selected }) => (selected ? checkboxActive : checkbox)});
  margin-right: 10px;
`;

export const StatsSeparator = styled.div`
  grid-column: span 2;
  margin: 0 20px;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.15) 49.83%,
    rgba(255, 255, 255, 0.06) 100%
  );
`;

export const ModalInner = styled.div`
  padding: 20px;
`;

export const ModalTitle = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 15px;
`;

export const MobFilterWrapper = styled.div`
  //margin-top: 24px;
  //margin-bottom: 10px;
  width: 100%;
`;
export const MobTitle = styled.div`
  border: 1.2px solid;
  height: 32px;
  display: flex;
  width: 100%;
  transform: skewX(-10deg);
  background: rgba(255, 255, 255, 0.05);
  align-items: center;
  border-image: linear-gradient(
      89.16deg,
      rgba(255, 255, 255, 0.12) 2.23%,
      rgba(255, 255, 255, 0.32) 30.73%
    )
    1;
  justify-content: space-between;
  div {
    transform: skew(10deg);
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    opacity: 0.7;
    margin-left: 16px;
  }
`;

export const FilterIconMob = styled.img.attrs({
  src: mobFilterIcon,
})`
  transform: skew(10deg);
  height: 10px;
  margin-right: 11px;
`;
