import { IEvent, IItemsNormalized } from "api/api.types";
import { TabletTierItem } from "components/TieredLeaderboard/componetns/TabletTierItem";
import * as s from "./Tablet.styled";

export function TabletTable({
  clans,
  event,
  items,
}: {
  clans: IGetClanLeaderboard[];
  event: IEvent;
  items: IItemsNormalized;
}) {
  return (
    <div>
      <s.Header>
        <div>CLAN</div>
        <div>MEMBERS</div>
        <div>POINTS</div>
      </s.Header>
      {event.rewardsList.map((item) => (
        <TabletTierItem
          clans={clans}
          items={items}
          item={item}
          event={event}
          key={item.tier}
        />
      ))}
    </div>
  );
}

