import butter from "assets/butter_icon.png";
import uranium from "assets/uranium_icon.png";
import Box from "components/Box";
import Text from "components/Text";
import buildPoints from "../assets/buildPoints.png";
import cityShield from "../assets/cityShield.png";
import ironDome from "../assets/ironDome.png";
import missileSilo from "../assets/missileSilo.png";
import nuke from "../assets/nukes.png";
import oilRig from "../assets/oilRig.png";
import stable from "../assets/stable.svg";
import * as s from "./StatsPanel.styled";
import { Icon } from "./StatsPanel.styled";

export type IShowModal =
  | "oil_rig"
  | "missile_silo"
  | "nuke"
  | "iron_dome"
  | "city_shield"
  | null;

export function WDStatsPanelViewDisabled() {
  return (
    <s.Wrapper>
      <s.BlockTurn>
        <s.Yellow>Current Turn:</s.Yellow>
        <s.White>None</s.White>
        <s.Yellow>Turn Ends In:</s.Yellow>
        <s.White>None</s.White>
      </s.BlockTurn>
      <s.BlockPoints>
        <s.Yellow>Build Points:</s.Yellow>
        <s.White>
          <s.Icon src={buildPoints} />
          None
        </s.White>
        <s.Yellow> Butter:</s.Yellow>
        <s.White>
          <s.Icon src={butter} />
          None
        </s.White>
      </s.BlockPoints>
      <s.BlockMarket>
        <s.Yellow>Uranium Cost:</s.Yellow>
        <s.Blue>
          <Icon src={butter} width={20} />
          None
        </s.Blue>
        <s.MarketDirection>
          <img src={stable} width={45} />
          Global Prices Stable <br/> No Major Activity
        </s.MarketDirection>
        <s.Yellow>Uranium Balance:</s.Yellow>
        <s.Green>
          <Icon src={uranium} width={20} />
          None
        </s.Green>
      </s.BlockMarket>
      <s.BlockConstruction>
        <div>
          <s.Title>Construction:</s.Title>
          <s.HelpText>(click on each to build more)</s.HelpText>
          <Box flex="1" />
          {/*<Box*/}
          {/*  alignItems="center"*/}
          {/*>*/}
          {/*  <Img src={cogs} mr={5} />{" "}*/}
          {/*  <Text w={400} s={14} grey capitalize>*/}
          {/*    Set Limits*/}
          {/*  </Text>*/}
          {/*</Box>*/}
        </div>
        <div className="items">
          <div className="disabled">
            <img
              src={oilRig}
              width={108}
            ></img>
            <div className="name">OIL RIG</div>
            <Text grey s={20}>0</Text>
          </div>
          <div className="disabled">
            <img
              src={missileSilo}
              width={108}
            ></img>
            <div className="name">MISSILE SILO</div>
            <Text grey s={20}>0</Text>
          </div>
          <div className="disabled">
            <img
              src={nuke}
              width={108}
            ></img>
            <div className="name">NUKE</div>
            <Text grey s={20}>0</Text>
          </div>
          <div className="disabled">
            <img
              src={ironDome}
              width={108}
            ></img>
            <div className="name">IRON DOME</div>
            <Text grey s={20}>0</Text>
          </div>
          <div className="disabled">
            <img
              src={cityShield}
              width={108}
            ></img>
            <div className="name">CITY SHIELD</div>
            <Text grey s={20}>0</Text>
          </div>
        </div>
      </s.BlockConstruction>
      <s.Summary>
        <div className="title">Defense Summary:</div>
        <div className="content">
            <s.NoSummary />
        </div>
      </s.Summary>
      <s.Summary>
        <div className="title">Offence Summary:</div>
        <div className="content">
            <s.NoSummary />
        </div>
      </s.Summary>
    </s.Wrapper>
  );
}

