import { IRewardListEntry } from "api/api.types";

export const filterClansToTier = (
  item: IRewardListEntry,
  clan: IGetClanLeaderboard
) => {
  if (item) {
    return (
      clan.rank >= item.placeFromInclusive && clan.rank < item.placeToExclusive
    );
  }
};
