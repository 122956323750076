import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { ICurrency } from "api/api.types";
import Box from "components/Box";
import { Gap } from "components/Gap";
import GreenButton from "components/GreenButton";
import Img from "components/Img";
import { QuantityCounterFlex } from "components/QuantityCounterFlex/QuantityCounterFlex";
import { HorizontalLine } from "components/TableClans/CloseModal.styled";
import Text from "components/Text/Text";
import bp from "pages/WorldDomination/assets/build-points-icon.png";
import oilRig from "pages/WorldDomination/assets/oilRigBig.png";
import whiteCheck from "pages/WorldDomination/assets/whiteCheck.svg";
import { Close } from "pages/WorldDomination/WDStatsPanel/Close";
import * as s from "pages/WorldDomination/WDStatsPanel/ConstructionModal.styled";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import {
  useAddToQueueMutation,
  useGetClanAssetsQuery,
  useGetClanInfoQuery,
  useGetEventQuery,
  useGetEventTurnQuery,
  useGetRoundQueueQuery,
  useGetUserRestrictionsQuery,
  useRemoveFromQueueMutation,
} from "redux/rtkQuery/rtkApi";
import { addErrorMessage } from "redux/slices/messageSystemSlice";
import { selectStreamItemById2 } from "redux/slices/worldDominationSlice";
import { useAppDispatch, useAppSelector } from "store";
import { numberWithCommas } from "utils/numberWithComas";
import { toFixedWithoutZeros } from "utils/toFixedWithoutZeros";
import { v4 as uuidv4 } from "uuid";

export function OilRigModalContent(props: { onClose: Function }) {
  const dispatch = useAppDispatch();
  const { data: event } = useGetEventQuery();
  const { data: turn, refetch: refetchTurn } = useGetEventTurnQuery(
    event?.id ?? skipToken
  );
  const { data: queue } = useGetRoundQueueQuery();
  const [addToQueue] = useAddToQueueMutation();
  const [removeFromQueue] = useRemoveFromQueueMutation();
  const { data: assets } = useGetClanAssetsQuery(event?.id ?? skipToken);
  const { data: userRestrictions } = useGetUserRestrictionsQuery();
  const { data: info } = useGetClanInfoQuery();

  const [status, setStatus] = useState<IStatus>("idle");
  const [amount, setAmount] = useState(1);
  const [requestId, setRequestId] = useState(() => uuidv4());
  const transactionResult = useAppSelector(selectStreamItemById2(requestId));

  // Wait transaction to finish
  useEffect(() => {
    if (transactionResult === undefined) return;
    if (transactionResult) {
      if (transactionResult?.error) {
        if (transactionResult?.error?.code === 3) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Insufficient funds",
            })
          );
        } else if (transactionResult?.error?.code === 6) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Construction limit exceeded",
            })
          );
        } else if (transactionResult?.error?.code === 5) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Can not allocate space for construction",
            })
          );
        } else {
          dispatch(
            addErrorMessage({ title: "Error", text: "Something went wrong" })
          );
        }
        props.onClose();
      } else {
        setStatus("done");
        setRequestId(uuidv4());
      }
    }
  }, [transactionResult]);

  if (!event || !queue || !turn || !assets || !userRestrictions || !info)
    return null;

  const currency: ICurrency = "BUILD_POINT";
  const priceData = turn.market.productsList
    .find((i) => i.productId === "BUILD_OIL_RIG")!
    .pricesList.find((i) => i.currency === currency)!;
  const price = priceData.amount;

  const oilRigCount = assets.oilRigsList.length;
  const oilRigPlannedIds = queue.oilRig.map((i) => i.id);
  const oilRigPlannedCount = queue.oilRig.reduce((acc, i) => acc + i.count, 0);
  const passiveBonusPoints =
    event.extensions.worldDominationConfig.assets.oilRig.passiveBonusPoints;
  const activeBonusPercent =
    event.extensions.worldDominationConfig.assets.oilRig.activeBonusPercent /
    10000;

  const balance = turn.clan.balancesList.find(
    (i) => i.currency === currency
  )!.amount;

  let hintText = "";
  if (price * amount > balance) hintText = "Insufficient Build Points";
  if (
    price * amount > userRestrictions.queueRestrictions.buildpoints.limit &&
    !userRestrictions.queueRestrictions.buildpoints.unlimited
  )
    hintText = "The total cost is over your build limit";
  if (!["LEADER", "OFFICER"].includes(info.role))
    hintText = "You Must be a Clan Leader, or Officer in order to Build";

  const handleOrder = async () => {
    setStatus("pending");
    const data = (await addToQueue({
      type: "oilRig",
      amount,
      turnId: turn.turn.turnId,
      currency,
      requestId,
    })) as any;
    if (data?.error) {
      props.onClose();
      dispatch(
        addErrorMessage({ title: "Error", text: "Something went wrong" })
      );
    }
    refetchTurn();
  };

  const handleRemove = async () => {
    setStatus("pending");
    const data = (await removeFromQueue({
      turnId: turn.turn.turnId,
      itemIds: oilRigPlannedIds,
    })) as any;
    if (data?.error) {
      props.onClose();
      dispatch(
        addErrorMessage({ title: "Error", text: "Something went wrong" })
      );
    }
    refetchTurn();
    props.onClose();
  };

  //рендер
  if (status === "idle" || status === "pending")
    return (
      <s.Content>
        <Close onClose={props.onClose} />
        <s.Info>
          <s.Img src={oilRig} />
          <s.InfoContent>
            <s.Title>Oil Rig Construction</s.Title>
            <Gap h={16} />
            <s.DescriptionTitle>
              Each Oil Rig provides the following bonuses:
            </s.DescriptionTitle>
            <s.Description>
              <li>
                Passive: +{passiveBonusPoints} score points per turn
                <br />
                (but 0 build points)
              </li>
              <li>
                Active: +{toFixedWithoutZeros(activeBonusPercent * 100)}%
                military points bonus <br />
                on each Raid
              </li>
            </s.Description>
            <Box flex="1 1 auto" />
            <s.Hint>Oil Rigs cannot be destroyed by enemy attacks</s.Hint>
          </s.InfoContent>
        </s.Info>
        <HorizontalLine />
        <s.OrderBlock>
          <s.Current>
            <div>Current Quantity:</div>
            <div className="count">{oilRigCount}</div>
            <Box justifyContent="space-between" flex="1">
              <div>Cost:</div>

              <Text>
                <Box alignItems="center">
                  <img src={bp} height={20} />
                  {numberWithCommas(price)} Build Points
                </Box>
              </Text>
            </Box>
          </s.Current>
          <s.Planned>
            <div>
              Planned for Next Turn:
              {oilRigPlannedCount > 0 && <s.Remove onClick={handleRemove} />}
            </div>
            <s.PlannedCount className="count" count={oilRigPlannedCount} />
            <Box justifyContent="space-between" alignItems="center" flex="1">
              <div>Quantity:</div>
              <QuantityCounterFlex
                amount={amount}
                setAmount={setAmount}
                max={100}
              />
            </Box>
          </s.Planned>
        </s.OrderBlock>
        <Box w={278} mt={21} mb={10} alignSelf="center">
          <GreenButton
            h={60}
            onClick={handleOrder}
            disabled={
              !!hintText ||
              typeof amount !== "number" ||
              amount === 0 ||
              amount > 100
            }
            loading={status === "pending"}
          >
            Build
          </GreenButton>
        </Box>
        <s.ButtonHint>{hintText}</s.ButtonHint>
      </s.Content>
    );

  if (status === "done")
    return (
      <s.Content>
        <Close onClose={props.onClose} />
        <Gap h={28} />
        <Img src={oilRig} w={160} center />
        <Gap h={12} />
        <Text s={22} center>
          You Built {amount} Oil {pluralize("Rig", amount)}
          <br />
          for <Img src={bp} w={20} inline mb={-2} />{" "}
          {numberWithCommas(amount * price)} build point!
        </Text>
        <Gap h={10} />
        <Text grey center w={350}>
          Clan build Point Balance: {numberWithCommas(balance)}
        </Text>
        <Gap h={38} />
        <GreenButton onClick={props.onClose} h={60} w={276} center>
          <Img src={whiteCheck} /> Done!
        </GreenButton>
      </s.Content>
    );

  return null;
}
