import { IEvent, IItemsNormalized } from "api/api.types";
import { MobTierItem } from "components/TieredLeaderboard/componetns/MobTierItem";

export function MobTable({
  clans,
  event,
  items,
}: {
  clans: IGetClanLeaderboard[];
  event: IEvent;
  items: IItemsNormalized;
}) {
  return (
    <div>
      {event.rewardsList.map((item) => (
        <MobTierItem
          clans={clans}
          items={items}
          item={item}
          event={event}
          key={item.tier}
        />
      ))}
    </div>
  );
}

