import * as s from "components/Profile/styled";
import {
  SkeletonRect,
  SkeletonCircle,
  SkeletonHexagon,
} from "components/Skeleton/index";
import { useIsMobile } from "hooks/useMediaQuery";
import { HeadImg } from "components/TableScores/styled";

export default function ProfileSkeleton() {
  const isMobile = useIsMobile();
  const Row = isMobile ? s.GridRowMobile : s.GridRow;
  return (
    <s.ProfileWrapper>
      <s.UserBlockWrapper>
        <s.UserTop>
          <SkeletonCircle r={80} mt={10} mr={20} />
          <s.UserInfo>
            <s.UserName>
              <SkeletonRect w={132} h={15} mb={10} mt={20} />
            </s.UserName>
            <s.UserClan>
              <SkeletonRect w={90} h={10} />
            </s.UserClan>
            <s.UserOnline>
              <SkeletonRect w={90} h={10} />
            </s.UserOnline>
          </s.UserInfo>
          <s.Separator />
          <s.Level>
            <SkeletonHexagon w={80} h={80} />
          </s.Level>
        </s.UserTop>
        <s.HorizontalLine2 />
        <s.FollowWrapper>
          <div>
            <s.Followers>
              <SkeletonCircle r={20} mr={10} ml={10} />
              <SkeletonRect w={55} h={10} mr={70} />
              <SkeletonRect w={32} h={10} />
            </s.Followers>
            <s.Followers>
              <SkeletonCircle r={20} mr={10} ml={10} />
              <SkeletonRect w={55} h={10} mr={70} />
              <SkeletonRect w={32} h={10} />
            </s.Followers>
          </div>
          <s.Separator />
          <div>
            <s.FollowBtnWrapper>
              <SkeletonCircle r={20} mr={10} ml={10} />
              <SkeletonRect w={55} h={10} mr={20} />
            </s.FollowBtnWrapper>
            <s.ReportBtnWrapper>
              <SkeletonCircle r={20} mr={10} ml={10} />
              <SkeletonRect w={55} h={10} mr={20} />
            </s.ReportBtnWrapper>
          </div>
        </s.FollowWrapper>
      </s.UserBlockWrapper>
      <s.SmallBlock>
        <SkeletonCircle r={44} mb={13} />
        <SkeletonRect w={100} h={15} mb={8} />
        <SkeletonRect w={80} h={10} />
      </s.SmallBlock>
      <s.SmallBlock>
        <SkeletonCircle r={44} mb={13} />
        <SkeletonRect w={100} h={15} mb={8} />
        <SkeletonRect w={80} h={10} />
      </s.SmallBlock>
      <s.SmallBlock>
        <SkeletonCircle r={44} mb={13} />
        <SkeletonRect w={100} h={15} mb={8} />
        <SkeletonRect w={80} h={10} />
      </s.SmallBlock>
      <OverallSkeleton />
      <OverallSkeleton />
      <s.MhWrapper>
        <HeadImg />
        <s.BottomImg />
        <SkeletonRect w={130} h={15} ml={30} mt={22} mb={22} />
        {Array.from({ length: 10 }, (_, i) => (
          <Row $i={i} key={i}>
            <s.GridCell>
              <SkeletonRect as={"p"} w="50%" h={8} />
              <SkeletonRect h={12} w="80%" />
            </s.GridCell>
            {Array.from({ length: isMobile ? 0 : 5 }, (_, j) => (
              <s.GridCell key={j}>
                <SkeletonRect as={"p"} h={8} w="60%" />
                <SkeletonRect h={12} />
              </s.GridCell>
            ))}

            <s.GridCell>
              <SkeletonRect as={"p"} h={8} w="60%" />
              <SkeletonRect h={12} w="80%" />
            </s.GridCell>
          </Row>
        ))}
      </s.MhWrapper>
    </s.ProfileWrapper>
  );
}

function OverallSkeleton() {
  const isMobile = useIsMobile();
  return (
    <s.Overall>
      <s.OverallHeader>
        <SkeletonRect w={90} h={15} />
      </s.OverallHeader>
      <s.OverallContent>
        <s.OverallBlock>
          <SkeletonCircle r={24} />
          <s.BlockCaption>
            <SkeletonRect w={80} h={10} />
          </s.BlockCaption>
          <s.BlockValue>
            <SkeletonRect w={60} h={15} />
          </s.BlockValue>
        </s.OverallBlock>
        <s.VerticalLine />
        <s.OverallBlock>
          <SkeletonCircle r={24} />
          <s.BlockCaption>
            <SkeletonRect w={80} h={10} />
          </s.BlockCaption>
          <s.BlockValue>
            <SkeletonRect w={60} h={15} />
          </s.BlockValue>
        </s.OverallBlock>
        {isMobile ? <s.HorizontalLine /> : <s.VerticalLine />}
        <s.OverallBlock>
          <SkeletonCircle r={24} />
          <s.BlockCaption>
            <SkeletonRect w={80} h={10} />
          </s.BlockCaption>
          <s.BlockValue>
            <SkeletonRect w={60} h={15} />
          </s.BlockValue>
        </s.OverallBlock>
        {isMobile ? <s.VerticalLine /> : <s.HorizontalLine />}
        <s.OverallBlock>
          <SkeletonCircle r={24} />
          <s.BlockCaption>
            <SkeletonRect w={80} h={10} />
          </s.BlockCaption>
          <s.BlockValue>
            <SkeletonRect w={60} h={15} />
          </s.BlockValue>
        </s.OverallBlock>
        {isMobile ? <s.HorizontalLine /> : <s.VerticalLine />}
        <s.OverallBlock>
          <SkeletonCircle r={24} />
          <s.BlockCaption>
            <SkeletonRect w={80} h={10} />
          </s.BlockCaption>
          <s.BlockValue>
            <SkeletonRect w={60} h={15} />
          </s.BlockValue>
        </s.OverallBlock>
      </s.OverallContent>
    </s.Overall>
  );
}
