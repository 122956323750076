import * as s from "./styled";
import { ClanTag } from "./styled";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ClanModal } from "components/TableClans/ClanModal";

export default function TableContentClansMob({
  clanList,
}: {
  clanList: IClan[];
}) {
  const { clanId } = useParams();
  const selectedClan = clanId
    ? clanList.find((item) => item.id === clanId)
    : undefined;
  return (
    <>
      <s.BodyWrapper>
        <s.Grid>
          <s.HeaderRowMobile>
            <s.HeaderCell>#</s.HeaderCell>
            <s.HeaderCell>CLAN NAME</s.HeaderCell>
            <s.HeaderCell>SCORE</s.HeaderCell>
          </s.HeaderRowMobile>
          {clanList.map((clan, i) => (
            <GridRowMob key={clan.id} i={i} clan={clan} />
          ))}
        </s.Grid>
      </s.BodyWrapper>
      <s.BodyImg />
      <ClanModal clan={selectedClan} />
    </>
  );
}

function GridRowMob(props: { i: number; clan: IClan }) {
  const { clan } = props;
  const navigate = useNavigate();
  const { clanId } = useParams();
  const [open, setOpen] = useState(false);
  return (
    <>
      <s.GridRowMobile $i={props.i}>
        <s.GridCell>{clan.rank}</s.GridCell>
        <s.GridCell pointer onClick={() => navigate(`/clans/${clan.id}`)}>
          <s.GridImg tag={clan.slug} />
          {clan.name}
        </s.GridCell>
        <s.GridCell>{clan.score}</s.GridCell>
        <s.GridCell>
          <s.TableArrowMob open={open} onClick={() => setOpen(!open)} />
        </s.GridCell>
      </s.GridRowMobile>
      <s.HiddenStats open={open}>
        {!!(props.i % 2) && <s.StatsSeparator />}
        <s.StatItem>
          <s.StatTitle>TAG</s.StatTitle>
          <s.StatText>{clan.slug}</s.StatText>
        </s.StatItem>

        <s.StatItem>
          <s.StatTitle>MEMBERS</s.StatTitle>
          <s.StatText>{clan.members}</s.StatText>
        </s.StatItem>
        <s.StatsSeparator />
        <s.StatItem onClick={() => navigate(`/profile/${clan.owner.id}`)}>
          <s.StatTitle>OWNER</s.StatTitle>
          <s.StatText>
            {clan.owner.username}
            <ClanTag>&nbsp;#{clan.owner.suffix}</ClanTag>
          </s.StatText>
        </s.StatItem>
        {!(props.i % 2) && <s.StatsSeparator />}
      </s.HiddenStats>
    </>
  );
}
