import closeDrawer from "assets/closeDrawer.svg";
import drawer from "assets/drawer.svg";
import logo from "assets/logo.png";
import { Level } from "components/Profile/styled";
import styled, { css } from "styled-components/macro";

export const SkeletonWrapper = styled.div`
  display: flex;
  width: 132px;
  align-items: center;
  justify-content: center;
`;
export const ProfileHdrWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const UserNameHdr = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
`;

export const ClanHdr = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  opacity: 0.6;
`;
export const UserLevelHeader = styled(Level)`
  width: 44px;
  height: 44px;
  font-size: 9px;
  line-height: 11px;
  font-weight: 325;
  color: white;
  margin-right: 8px;

  p {
    font-weight: 600;
    margin: 0;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #ffb60c;
  }
`;

export const HeaderWrapper = styled.div`
  background-color: #000000;
  display: flex;
  justify-content: center;
  height: 60px;
`;

export const HeaderContainer = styled.div`
  max-width: 1170px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex: 1;
  @media (min-width: 1170px) {
    padding: 0 30px;
  }
`;

export const HeaderImg = styled.img.attrs({
  src: logo,
})`
  margin-top: 8px;
  height: 46px;
  flex-basis: 94px;
  cursor: pointer;
`;

export const Grow = styled.div`
  flex: 1 1 auto;
`;
export const MenuIcon = styled.img.attrs<{ open: boolean }>((props) => ({
  src: props.open ? closeDrawer : drawer,
  alt: "drawer",
}))<{ open: boolean }>`
  cursor: pointer;
`;

export const MenuItemBg = styled.div<{ active: boolean }>`
  position: relative;
  padding: 1px;
  transform: skewX(-15deg);
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  ${({ active }) =>
    active &&
    css`
      background: linear-gradient(
        245.7deg,
        rgb(255, 182, 12) 48.18%,
        rgba(255, 182, 12, 0) 69.38%
      );
    `}
`;

export const MenuItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  //transform: skew(-15deg);
  padding: 0 27px 0 22px;
  //background: radial-gradient(62.57% 86% at 58.02% 100%, rgba(255, 128, 12, 0.2) 0%, rgba(255, 128, 12, 0) 100%), linear-gradient(rgb(33, 33, 33) 0%, rgba(33, 33, 33, 0) 100%) rgb(0, 0, 0);
  height: 100%;
  box-sizing: border-box;
  font-size: 12px;

  & > div,
  & > img {
    transform: skew(15deg);
    color: #7f7f7f;
    font-weight: 700;
  }

  ${({ active }) =>
    active &&
    css`
      div {
        color: #ffb60c;
      }

      background: radial-gradient(
          62.57% 86% at 58.02% 100%,
          rgba(255, 128, 12, 0.2) 0%,
          rgba(255, 128, 12, 0) 100%
        ),
        linear-gradient(rgb(33, 33, 33) 0%, rgba(33, 33, 33, 0) 100%)
          rgb(0, 0, 0);
    `}
`;

export const MenuImg = styled.img`
  margin-right: 10px;
  //width: 20px;
  //height: 20px;
`;

export const MobMenuItemBg = styled.div<{ active: boolean }>`
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      background: linear-gradient(
          270deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0) 47.92%,
          rgba(0, 0, 0, 0.8) 100%
        ),
        radial-gradient(
          62.57% 86% at 58.02% 100%,
          rgba(255, 128, 12, 0.2) 0%,
          rgba(255, 128, 12, 0) 100%
        ),
        linear-gradient(180deg, #212121 0%, rgba(33, 33, 33, 0) 100%);
    `};
`;

export const MobMenuItem = styled.div<{ active: boolean }>`
  width: 100px;
  margin: 0 auto;
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 12px;

  img {
    width: 24px;
    margin-right: 10px;
  }

  div {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: ${({ active }) =>
      active ? `rgb(255, 182, 12)` : `rgb(128, 128, 128)`};
  }
`;
export const MenuSeparator = styled.div`
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.15) 49.83%,
    rgba(255, 255, 255, 0.06) 100%
  );

  @media (min-width: 1170px) {
    height: 100%;
    background: rgb(69, 69, 69);
    width: 1px;
    transform: skew(-14deg);
  }
`;
