import styled from "styled-components/macro";
import Arrow from "assets/arrow";

export const Container = styled.div<{ w?: number | "full" }>`
  display: flex;
  align-items: center;
  width: ${({ w }) =>
    w === "full" ? "100%" : typeof w === "number" ? w + "px" : "auto"};
  @media (max-width: 768px) {
    margin-top: 6px;
  }
`;

export const LeftArrow = styled(Arrow)`
  padding: 17px 14px;
  background-color: #373737;
  box-sizing: content-box;
  cursor: pointer;
  user-select: none;
`;

export const RightArrow = styled(Arrow)`
  padding: 17px 14px;
  display: inline-block;
  background-color: #ffb60c;
  box-sizing: content-box;
  transform: rotate(180deg);
  cursor: pointer;
  user-select: none;
`;

export const NumberContiner = styled.input<{ w?: number | "full" }>`
  height: 50px;
  padding: 13.5px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Forza';
  text-align: center;
  color: #fff;
  background-color: #171717;
  border: 2px solid #373737;
  width: ${({ w }) =>
    w  ? "100%" : "96px"};
  margin: 0 2px;
  &:focus-visible {
    outline: none;
    border-color: white;
  }
`;
