import styled from "styled-components/macro";

export const TopWrapper = styled.div`
  margin-top: 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  flex-direction: row;
  align-items: flex-end;
`;

export const Top3Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 30px;
  @media (min-width: 1280px) {
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 30px;
  }
`;


