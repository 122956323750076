import { AnchorProvider, Program, web3 } from "@project-serum/anchor";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
// import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { Connection, PublicKey, SYSVAR_RENT_PUBKEY } from "@solana/web3.js";
import { endpoint } from "appConstants";
import idl from "./idl.json";

require("@solana/wallet-adapter-react-ui/styles.css");

var Buffer = require("buffer").Buffer;


const {
  SystemProgram,
  // , Keypair
} = web3;
/* create an account  */
// const baseAccount = Keypair.generate();
const opts = { preflightCommitment: "processed" };
const programID = new PublicKey("viNhyaGDiC92FNqtvVSDEJ749dgsmCDKXnr6hzGSbg1");
const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

function toBytesInt32(num) {
  return Buffer.from([
    (num & 0xff000000) >> 24,
    (num & 0x00ff0000) >> 16,
    (num & 0x0000ff00) >> 8,
    num & 0x000000ff,
  ]);
}

const getMetadata = async (mint) => {
  return (
    await PublicKey.findProgramAddress(
      [
        Buffer.from("metadata"),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
      ],
      TOKEN_METADATA_PROGRAM_ID
    )
  )[0];
};

export function getProvider(wallet) {
  const connection = new Connection(endpoint, opts.preflightCommitment);
  return new AnchorProvider(connection, wallet, opts.preflightCommitment);
}

export async function stakeItemSolana(wallet, tokenMint, slotId) {
  const provider = await getProvider(wallet);
  const program = new Program(idl, programID, provider);
  const userToken = new PublicKey(tokenMint);

  const userTokenAccount = (
    await provider.connection.getTokenAccountsByOwner(wallet.publicKey, {
      mint: userToken,
    })
  ).value[0].pubkey;

  const [pdaAuthority, pdaAuthorityBump] = await PublicKey.findProgramAddress(
    [Buffer.from("authority")],
    program.programId
  );

  const [pdaToken, pdaTokenBump] = await PublicKey.findProgramAddress(
    [userToken.toBuffer(), Buffer.from("token")],
    program.programId
  );

  const [pdaTokenAccount, pdaTokenAccountBump] =
    await PublicKey.findProgramAddress(
      [userToken.toBuffer(), Buffer.from("token_account")],
      program.programId
    );

  const [pdaConfig] = await PublicKey.findProgramAddress(
    [Buffer.from("config")],
    program.programId
  );

  const [pdaRecord] = await PublicKey.findProgramAddress(
    [wallet.publicKey.toBuffer(), toBytesInt32(slotId)],
    program.programId
  );

  const userTokenMetadata = await getMetadata(userToken);
  const pdaTokenMetadata = await getMetadata(pdaToken);
  console.log('stakeItemSolana ready to send transaction')
  try {
    /* interact with the program via rpc */
    let tx = await program.rpc.stake(
      pdaAuthorityBump,
      pdaTokenBump,
      pdaTokenAccountBump,
      slotId,
      {
        accounts: {
          userAuthority: wallet.publicKey,
          userToken: userToken,
          userTokenAccount: userTokenAccount,
          userTokenMetadata: userTokenMetadata,

          pdaAuthority: pdaAuthority,
          pdaToken: pdaToken,
          pdaTokenAccount: pdaTokenAccount,
          pdaTokenMetadata: pdaTokenMetadata,
          pdaConfig: pdaConfig,
          pdaRecord: pdaRecord,

          tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          tokenProgram: TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        },
      }
    );
    console.log("Staked transaction: ", tx);
    return tx;
  } catch (err) {
    // console.log("slotId", slotId);
    // console.log(`itemToken: `, userToken.toString());
    // console.log(`itemTokenAccount: `, userTokenAccount.toString());
    // console.log("Transaction error: ", err.logs);
    const log = {
      request: "stakeItemSolana",
      inputParams: {
        wallet:wallet.publicKey.toString(), tokenMint, slotId
      },
      generatedParams: {
        userAuthority: wallet.publicKey.toString(),
        userToken: userToken.toString(),
        userTokenAccount: userTokenAccount.toString(),
        userTokenMetadata: userTokenMetadata.toString(),
        pdaAuthority: pdaAuthority.toString(),
        pdaToken: pdaToken.toString(),
        pdaTokenAccount: pdaTokenAccount.toString(),
        pdaTokenMetadata: pdaTokenMetadata.toString(),
        pdaConfig: pdaConfig.toString(),
        pdaRecord: pdaRecord.toString(),
      },
      errorLogs: err.logs,
    };
    console.warn(log.request,log);
    throw err;
  }
}

export async function unStakeItem(wallet, slotId) {
  console.log('unstakeItemPrepare_inputParams:',{wallet:wallet.publicKey.toString(),slotId})
  const provider = await getProvider(wallet);
  const program = new Program(idl, programID, provider);
  const [pdaAuthority, pdaAuthorityBump] = await PublicKey.findProgramAddress(
    [Buffer.from("authority")],
    program.programId
  );
  console.log('pdaAuthority',pdaAuthority.toString())
  const [pdaRecord] = await PublicKey.findProgramAddress(
    [wallet.publicKey.toBuffer(), toBytesInt32(slotId)],
    program.programId
  );
  console.log(`pdaRecord: `,pdaRecord.toString())
  let pdaRecordAccount
  try {
    pdaRecordAccount = await program.account.record.fetch(pdaRecord);
    console.log("pdaRecordAccount.tokenAccount", pdaRecordAccount.userTokenAccount.toString());
  } catch (e){
    console.log(e)
  }

  const [pdaToken] = await PublicKey.findProgramAddress(
    [pdaRecordAccount.userToken.toBuffer(), Buffer.from("token")],
    program.programId
  );
  console.log(`pdaToken: `,pdaToken)
  const [pdaTokenAccount] = await PublicKey.findProgramAddress(
    [pdaRecordAccount.userToken.toBuffer(), Buffer.from("token_account")],
    program.programId
  );
  console.log(`pdaTokenAccount: `,pdaTokenAccount)
  console.log('UnstakeItemSolana ready to send transaction')
  try {
    /* interact with the program via rpc */
    let tx = await program.rpc.unstake(pdaAuthorityBump, slotId, {
      accounts: {
        userAuthority: wallet.publicKey,
        userToken: pdaRecordAccount.userToken,
        userTokenAccount: pdaRecordAccount.userTokenAccount,

        pdaAuthority: pdaAuthority,
        pdaToken: pdaToken,
        pdaTokenAccount: pdaTokenAccount,
        pdaRecord: pdaRecord,

        tokenProgram: TOKEN_PROGRAM_ID,
      },
    });
    console.log("UnStaked transaction: ", tx);
    return tx;
  } catch (e) {
    const log = {
      request: "unstakeItemSolana",
      params: {
        slotId,
        pdaToken: pdaToken.toString(),
        userAuthority: wallet.publicKey.toString(),
        userToken: pdaRecordAccount.userToken.toString(),
        userTokenAccount: pdaRecordAccount.userTokenAccount.toString(),
        pdaAuthority: pdaAuthority.toString(),
        pdaTokenAccount: pdaTokenAccount.toString(),
        pdaRecord: pdaRecord.toString(),
        tokenProgram: TOKEN_PROGRAM_ID,
      },
      errorLogs: e.logs,
    };
    console.warn(log.request,log);

    throw e;
  }
}
