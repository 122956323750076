import Uncommon from "./assets/Uncommon.png";
import Epic from "./assets/Epic.png";
import Legendary from "./assets/Legendary.png";
import Rare from "./assets/Rare.png";

export function mapRarityToIcon(rarity: number) {
  switch (rarity) {
    case 2:
      return Uncommon;
    case 3:
      return Rare;
    case 4:
      return Epic;
    case 5:
      return Legendary;
    default:
      return Uncommon;
  }
}