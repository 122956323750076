import {inventory_getItemMany} from "api";
import {IEvent, IItemsNormalized,} from "api/api.types";
import {useSwrImmutable} from "hooks/useSwr";
import _ from "lodash";

// TODO: убрать нахер, вынести загрузку на уровень компонента по одной шт.
export const useRewards = (
  event?: IEvent
): [
  (
    | { event: IEvent; items: IItemsNormalized;}
    | undefined
  ),
  boolean,
  boolean
] => {
  let itemIds = event
    ? event.rewardsList.reduce((acc: Array<string>, item) => {
        const ids = item.rewardsList.reduce((acc: Array<string>, item) => {
          const id = item.inventory?.itemId;
          if (id) acc.push(id);
          return acc;
        }, []);

        acc = acc.concat(ids);
        return acc;
      }, [])
    : [];

  itemIds = _.uniq(itemIds);


  const [items, loadingItems, errorItems] = useSwrImmutable(
    event && itemIds.length ? ["rewardItemsInventory"] : null,
    () => inventory_getItemMany(itemIds)
  );


  const loading = loadingItems
  const error = !!errorItems ;

  if (loading) return [undefined, true, false];
  else if (event)
    return [
      { event, items: items || {} },
      loadingItems ,
      !!errorItems ,
    ];
  return [undefined, false, error];
};
