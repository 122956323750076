import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getMetadataCollection, inventory_getItems } from "api";
import { AppThunk, RootState } from "store";
import { IAllItemsInfo, ICollection, IItem } from "types/stakingTypes";

export const initialState: ICollection = {
  allItems: {
    characters: 0,
    mintableItems: 0,
    lootCollection: 0,
    premiumCollection: 0,
    collectionInfo: [],
    weapons: 0,
    totalItems: [],
  },
};


export const collectionPage = createSlice({
  name: "collection",
  initialState,
  reducers: {
    setCollection: (state, action: PayloadAction<IAllItemsInfo>) => {
      state.allItems = action.payload;
    },
  },
});

export const { setCollection } = collectionPage.actions;

export const fetchAllItems = ():AppThunk => async (dispatch) => {
  const response = await inventory_getItems()
  const ids = response?.totalItems?.filter((item: IItem) => item.generated).map((item: IItem) => item.id)

  const collectionInfo = await getMetadataCollection(ids)

  const lootCollection = collectionInfo.filter(item => item?.collectionName?.includes("Loot Collection")).length
  const premiumCollection = collectionInfo.filter(item => item?.collectionName?.includes("Premium Drop")).length

  const newResponse = {...response, lootCollection, premiumCollection, collectionInfo }
  dispatch(setCollection(newResponse));
}

export default collectionPage.reducer;

//selectors
export const selectCollectionPage = (state: RootState) => state.collectionPage;

export const selectAllItems = (state: RootState) => state.collectionPage.allItems;

export const getCollectionInfo = (state: RootState) => state.collectionPage.allItems.collectionInfo;