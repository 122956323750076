import styled from "styled-components/macro";
import userBlockBG2 from "assets/userBlockBG2.png";
import timerField from "assets/timerField.svg";
import { Wrapper } from "components/Wrapper";

export const EmptyResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 100px;
  margin: 15vh auto 0;
  background-image: url(${userBlockBG2});
  max-width: 1170px;
  padding: 20px;
  @media (min-width: 768px) {
    width: 90%;
  }

  background-size: 100% 100%;

  &:after {
    color: white;
    opacity: 0.8;
    content: "Stats update in progress. Please check again tomorrow";
  }
`;

export const TimerWrapper = styled.div`
  display: flex;
  justify-content: end;
  flex: 0 0 auto;

  & > div {
    max-width: 545px;
    height: 52px;
    background: rgba(0, 0, 0, 0.8);
    display: flex;

    & > div {
      padding-left: 18px;
      padding-right: 20px;
      flex: 1 1 auto;
      font-weight: 700;
      font-size: 16px;
      color: white;
      line-height: 52px;
    }
  }
`;
export const TimerField = styled.p`
  flex: 0 0 164px;
  width: 164px;
  background: url(${timerField});
  height: 52px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 40px;
`;

export const TopWrapper = styled(Wrapper)`
  margin-top: 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  @media (min-width: 1150px) {
    flex-direction: row;
    align-items: flex-end;
  }
`;
export const Flex = styled.div`
  display: none;
  @media (min-width: 1150px) {
    flex: 1 1 auto;
    display: block;
  }
`;
