import styled from "styled-components/macro";

export function Rank({
  type,
  rank,
  clanName,
}: {
  type: "daily" | "seasonal" | "clan" | "no";
  rank?: number;
  clanName?: string;
}) {
  let text = `Your ${clanName || ""} ${type} Rank:`;
  if (!clanName && type === "clan") text = "You don’t have Clan yet";

  return (
    <RankDiv>
      <div>{text}</div>
      {rank || "9999+"}
    </RankDiv>
  );
}

const RankDiv = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  row-gap: 30px;
  border-left: 3px solid #ffb60c;
  height: 52px;
  background: rgba(0, 0, 0, 0.7);
  padding-right: 10px;
  text-transform: capitalize;

  & > div {
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    margin-left: 12px;
    margin-right: 10px;
  }

  font-weight: 700;
  font-size: 24px;
  color: #ffb60c;
`;
