import { useEffect, useState } from "react";
import { secondsToDHM } from "utils/dateHelpers";
import * as s from "./EventEndTimer.styled";
import timerIcon from "assets/timerIcon.svg";
import { useRewards } from "components/TieredLeaderboard/componetns/hooks";

export function EventEndTimer() {
  const [time, setTime] = useState("");
  const [data] = useRewards();
  const isNegative =
    data?.event && Math.sign(data.event.endAt.seconds * 1000 - Date.now());

  const isFutureEvent = (data?.event?.endAt as any)?.startsIn;
  useEffect(() => {
    if (!data?.event) return;
    const interval = setInterval(() => {
      setTime(
        secondsToDHM(
          Math.floor(
            (isFutureEvent
              ? data.event.startAt.seconds
              : data.event.endAt.seconds) -
              Date.now() / 1000
          )
        )
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [data?.event]);
  if (!time) return null;

  return (
    <s.TimerWrapper>
      <div>
        <div className="title">
          {isNegative === -1
            ? `Event Ended:`
            : isFutureEvent
            ? `Event Starts In:`
            : `Event Ends In:`}
        </div>
        <s.TimerField>
          <img src={timerIcon} alt="timer" />
          <div>{time}</div>
        </s.TimerField>
      </div>
    </s.TimerWrapper>
  );
}
