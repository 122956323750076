import { SVGAttributes } from 'react';

const TriangleIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
   width="15" 
   height="13" 
   viewBox="0 0 15 13" 
   fill="none"
  {...props}
    >
<path d="M7.5 13L14.8612 0.25H0.138784L7.5 13Z" fill="currentColor"/>
</svg>


);

export default TriangleIcon;