export function mapRarityToColor(rarity: number) {
  switch (rarity) {
    case 0:
      return "#FFFFFF";
    case 1:
      return "rgb(165,165,165)";
    case 2:
      return "rgb(87,177,20)";
    case 3:
      return "rgb(33,80,193)";
    case 4:
      return "rgb(142,0,168)";
    case 5:
      return "rgb(230,109,37)";
    case 6:
      return "transparent";
    default:
      return "#FFFFFF";
  }
}