import * as s from "components/IntraClan/styled";
import { Submenu } from "components/IntraClan/Submenu/Submenu";
import React from "react";
import { Wrapper as StWrapper } from "components/Wrapper";
import { TieredLeaderboard } from "components/TieredLeaderboard/TieredLeaderboard";

export default function TieredLBPage() {
  return (
    <StWrapper>
      <s.TopWrapper>
        <Submenu />
      </s.TopWrapper>
      <TieredLeaderboard />
    </StWrapper>
  );
}
