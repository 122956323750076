import useSWR, { Fetcher, Key, KeyedMutator } from "swr";
import { SWRConfiguration } from "swr/dist/types";
import useSWRImmutable from "swr/immutable";

export function useSwr<Data = any, SWRKey extends Key = null>(
  key: SWRKey,
  fetcher: Fetcher<Data, SWRKey> | null,
  config?: SWRConfiguration<Data, Error, Fetcher<Data, SWRKey>>
) {
  const { data, error, isValidating, mutate } = useSWR(key, fetcher, config);
  if (error) console.log("error " + fetcher?.name, error);
  let result: [typeof data, boolean, Error | undefined, KeyedMutator<Data>] = [
    data,
    isValidating,
    error,
    mutate,
  ];
  return result;
}

export function useSwrImmutable<Data = any, SWRKey extends Key = null>(
  key: SWRKey,
  fetcher: Fetcher<Data, SWRKey> | null,
  config?: SWRConfiguration<Data, Error, Fetcher<Data, SWRKey>>
) {
  const { data, error, isValidating, mutate } = useSWRImmutable(key, fetcher,config);
  if (error) console.log("error " + fetcher?.name, error);
  let result: [typeof data, boolean, Error | undefined, KeyedMutator<Data>] = [
    data,
    isValidating,
    error,
    mutate,
  ];
  return result;
}
