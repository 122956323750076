import React from "react";
import styled from "styled-components/macro";

export const Checkbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-image: ${({ checked }) =>
    checked
      ? "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANWSURBVHgB7Zs/bBJRHMd/D2ik9o9EHepWE0hcTNpNByNNHCwbg87FvXRW97oiDG42rjrUxcJWjCa6ldGEJjZODGrQgtJCPd/34BGCwr37B8fjPknb4ygXPnm8e78/PEYdtN1IhMKtLdIozh/GSQU0KhHTMixRfyFOMf18PrxMWnCfP1wmJdGO6ORsjSUbR23hvbnPkK1Ug7S9e4HKlRmqNRhNOreuNSi9fkxLkTNqS8+sBrU3cxvE2AZkU88u05evITptTb4sgEu+NEt3rjdoPqxFKPjnJMBHdgtPZvMLSoxqP7VGQP/U6jC6HeC/VnD87lOYVKVcCYnDeICmAIyyYCqEe/GFVccXVh1fWHV8YdWZOOHoUpNnQD/JKiGaICCbS31H5qP/dJMCE0zMCCOnFbJgfeW3pZGeCOF+WcG9G78otVYjM3heWMi2qxb/8iBeMyXtaWEjWQHTSBrPCsvK7uzP0/PiPMniSWHMVRnZVx/Om5IFnhNuy34zlM0fzFK2sEhm8ZSwkI0utYb+H2S3X5tfg4Ft4U2+FsZ4QOAEuJaR7CEvyFkZWYEt4YfJH3Sfr4VZPt/sSuNaCR5MDAOymzuXbJWTLQtjNMQbxEcxK3GTkbnWINAosCsLLAljocfI9iJ7Z5W5Vj9t2YuONApMC+MNIrr5H7Jrp8y1BEIWf53AtLBRVCMrPQ5ZYFoYCz2im2EYSY9LFliaw5BGlDOMgRnOzbqhLOaqG7LA8l0aayECgGFAOssDiW4Ou8pz2LvHQ1/jpiywVfEQ0Q5EBhHjgQSk0aeVlT3kDXm3sF3iyRYWKHqlqYsNAs/FDGRBLr/oqiywHVqiFZnmo9LTg7XEE16f2ivNkts4kjwIaavzLpdfGIkscCxbgrSVmw2WuJcf52hUOJoeml07zVYrnMDxfFhWehyywJUCgJH0uGSBaxWPQRmOlTqUk7ha4oF0ukfaah3KSVzvLeFrjJBGa2TcsmAkzTRIlwvuRlCy+P1h1fGFVccXVp3pFe4vtqkKhIs4cKoh5kWw2aNDKUAavcXRI97MUnGU4YSdLW20DNM3aJ1rHohtPE95ueW9AvsfIIrvdT3mAym28bBE/ep0btTqnsYeJmzr6ex0UYCiPmVPQxmWrFZx4i/8nJMlfK0bHgAAAABJRU5ErkJggg==')"
      : "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFpSURBVHgB7du9bYNQFIbhw43l38ayPICzASksuwvZxBuwCitkkpAOaOINQkWFUAqEFECQ+0UQWdnAH+ctbC5Uj+6F7jgy5Hnetqoq33EcD0vh6Nr3fZAkyet4w8HP+Xw+2Adv9vIgnKV2I1+iKEp/wafT6dP+Hdq2lTzPpa5r6bpO7r31ei273U5msxmW6Wq1eno4Ho8Xq78Am2WZNE0jdreFIVjKspTNZiPGmK1dfxuL9fGwKAqKXf0fTDi1yFqfAXaxsB8sYQ2v6JBnZALdntxJgG9TMHsKZk/B7CmYPQWzp2D2FMyegtlTMHsKZk/B7CmYPQWzp2D2FMyegtlTMHsKZk/B7CmYPQWzp2D2FMzeH9iYadihDHExn8+FNQx7oL7vrxgBeMdiv99T7jJMmGwZrgPHdd3tYrH4kGGMB8MeDPMPgOLUYiPHMZ44jh+nOag1ZuGXruv8cdKFoBCv7HK5DMIw/MKNH00peDatPpxkAAAAAElFTkSuQmCC')"};
  background-size: 30px 30px;
`;


