import { offchain_wallets_getButterBalance } from "api";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import {
  GreenButton,
  RedButton,
} from "components/Staking/StakeForEventPoints/Grid/Grid.styled";
import { modalStyles } from "components/Staking/StakeForEventPoints/Grid/UnlockModal/UnlockModal";
import * as s from "components/Staking/StakeForEventPoints/Grid/UnlockModal/UnlockModal.styled";
import { HorizontalLine } from "components/TableClans/CloseModal.styled";
import { useEffect, useState } from "react";
import Modal from "react-modal";

export function Locked({isOpen, set, setUnlockModal, handleUnlock }: {isOpen: boolean, set: any, setUnlockModal: (val: boolean) => void, handleUnlock: () => void }) {
  const handleClose = () => setUnlockModal(false);

  const [ balance, setBalance] = useState(0)
  const [isWarning, setWarning] = useState(false)

  const canUnlock = balance >= set?.paymentDetails?.amount
  useEffect(() => {
    offchain_wallets_getButterBalance().then((data: number) => setBalance(data))
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      preventScroll
      onRequestClose={handleClose}
      style={modalStyles}
    >
      <s.Locked>
        <img src={closeIcon} onClick={handleClose} />
        <div className="title">set unlock confirmation</div>
        <div className="titleHelp">
          Are you sure you want to unlock this Set?
        </div>
        <s.Warning isActive={isWarning}>Not Enough Butter!</s.Warning>
        <div className="grid">
          <div>Cost</div>
          <div>{set?.paymentDetails?.amount} Butter</div>
          <div>Your Balance:</div>
          <div>{balance}</div>
        </div>
        <HorizontalLine />
        <div className="duration">Unlock duration for Set {set?.setNumber} is: {set?.paymentDetails?.unlockDurationText}</div>
        <div className="buttons">
          <RedButton onClick={handleClose}>Cancel</RedButton>
          <GreenButton onClick={() => canUnlock ? handleUnlock() : setWarning(true)}>Confirm</GreenButton>
        </div>
      </s.Locked>
    </Modal>
  );
}
