import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { clantreasury_donate, offchain_wallets_getButterBalance } from "api";
import butter from "assets/butter_icon.png";
import Box from "components/Box";
import { Gap } from "components/Gap";
import GreenButton from "components/GreenButton";
import Img from "components/Img";
import { QuantityCounterFlex } from "components/QuantityCounterFlex/QuantityCounterFlex";
import { Spinner } from "components/Spinner";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import { HorizontalLine } from "components/TableClans/CloseModal.styled";
import Text from "components/Text/Text";
import { useSwrImmutable } from "hooks/useSwr";
import { WDModalStyles } from "pages/WorldDomination/modalStyles";
import { useState } from "react";
import Modal from "react-modal";
import { useGetEventQuery, useGetEventTurnQuery } from "redux/rtkQuery/rtkApi";
import { numberWithCommas } from "utils/numberWithComas";
import donateButterMiddle1 from "../assets/donateButterMiddle1.png";
import donateButterMiddle2 from "../assets/donateButterMiddle2.png";
import noButter from "../assets/noButter.png";
import * as s from "./DonateButterModal.styled";

export function DonateButterModal({ onClose }: { onClose: Function }) {
  const [status, setStatus] = useState<IStatus>("idle");
  const [amount, setAmount] = useState(1);
  let [playerButterBalance, , , mutate] = useSwrImmutable(
    ["BUTTER", "balance"],
    offchain_wallets_getButterBalance
  );
  const { data: event } = useGetEventQuery();
  const { data: turn, refetch: refetchTurn } = useGetEventTurnQuery(
    event?.id ?? skipToken
  );
  if (playerButterBalance === undefined || !turn) return null;

  const clanButterBalance = turn.clan.balancesList.find(
    (i) => i.currency === "BUTTER"
  )!.amount;

  const handleDonate = () => {
    setStatus("pending");
    clantreasury_donate(amount)
      .then(() => {
        return mutate().then(() => refetchTurn());
      })
      .then(() => {
        setStatus("done");
      })
      .catch((e) => console.log(e));
  };

  let hintText = "";
  if (amount > playerButterBalance) hintText = "Insufficient Butter";
  // if (player?.isOwner === false)
  //   hintText = "You must be in a Clan for at least 7 days to Donate";

  return (
    <Modal
      isOpen
      style={WDModalStyles}
      shouldCloseOnEsc
      preventScroll
      onRequestClose={() => onClose()}
    >
      <s.Close onClick={() => onClose()}>
        <img src={closeIcon} />
      </s.Close>
      <s.Content>
        {playerButterBalance === 0 && status === "idle" && (
          <>
            <Gap h={60} />
            <Img src={noButter} w={196} h={169} />
            <Text w={400} s={30} capitalize>
              Not Enough Butter!
            </Text>
            <Gap h={15} />
            <Text yellow center>
              You can earn Butter by completing Quests, unlocking Battle Pass
              tiers, and participating in Clan Wars. You can also buy Butter
              from the in-game Exchange.
            </Text>
          </>
        )}
        {status === "pending" && <Spinner />}
        {((playerButterBalance > 0 && status === "idle") ||
          status === "done") && (
          <>
            {status === "idle" ? <Gap h={44} /> : <Gap h={69} />}
            <s.Title>
              {status !== "done" ? "Donate butter to clan" : "Done!"}
            </s.Title>
            {status === "idle" ? <Gap h={25} /> : <Gap h={11} />}
            {status === "done" && (
              <>
                <Box gap={3} justifyContent="center">
                  <Text w={400} s={20}>
                    You donated to Your Clan
                  </Text>
                  <img src={butter} width={26} />
                  <Text w={400} s={20} color="#77DCFF">
                    {amount} Butter.
                  </Text>
                </Box>
                <Gap h={40} />
              </>
            )}
            <Box mb={30} fullWidth>
              <s.MyBalance>
                {/*<s.PlusIcon />*/}
                <Text w={400} s={16}>
                  My balance:
                </Text>
                <s.BalanceText>
                  <img src={butter} width={26} />
                  {numberWithCommas(playerButterBalance)}
                </s.BalanceText>
              </s.MyBalance>
              <s.Middle>
                {status === "idle" && (
                  <img src={donateButterMiddle1} width={17} />
                )}
                {status === "done" && (
                  <img src={donateButterMiddle2} width={29} />
                )}
              </s.Middle>
              <s.ClanBalance>
                <Text w={400} s={16}>
                  Clan balance:
                </Text>
                <s.BalanceText>
                  <img src={butter} width={26} />
                  {numberWithCommas(clanButterBalance)}
                </s.BalanceText>
              </s.ClanBalance>
            </Box>
            {status === "idle" && (
              <>
                <HorizontalLine />
                <s.Quantity>
                  <s.QuantityText>Quantity:</s.QuantityText>
                  <QuantityCounterFlex
                    amount={amount}
                    setAmount={setAmount}
                    w="full"
                  />
                  <Gap h={12} />
                  <GreenButton
                    h={60}
                    onClick={handleDonate}
                    disabled={!!hintText}
                  >
                    Donate
                  </GreenButton>
                </s.Quantity>
              </>
            )}
            {status === "done" && (
              <Box w="full" justifyContent="center">
                <GreenButton h={60} w={182} onClick={onClose}>
                  Ok
                </GreenButton>
              </Box>
            )}
            <s.Hint>{hintText}</s.Hint>
          </>
        )}
      </s.Content>
    </Modal>
  );
}
