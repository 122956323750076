import styled from "styled-components/macro";

export const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 40;
  cursor: pointer;
  & > img {
    width: 16px;
    height: 16px;
  }
`;

export const Content = styled.div`
  width: 680px;
  height: 516px;
  padding: 50px 55px 37px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ContentNuke = styled(Content)`
  height: 550px;
`;
export const ContentIronDome = styled(Content)`
  height: 500px;
`;

export const Info = styled.div`
  display: flex;
  gap: 17px;
  min-height: 199px;
`;

export const InfoNuke = styled(Info)`
  min-height: 260px;
`;

export const InfoCityShield = styled(Info)`
  min-height: 180px;
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Img = styled.img`
  min-width: 160px;
  height: 160px;
  border-radius: 10px;
`;
export const Title = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-transform: capitalize;
  color: #ffffff;
`;

export const DescriptionTitle = styled.div`
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`;

export const Description = styled.ul`
  margin: 16px 0 0;
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  color: #838383;
  padding-inline-start: 16px;
`;

export const DescriptionNuke = styled(Description)`
  margin: 9px 0 0;
`;
export const DescriptionIronDome = styled(Description)`
  margin: 10px 0 0;
`;
export const DescriptionCityShield = styled.div`
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  color: #838383;
`;

export const Hint = styled.div`
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #ffb60c;
  margin-bottom: 19px;
`;

export const HintIronDome = styled(Hint)`
  margin-bottom: 19px;
  margin-top: 6px;
`;

export const OrderBlock = styled.div`
  margin-top: 19px;
`;

export const Current = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #838383;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  & > div:nth-child(1) {
    flex: 0 0 197px;
  }
  & > .count {
    flex: 0 0 74px;
  }
  & > .cost {
    margin-left: 2px;
    color: white;
  }
`;

export const CurrentCityShield = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #838383;
  display: flex;

  align-items: center;
  margin-bottom: 8px;
  & > div:nth-child(1) {
    flex: 0 0 197px;
  }
  & > .count {
    flex: 0 0 84px;
  }
`;

export const CityShieldCost = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const CurrentNuke = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #838383;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  & > div:nth-child(1) {
    flex: 0 0 197px;
  }
  & > .count {
    flex: 0 0 74px;
  }
  & > div:nth-child(3) {
    flex: 0 0 93px;
  }
  & > .cost {
    margin-left: 2px;
    color: white;
  }
`;

export const Planned = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #838383;
  display: flex;
  align-items: center;
  & > div:nth-child(1) {
    position: relative;
    flex: 0 0 197px;
  }

  & > div:nth-child(2) {
    flex: 0 0 74px;
  }
`;

export const PlannedCityShield = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #838383;
  display: flex;
  align-items: center;
  & > div:nth-child(1) {
    position: relative;
    flex: 0 0 197px;
  }

  & > div:nth-child(2) {
    flex: 0 0 84px;
  }
`;

export const PlannedCount = styled.div<{ count: number }>`
  flex: 0 0 70px;
  &::before {
    color: ${({ count }) => (count === 0 ? "#838383" : "#59B015")};
    content: "${({ count }) => (count === 0 ? 0 : "+" + count)}";
  }
`;

export const Remove = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #ec2e00;
  cursor: pointer;
  &::before {
    content: "Remove from Queue";
  }
`;

export const ButtonHint = styled.div`
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  text-align: center;
  color: #ec2e00;
  position: absolute;
  bottom: 7px;
  left: 0;
  right: 0;
`;

export const NukeBuild = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #838383;
  align-items: center;
`;

export const Stats = styled.div`
  min-width: 268px;
`;

export const SiloCapacity = styled.div`
  display: flex;
  flex: 0 0 197px;
  margin-bottom: 10px;
`;
export const SiloCapacityCount = styled.div`
  display: flex;
  flex: 0 0 70px;
`;

export const SiloPlanned = styled.div`
  display: flex;
  position: relative;
  flex: 0 0 197px;
`;

export const SelectCityWrapper = styled.div`
  flex: 1;
  border: 2px solid #373737;
  margin-bottom: 8px;
  height: 50px;
  position: relative;
`;

export const SelectCityInput = styled.div<{ open: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  background: #171717;
  padding: 0 13px 0 19px;
  cursor: pointer;
`;

export const SelectCityContent = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? "block" : "none")};
  border: 2px solid #373737;
  position: absolute;
  z-index: 100;
  right: -2px;
  top: 45px;
  width: calc(100% + 4px);
  background: #242424;
`;

export const SelectItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 13px 0 19px;
  height: 50px;
  color: white;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  background-color: #171717;
  cursor: pointer;
  &:hover {
    background: #000000;
  }
`;
