export function mapRarityToBgColor(rarity: number) {
  switch (rarity) {
    case 0:
      return "rgba(255,255,255,0.2)";
    case 1:
      return "rgba(255,255,255,0.2)";
    case 2:
      return "rgba(89, 176, 21, 0.2)";
    case 3:
      return "rgba(46, 155, 255, 0.2)";
    case 4:
      return "rgba(152, 74, 198, 0.2)";
    case 5:
      return "rgba(255, 128, 12, 0.2)";
    default:
      return "rgba(255,255,255,0.2)";
  }
}