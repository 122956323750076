import React from "react";
import Modal from "react-modal";
import * as s from "components/Staking/StakeForEventPoints/Grid/UnlockModal/UnlockModal.styled";
import { Spinner } from "components/Spinner";
import { modalStyles } from "components/Staking/StakeForEventPoints/Grid/UnlockModal/UnlockModal";

export function Pending() {
  return (
    <Modal isOpen preventScroll style={modalStyles}>
      <s.ModalWrapper>
        <Spinner />
      </s.ModalWrapper>
    </Modal>
  );
}
