import styled from "styled-components/macro";

export const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  width: 520px;
  height: 410px;

  & > img {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
`;

export const Unstake = styled(ModalWrapper)`
  & .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-transform: capitalize;
    color: #ffffff;
  }

  & .titleHelp {
    font-weight: 350;
    font-size: 16px;
    line-height: 19px;
    color: #838383;
    margin-bottom: 23px;
  }

  & .item {
    width: 152px;
    height: 152px;
    margin-bottom: 30px;
  }

  & .buttons {
    display: flex;
    width: 100%;
    gap: 7px;

    & > button {
      height: 60px;
    }
  }
`;
