import Modal from "react-modal";
import * as s from "components/Staking/StakeForEventPoints/Grid/UnlockModal/UnlockModal.styled";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import { GreenButton } from "components/Staking/StakeForEventPoints/Grid/Grid.styled";
import { modalStyles } from "components/Staking/StakeForEventPoints/Grid/UnlockModal/UnlockModal";

export function Unlocked({ isOpen, setUnlockModal, setUloked, set }: { isOpen: boolean, set: any, setUnlockModal: (val: boolean) => void, setUloked: (val: boolean) => void }) {
  const handleClose = () => {
    setUnlockModal(false)
    setUloked(false)
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      preventScroll
      onRequestClose={handleClose}
      style={modalStyles}
    >
      <s.Unlocked>
        <img src={closeIcon} onClick={handleClose} />
        <div className="text1">Unlock Successful!</div>
        <div className="text2">Set {set?.setNumber} Unlocked</div>
        <div className="text3">Unlock duration for Set {set?.setNumber} is: {set?.paymentDetails?.unlockDurationText}</div>
        <GreenButton className="button" onClick={handleClose}>
          OK
        </GreenButton>
      </s.Unlocked>
    </Modal>
  );
}
