import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { ICurrency } from "api/api.types";
import Box from "components/Box";
import { Gap } from "components/Gap";
import GreenButton from "components/GreenButton";
import Img from "components/Img";
import { QuantityCounterFlex } from "components/QuantityCounterFlex/QuantityCounterFlex";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import { HorizontalLine } from "components/TableClans/CloseModal.styled";
import Text from "components/Text/Text";
import bp from "pages/WorldDomination/assets/build-points-icon.png";
import missileSilo from "pages/WorldDomination/assets/missileSiloBig.png";
import whiteCheck from "pages/WorldDomination/assets/whiteCheck.svg";
import { Close } from "pages/WorldDomination/WDStatsPanel/Close";
import * as s from "pages/WorldDomination/WDStatsPanel/ConstructionModal.styled";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import {
  useAddToQueueMutation,
  useGetClanAssetsQuery,
  useGetClanInfoQuery,
  useGetEventQuery,
  useGetEventTurnQuery,
  useGetRoundQueueQuery,
  useGetUserRestrictionsQuery,
  useRemoveFromQueueMutation,
} from "redux/rtkQuery/rtkApi";
import { addErrorMessage } from "redux/slices/messageSystemSlice";
import { selectStreamItemById2 } from "redux/slices/worldDominationSlice";
import { useAppDispatch, useAppSelector } from "store";
import { numberWithCommas } from "utils/numberWithComas";
import { v4 as uuidv4 } from "uuid";

export function MissileSiloModalContent(props: { onClose: any }) {
  const dispatch = useAppDispatch();
  const { data: event } = useGetEventQuery();
  const { data: turn, refetch: refetchTurn } = useGetEventTurnQuery(
    event?.id ?? skipToken
  );
  const { data: queue } = useGetRoundQueueQuery();
  const [addToQueue] = useAddToQueueMutation();
  const [removeFromQueue] = useRemoveFromQueueMutation();
  const { data: assets } = useGetClanAssetsQuery(event?.id ?? skipToken);
  const { data: userRestrictions } = useGetUserRestrictionsQuery();
  const { data: info } = useGetClanInfoQuery();

  const [status, setStatus] = useState<IStatus>("idle");
  const [amount, setAmount] = useState(1);

  const [requestId, setRequestId] = useState(() => uuidv4());
  const transactionResult = useAppSelector(selectStreamItemById2(requestId));

  // Wait transaction to finish
  useEffect(() => {
    if (transactionResult === undefined) return;
    if (transactionResult) {
      if (transactionResult?.error) {
        if (transactionResult?.error?.code === 3) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Insufficient funds",
            })
          );
        } else if (transactionResult?.error?.code === 6) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Construction limit exceeded",
            })
          );
        } else if (transactionResult?.error?.code === 5) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Can not allocate space for construction",
            })
          );
        } else {
          dispatch(
            addErrorMessage({ title: "Error", text: "Something went wrong" })
          );
        }
        props.onClose();
      } else {
        setStatus("done");
        setRequestId(uuidv4());
      }
    }
  }, [transactionResult]);

  if (!event || !queue || !turn || !assets || !userRestrictions || !info)
    return null;

  const currency: ICurrency = "BUILD_POINT";
  const priceData = turn.market.productsList
    .find((i) => i.productId === "BUILD_MISSILE_SILO")!
    .pricesList.find((i) => i.currency === currency)!;
  const price = priceData.amount;

  const missileSiloCount = assets.missileSilosList.length;
  const missileSiloPlannedIds = queue.missileSilo.map((i) => i.id);
  const missileSiloPlannedCount = queue.missileSilo.reduce(
    (acc, i) => acc + i.count,
    0
  );
  const nukeCapacity =
    event.extensions.worldDominationConfig.assets.missileSilo.nukeCapacity;
  const balance = turn.clan.balancesList.find(
    (i) => i.currency === currency
  )!.amount;

  let hintText = "";
  if (price * amount > balance) hintText = "Insufficient Build Points";
  if (
    price * amount > userRestrictions.queueRestrictions.buildpoints.limit &&
    !userRestrictions.queueRestrictions.buildpoints.unlimited
  )
    hintText = "The total cost is over your build limit";
  if (!["LEADER", "OFFICER"].includes(info.role))
    hintText = "You Must be a Clan Leader, or Officer in order to Build";
  const handleOrder = async () => {
    setStatus("pending");
    const data = (await addToQueue({
      type: "missileSilo",
      amount,
      turnId: turn.turn.turnId,
      currency,
      requestId,
    })) as any;
    if (data?.error) {
      props.onClose();
      dispatch(
        addErrorMessage({ title: "Error", text: "Something went wrong" })
      );
    }
    refetchTurn();
  };

  const handleRemove = async () => {
    setStatus("pending");
    const data = (await removeFromQueue({
      turnId: turn.turn.turnId,
      itemIds: missileSiloPlannedIds,
    })) as any;
    if (data?.error) {
      props.onClose();
      dispatch(
        addErrorMessage({ title: "Error", text: "Something went wrong" })
      );
    }
    refetchTurn();
    props.onClose();
  };

  if (status === "idle" || status === "pending")
    return (
      <>
        <s.Close onClick={props.onClose}>
          <img src={closeIcon} />
        </s.Close>
        <s.Content>
          <s.Info>
            <s.Img src={missileSilo} />
            <s.InfoContent>
              <s.Title>Missile Silo Construction</s.Title>
              <Gap h={16} />
              <s.DescriptionTitle>
                Each Missile Silo provides the following:
              </s.DescriptionTitle>
              <s.Description>
                <li>Ability to construct Nukes</li>
                <li>Capacity to hold up to {nukeCapacity} Nuke at a time</li>
              </s.Description>
              <Box flex="1 1 auto" />
              <s.Hint>
                Missile Silos cannot be destroyed <br /> by enemy attacks
              </s.Hint>
            </s.InfoContent>
          </s.Info>
          <HorizontalLine />
          <s.OrderBlock>
            <s.Current>
              <div>Current Quantity:</div>
              <div className="count">{missileSiloCount}</div>
              <Box justifyContent="space-between" flex="1">
                <div>Cost:</div>

                <Text color="white">
                  <Box alignItems="center">
                    <img src={bp} height={20} />
                    {numberWithCommas(price)} Build Points
                  </Box>
                </Text>
              </Box>
            </s.Current>
            <s.Planned>
              <div>
                Planned for Next Turn:
                {missileSiloPlannedCount > 0 && (
                  <s.Remove onClick={handleRemove} />
                )}
              </div>
              <s.PlannedCount
                className="count"
                count={missileSiloPlannedCount}
              />
              <Box justifyContent="space-between" alignItems="center" flex="1">
                <div>Quantity:</div>
                <QuantityCounterFlex
                  amount={amount}
                  setAmount={setAmount}
                  max={100}
                />
              </Box>
            </s.Planned>
          </s.OrderBlock>
          <Box w={278} mt={21} mb={10} alignSelf="center">
            <GreenButton
              h={60}
              onClick={handleOrder}
              disabled={
                !!hintText ||
                typeof amount !== "number" ||
                amount === 0 ||
                amount > 100
              }
              loading={status === "pending"}
            >
              Build
            </GreenButton>
          </Box>
          <s.ButtonHint>{hintText}</s.ButtonHint>
        </s.Content>
      </>
    );
  if (status === "done")
    return (
      <s.Content>
        <Close onClose={props.onClose} />
        <Gap h={28} />
        <Img src={missileSilo} w={160} center />
        <Gap h={12} />
        <Text s={22} center>
          You Built {amount} Missile {pluralize("Silo", amount)}
          <br />
          for <Img src={bp} w={20} inline mb={-2} />{" "}
          {numberWithCommas(amount * price)} Build Points!
        </Text>
        <Gap h={10} />
        <Text grey center w={350}>
          Clan Build Point Balance: {numberWithCommas(balance)}
        </Text>
        <Gap h={38} />
        <GreenButton onClick={props.onClose} h={60} w={276} center>
          <Img src={whiteCheck} /> Done!
        </GreenButton>
      </s.Content>
    );
  return null;
}