import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import { WDModalStyles } from "pages/WorldDomination/modalStyles";
import Modal from "react-modal";
import {
  removeMessage,
  selectAllMessages,
} from "redux/slices/messageSystemSlice";
import { useAppDispatch, useAppSelector } from "store";
import * as s from "./ErrorMessageMopdal.styled";

export function ErrorMessageModal() {
  const dispatch = useAppDispatch();
  const messages = useAppSelector(selectAllMessages);
  if (!messages.length) return null;
  const message = messages[0];

  const handleClose = () => {
    dispatch(removeMessage(message.id));
  };

  return (
    <Modal
      isOpen
      style={WDModalStyles}
      shouldCloseOnEsc
      preventScroll
      onRequestClose={handleClose}
    >
      <s.Close onClick={() => handleClose()}>
        <img src={closeIcon} />
      </s.Close>
      <s.Content>
        <s.Title>{message.title}</s.Title>
        <s.Text>{message.text}</s.Text>
      </s.Content>
    </Modal>
  );
}
