import { SVGAttributes } from 'react';

const Polygon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="10"
    height="5" 
    viewBox="0 0 10 5" 
    fill="currentColor"
    {...props}
    >
    <path opacity="0.5" d="M5 5L0 5.04737e-07L10 0L5 5Z" fill="currentColor"/>
 </svg>

);

export default Polygon;