import * as s from "./TieredLeaderboard.styled";
import { IItemsNormalized } from "api/api.types";

const url = "https://assets-cdn.miniroyale.io/";

export function RewardItem({
  item: { itemId, amount },
  items,
}: {
  item: {
    itemId: string;
    amount: number;
  };
  items: IItemsNormalized;
}) {
  const itemSrc = items[itemId].standard.asset.icon;
  return (
    <s.RewardItem rarity={items[itemId].rarity}>
      <s.RewardItemAmount amount={amount} />
      <img src={url + itemSrc} alt={items[itemId].standard.asset.icon} />
    </s.RewardItem>
  );
}



