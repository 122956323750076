import * as s from "components/Staking/StakeForEventPoints/StakePage/StakeItemPage.styled";

export function FilterBtn(props: {
  icon?: string;
  label?: string | number;
  active?: boolean;
  onClick?: () => void;
  w?: number;
}) {
  return (
    <s.FilterButton w={props.w} active={props.active} onClick={props.onClick}>
      {props.icon && <img src={props.icon} />}

      {props.label ? <div>{props.label}</div> : null}
    </s.FilterButton>
  );
}
