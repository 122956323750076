import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useMyProfile } from "hooks/useMyProfile";
import * as s from "pages/WorldDomination/WDGridPanel/WDGrid.styled";
import { WDGridRow } from "pages/WorldDomination/WDGridPanel/WDGridRow";
import {
  useGetClanAssetsQuery,
  useGetClanLeaderboardQuery,
  useGetEventQuery,
  useGetEventTurnQuery,
} from "redux/rtkQuery/rtkApi";
import { selectFilter, selectSearch } from "redux/slices/worldDominationSlice";
import { useAppSelector } from "store";

export type ICity = { name: string; points: number; id: string };
export type IRow = {
  tier: number;
  clanName: string;
  totalPoints: number;
  vaultedPoints: number;
  cities: ICity[];
  cityEconomy: number;
  clanId: string;
};
type IAllRows = { tier: number; groupRows: IRow[] }[];

export function WDGrid() {
  const filter = useAppSelector(selectFilter);
  const search = useAppSelector(selectSearch);

  const { data: event } = useGetEventQuery();
  let skip: typeof skipToken | undefined = skipToken;
  if (event?.id !== undefined && event?.extensions?.worldDomination !== false)
    skip = undefined;

  const { data: clanLb } = useGetClanLeaderboardQuery(
    event && !skip
      ? { event, params: { clanSlots: true, allItems: true } }
      : skipToken
  );
  const { data: turn } = useGetEventTurnQuery(skip ?? event?.id!);
  const { data: assets } = useGetClanAssetsQuery(skip ?? event?.id!);

  const user = useMyProfile();

  if (!clanLb || !event || !turn || !user || !assets) return null;

  const myClanName = user.clan.name;
  let allRows = transform(clanLb, search);
  let num = 0;

  const myRowFromLB = allRows.filter((item) => {
    return item.groupRows.find((row) => row.clanName === myClanName);
  });
  // select my cities from lb or from turn
  if (filter === "My Cities") {
    if (!myRowFromLB.length) {
      allRows = [
        {
          tier: 100,
          groupRows: [
            {
              tier: 100,
              clanName: myClanName,
              cityEconomy: 0,
              totalPoints: 0,
              vaultedPoints: 0,
              cities: turn.clan.slotsList.filter((c) => c.kind === 2),
              clanId: "undefined",
            },
          ],
        },
      ];
    } else allRows = myRowFromLB;
  }

  return (
    <div>
      {allRows.map((group) => {
        return (
          <s.TierGroup key={group.tier}>
            <s.Tier val={group.tier} />
            <s.Rows>
              {group.groupRows.map((row, idx) => {
                num++;
                return (
                  <WDGridRow
                    key={idx}
                    data={row}
                    num={num}
                    myClanName={myClanName}
                    assets={assets}
                  />
                );
              })}
            </s.Rows>
          </s.TierGroup>
        );
      })}
    </div>
  );
}

function transform(clanLb: IGetClanLeaderboard[], search: string): IAllRows {
  const allRows: IAllRows = [];
  const maxTier = clanLb.reduce((acc, item) => Math.max(acc, item.rank), 0);
  for (let tier = 1; tier <= maxTier; tier++) {
    let groupRows: IRow[] = [];
    let group = clanLb.filter((item) => item.rank === tier);
    group.forEach((rowItem) => {
      let rowCreated: IRow;
      const slotsList = rowItem.worldDomination.slotsList;
      const cities = slotsList.filter((i) => i.kind === 2);
      const vault = slotsList.find((i) => i.kind === 1);
      rowCreated = {
        tier,
        clanName: rowItem.name,
        cities,
        cityEconomy: 1,
        totalPoints: rowItem.totalScore,
        vaultedPoints: vault!.points,
        clanId: rowItem.id,
      };

      if (
        search &&
        rowCreated.clanName.toLowerCase().includes(search.toLowerCase())
      ) {
        groupRows.push(rowCreated);
      } else if (!search) groupRows.push(rowCreated);
    });
    groupRows.sort((a, b) => b.totalPoints - a.totalPoints);
    if (groupRows.length > 0) allRows.push({ tier, groupRows });
  }
  return allRows;
}
