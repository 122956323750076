import * as s from "./styled";
import { useNavigate } from "react-router";
import { useState } from "react";
import iconOrbsG from "assets/enrich-orbs-icon-grey.png";
import iconMilitaryG from "assets/military-point-icon-grey.png";
import iconEconomicG from "assets/economic-point-icon-grey.png";
import iconTotalG from "assets/total-point-icon-grey.png";
import iconSortG from "assets/sort-order-icon-grey.svg";
import iconOrbsY from "assets/enrich-orbs-icon-yellow.png";
import iconMilitaryY from "assets/military-point-icon-yellow.png";
import iconEconomicY from "assets/economic-point-icon-yellow.png";
import iconTotalY from "assets/total-point-icon-yellow.png";
import iconSortY from "assets/sort-order-icon-yellow.svg";
import { numberWithCommas } from "utils/numberWithComas";
import { useMediaQuery } from "react-responsive";
import { useSwrImmutable } from "hooks/useSwr";
import { getAccountDetails } from "api";
import _ from "lodash";

let headerTitle = {
  long: {
    orbsDonated: "Enriched Orbs Donated",
    militaryScore: "Military Points",
    economicScore: "Economic Points",
    totalScore: "Total Points",
  },
  short: {
    orbsDonated: "Donated",
    militaryScore: "MP",
    economicScore: "EP",
    totalScore: "TP",
  },
};

type ITableContent = {
  data: IIntraClanPlayer[];
};

type ISortBy = "orbsDonated" | "militaryScore" | "economicScore" | "totalScore";

function TableRow(props: {
  $i: number;
  onClick: () => void;
  player: IIntraClanPlayer;
}) {
  const [profile] = useSwrImmutable(
    [props.player.id, "user"],
    getAccountDetails
  );
  if (!profile) return null;
  return (
    <s.GridRow $i={props.$i}>
      <s.GridCell>{props.$i+1}</s.GridCell>
      <s.GridCellNav onClick={props.onClick}>
        <s.GridImg src={profile.icon} />
        {profile.name} #{profile.suffix}
      </s.GridCellNav>
      <s.GridCell center>
        {numberWithCommas(props.player.orbsDonated)}
      </s.GridCell>
      <s.GridCell center>
        {numberWithCommas(props.player.militaryScore)}
      </s.GridCell>
      <s.GridCell center>
        {numberWithCommas(props.player.economicScore)}
      </s.GridCell>
      <s.GridCell center>
        {numberWithCommas(props.player.totalScore)}
      </s.GridCell>
    </s.GridRow>
  );
}

export default function TableContent({ data }: ITableContent) {
  const [sortBy, setSortBy] = useState<ISortBy>("totalScore");
  const [sortDesc, setSortDesc] = useState(true);
  const navigate = useNavigate();
  const isShort = useMediaQuery({ query: "(max-width: 1090px)" });
  const handleSort = (sort: ISortBy) => {
    if (sortBy === sort) setSortDesc(!sortDesc);
    else {
      setSortBy(sort);
      setSortDesc(true);
    }
  };
  // sorting
  let sorted = _.cloneDeep(data)
  if (sortDesc) sorted.sort((a, b) => b[sortBy] - a[sortBy]);
  else sorted.sort((a, b) => a[sortBy] - b[sortBy]);

  const headers = isShort ? headerTitle.short : headerTitle.long;

  if (sorted.length === 0) return <s.NoItems>Not found</s.NoItems>;
  return (
    <>
      <s.BodyWrapper>
        <s.Grid>
          <s.HeaderRow>
            <s.HeaderCell>RANK</s.HeaderCell>
            <s.HeaderCell>PLAYER</s.HeaderCell>
            <s.HeaderCell center active={sortBy === "orbsDonated"}  onClick={() => handleSort("orbsDonated")}>
              <img
                src={sortBy === "orbsDonated" ? iconOrbsY : iconOrbsG}
                alt="orbsDonated"
              />
              <div>
                {headers["orbsDonated"]}
              </div>
              <img
                src={sortBy === "orbsDonated" ? iconSortY : iconSortG}
                style={
                  !sortDesc && sortBy === "orbsDonated"
                    ? { transform: "rotate(0.5turn)" }
                    : {}
                }
                alt="orbsDonated"
              />
            </s.HeaderCell>
            <s.HeaderCell center active={sortBy === "militaryScore"} onClick={() => handleSort("militaryScore")}>
              <img
                src={sortBy === "militaryScore" ? iconMilitaryY : iconMilitaryG}
              />
              <div >
                {headers["militaryScore"]}
              </div>
              <img
                src={sortBy === "militaryScore" ? iconSortY : iconSortG}
                style={
                  !sortDesc && sortBy === "militaryScore"
                    ? { transform: "rotate(0.5turn)" }
                    : {}
                }
              />
            </s.HeaderCell>
            <s.HeaderCell center active={sortBy === "economicScore"} onClick={() => handleSort("economicScore")}>
              <img
                src={sortBy === "economicScore" ? iconEconomicY : iconEconomicG}
              />
              <div >
                {headers["economicScore"]}
              </div>
              <img
                src={sortBy === "economicScore" ? iconSortY : iconSortG}
                style={
                  !sortDesc && sortBy === "economicScore"
                    ? { transform: "rotate(0.5turn)" }
                    : {}
                }
              />
            </s.HeaderCell>
            <s.HeaderCell center active={sortBy === "totalScore"} onClick={() => handleSort("totalScore")}>
              <img src={sortBy === "totalScore" ? iconTotalY : iconTotalG} />
              <div >
                {headers["totalScore"]}
              </div>
              <img
                src={sortBy === "totalScore" ? iconSortY : iconSortG}
                style={
                  !sortDesc && sortBy === "totalScore"
                    ? { transform: "rotate(0.5turn)" }
                    : {}
                }
              />
            </s.HeaderCell>
          </s.HeaderRow>
          {sorted.map((player, i) => (
            <TableRow
              key={player.id}
              $i={i}
              onClick={() => navigate(`/profile/${player.id}`)}
              player={player}
            />
          ))}
        </s.Grid>
      </s.BodyWrapper>
      <s.BodyImg />
    </>
  );
}
