import Box from "components/Box";
import { Spinner } from "components/Spinner";
import * as s from "components/Staking/StakeForEventPoints/StakePage/StakeItemPage.styled";
import Text from "components/Text/Text";
import { useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router";
import { fetchAllItems } from "redux/slices/collectionSlice";
import {
  fetchEvent,
  fetchSlots,
  selectEvent,
  selectSlots,
} from "redux/slices/eventPointsSlice";
import { actions } from "redux/slices/stakePageSlice";
import { useAppDispatch, useAppSelector } from "store";
import { IGeneratedItem } from "types/stakingTypes";
import back from "./assets/back.svg";
import { Filters } from "./Filters";
import { ItemsList } from "./ItemsList";
import { RightSideContent } from "./RightSideContent";

export default function StakeItemPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => window.scroll(0, 0), []);

  let totalItems = useAppSelector(
    (state) => state.collectionPage.allItems.totalItems
  );

  const selectedItemId = useAppSelector(
    (state) => state.stakePage.selectedItemId
  );
  const sets = useAppSelector(selectSlots);
  const event = useAppSelector(selectEvent);

  const selectedItem = totalItems.find(
    (i) => i.id === selectedItemId
  ) as unknown as IGeneratedItem;

  useLayoutEffect(() => {
    if (!totalItems.length) dispatch(fetchAllItems());
    dispatch(actions.setSelectedItemId(undefined));
  }, []);
  useLayoutEffect(() => {
    if (!event.id) dispatch(fetchEvent());
    dispatch(fetchSlots());
  }, [event.id]);

  const itemIds = sets.reduce((acc, row) => {
    const rowIds = row.slotsList.map((item) => item.itemId);
    return acc.concat(rowIds);
  }, [] as string[]);

  let filteredItems = totalItems.filter(
    (item) => item?.generated?.mintStatus === 2 && !itemIds.includes(item?.id)
  ) as unknown as IGeneratedItem[];

  return (
    <s.Wrapper>
      <s.Title>
        <img src={back} onClick={() => navigate(-1)} />
        Stake for event point
      </s.Title>
      {sets.length === 0 && <Spinner />}
      {filteredItems.length > 0 && (
        <s.Body>
          <s.LeftSide>
            <Filters />
            <ItemsList items={filteredItems} />
          </s.LeftSide>
          <s.RightSide>
            {selectedItem && <RightSideContent item={selectedItem} />}
          </s.RightSide>
        </s.Body>
      )}

      {totalItems.length > 0 && filteredItems.length === 0 && sets.length > 0 && (
        <Box mt={20} h={300} alignItems="center" justifyContent="center">
          <Text w={500} s={26} color="white">
            You have no items ready for staking. Please mint some items first.
          </Text>
        </Box>
      )}
    </s.Wrapper>
  );
}
