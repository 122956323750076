import { getClanInfo } from "api";
import { Submenu } from "components/IntraClan/Submenu/Submenu";
import {
  BlockWrapper,
  BtnWrapper,
  Container,
  Title,
  TransferBtn,
} from "components/Transfer/ClanMembers/ClanMembers.style";
import { TransferHistoy } from "components/Transfer/TransferHistory/TransferHistory";
import { Wrapper } from "components/Wrapper";
import { useIsMobile } from "hooks/useMediaQuery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

export const HistoryPage = () => {

  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [clanName, setClanName] = useState('')
  const [isClanLeader, setLeader] = useState(false)

  const getUserInfo = async () => {
      const res = await getClanInfo()
      setClanName(res.clanName)
      if(res.role === 2){
        sessionStorage.setItem('isClanLeader', 'true')
        setLeader(true)
      }else {
        sessionStorage.setItem('isClanLeader', '')
      }
  }

  useEffect(() => {
    getUserInfo()
  }, [])

  return (
    <Wrapper>
      <Submenu />
      {isMobile && (
        <BtnWrapper>
          <Title>
            <div style={{ fontWeight: "700" }}>
              Clan Transfer History View
            </div>
          </Title>
          <TransferBtn 
            onClick={() => isClanLeader && navigate("/transferItems")}
            disabled={!isClanLeader}
            >
            Transfer History
          </TransferBtn>
        </BtnWrapper>
      )}
      <Container isMobile={isMobile}>
        {!isMobile && (
          <BtnWrapper>
            <Title>
              <div style={{ fontWeight: "700" }}>
                Clan Transfer History View
              </div>
            </Title>
            {isClanLeader && <TransferBtn
              onClick={() => navigate("/clanWars/transferItems")}
            >
              Transfer Items
            </TransferBtn>}
          </BtnWrapper>
        )}
        <BlockWrapper>
            <TransferHistoy clanName={clanName} />
        </BlockWrapper>
      </Container>
    </Wrapper>
  );
};
