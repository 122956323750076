import styled from "styled-components/macro";
import iIcon from "assets/i_icon.svg";

export function DelayWarning() {
  return (
    <Delay>
      <img src={iIcon} alt="info" />
      Data is calculated with a delay
    </Delay>
  );
}

const Delay = styled.div`
  flex: 0 0 auto;
  border-left: 3px solid #ffb60c;
  //width: 271px;
  height: 32px;
  background: rgba(0, 0, 0, 0.8);
  color: #c1c1c1;
  display: flex;
  align-items: center;
  padding-right: 9px;
  font-weight: 350;
  font-size: 13px;

  & > img {
    margin-left: 7px;
    margin-right: 5px;
  }
`;
