import * as s from "components/Staking/staking.styled";

export function MobilePlaceholder(props: { text: string }) {
  return (
    <s.stakingMobileWrapper>
      <div>{props.text} is available only on desktop browser</div>
      <s.discordBtn />
    </s.stakingMobileWrapper>
  );
}
