import styled from "styled-components/macro";

export const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  width: 520px;
  height: 362px;

  & > img {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
`;

export const Locked = styled(ModalWrapper)`
  & .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-transform: capitalize;
    color: #ffffff;
    margin-bottom: 2px;
  }

  & .titleHelp {
    font-weight: 350;
    font-size: 16px;
    line-height: 19px;
    color: #9a9a9a;
  }

  & .duration {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-transform: capitalize;
    color: #ffffff;
    margin: 22px 0;
  }

  & .buttons {
    display: flex;
    width: 100%;
    gap: 7px;

    & > button {
      height: 60px;
    }
  }

  & .grid {
    margin-top: 29px;
    margin-bottom: 17px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    & > div:nth-child(2n + 1) {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: capitalize;
      color: #838383;
    }

    & > div :nth-child(2n + 2) {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #ffb60c;
      text-align: right;
    }
  }
`;

export const Unlocked = styled(ModalWrapper)`
  & > .text1 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    text-transform: capitalize;
    color: #ffb60c;
    margin-bottom: 29px;
    margin-top: 25px;
  }

  & > .text2 {
    font-weight: 350;
    font-size: 16px;
    line-height: 19px;
    color: #838383;
    margin-bottom: 3px;
  }

  & > .text3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    margin-bottom: 33px;
  }

  & > .button {
    height: 60px;
    width: 193px;
  }
`;

export const Warning = styled.div<{ isActive: boolean }>`
    margin-top: 5px;
    opacity: ${({ isActive }) => isActive ? 1 : 0 };
    color: #FFB60C;
    margin-bottom: -5px;
`;