import { useAppDispatch, useAppSelector } from "store";
import {
  actions,
  selectFilters,
} from "../../../../redux/slices/stakePageSlice";
import Box from "../../../Box";
import icon1 from "./assets/1.png";
import icon2 from "./assets/2.png";
import icon3 from "./assets/3.png";
import icon4 from "./assets/4.png";
import outfitsWhite from "./assets/outfitsWhite.png";
import outfitsYellow from "./assets/outfitsYellow.png";
import weaponsWhite from "./assets/weaponsWhite.png";
import weaponsYellow from "./assets/weaponsYellow.png";
import { FilterBtn } from "./FilterBtn";

export function Filters() {
  const filters = useAppSelector(selectFilters);
  const dispatch = useAppDispatch();
  const type = filters.type;
  const rarity = filters.rarity;
  return (
    <Box gap={4} mb={20}>
      <FilterBtn
        w={120}
        label="outfits"
        icon={type === "outfits" ? outfitsYellow : outfitsWhite}
        active={type === "outfits"}
        onClick={() => dispatch(actions.setType("outfits"))}
      />
      <FilterBtn
        w={120}
        label="weapon"
        icon={type === "weapons" ? weaponsYellow : weaponsWhite}
        active={type === "weapons"}
        onClick={() => dispatch(actions.setType("weapons"))}
      />
      <Box flex="1 1 auto" />
      <FilterBtn
        w={40}
        label="all"
        active={rarity.includes("all")}
        onClick={() => dispatch(actions.toggleFilter("all"))}
      />
      <FilterBtn
        w={40}
        icon={icon1}
        active={rarity.includes(2)}
        onClick={() => dispatch(actions.toggleFilter(2))}
      />
      <FilterBtn
        w={40}
        icon={icon2}
        active={rarity.includes(3)}
        onClick={() => dispatch(actions.toggleFilter(3))}
      />
      <FilterBtn
        w={40}
        icon={icon3}
        active={rarity.includes(4)}
        onClick={() => dispatch(actions.toggleFilter(4))}
      />
      <FilterBtn
        w={40}
        icon={icon4}
        active={rarity.includes(5)}
        onClick={() => dispatch(actions.toggleFilter(5))}
      />
    </Box>
  );
}
