import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { clanevents_getProductPrice, wd_getStats } from "api";
import { ICurrency, IGetClanAssets } from "api/api.types";
import butterIcon from "assets/butter_icon.png";
import Box from "components/Box";
import Column from "components/Column";
import { Gap } from "components/Gap";
import GreenButton from "components/GreenButton";
import Img from "components/Img";
import { QuantityCounterFlex } from "components/QuantityCounterFlex/QuantityCounterFlex";
import RedButton from "components/RedButton";
import { Spinner } from "components/Spinner";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import { HorizontalLine } from "components/TableClans/CloseModal.styled";
import Text from "components/Text/Text";
import { useSwr, useSwrImmutable } from "hooks/useSwr";
import arrowRight from "pages/WorldDomination/assets/arrowRight.png";
import butterBigIcon from "pages/WorldDomination/assets/butterBigIcon.png";
import cityShield from "pages/WorldDomination/assets/cityShieldBig.png";
import cityShieldGreenIcon from "pages/WorldDomination/assets/cityShieldGreenIcon.svg";
import * as s from "pages/WorldDomination/WDStatsPanel/ConstructionModal.styled";
import { Icon } from "pages/WorldDomination/WDStatsPanel/StatsPanel.styled";
import { useEffect, useState } from "react";
import {
  useAddToQueueMutation,
  useGetClanAssetsQuery,
  useGetClanInfoQuery,
  useGetEventQuery,
  useGetEventTurnQuery,
  useGetRoundQueueQuery,
  useGetUserRestrictionsQuery,
  useRemoveFromQueueMutation,
} from "redux/rtkQuery/rtkApi";
import { addErrorMessage } from "redux/slices/messageSystemSlice";
import {
  selectBuildShield,
  selectStreamItemById2,
} from "redux/slices/worldDominationSlice";
import { useAppDispatch, useAppSelector } from "store";
import { numberWithCommas } from "utils/numberWithComas";
import { v4 as uuidv4 } from "uuid";
import cityShieldIcon from "../assets/cityShieldIconSmall.svg";
import selectCityIcon from "../assets/selectCityIcon.svg";
import whiteCheck from "../assets/whiteCheck.svg";

type ICity = { id: string; name: string };

type IStatus = "idle" | "buy" | "pending" | "done";

export function CityShieldModalContent(props: {
  onClose: Function;
  player: IProfileFull;
}) {
  const dispatch = useAppDispatch();
  const currency: ICurrency = "BUTTER";

  const { data: event } = useGetEventQuery();
  const { data: turn, refetch: refetchTurn } = useGetEventTurnQuery(
    event?.id ?? skipToken
  );
  const { data: queue } = useGetRoundQueueQuery();
  const [addToQueue] = useAddToQueueMutation();
  const [removeFromQueue] = useRemoveFromQueueMutation();
  const { data: assets } = useGetClanAssetsQuery(event?.id ?? skipToken);
  const { data: userRestrictions } = useGetUserRestrictionsQuery();
  const { data: info } = useGetClanInfoQuery();

  const buildShieldCity = useAppSelector(selectBuildShield);
  const [status, setStatus] = useState<IStatus>("idle");
  const [stats] = useSwrImmutable("wdStats", wd_getStats);
  const [selectedCity, setSelectedCity] = useState<ICity | undefined>(buildShieldCity);
  const [amount, setAmount] = useState(1);
  const [prices, loadingPrices] = useSwr(
    event?.id && turn?.turn?.turnId && typeof amount !== "string" && amount > 0
      ? [
          {
            eventId: event.id,
            amount,
            turnId: turn.turn.turnId,
            currency,
            cityId: turn.clan.slotsList.filter((i) => i.kind === 2)[0].id,
          },
          "getProductPrices",
        ]
      : null,
    clanevents_getProductPrice
  );

  const [requestId, setRequestId] = useState(() => uuidv4());
  const transactionResult = useAppSelector(selectStreamItemById2(requestId));

  // Wait transaction to finish
  useEffect(() => {
    if (transactionResult === undefined) return;
    if (transactionResult) {
      if (transactionResult?.error) {
        if (transactionResult?.error?.code === 3) {
          dispatch(addErrorMessage({title: "Something went wrong", text: "Insufficient funds",}));
        } else if (transactionResult?.error?.code === 6) {
          dispatch(addErrorMessage({title: "Something went wrong", text: "Construction limit exceeded",}));
        } else if (transactionResult?.error?.code === 5) {
          dispatch(addErrorMessage({title: "Something went wrong", text: "Can not allocate space for construction",}));
        } else {
          dispatch(addErrorMessage({title:"Error", text: "Something went wrong" }));
        }
        props.onClose();
      } else {
        setStatus("done");
        setRequestId(uuidv4());
      }
    }
  }, [transactionResult]);

  if (!stats || !queue || !turn || !assets || !userRestrictions || !info)
    return null;

  const cities = turn.clan.slotsList.filter((i) => i.kind === 2);
  const balance = turn.clan.balancesList.find(
    (i) => i.currency === currency
  )!.amount;
  const cityShieldsCount = assets.cityShieldsList.length;
  const cityShieldsPlannedIds = queue.cityShield
    .filter((i) => (selectedCity ? i.cityId === selectedCity.id : true))
    .map((i) => i.id);
  const cityShieldsPlanned = queue.cityShield.reduce(
    (acc, item) => acc + item.count,
    0
  );

  let hintText = "";
  if ((prices?.totalPrice || 0) > balance) hintText = "Insufficient Butter";
  if ((prices?.totalPrice || 0) > userRestrictions.queueRestrictions.butter.limit && !userRestrictions.queueRestrictions.butter.unlimited)
    hintText = "The total cost is over your build limit";
  if (!["LEADER", "OFFICER"].includes(info.role))
    hintText = "You Must be a Clan Leader, or Officer in order to Build";

  const handleAmountChange = (n: number) => {
    setAmount(n);
  };

  const handleBuy = async () => {
    if (!selectedCity) return;
    setStatus("pending");
    const resp = (await addToQueue({
      type: "cityShield",
      amount,
      turnId: turn.turn.turnId,
      currency,
      cityId: selectedCity.id,
      requestId,
    })) as any;
    if (resp?.error) {
      props.onClose();
      dispatch(addErrorMessage({ title: "Error", text: "Something went wrong" }));
    }
    await refetchTurn();
  };

  const handleRemove = async () => {
    setStatus("pending");

    const data = (await removeFromQueue({
      turnId: turn.turn.turnId,
      itemIds: cityShieldsPlannedIds,
    })) as any;
    if (data?.error) {
      props.onClose();
      dispatch(
        addErrorMessage({ title: "Error", text: "Something went wrong" })
      );
    }
    refetchTurn();
    props.onClose();
  };

  return (
    <s.Content>
      <s.Close onClick={() => props.onClose()}>
        <img src={closeIcon} />
      </s.Close>
      {status === "idle" && (
        <>
          <s.InfoCityShield>
            <s.Img src={cityShield} />
            <s.InfoContent>
              <s.Title>City Shield Construction</s.Title>
              <Gap h={16} />

              <s.DescriptionCityShield>
                City Shields are able to defend a specific city against 1
                incoming Nuke. The shield is destroyed during this defense.
                Multiple shields are needed in order to protect from multiple
                simultaneous Nuke attacks on the same city in the same turn.
              </s.DescriptionCityShield>
            </s.InfoContent>
          </s.InfoCityShield>
          <HorizontalLine />
          <s.OrderBlock>
            <s.CurrentCityShield>
              <div>Current Quantity:</div>
              <div className="count">{cityShieldsCount}</div>
              <s.CityShieldCost>
                <div>Cost:</div>
                <Box>
                  <Icon src={butterIcon} height={20} />
                  <Text>
                    {amount > 0 && (
                      <>
                        {prices?.totalPrice
                          ? numberWithCommas(prices.totalPrice)
                          : "calculating"}{" "}
                        Butter
                      </>
                    )}
                  </Text>
                </Box>
              </s.CityShieldCost>
            </s.CurrentCityShield>
            <s.PlannedCityShield>
              <div>
                Planned for Next Turn:
                {cityShieldsPlanned > 0 && <s.Remove onClick={handleRemove} />}
              </div>
              <s.PlannedCount className="count" count={cityShieldsPlanned} />
              <SelectCity
                cities={cities}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                assets={assets}
              />
            </s.PlannedCityShield>
            <Box
              justifyContent="space-between"
              alignItems="center"
              w={289}
              ml="auto"
            >
              <Text grey>Quantity:</Text>
              <QuantityCounterFlex
                amount={amount}
                setAmount={handleAmountChange}
                max={100}
              />
            </Box>
          </s.OrderBlock>
          <Box w={278} mt={21} mb={10} alignSelf="center">
            <GreenButton
              h={60}
              onClick={() => setStatus("buy")}
              disabled={
              !!hintText ||
              !selectedCity ||
              typeof amount !== "number" ||
              amount === 0 ||
              amount > 100 ||
              loadingPrices
              }
            >
              Build
            </GreenButton>
          </Box>
          <s.ButtonHint>{hintText}</s.ButtonHint>
        </>
      )}
      {status === "buy" && (
        <>
          <Gap h={28} />
          <Box column alignItems="center">
            <Box alignItems="center">
              <img src={butterBigIcon} height={133} />
              <Gap w={12} />
              <img src={arrowRight} />
              <Gap w={22} />
              <img src={cityShield} width={160} />
            </Box>
            <Gap h={12} />

            <Text s={22}>
              Are you sure you want to buy{" "}
              <Text s={22} yellow inline>
                City Shields?
              </Text>
            </Text>
            <Box alignItems="center">
              <Text s={22}>On </Text>
              <Text s={22} yellow inline>
                {selectedCity?.name}
              </Text>
              <Text s={22}> For </Text>
              <Img src={butterIcon} w={20} h={20} />
              <Text s={22} blue inline>
                {" "}
                {prices?.totalPrice
                  ? numberWithCommas(prices.totalPrice)
                  : "calculating"}{" "}
                Butter
              </Text>
              <Text s={22}> Butter</Text>
            </Box>
            <Gap h={10} />
            <Text w={350} grey>
              Clan Butter Balance: {numberWithCommas(balance)}
            </Text>
          </Box>
          <Gap h={38} />
          <Box gap={17}>
            <RedButton h={60} onClick={() => setStatus("idle")}>
              Cancel
            </RedButton>
            <GreenButton h={60} onClick={() => handleBuy()}>
              Confirm
            </GreenButton>
          </Box>
        </>
      )}
      {status === "pending" && <Spinner />}
      {status === "done" && (
        <Column alignItems="center">
          <Gap h={28} />
          <Img src={cityShield} w={160} />
          <Gap h={12} />
          <Box fontSize={22}>
            You bought
            <Text yellow> {amount} City Shields</Text>
          </Box>
          <Box fontSize={22} alignItems="center">
            <Text>On </Text>
            <Text yellow>{selectedCity?.name}</Text>
            <Text> For </Text>
            <Img src={butterIcon} w={20} h={20} />
            <Text blue>
              {" "}
              {prices?.totalPrice
                ? numberWithCommas(prices.totalPrice)
                : "calculating"}{" "}
              Butter{" "}
            </Text>
            Butter!
          </Box>
          <Gap h={10} />
          <Text w={350} grey>
            Clan Butter Balance: {numberWithCommas(balance)}
          </Text>
          <Gap h={38} />
          <GreenButton onClick={() => props.onClose()} h={70} w={276}>
            <Img src={whiteCheck} /> Done!
          </GreenButton>
        </Column>
      )}
    </s.Content>
  );
}

function SelectCity(props: {
  selectedCity?: ICity;
  setSelectedCity: Function;
  cities: ICity[];
  assets: IGetClanAssets;
}) {
  const [open, setOpen] = useState(false);
  return (
    <s.SelectCityWrapper>
      <s.SelectCityInput open={open} onClick={() => setOpen(!open)}>
        {props.selectedCity?.name || "Select city:"}
        <img src={selectCityIcon} />
      </s.SelectCityInput>
      <s.SelectCityContent open={open}>
        {props.cities.map((city) => (
          <SelectCityItem
            key={city.id}
            city={city}
            assets={props.assets}
            setOpen={setOpen}
            setSelectedCity={props.setSelectedCity}
          />
        ))}
      </s.SelectCityContent>
    </s.SelectCityWrapper>
  );
}

function SelectCityItem(props: {
  city: ICity;
  assets: IGetClanAssets;
  setSelectedCity: any;
  setOpen: any;
}) {
  const { data: queue } = useGetRoundQueueQuery();
  if (!queue) return null;
  const shieldsCount = props.assets.cityShieldsList.filter(
    (i) => i.cityId === props.city.id
  ).length;
  const shieldsPlanned = queue.cityShield
    .filter((i) => i.cityId === props.city.id)
    .reduce((acc, item) => acc + item.count, 0);
  return (
    <s.SelectItem
      key={props.city.name}
      onClick={() => {
        props.setSelectedCity(props.city);
        props.setOpen(false);
      }}
    >
      {props.city.name}
      <Box flex="1" />
      <Box alignItems="center">
        {shieldsPlanned > 0 && (
          <Text green s={12} mr={4}>
            +{shieldsPlanned}{" "}
            <Img inline src={cityShieldGreenIcon} w={10} h={11} />
          </Text>
        )}
        <Text s={12} grey mr={4}>
          {shieldsCount}
        </Text>{" "}
        <Img src={cityShieldIcon} mb={3} />
      </Box>
    </s.SelectItem>
  );
}
