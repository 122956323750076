import * as s from "components/CommonMessageModal/SimpleModal.styled";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import { GreenButton } from "components/Staking/StakeForEventPoints/Grid/Grid.styled";
import { WDModalStyles } from "pages/WorldDomination/modalStyles";
import Modal from "react-modal";

export function SimpleModal({onClose, text, title}: { title?: string; text: string; onClose: Function }) {
  const handleClose = () => {
    onClose()
  };
 
  return (
    <Modal
      isOpen
      style={WDModalStyles}
      shouldCloseOnEsc
      preventScroll
      onRequestClose={handleClose}
    >
      <s.Close onClick={()=>handleClose()}>
        <img src={closeIcon} />
      </s.Close>
      <s.Content>
        {title && <s.Title>{title}</s.Title>}
        <s.Text>{text}</s.Text>
        <GreenButton onClick={handleClose}>Ok</GreenButton>
      </s.Content>
    </Modal>
  );
}
