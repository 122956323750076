import * as s from "./styled";

import { useState } from "react";
import { useNavigate } from "react-router";
import { numberWithCommas } from "utils/numberWithComas";
import topThreeKills from "assets/topThreeKills.svg";
import topThreeDeath from "assets/topThreeDeath.svg";
import topThreeKdr from "assets/topThreeKdr.svg";
import { Gap } from "components/Gap";
import iconTotalG from "assets/total-point-icon-grey.png";
import { useSwrImmutable } from "hooks/useSwr";
import { getAccountDetails } from "api";

type ITableContent = {
  data: IIntraClanPlayer[];
};

export default function TableContentMobile({ data }: ITableContent) {
  return (
    <>
      <s.BodyWrapper>
        <s.Grid>
          <s.HeaderRowMobile>
            <s.HeaderCell>#</s.HeaderCell>
            <s.HeaderCell>PLAYER</s.HeaderCell>
            <s.HeaderCell>
              <img src={iconTotalG} alt="total" /> TP
            </s.HeaderCell>
          </s.HeaderRowMobile>
          {data.map((player, i) => (
            <GridRowMob key={player.id} i={i} player={player} />
          ))}
        </s.Grid>
      </s.BodyWrapper>
      <s.BodyImg />
    </>
  );
}

function GridRowMob({ i, player }: { i: number; player: IIntraClanPlayer }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handlePlayerClick = () => navigate(`/profile/${player.id}`);
  const [profile] = useSwrImmutable([player.id, "user"], getAccountDetails);
  if (!profile) return null;
  return (
    <>
      <s.GridRowMobile $i={i}>
        <s.GridCell>{i+1}</s.GridCell>
        <s.GridCell onClick={handlePlayerClick}>
          <s.GridImg src={profile.icon} />
          {profile.name}
        </s.GridCell>
        <s.GridCell>{numberWithCommas(player.totalScore)}</s.GridCell>
        <s.GridCell onClick={() => setOpen(!open)}>
          <s.TableArrowMob open={open} />
        </s.GridCell>
      </s.GridRowMobile>
      <s.HiddenStats open={open}>
        <Gap h={3} />
        <s.StatsItemCW>
          <s.StatsItemImg src={topThreeKills} alt="kills" />
          <s.StatsItemText>Military points</s.StatsItemText>
          <s.StatsItemCount>
            {numberWithCommas(player.militaryScore)}
          </s.StatsItemCount>
        </s.StatsItemCW>
        <s.StatsItemCW>
          <s.StatsItemImg src={topThreeDeath} alt="deaths" />
          <s.StatsItemText>Economic points</s.StatsItemText>
          <s.StatsItemCount>
            {numberWithCommas(player.economicScore)}
          </s.StatsItemCount>
        </s.StatsItemCW>
        <s.StatsItemCW>
          <s.StatsItemImg src={topThreeKdr} alt="kdr" />
          <s.StatsItemText>Enriched Orbs donated</s.StatsItemText>
          <s.StatsItemCount>
            {numberWithCommas(player.orbsDonated)}
          </s.StatsItemCount>
        </s.StatsItemCW>
        {/*{!(props.i % 2) && <s.Separator />}*/}
      </s.HiddenStats>
    </>
  );
}
