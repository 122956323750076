import butterImg from "assets/butter_icon.png";
import bpIcon from "components/TieredLeaderboard/assets/bp.png";
import * as s from "components/TieredLeaderboard/componetns/Desk.styled";
import { RewardItem } from "components/TieredLeaderboard/componetns/RewardItem";
import Tooltip from "rc-tooltip";
import { numberWithCommas } from "utils/numberWithComas";
import { IItemsNormalized, IRewardItem } from "../../../api/api.types";
import seeAll from "../assets/seeAll.svg";
import { DeskTooltipContent } from "./DeskTooltipContent";
import "./rc-tooltip.css";
import { RewardToken } from "./RewardToken";

export function DeskRewards({
  items,
  rewards,
}: {
  rewards: IRewardItem[];
  items: IItemsNormalized;
}) {
  const butter = rewards.find((item) => item.butter)?.butter?.amount || 0;
  const battlePassPoints =
    rewards.find((item) => item.battlePassPoints)?.battlePassPoints?.amount ||
    0;
  const inventory = rewards.filter((item) => item.inventory);
  const tokens = rewards.filter((item) => item.token);
  const totalItems = inventory.length + tokens.length;
  const allRewards = inventory.concat(tokens);
  let shownRewards: IRewardItem[];
  if (totalItems > 3) {
    shownRewards = allRewards.slice(0, 2);
  } else shownRewards = allRewards;

  return (
    <s.Rewards>
      <div>
        <Tooltip placement="top" overlay={<div>Butter</div>}>
          <div>
            <img src={butterImg} alt="butter" width={24} />
            {numberWithCommas(butter)}
          </div>
        </Tooltip>
        <Tooltip placement="top" overlay={<div>Battle Pass Points</div>}>
          <div>
            <img src={bpIcon} alt="bp" />
            {numberWithCommas(battlePassPoints)}
          </div>
        </Tooltip>
      </div>
      <div>
        {shownRewards.map((item) => {
          if (item.inventory)
            return (
              <RewardItem
                item={item.inventory!}
                items={items}
                key={item.inventory?.itemId!}
              />
            );
          if (item.token)
            return (
              <RewardToken token={item.token!} key={item.token!.configId} />
            );
          else return null;
        })}
        {/* TOOLTIP BLOCK IF MUCH ITEMS*/}
        {totalItems > 3 && (
          <Tooltip
            placement="topLeft"
            overlay={
              <DeskTooltipContent
                iRewardItems={allRewards}
                items={items}
                butter={butter}
                battlePassPoints={battlePassPoints}
              />
            }
          >
            {/*TOOLTIP TRIGGER*/}
            <s.SeeAll>
              <div>+{totalItems - 2}</div>
              <div>
                See all
                <img src={seeAll} alt="seeAll" />
              </div>
            </s.SeeAll>
          </Tooltip>
        )}
      </div>
    </s.Rewards>
  );
}
