import React, { useEffect } from "react";
import * as s from "./styled";
import Portal from "components/Portal";

export default React.memo(function Modal({
  open,
  children,
  onClose,
  width,
}: {
  open: boolean;
  children: JSX.Element | string;
  onClose?: () => void;
  width?: number | string;
}) {
  useEffect(() => {
    if (open) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);
  if (!open) return null;
  return (
    <Portal>
      <s.Background onClick={onClose}>
        <s.Wrapper
          width={width}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <s.CloseButton onClick={onClose} />
          <s.Inner>{children}</s.Inner>
        </s.Wrapper>
      </s.Background>
    </Portal>
  );
});

