import styled from "styled-components/macro";

export const WdWrapper = styled.div`
  max-width: 1200px;
  min-width: 1200px;
  margin: 40px auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderText = styled.div`
  font-weight: 400;
  font-size: 35px;
  line-height: 42px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-right: 8px;
`;

export const Grow = styled.div`
  flex: 1 1 10px;
`;

export const UserAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: whitesmoke;
  margin-right: 10px;
`;

export const UserClanName = styled.div`
  font-weight: 400;
  font-size: 18px;
  color: white;
`;

export const UserClanHash = styled.div`
  margin-left: 6px;
  font-weight: 400;
  font-size: 18px;
  color: #A7A7A7;
`;







