import styled, { css } from "styled-components/macro";
import userBlockBG1 from "assets/userBlockBG1.png";
import userBlockBG2 from "assets/userBlockBG2.png";
import smallBg from "assets/smallBg.png";
import matchHistoryBG from "assets/matchHistoryBG.png";
import matchHistoryBG_s from "assets/matchHistoryBG_s.png";
import { BodyImg } from "components/TableScores/styled";
import ring2 from "assets/ring2.svg";
import levelBg from "assets/level_bg.png";
import follow_bg from "assets/follow_bg.svg";

import followBtnBg from "assets/followButtonBg.svg";
import unfollowBtnBg from "assets/unfollowBtnBg.svg";
import followBtnBgMob from "assets/folloewBtnBgMob.svg";
import unfollowBtnBgMob from "assets/unfollowBtnBgMob.svg";
import reportButtonBg from "assets/reportButtonBg.svg";
import reportButtonBgMob from "assets/reportButtonBgMob.svg";
import arrow from "assets/filterArrow.svg";
import Box from "components/Box";

export const ReportModalContent = styled.div`
  width: 400px;
  padding: 30px;

  p {
    color: white;
  }
`;

export const ProfileWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  margin: 20px 20px;

  @media (min-width: 768px) {
    margin: 24px 24px;
    grid-gap: 24px;
  }
  @media (min-width: 1280px) {
    margin: 30px auto;
    grid-gap: 30px;
    max-width: 1170px;
  }
`;

export const Block = styled.div`
  grid-column: auto / span 4;
  background-image: url(${userBlockBG1});
  @media (min-width: 768px) {
    background-image: url(${userBlockBG2});
  }
  @media (min-width: 1280px) {
    grid-column: auto / span 2;
    grid-row: auto / span 2;
    width: 570px;
  }
  background-size: 100% 100%;
`;
export const UserBlockWrapper = styled(Block)`
  box-sizing: border-box;
  padding: 30px 30px;
  @media (min-width: 768px) {
    padding: 30px 40px;
  }
`;
export const UserTop = styled.div`
  display: flex;
`;

export const UserInfo = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
`;
export const UserName = styled.div`
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 5px;
`;

export const UserClan = styled.div`
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 11px;
`;

export const UserOnline = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  color: #ffffff;
  opacity: 0.8;
  align-items: center;
  visibility: hidden;
`;

export const Level = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  background: url(${levelBg});
  background-size: cover;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #ffffff;

  p {
    margin: 0;
    font-size: 24px;
    line-height: 29px;
    text-transform: uppercase;
    color: #ffb60c;
  }
  @media (min-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

export const Separator = styled.div`
  flex: 1 1 auto;
`;

export const HorizontalLine2 = styled.div`
  margin: 35px 0 30px;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.06) 100%
  );
`;

export const OnlineStatus = styled.div<{ $isOnline: boolean }>`
  width: 8px;
  height: 8px;
  margin-right: 4px;
  border-radius: 50%;
  background-color: ${(props) => (props.$isOnline ? "#59B015" : "#646464")};
`;

export const Overall = styled(Block)`
  display: flex;
  flex-direction: column;
`;

export const OverallHeader = styled.div`
  display: flex;
  align-items: center;
  padding-left: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  opacity: 0.6;
  min-height: 60px;
`;

export const OverallContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 2px 1fr 2px 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 2px 1fr;
  }
`;

export const OverallBlock = styled.div`
  padding: 36px 32px 33px 32px;
`;

export const BlockCaption = styled.div`
  margin-top: 14px;
  margin-bottom: 4px;
  font-size: 12px;
  color: #ffffff;
  opacity: 0.6;
`;

export const BlockValue = styled.div`
  font-size: 16px;
  color: #ffffff;
  opacity: 0.9;
`;

export const VerticalLine = styled.div`
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  height: 80px;
  align-self: center;
`;
export const HorizontalLine = styled.div`
  grid-column: span 5;
  @media (max-width: 768px) {
    grid-column: span 3;
  }
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  width: calc(100% - 60px);
  justify-self: center;
`;

export const SmallBlock = styled.div`
  color: #ffffff;
  min-height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-image: url(${smallBg});
  background-size: 100% 100%;

  grid-column: auto / span 4;
  @media (min-width: 768px) {
    grid-column: auto / span 2;
  }
  @media (min-width: 1280px) {
    grid-column: auto / span 1;
  }
`;
export const SmallCount = styled.div`
  margin-top: 6px;
  font-size: 24px;
  line-height: 29px;
  opacity: 0.9;
`;
export const SmallText = styled.div`
  font-size: 14px;
  line-height: 17px;
  opacity: 0.6;
`;
export const TableBlock = styled.div`
  grid-column: auto / span 4;
  grid-row: auto / span 4;
  background: #7f7f7f;
  min-height: 140px;
`;

export const MhWrapper = styled.div`
  position: relative;
  margin-top: 30px;
  grid-column: span 4;
  padding-bottom: 30px;
  background: url(${matchHistoryBG_s});
  @media (min-width: 768px) {
    background: url(${matchHistoryBG});
    background-size: 100% 100%;
  }
  background-size: 100% 100%;
`;

export const MhHeader = styled(OverallHeader)`
  margin-bottom: 20px;
`;
export const Row = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: minmax(min-content, 1.5fr) repeat(
      7,
      minmax(min-content, 1fr)
    );
  gap: 10px;
  align-items: center;
`;

export const GridRow = styled(Row)`
  height: 60px;
  margin: 0 30px;
  padding-left: 20px;
  ${(props: { $i: number }) => {
    return (
      !(props.$i % 2) &&
      css`
        background: rgba(255, 255, 255, 0.03);
        border: 1px solid rgba(255, 255, 255, 0.12);
      `
    );
  }};

  @media (min-width: 1280px) {
    padding-left: 25px;
  }
`;

export const RowMobile = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 2fr 1fr 30px;
  gap: 10px;
  align-items: center;
`;

export const GridRowMobile = styled(RowMobile)`
  height: 70px;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 20px;
  padding-right: 5px;
  ${(props: { $i: number }) => {
    return (
      !(props.$i % 2) &&
      css`
        background: rgba(255, 255, 255, 0.03);
        border: 1px solid rgba(255, 255, 255, 0.12);
      `
    );
  }};

  div:nth-child(4) {
    justify-content: center;
  }

  div:nth-child(3) {
    justify-content: flex-end;
  }
`;

export const GridCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #ffffff;

  //opacity: 0.9;
  p {
    font-size: 12px;
    line-height: 14px;
    margin: 0 0 4px;
    text-transform: capitalize;
    color: #ffffff;
    opacity: 0.6;
  }
`;

export const TableArrowMob = styled.img.attrs({
  src: arrow,
})<{ open: boolean }>`
  width: 20px;
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.1s;
  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      transform: rotate(-180deg);
    `};
`;

export const BottomImg = styled(BodyImg)`
  bottom: 20px;
  @media (min-width: 768px) {
    bottom: 30px;
  }
`;

export const IconRing = styled.div`
  box-sizing: border-box;
  flex-direction: row;
  width: 80px;
  height: 80px;
  margin: 0 20px 0 0;

  background-image: url(${ring2});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  padding: 12px;
`;

type IIcon = {
  icon: string;
  name: string;
};
export const Icon = styled.img.attrs<IIcon>((props) => ({
  src: props.icon,
  alt: props.name,
}))<IIcon>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: black;
  border-radius: 50%;
  filter: none;
`;

export const FollowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 748px) {
    flex-direction: column;
  }
`;

export const Followers = styled.div`
  display: flex;
  height: 40px;
  width: 220px;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #ffffff;
  opacity: 0.9;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-left: 12px;
  }
  p {
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;
    color: #ffffff;
    opacity: 0.6;
    margin-right: 60px;
  }
  background: url(${follow_bg});
  background-size: cover;
`;

const BtnWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  width: 220px;
  cursor: pointer;

  img {
    margin-right: 10px;
  }

  p {
    margin: 0 25px 0 0;
  }

  @media (max-width: 748px) {
    justify-content: flex-start;
    img {
      margin-left: 10px;
    }
  }
`;

export const FollowBtnWrapper = styled(BtnWrapper)<{
  status?: "unknown" | "following" | "not_following";
}>`
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  visibility: ${({ status }) => (status === "unknown" ? `hidden` : `visible`)};
  background: ${({ status }) =>
    status === "following" ? `url(${unfollowBtnBg})` : `url(${followBtnBg})`};
  @media (max-width: 748px) {
    background: ${({ status }) =>
        status === "following"
          ? `url(${unfollowBtnBgMob})`
          : `url(${followBtnBgMob})`}
      no-repeat;
  }
  background-size: 100% 100%;

  p {
    color: ${({ status }) =>
      status === "following"
        ? `#FF0000`
        : status === "not_following"
        ? `#FFB60C`
        : `#FFB60C`};
  }
`;

export const ReportBtnWrapper = styled(BtnWrapper)`
  background: url(${reportButtonBg});
  background-size: 100% 100%;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.6);

  @media (max-width: 748px) {
    background: url(${reportButtonBgMob});
  }
`;

export const HiddenStats = styled.div<{ open: boolean }>`
  height: ${({ open }) => (open ? `142px` : "0px")};
  overflow: hidden;
  display: grid;
  transition: all 0.1s;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const StatItem = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 20px;
  padding-top: 17px;
  height: 70px;
  box-sizing: border-box;
`;

export const StatTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 6px;
  color: rgba(255, 255, 255, 0.6);
`;

export const StatText = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;

export const StatsSeparator = styled.div`
  grid-column: span 3;
  margin: 0 20px;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.15) 49.83%,
    rgba(255, 255, 255, 0.06) 100%
  );
`;
