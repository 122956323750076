import * as s from "./styled";
import profileKills from "assets/profileKills.svg";
import profileDeaths from "assets/profileDeaths.svg";
import profileKDR from "assets/profileKDR.svg";
import followers from "assets/followers.svg";
import following from "assets/following.svg";

import { Overall } from "components/Profile/Overall";
import { LastMatch } from "components/Profile/LastMatch";
import { MatchHistoryTable } from "components/Profile/MatchHistoryTable";
import { useEffect, useState } from "react";
import Modal from "components/Modal";
import ApplyBtn from "components/ApplyBtn";
import { useSwrImmutable } from "hooks/useSwr";
import { friends_fetchFriends, idp_GetAccount } from "api";
import { useParams } from "react-router";

export default function Profile({
  player,
  matchHistory,
}: // user,
{
  player: IProfileFull;
  matchHistory?: IMatchHistory[];
  followings?: string[];
  user: IProfileFull | undefined;
}) {
  // const { mutate } = useSWRConfig();
  // const [hasReported, setHasReported] = useState(false);

  const { userId } = useParams();

  const [auth] = useSwrImmutable("auth", idp_GetAccount);
  const id = userId || auth?.userId?.value;

  const [friends] = useSwrImmutable(
    id ? [id, "friendsCount"] : null,
    friends_fetchFriends
  );
  // const followingStatus = friends?.followingStatus || "unknown";

  const [showModal, setShowModal] = useState(false);
  // const handleFollow = useMemo(
  //   () =>
  //     debounce(
  //       () => {
  //         addFriend(player.id).then(() => {
  //           mutate([player?.id, "friendsCount"]);
  //         });
  //       },
  //       2000,
  //       { leading: true, trailing: false }
  //     ),
  //   [player.name, player.id]
  // );

  // const handleUnfollow = useMemo(
  //   () =>
  //     debounce(
  //       () => {
  //         removeFriend(player.id).then(() => {
  //           mutate([player?.id, "friendsCount"]);
  //         });
  //       },
  //       2000,
  //       { leading: true, trailing: false }
  //     ),
  //   [player.name, player.id]
  // );

  // const handleFollowUnfollow = () => {
  //   if (followingStatus === "following") handleUnfollow();
  //   if (followingStatus === "not_following") handleFollow();
  // };
  // const handleReport = useCallback(
  //   debounce(
  //     () => {
  //       reportUser(player.name);
  //       setShowModal(true);
  //       setHasReported(true);
  //     },
  //     3000,
  //     { leading: true, trailing: false }
  //   ),
  //   [player.name, player.id]
  // );
  //
  // const showReportBtn = !!(
  //   user?.id &&
  //   player?.id &&
  //   user.id !== player.id &&
  //   !hasReported
  // );

  useEffect(() => window.scroll(0, 0), []);

  return (
    <>
      <s.ProfileWrapper>
        <s.UserBlockWrapper>
          <s.UserTop>
            <s.IconRing>
              <s.Icon icon={player?.icon} name={player?.name} />
            </s.IconRing>
            <s.UserInfo>
              <s.UserName>{player?.name}</s.UserName>
              <s.UserClan>{player?.clan?.name}</s.UserClan>
              <s.UserOnline>
                <s.OnlineStatus $isOnline={player?.onlineStatus} />
                {player?.onlineStatus ? "Now Online" : "Offline"}
              </s.UserOnline>
            </s.UserInfo>
            <s.Separator />
            <s.Level>
              <p>{player?.level}</p>
              LEVEL
            </s.Level>
          </s.UserTop>
          <s.HorizontalLine2 />
          <s.FollowWrapper>
            <div>
              <s.Followers>
                <img src={followers} alt="" />
                <p>Followers</p>
                {friends?.followersCount}
              </s.Followers>
              <s.Followers>
                <img src={following} alt="" />
                <p>Following</p>
                {friends?.followingCount}
              </s.Followers>
            </div>
            <s.Separator />
            {/* <Box>
              <s.FollowBtnWrapper
                status={followingStatus}
                onClick={handleFollowUnfollow}
              >
                <img
                  src={
                    followingStatus === "following" ? unfollowBtn : followBtn
                  }
                  alt=""
                />
                <p>
                  {followingStatus === "following" ? `Unfollow` : `Follow User`}{" "}
                </p>
              </s.FollowBtnWrapper>
              <s.ReportBtnWrapper
                onClick={handleReport}
                visible={showReportBtn}
              >
                <img src={reportBtn} alt="" />
                <p>Report User</p>
              </s.ReportBtnWrapper>
            </Box> */}
          </s.FollowWrapper>
        </s.UserBlockWrapper>
        <s.SmallBlock>
          <img src={profileKills} alt="kills" />
          <s.SmallCount>{player?.kills}</s.SmallCount>
          <s.SmallText>KILLS</s.SmallText>
        </s.SmallBlock>
        <s.SmallBlock>
          <img src={profileDeaths} alt="deaths" />
          <s.SmallCount>{player?.deaths}</s.SmallCount>
          <s.SmallText>DEATHS</s.SmallText>
        </s.SmallBlock>
        <s.SmallBlock>
          <img src={profileKDR} alt="kdr" />
          <s.SmallCount>{player?.kdr}</s.SmallCount>
          <s.SmallText>KDR</s.SmallText>
        </s.SmallBlock>
        <Overall overall={player?.overall} />
        {matchHistory && <LastMatch lastMatch={matchHistory[0]} />}
        <MatchHistoryTable data={matchHistory} />
      </s.ProfileWrapper>
      <Modal
        width={380}
        open={showModal}
        onClose={() => setShowModal(!showModal)}
      >
        <s.ReportModalContent>
          <p>User successfully reported</p>
          <ApplyBtn onClick={() => setShowModal(false)} label={"ОК"} />
        </s.ReportModalContent>
      </Modal>
    </>
  );
}
