import React from "react";
import * as s from "components/Staking/StakeForEventPoints/Grid/Grid.styled";
import { StakeButton } from "components/Staking/StakeForEventPoints/Grid/StakeButton";
import { useNavigate } from "react-router";

export function ItemBlockEmpty(props: { set: string; slot: number }) {
  const navigate = useNavigate();

  return (
    <s.ItemBlock>
      <s.EmptyImg />
      <StakeButton
        onClick={() => {
          navigate(`stake/${props.set}/${props.slot}`);
        }}
      />
    </s.ItemBlock>
  );
}
