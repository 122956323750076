import Box from "components/Box";
import { useAppDispatch, useAppSelector } from "store";
import {
  actions,
  IFilter,
  selectFilter,
  selectSearch,
} from "../../../redux/slices/worldDominationSlice";
import * as s from "./Filters.styled";

export function Filters() {
  const dispatch = useAppDispatch();

  const search = useAppSelector(selectSearch);
  const filter = useAppSelector(selectFilter);

  const setSelected = (val: IFilter) => {
    dispatch(actions.setFilter(val));
  };

  return (
    <s.Wrapper>
      <s.Input
        placeholder="Enter Clan Name"
        value={search}
        onChange={(e) => dispatch(actions.setSearch(e.target.value))}
      />
      <Box flex="1 1 10px" />
      <s.Text>City stats to show:</s.Text>
      <Button name="Available Points" selected={filter} setter={setSelected} />
      {/*<Button name="Population" selected={filter} setter={setSelected} />*/}
      <Button name="My Cities" selected={filter} setter={setSelected} />
    </s.Wrapper>
  );
}

export function Button({name, selected, setter,}: { name: IFilter; selected: IFilter; setter: any; }) {
  return (
    <s.Button onClick={() => setter(name)} active={selected === name}>
      {name}
    </s.Button>
  );
}
