import styled from "styled-components/macro";

export const Title = styled.div<{ text?: string }>`
  font-weight: 700;
  display: none;

  @media (min-width: 768px) {
    display: block;
    font-size: 40px;
    color: #ffffff;
    text-align: center;
  }
  @media (min-width: 1280px) {
    text-align: start;
    font-size: 40px;
    color: #ffffff;
  }

  &:after {
    content: "${({ text }) => text}";
  }
`;
