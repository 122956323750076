import styled from "styled-components/macro";
import logo from "../../../assets/logo.png";
import plus from "../assets/plus.svg";
import bg from "../assets/StatsPanelJoinBg.png";

export const Wrapper = styled.div`
  padding: 30px 21px;
  max-width: 1200px;
  min-width: 1200px;
  margin: 11px auto 30px;
  background: #000000cc;
  border-radius: 4px;
  display: grid;
  grid-template-rows: 92px 256px;
  grid-template-columns: 252px 344px 260px 260px;
  grid-row-gap: 20px;
  grid-column-gap: 14px;
`;

export const StatsPanelJoinWrapper = styled.div`
  margin-top: 10px;
  width: 1200px;
  height: 320px;
  border-radius: 4px;
  background: url(${bg});
  background-position: center center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Logo = styled.img.attrs({
  src: logo,
})`
  margin-top: 41px;
  margin-right: 178px;
  width: 148px;
  height: 72px;
`;

export const Text1 = styled.div`
  font-weight: 450;
  font-size: 50px;
  line-height: 60px;
  color: white;
  margin-top: 13px;
  margin-right: 93px;
  text-transform: uppercase;
  text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.8);
`;

export const Text2 = styled.div`
  font-weight: 450;
  font-size: 20px;
  line-height: 24px;
  color: white;
  margin-right: 120px;
  text-transform: uppercase;
  text-shadow: 1px 2px 0px rgba(0, 0, 0, 0.8);
`;

export const Button = styled.button`
  background: #ffb60c;
  border: 4px solid rgba(255, 182, 12, 0.2);
  width: 198px;
  height: 60px;
  margin-top: 18px;
  margin-right: 152px;
  font-weight: 700;
  font-size: 16px;
  line-height: 54px;
  text-align: center;
  color: #000000;
  background-clip: padding-box;
  cursor: pointer;
  &:active {
    filter: grayscale(0.2);
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);
`;

export const Yellow = styled(Text)`
  color: #ffb60c;
`;

export const White = styled(Text)`
  color: #ffffff;
`;

export const Blue = styled(Text)`
  color: #77dcff;
`;

export const Green = styled(Text)`
  color: #00ee6c;
`;

export const Grey = styled(Text)`
  color: #868686;
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

export const Plus = styled.img.attrs({ src: plus })`
  margin-left: 7px;
  cursor: pointer;
`;

export const Block = styled.div`
  background: #1f1f1f;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
`;

export const BlockTurn = styled(Block)`
  padding: 20px 0 18px 20px;
  display: grid;
  grid-template-columns: 125px max-content;
  row-gap: 16px;
`;

export const BlockPoints = styled(Block)`
  padding: 20px 0 18px 20px;
  display: grid;
  grid-template-columns: 115px max-content;
  row-gap: 11px;
`;
export const BlockMarket = styled(Block)`
  grid-auto-flow: row dense;
  grid-column: span 2;
  padding: 20px 15px 18px 20px;
  display: grid;
  grid-template-columns: 141px max-content 1fr;
  row-gap: 11px;
  column-gap: 10px;
`;

export const MarketDirection = styled(White)`
  grid-row: span 2;
  gap: 8px;
  padding-left: 10px;
  font-weight: 350;
  font-size: 12px;
  text-transform: capitalize;
`;

export const CostChange = styled.div<{ val: number }>`
  color: ${({ val }) => (val < 0 ? "#EC2E00" : "#59B015")};
  margin-bottom: 3px;
  margin-left: 3px;

  &::before {
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-weight: 350;
    content: "(${({ val }) => (val * 100).toFixed(2)}%)";
  }
`;

export const BlockConstruction = styled(Block)`
  padding: 26px 20px 0;
  grid-column: span 2;
  & > div:nth-child(1) {
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;
  }
  & > div.items {
    display: grid;
    gap: 7px;
    grid-template-columns: repeat(5, 1fr);
    & > div.item {
      display: flex;
      flex-direction: column;
      gap: 6px;
      align-items: center;
      position: relative;
      & > img {
        cursor: pointer;
      }
      & > .name {
        color: white;
        font-weight: 400;
        font-size: 13px;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);

        top: 94px;
        left: 0;
        right: 0;
        text-align: center;
        position: absolute;
      }
    }

    & > div.disabled {
      display: flex;
      flex-direction: column;
      gap: 6px;
      align-items: center;
      position: relative;
      & > img {
        filter: grayscale(1) brightness(0.5);
      }
      & > .name {
        color: white;
        font-weight: 400;
        font-size: 13px;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.6);

        top: 94px;
        left: 0;
        right: 0;
        text-align: center;
        position: absolute;
      }
    }
  }
`;

export const Title = styled(White)`
  font-weight: 400;
  font-size: 24px;
  margin-right: 6px;
`;

export const HelpText = styled(Grey)`
  font-weight: 350;
  font-size: 14px;
  margin-bottom: 3px;
`;

export const Number = styled.div<{ val: number }>`
  font-weight: 400;
  font-size: 20px;
  color: ${({ val }) => (val > 0 ? "#59B015" : "white")};
  &::before {
    content: "${({ val }) => val}";
  }
`;

export const Planned = styled.div`
  font-weight: 350;
  font-size: 14px;
  color: #59b015;
`;

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  & > .title {
    background: #323232;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px 4px 0px 0px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    color: white;
    font-weight: 400;
    font-size: 16px;
  }
  & > .content {
    background: #1f1f1f;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-top: 0;
    border-radius: 0px 0px 4px 4px;
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;
    & > .seeDetails {
      position: absolute;
      bottom: 12px;
      left: 0;
      right: 0;
      font-weight: 350;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      text-decoration-line: underline;
      color: #838383;
      cursor: pointer;
    }
  }
`;

export const SummaryItem = styled.div<{ positive: boolean }>`
  font-weight: 400;
  font-size: 14px;
  color: ${({ positive }) => (positive ? "#59B015" : "#EC2E00")};
`;

export const NoSummary = styled.div`
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  color: #515151;
  &::after {
    content: "No Recent Activity";
  }
`;
