import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import Box from "components/Box";
import { Gap } from "components/Gap";
import { QuantityCounterFlex } from "components/QuantityCounterFlex/QuantityCounterFlex";
import RedButton from "components/RedButton";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import { HorizontalLine } from "components/TableClans/CloseModal.styled";
import Text from "components/Text";
import { WDModalStyles } from "pages/WorldDomination/modalStyles";
import { ButtonHint } from "pages/WorldDomination/WDStatsPanel/ConstructionModal.styled";
import { useState } from "react";
import Modal from "react-modal";
import {
  useAddToQueueMutation,
  useGetClanAssetsQuery,
  useGetClanInfoQuery,
  useGetEventQuery,
  useGetEventTurnQuery,
  useGetRoundQueueQuery,
  useGetUserRestrictionsQuery,
  useRemoveFromQueueMutation,
} from "redux/rtkQuery/rtkApi";
import { addErrorMessage } from "redux/slices/messageSystemSlice";
import { selectAttack } from "redux/slices/worldDominationSlice";
import { useAppDispatch, useAppSelector } from "store";
import { numberWithCommas } from "utils/numberWithComas";
import { toFixedWithoutZeros } from "utils/toFixedWithoutZeros";
import cityImg from "../assets/cityModalIcon.png";
import nukeWhite from "../assets/nukeIconWhite.png";
import * as s from "./AttackModal.styled";

export function AttackModal(props: { onClose: any }) {
  const dispatch = useAppDispatch();
  const { data: event } = useGetEventQuery();
  const { data: turn } = useGetEventTurnQuery(event?.id ?? skipToken);
  const { data: queue } = useGetRoundQueueQuery();
  const [addToQueue] = useAddToQueueMutation();
  const [removeFromQueue] = useRemoveFromQueueMutation();
  const { data: assets } = useGetClanAssetsQuery(event?.id ?? skipToken);
  const { data: userRestrictions } = useGetUserRestrictionsQuery();
  const { data: info } = useGetClanInfoQuery();

  const { city, clanId } = useAppSelector(selectAttack)!;

  const [status, setStatus] = useState<IStatus>("idle");
  const [amount, setAmount] = useState(1);

  if (!event || !queue || !turn || !assets || !userRestrictions || !info)
    return null;

  const nukesCount = assets.nukesList.length;
  const attacksCount = queue.attackCityByNuke.reduce(
    (acc, i) => acc + i.count,
    0
  );

  const nukesAvailableCount = nukesCount - attacksCount;
  const numNukesPlannedForThisCity = queue.attackCityByNuke
    .filter((i) => i.cityId === city.id)
    .reduce((acc, item) => acc + item.count, 0);

  const availablePoints = city.points;

  const nukeDestructionPercent =
    event.extensions.worldDominationConfig.attacks.attackCityByNuke
      .nukeDestructionPercent / 10000;
  const ironDomeProtectionPercent =
    event.extensions.worldDominationConfig.attacks.attackCityByNuke
      .ironDomeProtectionPercent / 10000;
  const nukeVampirePercent =
    event.extensions.worldDominationConfig.attacks.attackCityByNuke
      .nukeVampirePercent / 10000;
  const maxDamage = Math.floor(availablePoints * nukeDestructionPercent);
  const priceData = turn.market.productsList.find(
    (i) => i.productId === "ATTACK_CITY_BY_NUKE"
  )!.pricesList[0];
  const currency = priceData.currency;

  let hintText = "";
  if (!userRestrictions.queueRestrictions.canAttack)
    hintText = "You are not allowed to attack cities";
  if (nukesAvailableCount === 0) hintText = "Insufficient Nukes";
  if (!["LEADER", "OFFICER"].includes(info.role))
    hintText = "You Must be a Clan Leader, or Officer in order to Attack";

  const handleAttack = async () => {
    setStatus("pending");
    const data = (await addToQueue({
      type: "attackCity",
      amount,
      turnId: turn.turn.turnId,
      currency,
      cityId: city.id,
      clanId,
      // nukeIds: nukesAvailableIds.slice(0, amount),
    })) as any;
    if (data?.error) {
      props.onClose();
      dispatch(
        addErrorMessage({ title: "Error", text: "Something went wrong" })
      );
    }
    props.onClose();
  };

  const handleRemove = async () => {
    setStatus("pending");
    const ids = queue.attackCityByNuke
      .filter((i) => i.cityId === city.id)
      .map((i) => i.id);
    const data = (await removeFromQueue({
      turnId: turn.turn.turnId,
      itemIds: ids,
    })) as any;
    if (data?.error) {
      props.onClose();
      dispatch(
        addErrorMessage({ title: "Error", text: "Something went wrong" })
      );
    }
    props.onClose();
  };

  return (
    <Modal
      isOpen
      style={WDModalStyles}
      shouldCloseOnEsc
      preventScroll
      onRequestClose={props.onClose}
    >
      <s.Content>
        <s.Close onClick={props.onClose}>
          <img src={closeIcon} />
        </s.Close>
        <s.Top>
          <s.TopLeft>
            <s.Img src={cityImg} alt="" width={160} />
            <Text w={350} s={14} color="#EC2E00">
              Max Damage:
            </Text>
            <Text w={400} s={16} color="#EC2E00" mb={7}>
              -{numberWithCommas(maxDamage)}
            </Text>
            <Text w={350} s={14}>
              Available Points:
            </Text>
            <Text w={400} s={16} color="#FFB60C">
              {numberWithCommas(availablePoints)}
            </Text>
          </s.TopLeft>
          <s.TopRight>
            <Text w={400} s={22} color="#FFCA0E" mb={16}>
              {city.name}
            </Text>
            <Text w={350} s={16} mb={4}>
              When attacking a City:
            </Text>

            <s.Description>
              <li>
                You can{" "}
                <s.White>
                  destroy up to{" "}
                  {toFixedWithoutZeros(nukeDestructionPercent * 100)}% of the
                  available
                </s.White>{" "}
                points for each successful Nuke that gets through the City
                Shields;
              </li>
              <li>
                You can{" "}
                <s.White>
                  steal up to {toFixedWithoutZeros(nukeVampirePercent * 100)}%
                </s.White>{" "}
                of the points that are destroyed;
              </li>
              <li>
                You don’t know in advance about the City’s defensive capacity;
              </li>
              <li>
                Each City Shield will block <s.White>1 incoming Nuke;</s.White>
              </li>
              <li>
                The Iron Dome will block{" "}
                <s.White>
                  {toFixedWithoutZeros(ironDomeProtectionPercent * 100)}% of the
                  damage
                </s.White>{" "}
                and will significantly reduce how many points you’re able to
                steal;
              </li>
              <li>
                If multiple attacks get through during the same turn, they will
                be split by all attackers pro rata based on number of Nukes
                used.
              </li>
            </s.Description>
          </s.TopRight>
        </s.Top>
        <HorizontalLine />
        <Gap h={25} />
        <s.Bottom>
          <s.BottomLeft>
            <s.Line>
              <s.Item1>Nukes Available:</s.Item1>
              <s.Count count={nukesAvailableCount} />
            </s.Line>
            <Gap h={10} />
            <s.Line>
              <s.Item1>Nuke Strikes Planned:</s.Item1>
              <s.CountRed count={numNukesPlannedForThisCity} />
            </s.Line>
            {numNukesPlannedForThisCity > 0 && (
              <s.Remove onClick={handleRemove} />
            )}
          </s.BottomLeft>
          <s.BottomRight>
            <s.Line2>
              <s.Item2>Attack Cost:</s.Item2>
              <s.NukesCost>
                <s.ImgNuke />1 Nuke
              </s.NukesCost>
            </s.Line2>
            <Gap h={12} />
            <s.Line2>
              <s.Item2>Quantity:</s.Item2>
              <QuantityCounterFlex
                amount={amount}
                setAmount={setAmount}
                max={nukesAvailableCount}
              />
            </s.Line2>
          </s.BottomRight>
        </s.Bottom>
        <Box w={296} mt={12} alignSelf="center">
          <RedButton
            h={60}
            onClick={handleAttack}
            disabled={
              !nukesAvailableCount ||
              !userRestrictions.queueRestrictions.canAttack
            }
            loading={status === "pending"}
          >
            <img src={nukeWhite} width={18} />
            <Gap w={12} /> Attack
            <Gap w={12} /> <img src={nukeWhite} width={18} />
          </RedButton>
        </Box>
        <ButtonHint>{hintText}</ButtonHint>
        <Gap h={40} />
      </s.Content>
    </Modal>
  );
}
