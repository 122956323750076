import { useState, ChangeEvent } from 'react';
import { Container, LeftArrow, RightArrow, NumberContiner } from 'components/QuantityCounter/quantityCounter.style';

interface ICounter {
    amount: number;
    setAmount: (val: number) => void
}

export const QuantityCounter = ({ amount, setAmount }: ICounter) => {

    const [count, setCounter] = useState(1);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if( +e.target.value > amount || +e.target.value < 1 || isNaN(+e.target.value)) return
    setCounter(+e.target.value)
    setAmount(+e.target.value)
};

    const increaseCount = () => {
        setCounter(+count + 1)
        setAmount(+count + 1)
    }

    const decreaseCount = () => {
        if(count === 1) return
        setCounter(+count - 1)
        setAmount(+count - 1)
    }
    return (
    <Container>
        <LeftArrow onClick={() => count > 0 && decreaseCount()} />
        <NumberContiner value={count} onChange={handleChange}/>
        <RightArrow onClick={() => count < amount && increaseCount()} />
    </Container>
    )
}