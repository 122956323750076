import * as s from "components/Staking/StakeForEventPoints/Grid/Grid.styled";
import { TooltipContent } from "components/Staking/StakeForEventPoints/InfoBloc/StakeForEventPoints.styled";
import { IItem, IToltipData } from "types/stakingTypes";
import { MyTooltip } from "../../MyTooltip";

export function ItemView(props: {
  rarity?: number;
  currentItem?: IItem;
  mintable?: boolean;
  boost?: boolean;
  tooltipData?: IToltipData;
  src?: string;
  className?: string;
  onClick?: () => void;
  pointer?: boolean;
  w?: number;
  h?: number;
}) {
  return (
    <s.ItemWrap
      w={props.w}
      h={props.h}
      pointer={props.pointer}
      rarity={props?.currentItem?.rarity || 0}
      className={props.className}
      onClick={props.onClick}
      data-id={props?.currentItem?.id}
    >
      <img src={props?.currentItem?.generated?.asset?.icon} />
      <div className="upsBlock">
        {props.boost && <s.StakingBoost />}
        <MyTooltip
          visible={!!props?.tooltipData?.totalPointsRate}
          overlay={
            <TooltipContent maxWidth={"350px"}>
              <div>
                <span style={{ color: "#FFB60C" }}>
                  {props?.tooltipData?.status}
                </span>{" "}
                = {props?.tooltipData?.nft} points/hr
              </div>
              {props?.tooltipData?.itemBonuses?.map((item: any) => (
                <div key={item?.name}>
                  <span style={{ color: "#FFB60C" }}>{item?.name}</span> ={" "}
                  {item?.description}
                </div>
              ))}
              <div style={{ marginTop: "16px" }}>
                <span>TOTAL POINTS PER HOUR</span> ={" "}
                <span style={{ color: "#FFB60C" }}>
                  {props?.tooltipData?.totalPointsRate} points/hr
                </span>
              </div>
            </TooltipContent>
          }
        >
          <s.Mintable />
        </MyTooltip>
      </div>
    </s.ItemWrap>
  );
}
