import { IEvent, IItemsNormalized, IRewardListEntry } from "api/api.types";
import { filterClansToTier } from "components/TieredLeaderboard/componetns/helpers";
import { Place } from "components/TieredLeaderboard/componetns/Place";
import * as s from "components/TieredLeaderboard/componetns/Tablet.styled";
import { TabletRewards } from "components/TieredLeaderboard/componetns/TabletRewards";
import { TabletTierClan } from "components/TieredLeaderboard/componetns/TabletTierClan";

export function TabletTierItem({
  clans,
  event,
  items,
  item,
}: {
  clans: IGetClanLeaderboard[];
  event: IEvent;
  item: IRewardListEntry;
  items: IItemsNormalized;
}) {
  const clansFiltered = clans.filter((clan) => filterClansToTier(item, clan));
  if (clansFiltered.length === 0) return null;
  return (
    <s.Tier key={item.tier} tier={item.tier}>
      <Place event={event} place={item.tier} />
      <div className="clans">
        {clansFiltered.map((clan) => (
          <TabletTierClan key={clan.id} clan={clan} />
        ))}
      </div>
      <TabletRewards
        rewards={event.rewardsList[item.tier - 1].rewardsList}
        items={items}
      />
    </s.Tier>
  );
}
