import React, { useContext } from "react";
import { GridCtx } from "components/Staking/StakeForEventPoints/Grid/Grid";
import { Unstake } from "./Unstake";
import { Pending } from "./Pending";

export const modalStyles = {
  content: {
    // required
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    overflow: "hidden",
    transform: "translate(-50%, -50%)",
    padding: 0,
    // marginRight: "-50%",
    // custom
    borderRadius: 0,
    border: "1px solid #1A1A1A",
    background: "black",
  },
  overlay: {
    zIndex: 20,
    backgroundColor: "rgba(255,255,255,0)",
  },
};

export function UnstakeModal() {
  const [context] = useContext(GridCtx);
  if (!context.showUnstakeModal) return null;
  if (context.selectedUnstakeStatus === "idle") return <Unstake />;
  else if (context.selectedUnstakeStatus === "pending") return <Pending />;
  else return null;
}
