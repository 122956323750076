import * as s from "components/Staking/StakeForEventPoints/Grid/Grid.styled";
import Text from "components/Text/Text";
import { secondsToDHMSingleValue } from "utils/dateHelpers";
import expired from "../assets/expired.svg";
import locked from "../assets/locked.png";
import timer from "../assets/timerIconYellow.svg";

export function UnlockBtn({ onClick }: { onClick: () => void }) {
  return <s.GreenButton onClick={onClick}>Unlock</s.GreenButton>;
}

export function UnlockBlock({
  unlocked,
  price,
  set,
  slot,
  setUnlockModal,
  setSlotSet,
  rowExpired,
  timeToLock,
}: {
  unlocked: boolean;
  price?: number;
  set: number;
  slot: any;
  setUnlockModal: (val: boolean) => void;
  setSlotSet: (val: string) => void;
  rowExpired: boolean;
  timeToLock: number;
}) {
  if (unlocked)
    return (
      <s.Unlocked>
        <div>Unlocked</div>
        <div>{(!price && "Free") || price + " Butter"}</div>
        {timeToLock > 0 && (
          <div className="remaining">
            <img src={timer} height={37} />
            <div>
              <Text grey w={350} s={13}>
                Remaining:
              </Text>
              <Text yellow w={700} s={16}>
                {secondsToDHMSingleValue(timeToLock)}
              </Text>
            </div>
          </div>
        )}
      </s.Unlocked>
    );
  else
    return (
      <s.Locked>
        <img src={rowExpired ? expired : locked} />
        <div className="text">{rowExpired ? "Expired" : "Locked"}</div>
        <div className="price">{price + " Butter"}</div>
        <UnlockBtn
          onClick={() => {
            setUnlockModal(true);
            setSlotSet({ ...slot, setNumber: set });
          }}
        />
      </s.Locked>
    );
}
