import * as s from "components/TableClans/styled";
import { SkeletonRect, SkeletonCircle } from "components/Skeleton/index";
import { FilterWrapper } from "components/Filters/styled";
import { useIsMobile } from "hooks/useMediaQuery";

export default function TableSkeleton({
  filtersCount,
}: {
  filtersCount: number;
}) {
  const isMobile = useIsMobile();
  const Header = isMobile ? s.HeaderRowMobile : s.HeaderRow;
  const GridRow = isMobile ? s.GridRowMobile : s.GridRow;
  return (
    <s.Wrapper>
      <s.Inner>
        <s.TableHeader>
          <s.HeadImg />
          <SkeletonRect w={170} h={16} />
          <FilterWrapper>
            {!isMobile ? (
              Array.from({ length: filtersCount }, (_, i) => (
                <s.SkeletonFilterWrap key={i}>
                  <SkeletonRect w={70} h={12} ml={16} mr={38} />
                  <SkeletonCircle r={12} mr={14} />
                </s.SkeletonFilterWrap>
              ))
            ) : (
              <SkeletonRect h={28} mt={24} mb={10} />
            )}
          </FilterWrapper>
        </s.TableHeader>

        <>
          <s.BodyWrapper>
            <s.Grid>
              <Header>
                <s.HeaderCell>
                  <SkeletonRect w={40} h={12} />
                </s.HeaderCell>
                <s.HeaderCell>
                  <SkeletonRect w={80} h={12} />
                </s.HeaderCell>
                {Array.from({ length: isMobile ? 1 : 4 }, (_, i) => (
                  <s.HeaderCell key={i}>
                    <SkeletonRect w={50} h={12} />
                  </s.HeaderCell>
                ))}
              </Header>
              {Array.from({ length: 10 }, (_, i) => (
                <GridRow $i={i} key={i}>
                  <s.GridCell>
                    <SkeletonCircle r={14} />
                  </s.GridCell>
                  <s.GridCell>
                    <SkeletonCircle r={30} mr={8} ml={40} />
                    <SkeletonRect w={80} h={12} />
                  </s.GridCell>
                  {Array.from({ length: isMobile ? 1 : 4 }, (_, i) => (
                    <s.GridCell key={i}>
                      <SkeletonRect w={60} h={12} />
                    </s.GridCell>
                  ))}
                </GridRow>
              ))}
            </s.Grid>
          </s.BodyWrapper>
          <s.BodyImg />
        </>
      </s.Inner>
    </s.Wrapper>
  );
}
