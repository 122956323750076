import { useState, useEffect } from "react";
import { timeTo24UTC } from "utils/dateHelpers";
import { TimerWrapper, TimerField } from "components/Leaderboard/styled";
import timerIcon from "assets/timerIcon.svg";
import styled from "styled-components/macro";

export function DailyTimer() {
  const [time, setTime] = useState(() => timeTo24UTC());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(timeTo24UTC());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <TimerWrapper>
      <div>
        <Text />
        <TimerField>
          <img src={timerIcon} alt="timer" />
          <span>{time}</span>
        </TimerField>
      </div>
    </TimerWrapper>
  );
}

const Text = styled.div`
  &:after {
    content: "Reset in:";
    font-size: 14px;
    @media (min-width: 535px) {
      font-size: 15px;
      content: "Leaderboard will reset in:";
    }
    @media (min-width: 600px) {
      font-size: 16px;
      content: "Daily Leaderboard will reset in:";
    }
  }
`;
