import styled from "styled-components/macro";

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  padding: 50px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 30px;
  color: #ffb60c;
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 22px;
  color: white;
`;

export const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 40;
  cursor: pointer;
  & > img {
    width: 16px;
    height: 16px;
  }
`;
