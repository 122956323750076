import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import React from "react";

export function WalletButton() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "100px",
        marginBottom: "100px",
      }}
    >
      <WalletMultiButton />
    </div>
  );
}