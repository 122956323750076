// import alignment from "./images/alignment1.png";
// import allegiance from "./images/allegiance1.png";
// import anarchists from "./images/anarchists1.png";
// import battlescarred from "./images/battlescarred1.png";
// import birthplace from "./images/birthplace1.png";
// import bloodthirsty from "./images/bloodthirsty1.png";
// import bouncehouse from "./images/bouncehouse1.png";
// import braindrain from "./images/braindrain1.png";
// import capitalists from "./images/capitalists1.png";
// import closequarters from "./images/closequarters1.png";
// import cripplingblow from "./images/cripplingblow1.png";
// import droptype from "./images/droptype1.png";
// import eagleeye from "./images/eagleeye1.png";
// import factorynew from "./images/factorynew1.png";
// import fasthands from "./images/fasthands1.png";
// import fearless from "./images/fearless1.png";
// import fieldtested from "./images/fieldtested1.png";
// import globalorder from "./images/globalorder1.png";
// import hotpursuit from "./images/hotpursuit1.png";
// import irongrip from "./images/irongrip1.png";
// import itemtype from "./images/itemtype1.png";
// import jackedup from "./images/jackedup1.png";

// import marksmanship from "./images/marksmanship1.png";
// import martyr from "./images/martyr1.png";
// import minimalwear from "./images/minimalwear1.png";
// import negativequirk from "./images/negativequirk1.png";
// import oldguard from "./images/oldguard1.png";
// import personality from "./images/personality1.png";
// import pet from "./images/pet1.png";
// import positivequirk from "./images/positivequirk1.png";
// import powerbrokers from "./images/powerbrokers1.png";
// import reconspecialist from "./images/reconspecialist1.png";
// import season from "./images/season1.png";
// import season1 from "./images/season11.png";
// import season2 from "./images/season21.png";
// import season3 from "./images/season31.png";
// import secretsociety from "./images/secretsociety1.png";
// import technorati from "./images/technorati1.png";
// import theunderground from "./images/theunderground1.png";
// import unaffiliated from "./images/unaffiliated1.png";
// import vampire from "./images/vampire1.png";
// import wakeupcall from "./images/wakeupcall1.png";
// import wellworn from "./images/wellworn1.png";

import sagittarius from "./images/sagittarius1.png";
import taurus from "./images/taurus1.png";
import aquarius from "./images/aquarius1.png";
import aries from "./images/aries1.png";
import cancer from "./images/cancer1.png";
import capricorn from "./images/capricorn1.png";
import gemini from "./images/gemini1.png";
import pisces from "./images/pisces1.png";
import virgo from "./images/virgo1.png";
import scorpio from "./images/scorpio1.png";
import leo from "./images/leo1.png";
import libra from "./images/libra1.png";

import alignment from "./images/alignment1.svg";
import allegiance from "./images/allegiance1.svg";
import bandana from "./images/bandana1.svg";
import birthplace from "./images/birthplace1.svg";
import boots from "./images/boots1.svg";
import condition from "./images/condition1.svg";
import droptype from "./images/droptype1.svg";
import eyebrows from "./images/eyebrows1.svg";
import eyes from "./images/eyes1.svg";
import facialhair from "./images/facialhair1.svg";
import haircolor from "./images/haircolor1.svg";
import hands from "./images/hands1.svg";
import headgear from "./images/headgear1.svg";
import headstyle from "./images/headstyle1.svg";
import itemtype from "./images/itemtype1.svg";
import legs from "./images/legs1.svg";
import legscolor from "./images/legscolor1.svg";
import mouth from "./images/mouth1.svg";
import negativequirk from "./images/negativequirk1.svg";
import perk1 from "./images/perk11.svg";
import perk2 from "./images/perk21.svg";
import personality from "./images/personality1.svg";
import pet from "./images/pet1.svg";
import positivequirk from "./images/positivequirk1.svg";
import relationshipstatus from "./images/relationshipstatus1.svg";
import season from "./images/season1.svg";
import skincolor from "./images/skincolor1.svg";
import specialty from "./images/specialty1.svg";
import top from "./images/top1.svg";
import topcolor from "./images/topcolor1.svg";
import vest from "./images/vest1.svg";
import vestattachments from "utils/mappers/mapMetadataToImg/images/vestattachments1.svg";
import weaponskin from "./images/weaponskin1.svg";
import weapontype from "./images/weapontype1.svg";

export const mapMetadataToImg: Record<string, string> = {
  alignment,
  allegiance,
  bandana,
  birthplace,
  boots,
  condition,
  droptype,
  eyebrows,
  eyes,
  facialhair,
  haircolor,
  hands,
  headgear,
  headstyle,
  itemtype,
  legs,
  legscolor,
  mouth,
  negativequirk,
  perk1,
  perk2,
  personality,
  pet,
  positivequirk,
  relationshipstatus,
  season,
  skincolor,
  specialty,
  top,
  topcolor,
  vest,
  vestattachments,
  weaponskin,
  weapontype,

  aquarius,
  aries,
  cancer,
  capricorn,
  gemini,
  pisces,
  virgo,
  scorpio,
  leo,
  libra,
  sagittarius,
  taurus,
};
