import * as s from "components/Profile/styled";
import gameTime from "assets/overalGameTime.svg";
import gamesPlayed from "assets/overalGamesPlayed.svg";
import overallExp from "assets/overallExp.svg";
import gamesWon from "assets/overalGamesWon.svg";
import headshots from "assets/overalHeadshots.svg";
import { useIsMobile } from "hooks/useMediaQuery";

export function Overall(props: { overall: IProfileOverall }) {
  const isMobile = useIsMobile();
  return (
    <s.Overall>
      <s.OverallHeader>OVERALL</s.OverallHeader>
      <s.OverallContent>
        <s.OverallBlock>
          <img src={gameTime} />
          <s.BlockCaption>Game Time</s.BlockCaption>
          <s.BlockValue>{props.overall.gameTime}</s.BlockValue>
        </s.OverallBlock>
        <s.VerticalLine />
        <s.OverallBlock>
          <img src={gamesPlayed} />
          <s.BlockCaption>Games Played</s.BlockCaption>
          <s.BlockValue>{props.overall.gamesPlayed}</s.BlockValue>
        </s.OverallBlock>
        {isMobile ? <s.HorizontalLine /> : <s.VerticalLine />}
        <s.OverallBlock>
          <img src={gamesWon} />
          <s.BlockCaption>Games Won</s.BlockCaption>
          <s.BlockValue>{props.overall.gamesWon}</s.BlockValue>
        </s.OverallBlock>
        {isMobile ? <s.VerticalLine /> : <s.HorizontalLine />}
        <s.OverallBlock>
          <img src={overallExp} />
          <s.BlockCaption>Experience</s.BlockCaption>
          <s.BlockValue>{props.overall.experience}</s.BlockValue>
        </s.OverallBlock>
        {isMobile ? <s.HorizontalLine /> : <s.VerticalLine />}
        <s.OverallBlock>
          <img src={headshots} />
          <s.BlockCaption>Headshots</s.BlockCaption>
          <s.BlockValue>{props.overall.headshots}</s.BlockValue>
        </s.OverallBlock>
      </s.OverallContent>
    </s.Overall>
  );
}
