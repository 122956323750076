import { useNavigate } from "react-router";
import * as s from "components/TopThree/styled";
import { ClanTag } from "components/TableClans/styled";

export function CardClan(clan: IClan) {
  const navigate = useNavigate();
  return (
    <s.Card rank={clan.rank}>
      <s.CardHeaderRank rank={clan.rank}>
        <s.RankText>{clan.rank}</s.RankText>
      </s.CardHeaderRank>
      <s.CardHeader onClick={() => navigate(`/clans/${clan.id}`)}>
        {clan.name}
      </s.CardHeader>
      <s.CardContent>
        <s.ScoreAndIcon>
          <s.ScoreAndIconInner>
            <s.IconRing rank={clan.rank}>
              <s.ClanIcon name={clan.slug} rank={clan.rank} />
            </s.IconRing>
            <s.ScoreWrapper>
              <s.Score>{clan.score}</s.Score>
              <s.ScoreText>score</s.ScoreText>
            </s.ScoreWrapper>
          </s.ScoreAndIconInner>
        </s.ScoreAndIcon>
        <s.Separator />
        <s.Stats>
          <s.StatsItem>
            <s.StatsItemText>Tag</s.StatsItemText>
            <s.StatsItemCount>{clan.slug}</s.StatsItemCount>
          </s.StatsItem>
          <s.StatsItem>
            <s.StatsItemText>Members</s.StatsItemText>
            <s.StatsItemCount>{clan.members}</s.StatsItemCount>
          </s.StatsItem>
          <s.StatsItem fullWidth>
            <s.StatsItemText>Owner</s.StatsItemText>
            <s.StatsItemCount>
              {clan.owner.username}
              <ClanTag>&nbsp;#{clan.owner.suffix}</ClanTag>
            </s.StatsItemCount>
          </s.StatsItem>
        </s.Stats>
      </s.CardContent>
    </s.Card>
  );
}
