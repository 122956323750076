import Box from "components/Box";
import Text from "components/Text";
import { Filters } from "pages/WorldDomination/WDGridPanel/Filters";
import { WDGrid } from "pages/WorldDomination/WDGridPanel/WDGrid";
import { Header } from "pages/WorldDomination/WDGridPanel/WDGrid.styled";
import * as s from "pages/WorldDomination/WDGridPanel/WDGridPanel.styled";

export function WDGridPanel(props:{skip?:boolean}) {

  return (
    <s.Wrapper>
      <Filters />
      <Header>
        <div> Tier </div>
        <div> Clan name </div>
        <div> Total Points </div>
        <div> Vaulted Points </div>
        <div> City 1 </div>
        <div> City 2 </div>
        <div> City 3 </div>
        <div> City 4 </div>
        <div> City 5 </div>

        {/*<div> City Economy </div>*/}
      </Header>
      {props.skip ?  <DisabledWD /> : <WDGrid />}
    </s.Wrapper>
  );
}

function DisabledWD() {
  return (
    <Box h={400} center>
      <Text yellow s={24} center>
        World Domination is not active
      </Text>
    </Box>
  );
}
