import * as s from "components/Header/styled";
import { useSwrImmutable } from "hooks/useSwr";
import profileImg from "assets/profile.svg";
import profileActive from "assets/profileActive.svg";
import { useLocation } from "react-router";
import { SkeletonCircle, SkeletonRect } from "components/Skeleton";
import Box from "components/Box";
import { getAccountDetails, idp_GetAccount } from "api";
import { useMediaQuery } from "react-responsive";

type IMenuItem = {
  text: string;
  onClick: () => void;
  handleClose?: () => void;
};

export function MenuItemProfile({ onClick, text, handleClose }: IMenuItem) {
  const isMobile = useMediaQuery({ maxWidth: 1170 });
  const location = useLocation();
  const active = location.pathname === "/profile";
  const [auth, loadingAuth, errorAuth] = useSwrImmutable("auth", idp_GetAccount);
  const [player, loadingDetails] = useSwrImmutable(
    auth?.userId?.value ? [auth?.userId?.value, "user"] : null,
    getAccountDetails
  );

  if (loadingAuth || loadingDetails) return <ProfileMenuItemSkeleton />;

  const isLoggedIn =
    errorAuth?.message !== "wrong credentials" && player !== undefined && auth;

  const handleClick = () => {
    if (isMobile) {
      if (handleClose) handleClose();
    }
    if (isLoggedIn) {
      onClick();
    } else {
      window.open("https://miniroyale.io/?login", "_blank");
    }
  };

  return isMobile ? (
    <s.MobMenuItemBg active={active}>
      <s.MobMenuItem active={active} onClick={handleClick}>
        {isLoggedIn ? (
          <LoggedIn
            level={player?.level}
            username={auth.ident.username}
            clan={player?.clan?.name}
          />
        ) : (
          <>
            <img src={active ? profileActive : profileImg} alt={text} />
            <div>{text}</div>
          </>
        )}
      </s.MobMenuItem>
    </s.MobMenuItemBg>
  ) : (
    <s.MenuItemBg active={active}>
      <s.MenuItem active={active} onClick={handleClick}>
        {isLoggedIn ? (
          <Box w={130}>
            <LoggedIn
              level={player?.level}
              username={auth.ident.username}
              clan={player?.clan?.name}
            />
          </Box>
        ) : (
          <>
            <s.MenuImg src={active ? profileActive : profileImg} />
            <div>{text}</div>
          </>
        )}
      </s.MenuItem>
    </s.MenuItemBg>
  );
}

function LoggedIn({
  level,
  username,
  clan,
}: {
  level: number;
  username: string;
  clan: string;
}) {
  return (
    <s.ProfileHdrWrapper>
      <s.UserLevelHeader>
        <p>{level}</p>
        Level
      </s.UserLevelHeader>
      <div>
        <s.UserNameHdr>{username}</s.UserNameHdr>
        <s.ClanHdr>{clan}</s.ClanHdr>
      </div>
    </s.ProfileHdrWrapper>
  );
}

function ProfileMenuItemSkeleton() {
  return (
    <s.SkeletonWrapper>
      <SkeletonCircle r={24} mr={4} ml={20} />
      <SkeletonRect w={60} h={16} mr={20} />
    </s.SkeletonWrapper>
  );
}
