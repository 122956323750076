import styled from "styled-components/macro";
import timerField from "assets/timerField.svg";

export const TimerWrapper = styled.div`
  display: flex;
  justify-content: end;
  flex: 0 0 auto;
  @media (max-width: 760px) {
    width: 100%;
  }

  & > div {
    //width: 328px;
    height: 82px;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    @media (min-width: 960px) {
      height: 52px;
    }

    @media (max-width: 760px) {
      width: 100%;
      height: 52px;
    }

    & > div.title {
      color: white;
      padding-right: 20px;
    }

    & > div {
      color: black;
      padding-left: 40px;
      flex: 1 1 auto;
      font-weight: 700;
      font-size: 16px;
      white-space: nowrap;
    }
  }
`;
export const TimerField = styled.div`
  flex: 0 0 auto;
  transition: width 2s;
  background: url(${timerField}) no-repeat,
    linear-gradient(
      90deg,
      rgba(255, 182, 12, 0) 0%,
      rgba(255, 182, 12, 0) 47%,
      rgba(255, 182, 12, 1) 51%,
      rgba(255, 182, 12, 1) 100%
    );
  height: 52px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 40px;

  & > div {
    padding: 0 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    white-space: nowrap;
  }
`;
