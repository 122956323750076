import { GridCtx } from "components/Staking/StakeForEventPoints/Grid/Grid";
import * as s from "components/Staking/StakeForEventPoints/Grid/Grid.styled";
import { ItemView } from "components/Staking/StakeForEventPoints/Grid/ItemView";
import { UnstakeButton } from "components/Staking/StakeForEventPoints/Grid/UnstakeButton";
import { useContext } from "react";
import styled from "styled-components/macro";
import { IItem, IToltipData } from "types/stakingTypes";

export const mockItemSrc =
  "https://assets-cdn.miniroyale.io/nft/weapons-skins/icons/v1/P90-Strawberry_Shortcake-icon.png";

export const ItemBlock = ({
  currentItem,
  set,
  slot,
  slotStatus,
  unstakeId,
  tooltipData,
}: {
  currentItem?: IItem;
  set: string;
  slot: number;
  tooltipData: IToltipData;
  slotStatus: number;
  unstakeId: string;
}) => {
  const [, setField] = useContext(GridCtx);
  return (
    <s.ItemBlock data-status={slotStatus}>
      {currentItem ? (
        <ItemView w={152} h={152} currentItem={currentItem} tooltipData={tooltipData} />
      ) : (
        <Error />
      )}
      {slotStatus === 1 ? (
        <div className="processing_text_staking" />
      ) : slotStatus === 3 ? (
        <div className="processing_text_unstaking" />
      ) : (
        <UnstakeButton
          onClick={() => {
            setField("selectedUnstakeSet", set);
            setField("selectedUnstakeItem", currentItem);
            setField("selectedUnstakeSlot", slot);
            setField("selectedUnstakeId", unstakeId);
            setField("selectedUnstakeStatus", "idle");
            setField("showUnstakeModal", true);
          }}
        />
      )}
    </s.ItemBlock>
  );
};

export const Error = styled.div`
  background: #383838;
  border: 1px solid #6f6f6f;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;

  &:before {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    content: "No item data";
    font-weight: 350;
    font-size: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.3);
  }
`;
