import tier1bg from "components/TieredLeaderboard/assets/tier1desk.png";
import tier2bg from "components/TieredLeaderboard/assets/tier2desk.png";
import tier3bg from "components/TieredLeaderboard/assets/tier3desk.png";
import styled, { css } from "styled-components/macro";
import { numberWithCommas } from "utils/numberWithComas";

export const Rows = styled.div`
  flex: 1 1 1px;
  //width: calc(100% - 110px);
`;

export const Row = styled.div<{ num: number }>`
  display: grid;

  grid-template-columns: 44px 150px repeat(7, 1fr);
  ${({ num }) =>
    num % 2 === 0 &&
    css`
      background: rgba(255, 255, 255, 0.08);
    `}
`;

export const Header = styled.div`
  margin-top: 24px;
  margin-bottom: 4px;
  display: grid;

  grid-template-columns: 110px 44px 150px repeat(7, 1fr);
  & > div {
    padding: 0 20px;
    text-align: center;
    height: 56px;
    font-weight: 350;
    font-size: 14px;
    text-transform: capitalize;
    color: #000000;
    background: #ff9c41;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > div:nth-child(2n) {
    background: #e38732;
  }
  & > div:nth-child(1) {
    grid-column: span 2;
  }
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 350;
  font-size: 14px;
  text-transform: capitalize;
  color: #ffffff;
  height: 80px;
`;

export const TierGroup = styled.div`
  display: flex;
`;

export const Tier = styled.div<{ val: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: ${({ val }) => (val < 4 ? "#000000" : "#FFFFFF")};
  background: ${({ val }) =>
    val === 1
      ? `url(${tier1bg})`
      : val === 2
      ? `url(${tier2bg})`
      : val === 3
      ? `url(${tier3bg})`
      : "black"};
  &:before {
    content: "Tier ${({ val }) => val}";
  }
`;

export const Num = styled(Cell)<{ val: number }>`
  font-weight: 350;
  font-size: 14px;
  text-transform: capitalize;
  color: #ffffff;

  &:before {
    content: "${({ val }) =>
      val.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}";
  }
`;

export const ClanName = styled(Cell)<{ val: string }>`
  justify-self: flex-start;
  padding-left: 9px;
  &:before {
    content: "${({ val }) => val}";
  }
`;

export const TotalPoints = styled(Cell)<{ val: number }>`
  color: #ffb60c;
  &:before {
    content: "${({ val }) => numberWithCommas(val)}";
  }
`;
export const VaultedPoints = styled(Cell)<{ val: number }>`
  &:before {
    content: "${({ val }) => numberWithCommas(val)}";
  }
`;
export const CityWrapper = styled.div`
  position: relative;
`;

export const City = styled(Cell)<{ pointer?: boolean; color: string }>`
  background: ${({ color }) =>
    color === "yellow" ? "#D7A012" : color === "blue" ? "#2E9BFF" : "none"};
  flex-direction: column;
  row-gap: 3px;
  & > .cityName {
    color: ${({ color }) => (color === "" ? "#838383" : "#ffffff")};
  }
  & > .cityPoints {
    color: #ffffff;
  }
  &:hover {
    border: 2px solid #ffb60c;
  }
  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}
`;

export const CityEconomy = styled(Cell)<{ val: number }>`
  color: ${({ val }) => (val === 1 ? "#59B015" : "#FFFFFF")};
  &:before {
    content: "${({ val }) => Math.floor(val * 100)}%";
  }
`;
