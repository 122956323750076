import * as s from "components/TopThree/styled";
import { useNavigate } from "react-router";
import { numberWithCommas } from "utils/numberWithComas";
import iconOrbsG from "assets/enrich-orbs-icon-grey.png";
import iconMilitaryG from "assets/military-point-icon-grey.png";
import iconEconomicG from "assets/economic-point-icon-grey.png";
import {
  StatsItemCount,
  StatsItemCW,
  StatsItemImg,
  StatsItemText,
} from "components/IntraClan/PlayerCard.styled";
import { useSwrImmutable } from "hooks/useSwr";
import { getAccountDetails } from "api";

export function PlayerCard({ player }: { player: IIntraClanPlayer }) {
  const navigate = useNavigate();

  const [profile] = useSwrImmutable([player.id, "user"], getAccountDetails);
  if (!profile) return null;

  return (
    <s.Card rank={player.rank}>
      <s.CardHeaderRank rank={player.rank}>
        <s.RankText>{player.rank}</s.RankText>
      </s.CardHeaderRank>
      <s.CardHeader onClick={() => navigate("/profile/" + player.id)}>
        {profile.name}
      </s.CardHeader>
      <s.CardContent>
        <s.ScoreAndIcon>
          <s.ScoreAndIconInner>
            <s.IconRing rank={player.rank}>
              <s.Icon
                icon={profile.icon}
                name={profile.name}
                rank={player.rank}
              />
            </s.IconRing>
            <s.ScoreWrapper>
              <s.Score>{numberWithCommas(player.totalScore)}</s.Score>
              <s.ScoreText>TOTAL POINTS</s.ScoreText>
            </s.ScoreWrapper>
          </s.ScoreAndIconInner>
        </s.ScoreAndIcon>
        <s.Separator />
        <s.Stats>
          <StatsItemCW>
            <StatsItemImg src={iconMilitaryG} alt="kills" />
            <StatsItemText>Military points</StatsItemText>
            <StatsItemCount>
              {numberWithCommas(player.militaryScore)}
            </StatsItemCount>
          </StatsItemCW>
          <StatsItemCW>
            <StatsItemImg src={iconEconomicG} alt="deaths" />
            <StatsItemText>Economic points</StatsItemText>
            <StatsItemCount>
              {numberWithCommas(player.economicScore)}
            </StatsItemCount>
          </StatsItemCW>
          <StatsItemCW>
            <StatsItemImg src={iconOrbsG} alt="kdr" />
            <StatsItemText>Enriched Orbs donated</StatsItemText>
            <StatsItemCount>
              {numberWithCommas(player.orbsDonated)}
            </StatsItemCount>
          </StatsItemCW>
        </s.Stats>
      </s.CardContent>
    </s.Card>
  );
}
