import {
  Container,
  LeftArrow,
  NumberContiner,
  RightArrow,
} from "components/QuantityCounterFlex/QuantityCounterFlex.style";
import { ChangeEvent } from "react";

interface ICounter {
  amount: number;
  setAmount: Function;
  w?: number | "full";
  max?: number;
}

export const QuantityCounterFlex = ({amount, setAmount,w,max}: ICounter) => {
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    
    if(e.target.value==="") setAmount("")
    else if (+e.target.value < 0 || isNaN(+e.target.value) ) return
    else if(max && +e.target.value > max) setAmount(max)
    else setAmount(+e.target.value)
  };
  
  const increaseCount = () => {
    if(max===0) return
    if(max && (amount >= max)) return
    setAmount(amount + 1)
  }
  
  const decreaseCount = () => {
    if (amount === 1) return
    setAmount(amount - 1)
  }
  
  return (
    <Container w={w}>
      <LeftArrow onClick={() => amount > 0 && decreaseCount()}/>
      <NumberContiner w={w} value={amount} onChange={handleChange}/>
      <RightArrow onClick={() => increaseCount()}/>
    </Container>
  )
}