import { SVGAttributes } from 'react';

const ClanIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
   width="20" 
   height="20" 
   viewBox="0 0 20 20"
   fill="currentColor" 
   xmlns="http://www.w3.org/2000/svg"
   {...props}
    >
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.1107 0.558594L12.1469 1.25318L14.6767 0.558594H17.5215C17.7708 0.558594 18.01 0.656112 18.1863 0.829696C18.3626 1.00328 18.4617 1.23871 18.4617 1.4842V7.59694C18.1398 7.91084 17.8014 8.23247 17.4752 8.54245L17.4752 8.54245L17.4752 8.54246L17.4752 8.54247L17.4752 8.54248C16.6113 9.36349 15.8335 10.1026 15.6792 10.3992L18.4617 9.06229V19.3261C18.4617 19.4033 18.4422 19.4793 18.4048 19.5472C18.3674 19.6151 18.3133 19.6727 18.2475 19.7147C18.1817 19.7568 18.1063 19.782 18.0281 19.788C17.9499 19.794 17.8714 19.7806 17.7998 19.7491L10.0001 16.3216L2.20045 19.7482C2.12891 19.7796 2.05051 19.793 1.97238 19.7871C1.89425 19.7811 1.81887 19.756 1.7531 19.7141C1.68733 19.6721 1.63325 19.6147 1.59578 19.5469C1.55832 19.4791 1.53865 19.4032 1.53857 19.3261V1.4842C1.53857 1.23871 1.63763 1.00328 1.81394 0.829696C1.99026 0.656112 2.2294 0.558594 2.47875 0.558594H4.68164L2.87463 1.25318L3.31617 2.52608L4.9541 0.558594H13.1107ZM12.7633 12.6331L10.0001 11.203L7.23789 12.6331L7.76439 9.6045L5.5296 7.45895L8.61806 7.01652L10.0001 4.261L11.3812 7.01652L14.4706 7.45895L12.2358 9.6045L12.7633 12.6331Z" fill="#838383"/>
</svg>


);

export default ClanIcon;