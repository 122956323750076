import { WDModalStyles } from "pages/WorldDomination/modalStyles";
import { CityShieldModalContent } from "pages/WorldDomination/WDStatsPanel/CityShieldModalContent";
import { IronDomeModalContent } from "pages/WorldDomination/WDStatsPanel/IronDomeModalContent";
import { MissileSiloModalContent } from "pages/WorldDomination/WDStatsPanel/MissileSiloModalContent";
import { NukeModalContent } from "pages/WorldDomination/WDStatsPanel/NukeModalContent";
import { OilRigModalContent } from "pages/WorldDomination/WDStatsPanel/OilRigModalContent";
import { IShowModal } from "pages/WorldDomination/WDStatsPanel/WDStatsPanelView";
import Modal from "react-modal";

export function ConstructionModal({
  onClose,
  type,
  player,
}: {
  type: IShowModal;
  onClose: any;
  player: IProfileFull;
}) {
  return (
    <Modal
      isOpen
      // htmlOpenClassName={classes.content}
      style={WDModalStyles}
      shouldCloseOnEsc
      preventScroll
      onRequestClose={onClose}
    >
      {type === "oil_rig" && <OilRigModalContent onClose={onClose} />}
      {type === "missile_silo" && <MissileSiloModalContent onClose={onClose} />}
      {type === "nuke" && (
        <NukeModalContent onClose={onClose} player={player} />
      )}
      {type === "iron_dome" && <IronDomeModalContent onClose={onClose} />}
      {type === "city_shield" && (
        <CityShieldModalContent onClose={onClose} player={player} />
      )}
    </Modal>
  );
}
