import { PayloadAction } from "@reduxjs/toolkit";
import Box from "components/Box";
import Img from "components/Img";
import Text from "components/Text";
import cityShieldGreenIcon from "pages/WorldDomination/assets/cityShieldGreenIcon.svg";
import cityShieldIcon from "pages/WorldDomination/assets/cityShieldIcon.svg";
import { AttackModal } from "pages/WorldDomination/WDGridPanel/AttackModal";
import { ICity } from "pages/WorldDomination/WDGridPanel/WDGrid";
import * as s from "pages/WorldDomination/WDGridPanel/WDGrid.styled";
import { NukesPlanned } from "pages/WorldDomination/WDGridPanel/WDGridRow";
import { useGetEventQuery, useGetEventTurnQuery } from "redux/rtkQuery/rtkApi";
import { greenLog } from "utils/advancedLog";
import { numberWithCommas } from "utils/numberWithComas";

export function City(props: {
  onClick: () => void;
  shields: false | number;
  shieldsPlanned: number;
  isMyClan: boolean;
  name: string;
  cityPoints: number;
  nukesPlanned: any;
  attack: any;
  cityId: string;
  onClose: () => PayloadAction<
    { city: ICity; clanId: string } | undefined,
    string
  >;
}) {
  const { data: event } = useGetEventQuery();
  const { data: turn } = useGetEventTurnQuery(event?.id!);

  if (!turn) return null;
  let cityOffenceLastTurn;
  let cityDefenceLastTurn;
  let successful = 0; // how many nukes city took
  let failed = 0; // how many nukes city blocked
  let percentsLost = 0;
  let color = "";

  //LOGIC
  if (props.isMyClan)
    cityDefenceLastTurn = turn.battleSummaryList
      .find((i) => i.round === turn.round - 1)
      ?.defence?.citiesList?.find((i) => i.cityId === props.cityId);
  else
    cityOffenceLastTurn = turn.battleSummaryList
      .find((i) => i.round === turn.round - 1)
      ?.offence?.citiesList?.find((i) => i.cityId === props.cityId);

  if (cityOffenceLastTurn && !props.isMyClan) {
    successful = cityOffenceLastTurn.myNukes.successful;
    failed = cityOffenceLastTurn.myNukes.failed;
    const totalVampirePoints = cityOffenceLastTurn.nukes.vampirePoints;
    if (totalVampirePoints)
      percentsLost =
        (totalVampirePoints * 4) / (props.cityPoints + totalVampirePoints * 4);
  }

  if (cityDefenceLastTurn && props.isMyClan) {
    successful = cityDefenceLastTurn.successfulNukes;
    failed = cityDefenceLastTurn.failedNukes;
    if (cityDefenceLastTurn.damagePoints)
      percentsLost =
        cityDefenceLastTurn.damagePoints /
        (props.cityPoints + cityDefenceLastTurn.damagePoints);
  }
  percentsLost = Math.round(percentsLost * 100);
  if (successful === 0 && failed === 0) color = "";
  if (successful > 0) color = "yellow";
  if (failed > 0 && successful === 0) color = "blue";

  return (
    <s.CityWrapper data-id={props.cityId}>
      <s.City onClick={props.onClick} pointer color={color}>
        {props.shields || props.shieldsPlanned ? (
          <Box gap={10}>
            {props.shields > 0 && (
              <Text>
                {props.shields}{" "}
                <Img inline src={cityShieldIcon} w={10} h={11} />
              </Text>
            )}
            {props.shieldsPlanned > 0 && (
              <Text green>
                +{props.shieldsPlanned}{" "}
                <Img inline src={cityShieldGreenIcon} w={10} h={11} />
              </Text>
            )}
          </Box>
        ) : null}
        <div className="cityName">{props.name}</div>
        <div className="cityPoints">{numberWithCommas(props.cityPoints)}</div>
        {props.nukesPlanned > 0 && <NukesPlanned count={props.nukesPlanned} />}
        {/*{percentsLost > 0 && <PercentsLost count={percentsLost} />}*/}
      </s.City>
      {props.attack?.city?.id === props.cityId && (
        <AttackModal onClose={props.onClose} />
      )}
    </s.CityWrapper>
  );
}
