import * as s from "components/Staking/StakeForEventPoints/Grid/Grid.styled";
import Text from "components/Text/Text";

export const PointsBlockNotInClan = () => (
  <s.PointsBlock>
    <Text s={14} color="white">
      To stake and earn points, please
    </Text>
    <Text
      w={700}
      s={20}
      color="yellow"
      underline
      pointer
      onClick={() => {
        window.open("https://miniroyale.io/?login", "_blank");
      }}
    >
      Join Clan
    </Text>
  </s.PointsBlock>
);
