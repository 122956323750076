import React from "react";
import { useMediaQuery } from "react-responsive";

export const useIsDesktop = () => useMediaQuery({ minWidth: 1280 });

export const useIsTablet = () =>
  useMediaQuery({ minWidth: 768, maxWidth: 1279 });

export const useIsMobile = () => useMediaQuery({ query: "(max-width: 767px)" });

export const useMediaAll = () => {
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  return { isDesktop, isTablet, isMobile };
};

interface IProps {
  children: React.ReactNode;
}

export const SizeDesktop = ({ children }: IProps) => {
  const isDesktop = useIsDesktop();
  return isDesktop ? children : null;
};
export const SizeTablet = ({ children }: IProps) => {
  const isTablet = useIsTablet();
  return isTablet ? children : null;
};
export const SizeMobile = ({ children }: IProps) => {
  const isMobile = useIsMobile();
  return isMobile ? children : null;
};

