import { SVGAttributes } from 'react';

const SuccessIcon = (props: SVGAttributes<SVGElement>) => (
  <svg 
    width="20"
    height="17" 
    viewBox="0 0 20 17" 
    fill="currentColor"
    {...props}
>
<path d="M7.14445 16.1153L0.599121 9.56988L3.50835 6.66074L7.14445 10.2968L16.4921 0.949219L19.4012 3.85845L7.14445 16.1153Z" 
    fill="currentColor"/>
</svg>


);

export default SuccessIcon;