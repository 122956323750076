import Tooltip from "rc-tooltip";

export const overlayInnerStyle = {
  border: "1px solid #FFB60C",
  borderRadius: "4px",
  display: "flex",
  padding: "4px 10px",
  alignItems: "center",
  justifyContent: "center",
};

export function SimpleTextTooltip({
  children,
  overlay,
  visible = true,
  placement = "topRight",
}: {
  overlay: string;
  children: JSX.Element;
  placement?: string;
  visible?: boolean;
}) {
  return (
    <Tooltip
      trigger={visible ? "hover" : "focus"}
      placement={placement}
      overlay={overlay}
      overlayInnerStyle={overlayInnerStyle}
    >
      {children}
    </Tooltip>
  );
}
