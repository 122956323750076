import TableContent from "./TableContent";
import * as s from "./styled";
import { useIsMobile } from "hooks/useMediaQuery";
import TableContentMobile from "./TableContentMobile";

export default function TableClanWars({ data }: { data: IIntraClanPlayer[] }) {
  const isMobile = useIsMobile();
  return (
    <s.Wrapper>
      <s.Inner>
        <s.TableHeader>
          <s.HeadImg />
          <s.TableName>Clan Wars Members</s.TableName>
        </s.TableHeader>
        {!isMobile ? (
          <TableContent data={data} />
        ) : (
          <TableContentMobile data={data} />
        )}
      </s.Inner>
    </s.Wrapper>
  );
}
