import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { ICurrency } from "api/api.types";
import Box from "components/Box";
import { Gap } from "components/Gap";
import GreenButton from "components/GreenButton";
import Img from "components/Img";
import { Spinner } from "components/Spinner";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import { HorizontalLine } from "components/TableClans/CloseModal.styled";
import Text from "components/Text/Text";
import bp from "pages/WorldDomination/assets/build-points-icon.png";
import ironDome from "pages/WorldDomination/assets/ironDomeBig.png";
import whiteCheck from "pages/WorldDomination/assets/whiteCheck.svg";
import { Close } from "pages/WorldDomination/WDStatsPanel/Close";
import * as s from "pages/WorldDomination/WDStatsPanel/ConstructionModal.styled";
import { useEffect, useState } from "react";
import {
  useAddToQueueMutation,
  useGetClanAssetsQuery,
  useGetClanInfoQuery,
  useGetEventQuery,
  useGetEventTurnQuery,
  useGetRoundQueueQuery,
  useGetUserRestrictionsQuery,
  useRemoveFromQueueMutation,
} from "redux/rtkQuery/rtkApi";
import { addErrorMessage } from "redux/slices/messageSystemSlice";
import { selectStreamItemById2 } from "redux/slices/worldDominationSlice";
import { useAppDispatch, useAppSelector } from "store";
import { numberWithCommas } from "utils/numberWithComas";
import { toFixedWithoutZeros } from "utils/toFixedWithoutZeros";
import { v4 as uuidv4 } from "uuid";

export function IronDomeModalContent(props: { onClose: Function }) {
  const dispatch = useAppDispatch();
  const { data: event } = useGetEventQuery();
  const { data: turn, refetch: refetchTurn } = useGetEventTurnQuery(
    event?.id ?? skipToken
  );
  const { data: queue } = useGetRoundQueueQuery();
  const [addToQueue] = useAddToQueueMutation();
  const [removeFromQueue] = useRemoveFromQueueMutation();
  const { data: assets } = useGetClanAssetsQuery(event?.id ?? skipToken);
  const { data: userRestrictions } = useGetUserRestrictionsQuery();
  const { data: info } = useGetClanInfoQuery();

  const [status, setStatus] = useState<IStatus>("idle");

  const [requestId, setRequestId] = useState(() => uuidv4());
  const transactionResult = useAppSelector(selectStreamItemById2(requestId));

  // Wait transaction to finish
  useEffect(() => {
    if (transactionResult === undefined) return;
    if (transactionResult) {
      if (transactionResult?.error) {
        if (transactionResult?.error?.code === 3) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Insufficient funds",
            })
          );
        } else if (transactionResult?.error?.code === 6) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Construction limit exceeded",
            })
          );
        } else if (transactionResult?.error?.code === 5) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Can not allocate space for construction",
            })
          );
        } else {
          dispatch(
            addErrorMessage({ title: "Error", text: "Something went wrong" })
          );
        }
        props.onClose();
      } else {
        setStatus("done");
        setRequestId(uuidv4());
      }
    }
  }, [transactionResult]);

  if (!event || !queue || !turn || !assets || !userRestrictions || !info)
    return null;

  const currency: ICurrency = "BUILD_POINT";
  const priceData = turn.market.productsList
    .find((i) => i.productId === "BUILD_IRON_DOME")!
    .pricesList.find((i) => i.currency === currency)!;
  const price = priceData.amount;

  const balance = turn.clan.balancesList.find(
    (i) => i.currency === currency
  )!.amount;

  const ironDomeCount = assets.ironDomesList.length;
  const ironDomePlannedIds = queue.ironDome.map((i) => i.id);
  const ironDomePlannedCount = ironDomePlannedIds.length;
  const ironDomeProtectionPercent =
    event.extensions.worldDominationConfig.attacks.attackCityByNuke
      .ironDomeProtectionPercent / 10000;

  let hintText = "";
  if (price > balance) hintText = "Insufficient Build Points";
  if (ironDomeCount > 0 || ironDomePlannedCount > 0)
    hintText = "Can have or build only one Iron Dome at a time";
  if (
    price > userRestrictions.queueRestrictions.buildpoints.limit &&
    !userRestrictions.queueRestrictions.buildpoints.unlimited
  )
    hintText = "The total cost is over your build limit";
  if (!["LEADER", "OFFICER"].includes(info.role))
    hintText = "You Must be a Clan Leader, or Officer in order to Build";

  const handleOrder = async () => {
    setStatus("pending");
    const data = (await addToQueue({
      type: "ironDome",
      amount: 1,
      turnId: turn.turn.turnId,
      currency,
      requestId,
    })) as any;
    if (data?.error) {
      props.onClose();
      dispatch(
        addErrorMessage({ title: "Error", text: "Something went wrong" })
      );
    }
    refetchTurn();
  };

  const handleRemove = async () => {
    setStatus("pending");
    const data = (await removeFromQueue({
      turnId: turn.turn.turnId,
      itemIds: ironDomePlannedIds,
    })) as any;
    if (data?.error) {
      props.onClose();
      dispatch(
        addErrorMessage({ title: "Error", text: "Something went wrong" })
      );
    }
    refetchTurn();
    props.onClose();
  };

  if (status === "idle")
    return (
      <>
        <s.Close onClick={() => props.onClose()}>
          <img src={closeIcon} />
        </s.Close>
        <s.ContentIronDome>
          <s.Info>
            <s.Img src={ironDome} />
            <s.InfoContent>
              <s.Title>Iron Dome Construction</s.Title>
              <Gap h={16} />
              <s.DescriptionTitle>
                The Iron Dome (Defense Wonder) provides the following:
              </s.DescriptionTitle>
              <s.DescriptionIronDome>
                <li>Ability to Defend ALL of your cities from attack</li>
                <li>
                  In the case that a City’s shields are breached, the wonder
                  will reduce the amount of score points lost by{" "}
                  {toFixedWithoutZeros(ironDomeProtectionPercent * 100)}%
                </li>
              </s.DescriptionIronDome>
              <Box flex="1 1 auto" />
              <s.HintIronDome>
                The Iron Dome cannot be destroyed by enemy attacks, only 1 can
                be built during an Event
              </s.HintIronDome>
            </s.InfoContent>
          </s.Info>
          <HorizontalLine />
          <s.OrderBlock>
            <s.Current>
              <div>Current Quantity:</div>
              <div className="count">{ironDomeCount}</div>
              <Box justifyContent="space-between" flex="1">
                <div>Cost:</div>

                <Text>
                  <Box alignItems="center">
                    <img src={bp} height={20} />
                    {numberWithCommas(price)} Build Points
                  </Box>
                </Text>
              </Box>
            </s.Current>
            <Gap h={18} />
            <s.Planned>
              <div>
                Planned for Next Turn:
                {ironDomePlannedCount > 0 && (
                  <s.Remove onClick={handleRemove} />
                )}
              </div>
              <s.PlannedCount className="count" count={ironDomePlannedCount} />
              <Box justifyContent="space-between" alignItems="center" flex="1">
                <div>Quantity:</div>
                <Text green s={16} w={400}>
                  1
                </Text>
              </Box>
            </s.Planned>
          </s.OrderBlock>
          <Box w={278} mt={34} mb={10} alignSelf="center">
            <GreenButton h={60} onClick={handleOrder} disabled={!!hintText}>
              Build
            </GreenButton>
          </Box>
          <s.ButtonHint>{hintText}</s.ButtonHint>
        </s.ContentIronDome>
      </>
    );
  if (status === "pending")
    return (
      <s.Content>
        <Spinner />
      </s.Content>
    );

  if (status === "done")
    return (
      <s.Content>
        <Close onClose={props.onClose} />
        <Gap h={28} />
        <Img src={ironDome} w={160} center />
        <Gap h={12} />
        <Text s={22} center>
          You Built Iron Dome
          <br />
          for <Img src={bp} w={20} inline mb={-2} /> {numberWithCommas(price)}{" "}
          build point!
        </Text>
        <Gap h={10} />
        <Text grey center w={350}>
          Clan build Point Balance: {numberWithCommas(balance)}
        </Text>
        <Gap h={38} />
        <GreenButton onClick={() => props.onClose()} h={60} w={276} center>
          <Img src={whiteCheck} /> Done!
        </GreenButton>
      </s.Content>
    );
  return null;
}
