export const formatToMounthDay = (seconds: number) => {
    const time = new Date(seconds * 1000);
    const mounth = time.toLocaleString('en-UK', { month: 'long' })
    const day = time.getUTCDate()
    return `${mounth} ${day}`
}

export const getUTCTime = (seconds: number) => {
    const time = new Date(seconds * 1000);
    const minets = time.getUTCMinutes() < 10 ? `0${time.getUTCMinutes()}` : time.getUTCMinutes()
    return `${time.getUTCHours()}:${minets}`
}