import styled from "styled-components/macro";

export type IClanButtonType = "join" | "requested" | "leave";
export const JoinButton = ({
  type,
  onClick,
}: {
  type: IClanButtonType;
  onClick: () => void;
}) => {
  const text =
    type === "join"
      ? "JOIN CLAN"
      : type === "requested"
      ? "REQUESTED"
      : "LEAVE CLAN";
  return (
    <ButtonWrapper statusType={type} onClick={onClick}>
      <span>{text}</span>
    </ButtonWrapper>
  );
};
export const ButtonWrapper = styled.button<{ statusType: IClanButtonType }>`
  font-weight: 700;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  width: 120px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgb(255 255 255 / 12%);
  text-transform: uppercase;

  & span {
    color: ${({ statusType }) =>
      statusType === "join"
        ? "#59b015"
        : statusType === "requested"
        ? "#FFFFFF"
        : "#EC2E00"};
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &:active {
    background-color: rgba(184, 184, 184, 0.2);
  }
`;
