import * as s from "./styled";
import { useIsMobile } from "hooks/useMediaQuery";
import TableContentClans from "components/TableClans/TableContentClans";
import TableContentClansMob from "components/TableClans/TableContentClansMob";
import TableSkeleton from "components/Skeleton/TableSkeleton";
import { IFilterItem } from "components/Filters";
import { IGWClan } from "api/miniroyale-gateway";
import { useSwrImmutable } from "hooks/useSwr";
import { getAccountDetails, idp_GetAccount } from "api";

export default function TableClans({
  clans,
}: {
  clans?: IClan[];
  mergedFilters?: IFilterItem[];
}) {
  const [auth] = useSwrImmutable("auth", idp_GetAccount);
  // const [myClan] = useSwr("myClan", getClanDetailsGW);
  // const [clansGW] = useSwr("clansGW", getClansGW);
  const id = auth?.userId?.value;
  const [, loadingProfile] = useSwrImmutable(
    id ? [id, "user"] : null,
    getAccountDetails
  );
  const clansGW: IGWClan[] = [];
  const myClan = null;
  const isMobile = useIsMobile();
  if (
    clans === undefined ||
    auth === undefined ||
    myClan === undefined ||
    clansGW === undefined ||
    loadingProfile
  )
    return <TableSkeleton filtersCount={2} />;

  return (
    <s.Wrapper>
      <s.Inner>
        <s.TableHeader>
          <s.HeadImg />
          <s.TableName>Clan Seasonal Leaderboard</s.TableName>
          {/*<TableFilters mergedFilters={mergedFilters} />*/}
        </s.TableHeader>
        {isMobile ? (
          <TableContentClansMob clanList={clans} />
        ) : (
          <TableContentClans clanList={clans} />
        )}
      </s.Inner>
    </s.Wrapper>
  );
}
