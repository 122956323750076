import * as s from "./styled";
import { HeadImg } from "components/TableScores/styled";
import { useIsMobile } from "hooks/useMediaQuery";
import { useState } from "react";
import { mapTagToName } from "api/transformDataHelpers";
import styled from "styled-components/macro";

function GridRow(props: { $i: number; item: IMatchHistory }) {
  return (
    <s.GridRow $i={props.$i}>
      <s.GridCell>
        <p>mode</p>
        {props.item.mode}
      </s.GridCell>
      <s.GridCell>
        <p>time</p>
        {props.item.time}
      </s.GridCell>
      <s.GridCell>
        <p>map</p>
        {mapTagToName(props.item.map)}
      </s.GridCell>
      <s.GridCell>
        <p>rank</p>
        {props.item.rank}
      </s.GridCell>
      <s.GridCell>
        <p>kills</p>
        {props.item.kills}
      </s.GridCell>
      <s.GridCell>
        <p>deaths</p>
        {props.item.deaths}
      </s.GridCell>
      <s.GridCell>
        <p>experience</p>
        {props.item.experience}
      </s.GridCell>
      <s.GridCell>
        <Victory status={props.item.status} />
      </s.GridCell>
    </s.GridRow>
  );
}

export const Victory = styled.div<{ status: IMatchHistory["status"] }>`
  &:after {
    text-transform: capitalize;
    color: ${({ status }) => (status === "victory" ? "#59B015" : "#FC3D3D")};
    content: "${({ status }) => status}";
    font-weight: 350;
    font-size: 14px;
  }
`;
function GridRowMob(props: { $i: number; item: IMatchHistory }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <s.GridRowMobile $i={props.$i}>
        <s.GridCell>
          <p>mode</p>
          {props.item.mode}
        </s.GridCell>
        <s.GridCell>
          <p>time</p>
          {props.item.time}
        </s.GridCell>
        <s.GridCell onClick={() => setOpen(!open)}>
          <s.TableArrowMob open={open} />
        </s.GridCell>
      </s.GridRowMobile>
      <s.HiddenStats open={open}>
        {!!(props.$i % 2) && <s.StatsSeparator />}
        <s.StatItem>
          <s.StatTitle>Map</s.StatTitle>
          <s.StatText>{mapTagToName(props.item.map)}</s.StatText>
        </s.StatItem>
        <s.StatItem>
          <s.StatTitle>Rank</s.StatTitle>
          <s.StatText>{props.item.rank}</s.StatText>
        </s.StatItem>
        <s.StatItem>
          <s.StatTitle>Kills</s.StatTitle>
          <s.StatText>{props.item.kills}</s.StatText>
        </s.StatItem>
        <s.StatsSeparator />
        <s.StatItem>
          <s.StatTitle>Deaths</s.StatTitle>
          <s.StatText>{props.item.deaths}</s.StatText>
        </s.StatItem>
        <s.StatItem>
          <s.StatTitle>Experience</s.StatTitle>
          <s.StatText>{props.item.experience}</s.StatText>
        </s.StatItem>
        <s.StatItem>
          <Victory status={props.item.status} />
        </s.StatItem>
        {!(props.$i % 2) && <s.StatsSeparator />}
      </s.HiddenStats>
    </>
  );
}

export function MatchHistoryTable({ data }: { data?: IMatchHistory[] }) {
  const isMobile = useIsMobile();
  const Row = isMobile ? GridRowMob : GridRow;
  if (!data || data?.length === 0) return null;
  return (
    <s.MhWrapper>
      <HeadImg />
      <s.BottomImg />
      <s.MhHeader>MATCH HISTORY</s.MhHeader>
      {data.map((item, i) => (
        <Row key={item.id} $i={i} item={item} />
      ))}
    </s.MhWrapper>
  );
}
