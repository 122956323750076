import { idp_GetAccount } from "api";
import allTime from "assets/allTime.svg";
import allTimeActive from "assets/allTimeActive.svg";
import clan from "assets/clan.svg";
import clanActive from "assets/clanActive.svg";
import clanWar from "assets/clanWar.png";
import clanWarActive from "assets/clanWarActive.png";
import daily from "assets/daily.svg";
import dailyActive from "assets/dailyActive.svg";
import logo from "assets/logo.png";
import stakingIcon from "assets/stakingIcon.svg";
import stakingIconActive from "assets/stakingIconActive.svg";
import Box from "components/Box";
import Drawer from "components/Drawer";
import CWSumbenu from "components/Header/CWSubmenu";
import { MenuItem } from "components/Header/MenuItem";
import { MenuItemProfile } from "components/Header/MenuItemProfile";
import { useClickOutside } from "hooks/useClickOutside";
import { useSwrImmutable } from "hooks/useSwr";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

import { useLocation, useNavigate } from "react-router";
import * as s from "./styled";

export default function Menu() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 1170 });
  const pathname = useLocation().pathname;
  const active =
    pathname === "/" || pathname.includes("daily")
      ? "daily"
      : pathname.includes("clans")
      ? "clans"
      : pathname.includes("seasonal")
      ? "seasonal"
      : pathname.includes("profile")
      ? "profile"
      : pathname.includes("staking")
      ? "staking"
      : pathname.includes("clanWars")
      ? "clanWars"
      : pathname.includes("tieredLB")
      ? "clanWars"
      : pathname.includes("transferHistory")
      ? "clanWars"
      : pathname.includes("worldDomination")
      ? "clanWars"
      : "";

  return (
    <s.HeaderWrapper>
      <s.HeaderContainer>
        <s.HeaderImg src={logo} onClick={() => navigate("/")} />
        <s.Grow />
        {isMobile ? (
          <MobMenu active={active} />
        ) : (
          <MenuContent active={active} />
        )}
      </s.HeaderContainer>
    </s.HeaderWrapper>
  );
}

function MenuContent(props: { active: string; handleClose?: () => void }) {
  const navigate = useNavigate();
  const [auth] = useSwrImmutable("auth", idp_GetAccount);
  const [showCWSubmenu, setShowCWSubmenu] = useState(false);
  // const [flags] = useSwrImmutable("fflags", fflags_getUserFeatures);
  const stakingEnabled = true;
  const ref = useClickOutside(true, () => {
    setShowCWSubmenu(false);
  });
  
  return (
    <>
      <MenuItem
        active={props.active === "daily"}
        text={"DAILY"}
        img={daily}
        activeImg={dailyActive}
        onClick={() => navigate("/daily")}
        handleClose={props.handleClose}
      />
      <s.MenuSeparator />
      <MenuItem
        active={props.active === "seasonal"}
        text={"SEASONAL"}
        img={allTime}
        activeImg={allTimeActive}
        onClick={() => navigate("/seasonal")}
        handleClose={props.handleClose}
      />
      <s.MenuSeparator />
      <MenuItem
        active={props.active === "clans"}
        text={"CLAN SEASONAL"}
        img={clan}
        activeImg={clanActive}
        onClick={() => navigate("/clans")}
        handleClose={props.handleClose}
      />
      <s.MenuSeparator />
      {!!auth && (
        <Box position="relative" h={60} boxRef={ref}>
          <s.MenuItemBg active={props.active === "clanWars"}>
            <s.MenuItem
              active={props.active === "clanWars"}
              onClick={() => setShowCWSubmenu(!showCWSubmenu)}
            >
              <s.MenuImg
                src={props.active === "clanWars" ? clanWarActive : clanWar}
              />
              <div>CLAN WARS</div>
            </s.MenuItem>
          </s.MenuItemBg>
          {showCWSubmenu && <CWSumbenu onClose={()=>setShowCWSubmenu(false)} />}
        </Box>
      )}
      <s.MenuSeparator />
      {stakingEnabled && (
        <>
          <MenuItem
            active={props.active === "staking"}
            text={"STAKING"}
            img={stakingIcon}
            activeImg={stakingIconActive}
            onClick={() => navigate("/staking")}
            handleClose={props.handleClose}
          />
          <s.MenuSeparator />
        </>
      )}
      <MenuItemProfile
        text={"PROFILE"}
        onClick={() => navigate("/profile")}
        handleClose={props.handleClose}
      />
    </>
  );
}

function MobMenu({ active }: { active: string }) {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      <s.MenuIcon
        open={showDrawer}
        onClick={() => setShowDrawer(!showDrawer)}
      />
      <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
        <MenuContent active={active} handleClose={() => setShowDrawer(false)} />
      </Drawer>
    </>
  );
}
