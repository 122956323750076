import TableContent from "components/TableScores/TableContent";
import * as s from "./styled";
import { useIsMobile } from "hooks/useMediaQuery";
import TableContentMobile from "components/TableScores/TableContentMobile";
import TableFilters, { IFilterItem } from "components/Filters";
import TableSkeleton from "components/Skeleton/TableSkeleton";

export default function TableScores({
  data,
  title,
  mergedFilters,
  loading,
}: {
  data?: IPlayer[];
  title: string;
  mergedFilters?: IFilterItem[];
  loading: boolean;
}) {
  const isMobile = useIsMobile();
  if (loading || data === undefined || mergedFilters === undefined)
    return <TableSkeleton filtersCount={2} />;
  return (
    <s.Wrapper>
      <s.Inner>
        <s.TableHeader>
          <s.HeadImg />
          <s.TableName>{title}</s.TableName>
          <TableFilters mergedFilters={mergedFilters} />
        </s.TableHeader>
        {isMobile ? (
          <TableContentMobile data={data} />
        ) : (
          <TableContent data={data} />
        )}
      </s.Inner>
    </s.Wrapper>
  );
}
