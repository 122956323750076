import { idp_GetAccount } from "api";
import Box from "components/Box";
import { MobilePlaceholder } from "components/MobilePlaceholder";
import { StakeForBattlePassBlock } from "components/Staking/StakeForBattlePassBlock";
import { StakeForCheddarBlock } from "components/Staking/StakeForCheddarBlock";
import { StakeForEventPointsBlock } from "components/Staking/StakeForEventPointsBlock";
import * as s from "components/Staking/staking.styled";
import { StakingLogin } from "components/Staking/StakingLogin";
import { useSwrImmutable } from "hooks/useSwr";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { fetchEvent } from "redux/slices/eventPointsSlice";
import { useAppDispatch, useAppSelector } from "store";

export function StakingHomePage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const event = useAppSelector((state) => state.eventPointsPage.event);
  const isMobile = useMediaQuery({ maxWidth: 1170 });
  const [auth, loadingAuth] = useSwrImmutable("auth", idp_GetAccount);
  useEffect(() => {
    if (!event.id) dispatch(fetchEvent());
  }, []);

  useEffect(() => {
    if (!loadingAuth && auth) navigate("/staking/eventPoints");
  }, [loadingAuth, auth]);

  if (loadingAuth) return null;
  if (isMobile) return <MobilePlaceholder text="Staking" />;
  return (
    <s.StakingPage>
      {/*{auth !== null ? <MyCollection /> : <StakingLogin />}*/}
      {auth === null && <StakingLogin />}

      <Box mt={23} gap={20}>
        <StakeForBattlePassBlock />
        <StakeForEventPointsBlock />
        <StakeForCheddarBlock />
      </Box>
    </s.StakingPage>
  );
}
