import { Navigate } from "react-router-dom";
import { useSwrImmutable } from "hooks/useSwr";
import { idp_GetAccount } from "api";
import { Spinner } from "components/Spinner";

export const ProtectedRoute = ({
  children,
  redirectTo,
}: {
  children: JSX.Element;
  redirectTo: string;
}) => {
  const [auth, loading, error] = useSwrImmutable("auth", idp_GetAccount);

  if (loading) return <Spinner />;
  if (!auth || error) {
    return <Navigate to={redirectTo || "/"} replace />;
  }

  return children;
};
