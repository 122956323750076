import { unlockSlot } from "api";
import { useState } from "react";
import { fetchSlots } from "redux/slices/eventPointsSlice";
import { useAppDispatch } from "store";
import { ISetListItem } from "types/stakingTypes";
import { Locked } from "./Locked";
import { Pending } from "./Pending";
import { Unlocked } from "./Unlocked";

export const modalStyles = {
  content: {
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    overflow: "hidden",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: 0,
    border: "1px solid #1A1A1A",
    background: "black",
  },
  overlay: {
    zIndex: 20,
    backgroundColor: "rgba(255,255,255,0)",
  },
};

interface IUnlockModal {
  isOpen: boolean,
  set: ISetListItem,
  setUnlockModal: (val: boolean) => void 
}

export function UnlockModal({ isOpen, set, setUnlockModal }: IUnlockModal) {
  const [isLoading, setLoading] = useState(false)
  const [isUnlocked, setUloked] = useState(false)
  const [, setError] = useState("")

  const dispatch = useAppDispatch()

  const handleUnlock = () => {
    setLoading(true)
    unlockSlot(set?.id)
    .then(() => {
      dispatch(fetchSlots())
      setLoading(false)
      setUloked(true)
    })
    .catch((e: Error) => {
      setLoading(false)
      setError(e.message)
    })
  };

  if (!isOpen) return null;
  if (isUnlocked) return <Unlocked isOpen={isOpen} setUnlockModal={setUnlockModal} setUloked={setUloked} set={set}/>;

  return isLoading ?
  <Pending /> : 
  <Locked isOpen={isOpen} set={set} setUnlockModal={setUnlockModal} handleUnlock={handleUnlock}/>;

}
