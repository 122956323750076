import * as s from "components/Staking/StakeForEventPoints/StakePage/StakeItemPage.styled";
import Text from "components/Text/Text";

export function CharListItem(props: { icon: any; label: any; text: any }) {
  return (
    <s.CharListItem>
      <div className="horizontal" />
      {props.icon ? (
        <img className="img" src={props.icon} />
      ) : (
        <div className="img"></div>
      )}
      <Text w={350} s={12} color="grey" capitalize>
        {props.label}
      </Text>
      <Text w={350} s={16} color="white">
        {props.text}
      </Text>
    </s.CharListItem>
  );
}
