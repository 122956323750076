import { useSwr } from "hooks/useSwr";
import { fetchCanJoinClan, joinClan } from "api/miniroyale-gateway";
import Modal from "react-modal";
import Box from "components/Box";
import { JoinButton } from "components/Clan/JoinButton";
import { useNavigate } from "react-router";
import {
  ClanIconModal,
  ClanInfo,
  CloseModalBtn,
  HorizontalLine,
  IconRingModal,
  Info,
  modalStyles,
  ModalWrapper,
} from "components/TableClans/CloseModal.styled";
import { useSWRConfig } from "swr";

export function ClanModal({ clan }: { clan?: IClan }) {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();

  const [canJoin] = useSwr(
    clan ? [clan.slug, "canJoinClan"] : null,
    fetchCanJoinClan
  );

  console.log(`debug canJoin: `, canJoin);

  const onClose = () => navigate(`/clans`);

  const handleJoinClan = () => {
    if (clan?.slug)
      joinClan(clan.slug).then(() => {
        mutate([clan.slug, "canJoinClan"]);
      });
  };
  console.log(`debug clan: `, clan);
  if (!clan) return null;
  return (
    <Modal
      isOpen={!!clan}
      style={modalStyles}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      preventScroll
      onRequestClose={onClose}
    >
      <ModalWrapper>
        <CloseModalBtn onClick={onClose} />
        <Box display="flex">
          <Box mb={16} mr={22}>
            <IconRingModal rank={2}>
              <ClanIconModal name={clan.slug} rank={2} />
            </IconRingModal>
          </Box>
          <ClanInfo>
            <div>{clan.name}</div>
            <Box pointer onClick={() => navigate(`/profile/${clan.owner.id}`)}>
              Owner: <span>{clan.owner.username}</span>#
              <span>{clan.owner.suffix}</span>
            </Box>
            {canJoin === undefined ? null : canJoin.success ? (
              <JoinButton type="join" onClick={handleJoinClan} />
            ) : (
              <div className="error">{canJoin.message}</div>
            )}
          </ClanInfo>
        </Box>
        <HorizontalLine />
        <Box display="flex" mt={16} justifyContent="space-between">
          <Info>
            <div>RANK</div>
            <div>{clan.rank}</div>
          </Info>
          <Info>
            <div>TAG</div>
            <div>{clan.slug}</div>
          </Info>
          <Info>
            <div>MEMBERS</div>
            <div>{clan.members}</div>
          </Info>
          <Info>
            <div>SCORE</div>
            <div>{clan.score}</div>
          </Info>
        </Box>
      </ModalWrapper>
    </Modal>
  );
}
