export function mapRarityToString(rarity: number) {
  switch (rarity) {
    case 0:
      return "Unknown";
    case 1:
      return "Common";
    case 2:
      return "Uncommon";
    case 3:
      return "Rare";
    case 4:
      return "Epic";
    case 5:
      return "Legendary";
    default:
      return "Unknown";
  }
}