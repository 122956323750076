export const gameModeToStr = (mode: number) => {
  switch (mode) {
    case 1:
      return "Capture The Flag";
    case 2:
      return "Team Deathmatch";
    case 3:
      return "Battle Royale";
    case 4:
      return "Free For All";
    default:
      return "Unknown";
  }
};

export const followingStatusToStr = (followingStatus: number) => {
  switch (followingStatus) {
    case 0:
      return "unknown";
    case 1:
      return "following";
    case 2:
      return "not_following";
    default:
      return "unknown";
  }
};

export const mapTagToName = (tag:string) => {
  switch (tag) {
    case "port":
      return "Port";
    case "griefstreet":
      return "Suburbia";
    case "frostbite":
      return "Victory Lake";
    case "loudhills":
      return "Loud Hills";
    case "canals":
      return "Canals";
    case "industry":
      return "Industry";
    case "piquant":
      return "Piquant";
    case "cyberpunk":
      return "Neo Metro";
    case "invasion":
      return "Invasion";
    case "factory":
      return "Factory";
    default:
      return tag;
  }
}
