import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";
import { useGetEventQuery } from "redux/rtkQuery/rtkApi";
import { SubmenuMobile } from "./SubmenuMobile";
import * as s from "./sumbenu.styled";

export function Submenu() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const pathname = useLocation().pathname;
  const intraClanLB = pathname.includes("/clanWars/intraClanLB");
  const tieredLB = pathname.includes("tieredLB");
  const transfer = pathname.includes("transferHistory");
  const worldDomination = pathname.includes("worldDomination");
  const { data: event } = useGetEventQuery();
  const wdEnabled = event?.extensions?.worldDomination;

  const navigate = useNavigate();
  return (
    <>
      {isMobile ? (
        <SubmenuMobile />
      ) : (
        <s.Wrap>
          <s.Menuitem
            onClick={() => navigate("/clanWars/intraClanLB")}
            active={pathname.includes("/clanWars/intraClanLB")}
          >
            Intra-Clan Leaderboard
          </s.Menuitem>
          <s.Menuitem
            onClick={() => navigate("/clanWars/tieredLB")}
            active={pathname.includes("/clanWars/tieredLB")}
          >
            Clan War Tiered Leaderboard
          </s.Menuitem>
          <s.Menuitem
            onClick={() => navigate("/clanWars/transferHistory")}
            active={pathname.includes("/clanWars/transferHistory")}
          >
            Treasury Transfer History
          </s.Menuitem>
          <s.Menuitem
            onClick={() => navigate("/clanWars/worldDomination")}
            active={pathname.includes("/clanWars/worldDomination")}
          >
            World Domination
          </s.Menuitem>
        </s.Wrap>
      )}
    </>
  );
}
