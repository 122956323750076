import React from "react";
import Box from "../Box/index";

type IProps = React.ComponentProps<typeof Box>;

const Column = React.memo(function (props: IProps): JSX.Element {

  return (
    //@ts-ignore
    <Box {...props} flexDirection="column" >
      {props.children}
    </Box>
  );
});

export default Column;
