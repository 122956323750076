import styled from "styled-components/macro";
import fullwStatBg from "assets/stat-bg.png";

export const StatsItemCW = styled.div`
  display: flex;
  align-items: center;
  background-image: url(${fullwStatBg});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  grid-column: 1 / span 2;
`;

export const StatsItemImg = styled.img`
  margin-left: 10px;
  width: 18px;
  height: 18px;
`;

export const StatsItemText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  margin-left: 8px;
`;
export const StatsItemCount = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  margin-left: auto;
  margin-right: 12px;
`;
