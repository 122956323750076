import styled from "styled-components/macro";

export const Wrapper = styled.div`
  padding: 23px 21px;
  max-width: 1200px;
  min-width: 1200px;
  margin: 30px auto;
  background: #000000cc;
  border-radius: 4px;
`;

