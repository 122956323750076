import { cgs_getConfigDescription } from "api";
import { IRewardItemToken } from "api/api.types";
import { useSwrImmutable } from "hooks/useSwr";
import tokenIcon from "../assets/token-mintable.png";
import * as s from "./TieredLeaderboard.styled";

export function RewardToken({ token }: { token: IRewardItemToken }) {
  const [configDescription] = useSwrImmutable(
    [token.configId, token.level],
    cgs_getConfigDescription
  );
  if (!configDescription) return null;
  return (
    <s.RewardItem rarity={configDescription.rarity}>
      <s.RewardItemAmount amount={token.amount} />
      <img src={configDescription.asset.iconUrl} />
      <img className="token" src={tokenIcon} />
    </s.RewardItem>
  );
}
