import { AnyAction, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import collectionPage from "redux/slices/collectionSlice";
import eventPointsPage from "redux/slices/eventPointsSlice";
import messageSystem from "redux/slices/messageSystemSlice";
import stakePage from "redux/slices/stakePageSlice";
import worldDomination from "redux/slices/worldDominationSlice";
import { rtkApi } from "../redux/rtkQuery/rtkApi";

const store = configureStore({
  reducer: {
    stakePage,
    collectionPage,
    eventPointsPage,
    messageSystem,
    worldDomination,
    [rtkApi.reducerPath]: rtkApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rtkApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use this selectore and dispatch pls
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
  >
export default store;
