import { IEvent, IItemsNormalized } from "api/api.types";
import * as s from "components/TieredLeaderboard/componetns/Desk.styled";
import { DeskTierItem } from "components/TieredLeaderboard/componetns/DeskTierItem";

export function DeskTable({
  clans,
  event,
  items,
}: {
  clans: IGetClanLeaderboard[];
  event: IEvent;
  items: IItemsNormalized;
}) {
  return (
    <div>
      <s.Header>
        <div>TIER</div>
        <div>CLAN</div>
        <div>MEMBERS</div>
        <div>POINTS</div>
        <div>REWARDS</div>
      </s.Header>
      {event.rewardsList.map((item) => (
        <DeskTierItem
          clans={clans}
          items={items}
          item={item}
          event={event}
          key={item.tier}
        />
      ))}
    </div>
  );
}
