import styled, { keyframes } from "styled-components/macro";
import skeletonProfileIcon from "assets/skeletonProfileIcon.svg";

const load = keyframes`
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
`;
export const SkeletonRect = styled.div<{
  w?: number | string;
  h?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
}>`
  position: relative;
  overflow: hidden;
  ${({ w }) => {
    if (typeof w === "number") return `width:${w}px`;
    if (typeof w === "string" && w.includes("%")) return `width:${w}`;
    return `width: 100%`;
  }};
  ${({ h }) => h && `height:${h}px`};
  ${({ mt }) => mt && `margin-top:${mt}px`};
  ${({ ml }) => ml && `margin-left:${ml}px`};
  ${({ mr }) => mr && `margin-right:${mr}px`};
  ${({ mb }) => mb && `margin-bottom:${mb}px`};
  ${({ pt }) => pt && `padding-top:${pt}px`};
  ${({ pl }) => pl && `padding-left:${pl}px`};
  ${({ pr }) => pr && `padding-right:${pr}px`};
  ${({ pb }) => pb && `padding-bottom:${pb}px`};
  background: #808080;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -100%;
    top: 0;
    height: 100%;
    width: 30%;
    background: linear-gradient(
      to right,
      transparent 0%,
      #a1a1a1 50%,
      transparent 100%
    );
    animation: ${load} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
`;
export const SkeletonCircle = styled(SkeletonRect)<{ r: number }>`
  width: ${({ r }) => `${r}px`};
  height: ${({ r }) => `${r}px`};
  border-radius: 50%;
`;

export const SkeletonHexagon = styled(SkeletonRect)`
  width: 100%;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
`;
export const SkeletonIcon = styled.img.attrs({
  src: skeletonProfileIcon,
  alt: "profile",
})``;
