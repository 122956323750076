import { SVGAttributes } from 'react';

const Arrow = (props: SVGAttributes<SVGElement>) => (
  <svg 
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="currentColor" 
    {...props}
     >
<path d="M3.53467 8.00187L9.72217 1.81437L7.95467 0.046875L-0.000331879 8.00187L7.95467 15.9569L9.72217 14.1894L3.53467 8.00187Z" 
    fill="white"/>
</svg>
);

export default Arrow;