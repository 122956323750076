import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { v4 as uuid } from "uuid";

type IMsgType = "error" | "warning";
type IMsg = {
  id: string;
  type: IMsgType;
  title: string;
  text: string;
};
type InitialState = { messages: IMsg[] };

const initialState: InitialState = {
  messages: [],
};

//Использование
// dispatch(addErrorMessage({text:'Error with wallet'}))


export const messageSystem = createSlice({
  name: "messageSystem",
  initialState,
  reducers: {
    addErrorMessage: (
      state,
      action: PayloadAction<{ title?: string; text: string }>
    ) => {
      state.messages.push({
        text: action.payload.text,
        title: action.payload.title || "Something went wrong",
        type: "error",
        id: uuid(),
      });
    },
    removeMessage: (state, action: PayloadAction<string>) => {
      state.messages = state.messages.filter((i) => i.id !== action.payload);
    },
    clearAll: (state) => {
      state.messages = [];
    },
  },
});

export const { addErrorMessage, removeMessage, clearAll } =
  messageSystem.actions;

//selectors
export const selectAllMessages = (state: RootState) =>
  state.messageSystem.messages;

export const selectErrorMessages = (state: RootState) =>
  state.messageSystem.messages.filter((i) => i.type === "error");

export default messageSystem.reducer;
