import Modal from "react-modal";
import { modalStyles } from 'components/Transfer/ClanMembers/ClanMembers.style';
import { ClanMembersTable } from 'components/Transfer/ClanMembers/ClanMembersTable';
import { IMembersModalTypes } from 'types/transferTypes';

export const ClanMembersModal = ({ open, setOpen, currentItem, setGdImage, setUpdate, setGdname }: IMembersModalTypes) => {
    return (
    <Modal 
        isOpen={open}
        style={modalStyles}
        shouldCloseOnEsc
        preventScroll
        onRequestClose={() => setOpen(false)}
    >
        <ClanMembersTable setMembersOpen={setOpen} currentItem={currentItem} setGdImage={setGdImage} setUpdate={setUpdate} setGdname={setGdname} />
    </Modal>
    );
}