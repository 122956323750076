import React from "react";

type IProps = {
  src: string;
  w?: React.CSSProperties["width"];
  h?: React.CSSProperties["height"];
  alignSelf?: React.CSSProperties["alignSelf"];
  justifySelf?: React.CSSProperties["justifySelf"];
  mt?: React.CSSProperties["marginTop"];
  mb?: React.CSSProperties["marginBottom"];
  ml?: React.CSSProperties["marginLeft"];
  mr?: React.CSSProperties["marginRight"];
  display?: React.CSSProperties["display"];
  block?: boolean;
  visible?: boolean;
  pointer?: boolean;
  onClick?: any;
  inline?: boolean;
  center?: boolean;
};
const Img = React.memo(function (props: IProps): JSX.Element {
  const css = {
    ...(props.display
      ? { display: props.display }
      : props.block
      ? { display: "block" }
      : { display: "flex" }),
    ...(props.alignSelf ? { alignSelf: props.alignSelf } : {}),
    ...(props.justifySelf ? { justifySelf: props.justifySelf } : {}),
    ...(props.mt ? { marginTop: props.mt } : {}),
    ...(props.mb ? { marginBottom: props.mb } : {}),
    ...(props.ml ? { marginLeft: props.ml } : {}),
    ...(props.mr ? { marginRight: props.mr } : {}),
    ...(props.w ? { width: props.w } : {}),
    ...(props.h ? { height: props.h } : {}),
    ...(props.visible === undefined
      ? {}
      : { visibility: props.visible ? "visible" : "hidden" }),
    ...(props.pointer ? { cursor: "pointer" } : {}),
    ...(props.inline ? { display: "inline" } : {}),
    ...(props.center ? { marginLeft: "auto", marginRight:"auto" } : {}),
  };
  return (
    //@ts-ignore
    <img src={props.src} onClick={props.onClick} style={css} />
  );
});

export default Img;
