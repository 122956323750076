import styled from "styled-components/macro";
import Polygon from "assets/polygon";
import CupIcon from "assets/cupIcon";
import CloseIcon from "assets/closed";
import ClanIcon from "assets/clanIcon";

export const modalStyles = {
  content: {
    background: "none",
    // "overflow-y": 'scroll',
    inset: 0,
    border: "none",
    color: "white",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

export const SPolygon = styled(Polygon)`
    cursor: pointer;
`;

export const SCupIcon = styled(CupIcon)<{ color?: string }>`
    margin-right: 8px;
    color: ${({ color}) => color || '#FFB60C'};
`;

export const SClanIcon = styled(ClanIcon)`
    margin-right: 8px;
`;

export const Title = styled.div`
    text-align: center;
    cursor: text;
    font-size: 35px;

    @media (max-width: 768px) {
        font-size: 20px;
        padding: 8px 8px;
        color: #fff;
        opacity: 0.8;
        margin-bottom: 11px;
    }
`;

export const TransferBtn = styled.button<{ isLeader?: boolean }>`
    padding: 20px 70px;
    background: ${({ isLeader }) => isLeader ?  'rgba(255, 182, 12, 0.4)' : 'rgba(138, 199, 0, 0.4)'};
    border: 1px solid ${({ isLeader }) => isLeader ?  '#FFB60C' : '#8AC700'};
    box-shadow: 0px 10px 22px ${({ isLeader }) => isLeader ?  'rgba(255, 182, 12, 0.4)' : 'rgba(138, 199, 0, 0.4)'};
    font-weight: 700;
    color: white;
    outline: none;
    cursor: pointer;
    
    & :hover {
        opacity: 0.8;
        background-color: ${({ isLeader }) => isLeader ?  '#ffb60d' : '#75A703'};
    }

    & :active {
        background: ${({ isLeader }) => isLeader ?  'rgba(255, 182, 12, 0.25)' : 'rgba(138, 199, 0, 0.25)'};
    }

    @media (max-width: 768px) {
        padding: 21px 30px;
        padding: 8px 8px;
        width: 100%;
        font-size: 16px;
  }
`;

export const BlockWrapper = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 0;
  }
`;

export const Container = styled.div<{ isMobile: boolean }>`
    padding: 0 30px 25px;
    width: 100%;
    color: white;
    background-color: ${({ isMobile }) => !isMobile && '#000000'} ;
    margin-top: 20px;
    & :first-child {
        margin-right: 10px;
    }

    @media (max-width: 1279px) {
        padding: 0 22px 25px 34px;
  }

    @media (max-width: 768px) {
        padding: 0;
        margin-top: 8px;
  }
`;

export const ListContainer = styled.div`
    padding: 10px;
    background-color: #151515;
    overflow: hidden;
    width: 100%;
    & :first-child {
        margin-right: 10px;
    }

    @media (max-width: 1279px) {
        & :first-child {
        margin-right: 0;
        padding: 10px 22px;
    }
}

    @media (max-width: 767px) {
        & :first-child {
        padding: 0;
    }
  }
`;

export const ScrollContainer = styled.div<{ isTablet: boolean }>`
    overflow-y: ${({ isTablet }) => !isTablet && 'scroll'};
    max-height: ${({ isTablet }) => !isTablet && '635px'};
    &::-webkit-scrollbar-thumb {
        background-color: rgb(200, 138, 3);
  }

    &::-webkit-scrollbar-track {
        background-color: rgb(61, 74, 87);
        border-radius: 41px;
    }

    &::backdrop {
        background-color: rgb(7, 17, 27);
    }

    &::-webkit-scrollbar {
        width: 5px;
    }
`;

export const ContainerHeader = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 2fr 3fr;
    background-color: #1F1F1F;
    margin-bottom: 10px;

    @media (max-width: 767px) {
        grid-template-columns: 4fr 4fr;
        font-size: 12px;
    }
`;

export const HeaderItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 18px 11px 27px;
    font-size: 12px;
    & :first-child {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    @media (max-width: 767px) {
        margin: 0;
        padding: 13px 10px 13px 13px;
    }
`;

export const HeaderIconBlock = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const ContainerBlock = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 2fr 3fr;
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 700;
    background-color: #1F1F1F;
    border: 1px solid #151515;

    &:hover {
        background: #252525;
        border: 1px solid rgba(255, 255, 255, 0.2);
        cursor: pointer;

        button {
            background-color: #8AC700;
            box-shadow: 0px 10px 22px rgba(138, 199, 0, 0.4);
        }
    }

    @media (max-width: 767px) {
        grid-template-columns: 4fr 4fr;
        font-size: 12px;
    }
`;

export const ContainerItem = styled.div`
    padding: 7px 8px 7px 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & :first-child {
        border-right: 2px solid #151515;
    }

    @media (max-width: 767px) {
        padding-left: 15px;
    }
`;

export const LeftConteinerItem = styled(ContainerItem)`
    justify-content: flex-start;
`;

export const SubtitleText = styled.div`
    margin: 16px 11px 11px;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    opacity: 0.8;

    @media (max-width: 768px) {
        text-align: center;
  }
`;

export const SelectBtn = styled.button<{ selected?: boolean }>`
    padding: 16px 30px;
    background-color: ${({ selected }) => selected ? '#8AC700' : 'rgba(255, 255, 255, 0.1)'};
    box-shadow: ${({ selected }) => selected && '0px 10px 22px rgba(138, 199, 0, 0.4)'};
    border: ${({ selected }) => selected && '1px solid #8AC700'};
    color: white;
    border: none;
    font-weight: 700;
    cursor: pointer;

    @media (max-width: 768px) {
        padding: 16px 17px;
  }
`;

export const BtnWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;

    @media (max-width: 768px) {
        justify-content: center;
        flex-wrap: wrap;
  }
`;

export const TitleContainer = styled.div`
    @media (max-width: 1279px) {
        display: flex;
        justify-content: space-between;
        margin: 20px 0 24px;
  }
`;

export const CloseBtn = styled(CloseIcon)`
    cursor: pointer;
    @media (max-width: 768px) {
        margin: 5px 20px;
  }
`;