import TopThree from "components/TopThree/TopThree";
import TableClans from "components/TableClans";
import { useSwrImmutable } from "hooks/useSwr";
import { fetchClans } from "api";

export default function ClanListPage() {
  // const [filtersFromUrl] = useSearchParams();
  // const [filtersRaw, loadingFilters] = useSwrImmutable("filters", fetchFilters);
  // const filtersInitial = transformFilters("clans", filterSchema, filtersRaw);
  // const mergedFilters = getMergedFilters(filtersFromUrl, filtersInitial);

  // const [data, loadingData] = useSwr(mergedFilters ? [mergedFilters, "clans"] : null, fetchClans);

  const [clans] = useSwrImmutable("clans", fetchClans);

  return (
    <div>
      <TopThree data={clans} />
      <TableClans clans={clans} />
    </div>
  );
}

export const filterSchema = [
  {
    name: "Region",
    showSearch: false,
  },
  {
    name: "Modes",
    showSearch: false,
  },
  {
    name: "Maps",
    showSearch: false,
  },
];
