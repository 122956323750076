import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import butterIcon from "assets/butter_icon.png";
import uranium from "assets/uranium_icon.png";
import uraniumIcon from "assets/uranium_icon.png";
import Box from "components/Box";
import { Gap } from "components/Gap";
import GreenButton from "components/GreenButton";
import Img from "components/Img";
import { QuantityCounterFlex } from "components/QuantityCounterFlex/QuantityCounterFlex";
import RedButton from "components/RedButton";
import { Spinner } from "components/Spinner";
import closeIcon from "components/Staking/StakeForEventPoints/assets/closeIcon.png";
import { HorizontalLine } from "components/TableClans/CloseModal.styled";
import Text from "components/Text/Text";
import uraniumBig from "pages/WorldDomination/assets/uraniumBigIcon.png";
import whiteCheck from "pages/WorldDomination/assets/whiteCheck.svg";
import { WDModalStyles } from "pages/WorldDomination/modalStyles";
import { Close } from "pages/WorldDomination/WDStatsPanel/Close";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  useAddToQueueMutation,
  useGetClanInfoQuery,
  useGetEventQuery,
  useGetEventTurnQuery,
  useGetUserRestrictionsQuery,
} from "redux/rtkQuery/rtkApi";
import { addErrorMessage } from "redux/slices/messageSystemSlice";
import { selectStreamItemById2 } from "redux/slices/worldDominationSlice";
import { useAppDispatch, useAppSelector } from "store";
import { numberWithCommas } from "utils/numberWithComas";
import { v4 as uuidv4 } from "uuid";
import arrowRight from "../assets/arrowRight.png";
import bpIcon from "../assets/build-points-icon.png";
import buildPointsBigIcon from "../assets/buildPointsBigIcon.png";
import butterBigIcon from "../assets/butterBigIcon.png";
import * as s from "./BuyUraniumModal.styled";

export function BuyUraniumModal({
  onClose,
  balanceButter,
  balanceBP,
}: {
  onClose: Function;
  balanceButter: number;
  balanceBP: number;
}) {
  const dispatch = useAppDispatch();

  const [requestId, setRequestId] = useState(() => uuidv4());
  const transactionResult = useAppSelector(selectStreamItemById2(requestId));

  const { data: event } = useGetEventQuery();
  const { data: turn, refetch: refetchTurn } = useGetEventTurnQuery(
    event?.id ?? skipToken
  );
  const { data: userRestrictions } = useGetUserRestrictionsQuery();
  const { data: info } = useGetClanInfoQuery();
  const [addToQueue] = useAddToQueueMutation();

  const [status, setStatus] = useState<IStatus>("idle");
  const [amount, setAmount] = useState(1);
  const [currency, setCurrency] = useState<
    "BUILD_POINT" | "BUTTER" | "SQUIRREL" | ""
  >("");

  // Wait transaction to finish
  useEffect(() => {
    if (transactionResult === undefined) return;
    if (transactionResult) {
      if (transactionResult?.error) {
        if (transactionResult?.error?.code === 3) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Insufficient funds",
            })
          );
        } else if (transactionResult?.error?.code === 6) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Construction limit exceeded",
            })
          );
        } else if (transactionResult?.error?.code === 5) {
          dispatch(
            addErrorMessage({
              title: "Something went wrong",
              text: "Can not allocate space for construction",
            })
          );
        } else {
          dispatch(
            addErrorMessage({ title: "Error", text: "Something went wrong" })
          );
        }
        onClose();
      } else {
        setStatus("done");
        setRequestId(uuidv4());
      }
    }
  }, [transactionResult]);

  if (!turn || !userRestrictions || !info) return null;

  const priceBP = turn.market.productsList
    .find((i) => i.productId === "BUY_URANIUM")!
    .pricesList.find((i) => i.currency === "BUILD_POINT")?.amount!;

  const priceButter = turn.market.productsList
    .find((i) => i.productId === "BUY_URANIUM")!
    .pricesList.find((i) => i.currency === "BUTTER")?.amount!;

  const price =
    (currency === "BUTTER" && priceButter) ||
    (currency === "BUILD_POINT" && priceBP) ||
    0;
  const balance =
    (currency === "BUTTER" && balanceButter) ||
    (currency === "BUILD_POINT" && balanceBP) ||
    0;
  const currencyName =
    (currency === "BUTTER" && "Butter") ||
    (currency === "BUILD_POINT" && "Build Points") ||
    "";
  if (
    priceBP === undefined ||
    priceButter === undefined ||
    balanceBP === undefined ||
    balanceButter === undefined
  )
    return null;
  let hintText = "";
  if (!["LEADER", "OFFICER"].includes(info.role))
    hintText = "You Must be a Clan Leader, or Officer in order to Build";

  const handleBuy = async () => {
    setStatus("pending");
    const resp = (await addToQueue({
      type: "uranium",
      amount,
      turnId: turn.turn.turnId,
      currency,
      requestId,
    })) as any;
    if (resp?.error) {
      onClose();
      dispatch(
        addErrorMessage({ title: "Error", text: "Something went wrong" })
      );
    }
    await refetchTurn();
  };

  const handleBuyForBP = () => {
    setCurrency("BUILD_POINT");
    handleBuy();
  };
  const handleBuyForButter = () => {
    setCurrency("BUTTER");
    handleBuy();
  };

  const stopBuildWithButter =
    amount * priceButter > balanceButter ||
    typeof amount !== "number" ||
    amount === 0 ||
    (amount * priceBP > userRestrictions.queueRestrictions.butter.limit &&
      !userRestrictions.queueRestrictions.butter.unlimited);

  const stopBuildWithBP =
    amount * priceBP > balanceBP ||
    typeof amount !== "number" ||
    amount === 0 ||
    amount > 100 ||
    (amount * priceBP > userRestrictions.queueRestrictions.buildpoints.limit &&
      !userRestrictions.queueRestrictions.buildpoints.unlimited);

  const loading = status === "pending";
  return (
    <Modal
      isOpen
      style={WDModalStyles}
      shouldCloseOnEsc
      preventScroll
      onRequestClose={() => onClose()}
    >
      {currency === "" && status === "idle" && (
        <s.Content>
          <s.Close onClick={() => onClose()}>
            <img src={closeIcon} />
          </s.Close>
          <Gap h={50} />
          <s.Info>
            <s.Img src={uraniumBig} />
            <s.InfoContent>
              <s.Title>Buy Uranium</s.Title>
              <Gap h={17} />
              <s.DescriptionTitle>
                Uranium is a special material with the following properties:
              </s.DescriptionTitle>
              <s.Description>
                <li>It’s required in order to build Nukes;</li>
                <li>
                  Its prices fluctuate up and down depending on market demand;
                </li>
                <li>
                  Because it’s difficult to store you lose any unused Uranium at
                  the end of each Clan War event.
                </li>
              </s.Description>
              <Gap h={20} />
              <s.InfoGrid>
                <Text grey>Cost:</Text>
                <Text>
                  <Box alignItems="center">
                    <img src={uraniumIcon} /> = <img src={butterIcon} />
                    {numberWithCommas(priceButter)} Butter
                  </Box>
                  <Box alignItems="center">
                    <Gap h={6} />
                    <img src={uraniumIcon} /> = <img src={bpIcon} />{" "}
                    {numberWithCommas(priceBP)} Build Points
                  </Box>
                </Text>
              </s.InfoGrid>
            </s.InfoContent>
          </s.Info>
          <HorizontalLine />
          <Box mt={20} mb={23} justifyContent={"center"} w="100%">
            <QuantityCounterFlex
              amount={amount}
              setAmount={setAmount}
              max={100}
            />
          </Box>
          <s.Buttons>
            <GreenButton
              h={75}
              disabled={stopBuildWithBP}
              loading={loading}
              onClick={() => setCurrency("BUILD_POINT")}
            >
              <Box column gap={4}>
                <Text w={350} s={14} white>
                  Buy for Build Points
                </Text>
                {amount > 0 && (
                  <Box gap={5} alignItems="center">
                    <img src={bpIcon} width={32} />
                    {numberWithCommas(amount * priceBP)}
                  </Box>
                )}
              </Box>
            </GreenButton>
            <GreenButton
              h={75}
              disabled={stopBuildWithButter}
              loading={loading}
              onClick={() => setCurrency("BUTTER")}
            >
              <Box column gap={4}>
                <Text w={350} s={14} white>
                  Buy for butter
                </Text>
                {amount > 0 && (
                  <Box gap={5} alignItems="center">
                    <img src={butterIcon} width={32} />
                    {amount > 0 && numberWithCommas(amount * priceButter)}
                  </Box>
                )}
              </Box>
            </GreenButton>
          </s.Buttons>

          <Box position="relative" justifyContent={"space-around"}>
            {hintText ? (
              <Text red w={350} capitalize>
                {hintText}
              </Text>
            ) : (
              <>
                <s.Hint1>
                  {amount * priceBP >
                    userRestrictions.queueRestrictions.buildpoints.limit &&
                    !userRestrictions.queueRestrictions.buildpoints
                      .unlimited && (
                      <Text red w={350} capitalize>
                        Cost is over your build limit
                      </Text>
                    )}
                </s.Hint1>

                <s.Hint2>
                  {amount * priceBP >
                    userRestrictions.queueRestrictions.butter.limit &&
                    userRestrictions.queueRestrictions.butter.unlimited && (
                      <Text red w={350} capitalize>
                        Cost is over your build limit
                      </Text>
                    )}
                </s.Hint2>
              </>
            )}
          </Box>
        </s.Content>
      )}
      {currency === "BUTTER" && status === "idle" && (
        <s.Content>
          <s.Close onClick={() => setCurrency("")}>
            <img src={closeIcon} />
          </s.Close>
          <Gap h={100} />
          <Box column alignItems="center">
            <Box alignItems="center">
              <img src={butterBigIcon} height={133} />
              <Gap w={12} />
              <img src={arrowRight} />
              <Gap w={22} />
              <img src={uraniumBig} width={100} />
            </Box>
            <Gap h={34} />
            <Text s={22}>Are you sure you want to buy</Text>
            <Text s={22}>
              <Box gap={5} alignItems="center">
                <img src={uraniumIcon} width={20} height={20} /> {amount}{" "}
                Uranium For <img src={butterIcon} width={20} height={20} />{" "}
                {numberWithCommas(amount * priceButter)} Butter?
              </Box>
            </Text>
            <Gap h={10} />
            <Text w={350} grey>
              Clan Butter Balance: {numberWithCommas(balanceButter)}
            </Text>
          </Box>
          <Gap h={76} />
          <s.Buttons>
            <RedButton h={60} onClick={() => setCurrency("")}>
              Cancel
            </RedButton>
            <GreenButton h={60} onClick={() => handleBuyForButter()}>
              Confirm
            </GreenButton>
          </s.Buttons>
        </s.Content>
      )}
      {currency === "BUILD_POINT" && status === "idle" && (
        <s.Content>
          <s.Close onClick={() => onClose()}>
            <img src={closeIcon} />
          </s.Close>
          <Gap h={122} />
          <Box column alignItems="center">
            <Box alignItems="center">
              <img src={buildPointsBigIcon} height={109} />
              <Gap w={12} />
              <img src={arrowRight} />
              <Gap w={22} />
              <img src={uraniumBig} width={100} />
            </Box>
            <Gap h={34} />
            <Text s={22}>Are you sure you want to buy</Text>
            <Text s={22}>
              <Box gap={5} alignItems="center">
                <img src={uraniumIcon} width={20} height={20} /> {amount}{" "}
                Uranium For <img src={bpIcon} width={20} height={20} />{" "}
                {numberWithCommas(amount * priceBP)} Build Points?
              </Box>
            </Text>
            <Gap h={10} />
            <Text w={350} grey>
              Clan Build Points Balance: {numberWithCommas(balanceBP)}
            </Text>
          </Box>
          <Gap h={76} />
          <s.Buttons>
            <RedButton h={60} onClick={() => setCurrency("")}>
              Cancel
            </RedButton>
            <GreenButton h={60} onClick={() => handleBuyForBP()}>
              Confirm
            </GreenButton>
          </s.Buttons>
        </s.Content>
      )}
      {status === "pending" && (
        <s.Content>
          <Spinner />
        </s.Content>
      )}
      {status === "done" && (
        <s.Content>
          <Close onClose={onClose} />
          <Gap h={100} />
          <Img src={uraniumBig} w={160} center />
          <Gap h={12} />
          <Text s={22} center>
            You bought {amount} uranium{" "}
            <Img src={uranium} w={20} inline mb={-2} /> <br />
            for {numberWithCommas(amount * price)} {currencyName}!
          </Text>
          <Gap h={10} />
          <Text grey center w={350}>
            Clan {currencyName} Balance: {numberWithCommas(balance)}
          </Text>
          <Gap h={38} />
          <GreenButton onClick={() => onClose()} h={60} w={276} center>
            <Img src={whiteCheck} /> Done!
          </GreenButton>
        </s.Content>
      )}
    </Modal>
  );
}
