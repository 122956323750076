import styled from "styled-components/macro";
import { IconRing, ClanIcon } from "components/TopThree/styled";
import closeModal from "assets/closeModal.svg";

export const modalStyles = {
  content: {
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: 0,
    borderRadius: 0,
    background: "rgba(28, 28, 28, 0.99)",
    // width: `526px`,
    // height: `268px`,
    padding: `40px`,
  },
  overlay: {
    backgroundColor: "rgba(255,255,255,0)",
  },
};
export const ClanInfo = styled.div`
  & > div:nth-child(1) {
    font-weight: 350;
    font-size: 24px;
    color: white;
    margin-bottom: 2px;
  }

  & > div:nth-child(2) {
    font-size: 16px;
    font-weight: 350;
    margin-bottom: 13px;
    color: #848484;

    & > span:nth-child(1) {
      color: white;
    }
  }

  & > div:nth-child(3) {
  }

  & div.error {
    margin-top: 20px;
    font-size: 16px;
    color: white;
  }
`;

export const ModalWrapper = styled.div`
  position: relative;

  @media (min-width: 600px) {
    //padding:40px;
    width: 446px;
  }
`;
export const HorizontalLine = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  width: 100%;
`;
export const IconRingModal = styled(IconRing)<{
  rank: number;
}>`
  width: 112px;
  height: 112px;
`;
export const ClanIconModal = styled(ClanIcon)<{
  name: string;
  rank: number;
}>`
  &::before {
    font-size: 20px;
  }
`;
export const Info = styled.div`
  & > div:nth-child(1) {
    font-weight: 350;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 4px;
  }

  & > div:nth-child(2) {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #ffb60c;
  }
`;
export const CloseModalBtn = styled.img.attrs({
  src: closeModal,
})`
  position: absolute;
  right: -30px;
  top: -30px;
  cursor: pointer;
`;
