import { useEffect, useState } from "react";
import * as s from "components/Staking/StakeForEventPoints/InfoBloc/Timer.styled";
import timerBG from "components/Staking/StakeForEventPoints/assets/timerBG.png";
import timerIcon from "assets/timerIcon.svg";

export function Timer() {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(60 - new Date().getMinutes());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <s.TimerWrapper>
      <div className="label">Next Points Auto-Collect:</div>
      <s.TimerField>
        <img src={timerBG} alt="timer" />
        <div className="yellow">
          <div className="timerIcon">
            <img src={timerIcon} alt="" />
          </div>
          <div className="time">{time}m</div>
        </div>
      </s.TimerField>
    </s.TimerWrapper>
  );
}
