import styled from "styled-components/macro";

export const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 40;
  cursor: pointer;
  & > img {
    width: 16px;
    height: 16px;
  }
`;

export const Content = styled.div`
  width: 680px;
  height: 520px;
  padding: 0 43px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Img = styled.img`
  min-width: 160px;
  height: 160px;
  border-radius: 10px;
`;

export const Info = styled.div`
  display: flex;
  gap: 17px;
  margin-bottom: 16px;
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-transform: capitalize;
  color: #ffffff;
`;

export const DescriptionTitle = styled.div`
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #ffffff;
`;

export const Description = styled.ul`
  margin: 10px 0 0;
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #838383;
  padding-inline-start: 16px;
`;

export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 8px 13px;
  & img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 11px;
`;

export const Hint1 = styled.div`
  position: relative;
`;

export const Hint2 = styled.div`
  position: relative;
`;

