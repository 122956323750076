import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { Provider } from "react-redux";
import store from "store";
import { SWRConfig } from "swr";
import App from "./App";
import "./index.css";

const isDev = process.env.NODE_ENV !== "production";

!isDev && Sentry.init({
  dsn: isDev
    ? "https://96dacaf825a348b1a25ceaa7e967458c@o967800.ingest.sentry.io/6751730"
    : "https://5e5f4ab5a2fd4cfa8c81161398cc5ff5@o967800.ingest.sentry.io/6751730",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

Modal.setAppElement("#modal");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SWRConfig
        value={{
          // revalidateIfStale: true,
          revalidateOnReconnect: true,
          revalidateOnFocus: true,
          errorRetryCount: 0,
          // refreshInterval: 60_000,
        }}
      >
        <App />
      </SWRConfig>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
