import * as s from "./styled";
import { useNavigate, useParams } from "react-router";
import { ClanModal } from "components/TableClans/ClanModal";

export default function TableContentClans({ clanList }: { clanList: IClan[] }) {
  const navigate = useNavigate();
  const { clanId } = useParams();
  const selectedClan = clanId
    ? clanList.find((item) => item.id === clanId)
    : undefined;

  return (
    <>
      <s.BodyWrapper>
        <s.Grid>
          <s.HeaderRow>
            <s.HeaderCell>RANK</s.HeaderCell>
            <s.HeaderCell>CLAN NAME</s.HeaderCell>
            <s.HeaderCell>TAG</s.HeaderCell>
            <s.HeaderCell>MEMBERS</s.HeaderCell>
            <s.HeaderCell>SCORE</s.HeaderCell>
            <s.HeaderCell>LEADER</s.HeaderCell>
          </s.HeaderRow>
          {clanList.map((clan, i) => (
            <s.GridRow $i={i} key={clan.id}>
              <s.GridCell>{clan.rank}</s.GridCell>
              <s.GridCell pointer onClick={() => navigate(`/clans/${clan.id}`)}>
                <s.GridImg tag={clan.slug} />
                {clan.name}
              </s.GridCell>
              <s.GridCell>{clan.slug}</s.GridCell>
              <s.GridCell>{clan.members}</s.GridCell>
              <s.GridCell>{clan.score}</s.GridCell>
              <s.GridCell
                pointer
                onClick={() => navigate(`/profile/${clan.owner.id}`)}
              >
                {clan.owner.username}
                <s.ClanTag>&nbsp;#{clan.owner.suffix}</s.ClanTag>
              </s.GridCell>
            </s.GridRow>
          ))}
        </s.Grid>
      </s.BodyWrapper>
      <s.BodyImg />

      <ClanModal clan={selectedClan} />
    </>
  );
}
