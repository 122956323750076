import * as s from "components/Staking/StakeForEventPoints/StakePage/StakeItemPage.styled";
import { useSelector } from "react-redux";
import { selectAllItems } from "redux/slices/collectionSlice";
import { actions, selectFilters } from "redux/slices/stakePageSlice";
import { useAppDispatch, useAppSelector } from "store";
import { IGeneratedItem } from "types/stakingTypes";
import { ItemView } from "../Grid/ItemView";

export function ItemsList({ items }: { items: IGeneratedItem[] }) {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(selectFilters);
  const { totalItems } = useSelector(selectAllItems);

  let filteredItems = items;

  // фильтр по рарности
  if (!filters.rarity.includes("all"))
    filteredItems = filteredItems.filter((i) => {
      // @ts-ignore
      return filters.rarity.includes(i.rarity);
    });
  if (filters.type === "outfits")
    filteredItems = filteredItems.filter((i) => {
      // @ts-ignore
      return [0, 1, 2, 4].includes(i.type);
    });
  else {
    filteredItems = filteredItems.filter((i) => {
      // @ts-ignore
      return [5, 6, 7].includes(i.type);
    });
  }

  if (!totalItems.length) return null;
  // Тут логика фильтрации
  return (
    <s.ItemListScrollable>
      <s.ItemListContent>
        {filteredItems.map((item: any) => {
          const currentItem = totalItems.filter(
            (mainItem) => mainItem.id === item.id
          )[0];
          return (
            <s.ItemZoom key={item.id}>
              <ItemView
                w={155}
                h={155}
                pointer
                currentItem={currentItem}
                onClick={() => {
                  console.log("item", item);
                  dispatch(actions.setSelectedItemId(item.id));
                }}
              />
            </s.ItemZoom>
          );
        })}
      </s.ItemListContent>
    </s.ItemListScrollable>
  );
}
