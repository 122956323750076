import Modal from "react-modal";
import { Spinner } from "components/Spinner";

const modalStyles = {
  content: {
    width: 375,
    height: 200,
    display: "flex",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    overflow: "hidden",
    transform: "translate(-50%, -50%)",
    padding: 0,
    borderRadius: 0,
    border: "1px solid #1A1A1A",
    background: "black",
  },
  overlay: {
    zIndex: 30,
    backgroundColor: "rgba(255,255,255,0.08)",
  },
};

export function SimpleModalSpinner(props:{show:boolean}) {
 
  return (
    <Modal
      isOpen={props.show}
      style={modalStyles}
      preventScroll
    >
     <Spinner/>
    </Modal>
  );
}
