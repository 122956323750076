import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { wallets_getBoundWallet } from "api";
import Box from "components/Box";
import { Grid } from "components/Staking/StakeForEventPoints/Grid/Grid";
import { InfoBlock } from "components/Staking/StakeForEventPoints/InfoBloc/InfoBlock";
import * as s from "components/Staking/StakeForEventPoints/InfoBloc/StakeForEventPoints.styled";
import Text from "components/Text/Text";
import { useSwrImmutable } from "hooks/useSwr";
import React, { useEffect } from "react";
import { fetchAllItems, selectAllItems } from "redux/slices/collectionSlice";
import {
  fetchClanMembers,
  fetchClanPointsInfo,
  fetchEnrOrbs,
  fetchEvent,
  fetchSlots,
  selectSlots,
} from "redux/slices/eventPointsSlice";
import { useAppDispatch, useAppSelector } from "store";

export default function StakeForEventPointsPage() {
  const dispatch = useAppDispatch();
  const { publicKey } = useWallet();
  const { totalItems } = useAppSelector(selectAllItems);
  const [userBoundedWallet, loading] = useSwrImmutable(
    "wallet",
    wallets_getBoundWallet
  );
  const event = useAppSelector((state) => state.eventPointsPage.event);

  useEffect(() => {
    dispatch(fetchEvent());
    dispatch(fetchClanMembers());
    dispatch(fetchEnrOrbs());

    if (!totalItems.length) dispatch(fetchAllItems());
  }, []);

  useEffect(() => {
    if (event.id) {
      dispatch(fetchSlots());
      dispatch(fetchClanPointsInfo());
    }
  }, [event.id]);

  const slots = useAppSelector(selectSlots);

  useEffect(() => {
    const isNeedPoling = slots.reduce((acc, i) => {
      return (
        i.slotsList.reduce((acc, j) => {
          return j.status === 3 || j.status === 1 || acc;
        }, false) || acc
      );
    }, false);
    if (!isNeedPoling) return;
    const f2 = () => dispatch(fetchSlots());

    const interval = window.setInterval(() => {
      f2();
    }, 5000);
    return () => {
      if (interval) window.clearInterval(interval);
    };
  }, [slots]);

  return (
    <s.Wrapper>
      <InfoBlock />
      {!loading && !userBoundedWallet?.pubKey && (
        // ситуация 1 пользователь не привязал кошелек в игре
        <Block>
          <Text s={18}>
            You don't have a wallet connected to your game account. <br />{" "}
            Please connect Phantom wallet to your game account{" "}
            <s.Link href={"https://miniroyale.io/"} target={"_blank"}>
              here
            </s.Link>
          </Text>
        </Block>
      )}
      {!loading && userBoundedWallet?.pubKey && !publicKey && (
        // ситуация 2, привязал кошелек в игре, но не выбрал в расширении
        <Block>
          <Text s={18}>
            Please select a wallet connected to your game account
          </Text>
          <WalletMultiButton />
        </Block>
      )}
      {!loading &&
        publicKey &&
        userBoundedWallet?.pubKey &&
        (publicKey?.toString() !== userBoundedWallet?.pubKey ? (
          // ситуация 3, выбрал, но не тот
          <Block>
            <Text s={18}>
              Wallet <br /> {userBoundedWallet?.pubKey} is bounded to your game
              account, but wallet <br />
              {publicKey?.toString()} is selected in phantom browser extension.
              Please select correct wallet in browser extension and reload page
            </Text>
            <WalletMultiButton />
          </Block>
        ) : (
          <Grid />
        ))}
    </s.Wrapper>
  );
}

function Block({ children }: { children: React.ReactNode }) {
  return (
    <Box
      mt={20}
      h={200}
      w="100%"
      flexDirection="column"
      alignItems="center"
      gap={20}
      justifyContent="center"
    >
      {children}
    </Box>
  );
}
