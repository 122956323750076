import styled, { css } from "styled-components/macro";
import filterSearchBg from "assets/filterSearchBg.png";
import filterSearchIcon from "assets/filterSearch.svg";
import filterArrow from "assets/filterArrow.svg";
import checkboxActive from "assets/checkboxActive.svg";
import checkbox from "assets/checkbox.svg";

export const FilterWrapper = styled.div`
  display: flex;
  justify-self: flex-end;
  align-items: center;
  font-size: 14px;
  line-height: 17px;

  margin-right: 30px;
  @media (max-width: 768px) {
    align-self: stretch;
    margin-right: 20px;
  }
  span {
    color: #ffffff;
    opacity: 0.4;
    margin-right: 10px;
  }
`;

export const FilterItemWrapper = styled.div`
  margin-right: 10px;
  position: relative;
  display: flex;
`;

export const FilterInner = styled.div<{ open: boolean }>`
  transform: skew(-10deg);
  display: flex;
  justify-content: space-between;
  min-width: 100px;
  max-width: 150px;
  @media (min-width: 1280px) {
    min-width: 150px;;
    max-width: 150px;
  }
  margin-right: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 0.05);
  border-width: 1.2px;
  border-style: solid;
  border-color: initial;
  border-image: ${(props) =>
    props.open
      ? css`linear-gradient(88.27deg, rgba(255, 255, 255, 0.27) 4.49%, rgba(255, 255, 255, 0.72) 62.01%)
                      1 / 1 / 0 stretch;`
      : css`linear-gradient(88.27deg, rgba(255, 255, 255, 0.12) 4.49%, rgba(255, 255, 255, 0.32) 62.01%)
                      1 / 1 / 0 stretch;`};


  -webkit-box-align: center;
  padding-left: 16px;
  padding-right: 10px;
  position: relative;
  z-index: 100;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  //flex: 0 0 150px;
  align-items: center;
`;
export const FilterSearch = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? "flex" : "none")};
  align-items: center;
  height: 50px;
  padding: 10px 10px 20px 10px;
  margin: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  background: url(${filterSearchBg}) no-repeat 0px 0px;
  background-size: 100% 40px;
`;

export const FilterSearchIcon = styled.img.attrs({
  src: filterSearchIcon,
})`
  margin-left: 20px;
  margin-right: 12px;
`;

export const FilterSearchInput = styled.input.attrs({ type: "text" })`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  color: #838383;
  height: 30px;
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
`;

export const FilterTitle = styled.div<{ open: boolean }>`
  transform: skewX(10deg);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgb(255, 255, 255);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${({ open }) => (open ? 1 : 0.75)};
`;
export const FilterArrow = styled.img.attrs({
  src: filterArrow,
})<{ open: boolean }>`
  transform: skew(10deg);
  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg) skew(10deg);
    `}
`;

export const FilterContent = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? "block" : "none")};
  position: absolute;
  z-index: 100;
  top: 45px;
  right: 5px;
  width: 280px;
  background: #242424;
`;

export const FilterItems = styled.div`
  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    background-color: rgb(255, 138, 3);
    border-radius: 41px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(61, 74, 87);
    border-radius: 41px;
  }

  &::backdrop {
    background-color: rgb(7, 17, 27);
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
`;

export const FilterContentHdr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  height: 53px;
  color: #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
`;

export const FilterContentItem = styled.div<{ selected: boolean }>`
  height: 44px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  span {
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
  }
  background: ${({ selected }) =>
    selected
      ? "linear-gradient(90deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0) 98.46%), radial-gradient(86.15% 1839.23% at 0% 59.38%, rgba(255, 128, 12, 0.05) 0%, rgba(255, 128, 12, 0) 100%)"
      : "none"};
`;
export const FilterContentItemCheckbox = styled.div<{ selected: boolean }>`
  width: 20px;
  height: 20px;
  background: url(${({ selected }) => (selected ? checkboxActive : checkbox)});
  margin-right: 10px;
`;
