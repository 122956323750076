import { grpcDefault } from "api/index";
import { streamAdd } from "redux/slices/worldDominationSlice";
import { greenLog, redLog, yellowLog } from "utils/advancedLog";

let bundle = require("api/server_bundle");
// @ts-ignore
if (window.service) {
  // @ts-ignore
  bundle = window.service;
}

export type IWDStreamItem = {
  requestId: string
  error?: {
    code: number;
    message: string;
  };
  addToRoundQueue?: string;
  removeFromRoundQueue?: string;
};

let streamClient: any;
let grpcStream: any;
let dispatch: any;
const attachStreamHandlers = (s: any) => {
  s.on("error", () => {
    redLog("stream error. reconnecting.");
    setTimeout(resubscribeStream, 10000);
  });
  s.on("data", (response: any) => {
    const eventType = response.getType();

    if (eventType === "NOTIFICATION_TYPE_WD_REQUEST_FINISHED") {
      let payload = bundle.clanevents.NotificationWDRequest.deserializeBinary(response.getPayload());
      dispatch(streamAdd(payload.toObject()))
      if (payload.toObject()?.error?.message) {
        yellowLog(payload.toObject(), payload.toObject().error.message);
      } else {
        greenLog(payload.toObject().requestId, "REQUEST SECCEED");
        greenLog(payload.toObject(), "REQUEST BODY");
      }
    } else if (eventType === "ClientConnectionUnsubscribed") {
      redLog(eventType);
      s.cancel();
      streamClient = null;
    } else if (eventType === "PlayerStreamOpened") {
      greenLog(eventType);
    } else {
      yellowLog(eventType);
    }
  });
  return s;
};
export const registerClient = () => {
  streamClient = new bundle.stream.StreamClient(...grpcDefault);
  createStream();
  attachStreamHandlers(grpcStream);
};
export const resubscribeStream = () => {
  try {
    if (grpcStream !== null) {
      grpcStream.cancel();
    }
    registerClient();
  } catch (reSubscribeErr) {
    redLog(reSubscribeErr, "reSubscribeErr");
    setTimeout(resubscribeStream, 10000);
  }
};
export const createStream = () => {
  const request = new bundle.pb.Empty();
  grpcStream = streamClient.subscribe(request, {
    deadline: 0,
  });
};

export function stream(_dispatch: any): void {
  dispatch = _dispatch;
  registerClient();
}
