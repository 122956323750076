import tokenIcon from "../assets/token-mintable.png";
import * as s from "./TieredLeaderboard.styled";

export function TokenImage({
  info,
  setItemImage,
}: {
  info: any;
  setItemImage: any;
}) {

  return (
    <s.RewardItem
      rarity={
        info?.butterBalance ||
        info?.enrichedOrb ||
        typeof info === "number" ||
        info?.butter
          ? 6
          : setItemImage(info)?.rarity
      }
    >
      <img
        src={setItemImage(info)?.src || setItemImage(info)}
        alt={"icon"}
      />
      {(info?.mintableToken?.level || info?.token?.level) && (
        <img className="token" src={tokenIcon} alt={"configId"} />
      )}
    </s.RewardItem>
  );
}
