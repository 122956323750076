import styled from "styled-components/macro";
import joinDiscord from "assets/joinDiscord.png";
import bg from "assets/stackingLogin.png";

export const stakingMobileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  text-transform: uppercase;
  color: #ffb60c;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  text-align: center;
  transform: translateY(-10%);

  & > div {
    margin: 0 40px;
  }
`;

export const discordBtn = styled.img.attrs({
  src: joinDiscord,
})`
  width: 178px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
`;

export const StakingPage = styled.div`
  max-width: 1110px;
  margin: 0 auto 40px;
  padding-top: 40px;
`;

export const StackingLogin = styled.div`
  background: url("${bg}");
  background-size: cover;
  height: 563px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;

  & > div {
    margin-bottom: 35px;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #ffb60c;

    & > span {
      color: #ffffff;
    }
  }
`;

export const StakeLoginItem = styled.div<{ bgImg: string }>`
  cursor: pointer;
  position: relative;
  width: 385px;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: url("${({ bgImg }) => bgImg}");
  background-size: cover;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 4px;

  & .title {
    margin-top: 217px;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #ffb60c;
    line-height: 37px;
  }

  & .small {
    font-weight: 350;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
  }
`;

export const StakeForCheddarTitle = styled.div`
  margin-top: 217px;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 37px;
`;

export const StakeForCheddarCommingSoon = styled.div`
  background: #fc3d3d;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  top: 125px;
  position: absolute;
  padding: 5px 10px;

  &:after {
    content: "COMING SOON";
  }
`;

export const MyCollection = styled.div`
  height: 563px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 30px 34px;
  & > .title {
    margin-bottom: 21px;
    font-weight: 500;
    font-size: 35px;
    line-height: 42px;
    text-transform: capitalize;
    color: #ffffff;
  }
  & > .grid {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    & > div {
      height: 138px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #000;
      &.green {
        color: #59b015;
      }
      &.blue {
        color: #2e9bff;
      }
      &.purple {
        color: #984ac6;
      }
      &.orange {
        color: #ff7a00;
      }

      & > div.value {
        font-weight: 500;
        font-size: 50px;
        line-height: 60px;
      }
      & > div.name {
        font-weight: 350;
        font-size: 16px;
        line-height: 19px;
      }
    }
    & > div.fullWidth {
      grid-column: 1 / span 3;
    }
  }
`;
