import Profile from "components/Profile";
import ProfileSkeleton from "components/Skeleton/ProfileSkeleton";
import { useSwrImmutable } from "hooks/useSwr";
import { useParams } from "react-router";
import {
  getAccountDetails,
  idp_GetAccount,
  leaderboards_fetchRankAndHistory,
} from "api";

export default function ProfilePage() {
  const { userId } = useParams();

  const [auth, authLoading] = useSwrImmutable("auth", idp_GetAccount);

  const id = userId || auth?.userId?.value;

  // const [friends] = useSwrImmutable(
  //   id ? [id, "followNewCount"] : null,
  //   fetchFriends
  // );

  const [player, playerLoading] = useSwrImmutable(
    id ? [id, "profile"] : null,
    getAccountDetails
  );

  const [user, userLoading] = useSwrImmutable(
    auth?.userId?.value ? [auth?.userId?.value, "user"] : null,
    getAccountDetails
  );

  const [rankAndMatchHistory, mathHistoryLoading] = useSwrImmutable(
    id ? [id, "rankAndHistory"] : null,
    leaderboards_fetchRankAndHistory
  );

  console.log(`auth: `, auth);
  if (playerLoading || userLoading || mathHistoryLoading || authLoading)
    return <ProfileSkeleton />;

  if (!player) return null;
  return (
    <Profile
      player={player}
      user={user}
      matchHistory={rankAndMatchHistory?.matchHistory}
    />
  );
}
