import { ItemBlock } from "components/Staking/StakeForEventPoints/Grid/ItemBlock";
import { ItemBlockEmpty } from "components/Staking/StakeForEventPoints/Grid/ItemBlockEmpty";
import { PointsBlockNotInClan } from "components/Staking/StakeForEventPoints/Grid/PointsBlockNotInClan";
import { UnlockBlock } from "components/Staking/StakeForEventPoints/Grid/UnlockBlock";
import { UnlockModal } from "components/Staking/StakeForEventPoints/Grid/UnlockModal/UnlockModal";
import { useMyProfile } from "hooks/useMyProfile";
import React, { useEffect, useMemo, useState } from "react";
import { selectAllItems } from "redux/slices/collectionSlice";
import {
  fetchSlots,
  selectEventStatus,
  selectSlotsInfo,
} from "redux/slices/eventPointsSlice";
import { useAppDispatch, useAppSelector } from "store";
import { ISetListItem, ISlotsListItem } from "types/stakingTypes";
import { numberWithCommas } from "utils/numberWithComas";
import * as s from "./Grid.styled";
import { PointsRateBlock, Set } from "./Grid.styled";
import { UnstakeModal } from "./UstakeModal/UnstakeModal";

type IContextState = {
  showUnlockModal: boolean;
  selectedUnlockSet?: number;
  selectedSlotUnlockStatus?: "locked" | "pending" | "unlocked";

  showUnstakeModal: boolean;
  selectedUnstakeSet?: string;
  selectedUnstakeSlot?: number;
  selectedUnstakeId?: string;
  selectedUnstakeItem?: any;
  selectedUnstakeStatus?: "idle" | "pending";
};

const initialContext = {
  showUnlockModal: false,
  showUnstakeModal: false,
};

type IFieldName = keyof IContextState;
type IContext = [
  IContextState,
  (fieldName: keyof IContextState, value: any) => void
];

export const GridCtx = React.createContext<IContext>([initialContext, () => 0]);

export function Grid() {
  const dispatch = useAppDispatch()
  
  const {
    totalItems,
    // , collectionInfo
  } = useAppSelector(selectAllItems);
  const slotsInfo = useAppSelector(selectSlotsInfo);
  
  const [context, setContext] = useState<IContextState>(initialContext);
  const [unlockModal, setUnlockModal] = useState(false);
  const [set, setSlotSet] = useState({} as any);
  const [curTimeInSeconds, setCurTimeInSeconds] = useState(() =>
    Math.floor(new Date().getTime() / 1000)
  );

  const setField = (fldName: IFieldName, value: any) => {
    setContext((state) => ({ ...state, [fldName]: value }));
  };




  const getToltipData = (item: ISlotsListItem) => {
    let nft, status;

    if (item?.rateType === 1) {
      status = "Premium Character NFT";
      nft = slotsInfo?.premiumCharacterRate;
    } else if (item?.rateType === 2) {
      status = "Loot Character NFT";
      nft = slotsInfo?.lootCharacterRate;
    } else if (item?.rateType === 3) {
      status = "Loot Weapon NFT";
      nft = slotsInfo?.lootWeaponRate;
    } else {
      status = "Special Character NFT";
      nft = slotsInfo?.specialCharacterRate;
    }

    const totalPointsRate = item?.bonusRate + item?.baseRate;
    const itemBonuses = item?.bonusesList?.map((bonus: string) =>
      slotsInfo.bonusesList.find((item) => item.id === bonus)
    ) as any;

    return {
      status,
      nft,
      totalPointsRate,
      itemBonuses,
    };
  };

  // меняем настоящее время для корректного отображнения оставшегося времени
  // Если для какогото из рядов время истекло - fetchSlots()
  useEffect(() => {
    let interval = window.setInterval(() => {
      const time = Math.floor(new Date().getTime() / 1000)
      setCurTimeInSeconds(time);
      let someRowExpired = false;
      slotsInfo?.setsList?.forEach((row) => {
        const timeToLock =
          row?.details?.unlockedTill?.seconds - time;
       if (timeToLock < 0) someRowExpired = true
      });
      if(someRowExpired) dispatch(fetchSlots())
    }, 10_000);
    return ()=>window.clearInterval(interval);
  }, [setCurTimeInSeconds,slotsInfo]);

  const value = useMemo(() => [context, setField] as IContext, [context]);

  return (
    <GridCtx.Provider value={value}>
      <s.Grid>
        <div className="title">Staked Items</div>
        <div className="header">set</div>
        <div className="header">unlock cost</div>
        <div className="header">item 1</div>
        <div className="header">item 2</div>
        <div className="header">item 3</div>
        <div className="header">base points rate</div>
        <div className="header">bonus points rate</div>
        {slotsInfo?.setsList?.map((row: ISetListItem, i: number) => {
          const hasItems = row.slotsList.find((item) => item.itemId.length);
          const rowUnlocked = !!row?.details;
          const rowExpired = !rowUnlocked && hasItems;
          const timeToLock =
            row?.details?.unlockedTill?.seconds - curTimeInSeconds || 0;
          return (
            <React.Fragment key={i}>
              <Set val={i + 1} />
              <UnlockBlock
                unlocked={rowUnlocked}
                rowExpired={rowExpired}
                price={row?.paymentDetails?.amount}
                set={i + 1}
                slot={row}
                setUnlockModal={setUnlockModal}
                setSlotSet={setSlotSet}
                timeToLock={timeToLock}
              />
              {row.slotsList.map((item: ISlotsListItem, idx: number) => {
                if (item.itemId.length) {
                  const currentItem = totalItems.find(
                    (mainItem) => mainItem.id === item.itemId
                  );
                  return (
                    <ItemBlock
                      tooltipData={getToltipData(item)}
                      currentItem={currentItem}
                      key={item.itemId}
                      set={row.id}
                      slot={idx}
                      slotStatus={item.status}
                      unstakeId={item.id}
                    />
                  );
                }
                if (!rowUnlocked) return <s.ItemBlockLocked key={idx} />;
                return <ItemBlockEmpty key={idx} set={row.id} slot={idx} />;
              })}
              <PointsRate val={row?.details?.basePointsRate} />
              <PointsRate val={row?.details?.bonusPointsRate} />
            </React.Fragment>
          );
        })}
      </s.Grid>
      <UnlockModal
        isOpen={unlockModal}
        set={set}
        setUnlockModal={setUnlockModal}
      />
      <UnstakeModal />
    </GridCtx.Provider>
  );
}

function PointsRate(props: { val?: number }) {
  const user = useMyProfile()

  const eventStatus = useAppSelector(selectEventStatus);

  const val =
    eventStatus?.status === "current" && props.val
      ? numberWithCommas(props.val)
      : "-";

  return (
    <>{user?.clan ? <PointsRateBlock val={val} /> : <PointsBlockNotInClan />}</>
  );
}
