import { useNavigate } from "react-router";
import * as s from "components/TopThree/styled";
import topThreeKills from "assets/topThreeKills.svg";
import topThreeDeath from "assets/topThreeDeath.svg";
import topThreeKdr from "assets/topThreeKdr.svg";

export function CardPlayer(player: IPlayer) {
  const navigate = useNavigate();
  return (
    <s.Card rank={player.rank}>
      <s.CardHeaderRank rank={player.rank}>
        <s.RankText>{player.rank}</s.RankText>
      </s.CardHeaderRank>
      <s.CardHeader onClick={() => navigate("/profile/" + player.id)}>
        {player.name}
      </s.CardHeader>
      <s.CardContent>
        <s.ScoreAndIcon>
          <s.ScoreAndIconInner>
            <s.IconRing rank={player.rank}>
              <s.Icon
                icon={player.icon}
                name={player.name}
                rank={player.rank}
              />
            </s.IconRing>
            <s.ScoreWrapper>
              <s.Score>{player.score}</s.Score>
              <s.ScoreText>score</s.ScoreText>
            </s.ScoreWrapper>
          </s.ScoreAndIconInner>
        </s.ScoreAndIcon>
        <s.Separator />
        <s.Stats>
          <s.StatsItem>
            <s.StatsItemImg src={topThreeKills} alt="kills" />
            <s.StatsItemText>Kills</s.StatsItemText>
            <s.StatsItemCount>{player.kills}</s.StatsItemCount>
          </s.StatsItem>
          <s.StatsItem>
            <s.StatsItemImg src={topThreeDeath} alt="deaths" />
            <s.StatsItemText>Deaths</s.StatsItemText>
            <s.StatsItemCount>{player.deaths}</s.StatsItemCount>
          </s.StatsItem>
          {/*<s.StatsItem>*/}
          {/*  <s.StatsItemImg />*/}
          {/*  <s.StatsItemText>Assists</s.StatsItemText>*/}
          {/*  <s.StatsItemCount>{player.assists}</s.StatsItemCount>*/}
          {/*</s.StatsItem>*/}
          <s.StatsItem>
            <s.StatsItemImg src={topThreeKdr} alt="kdr" />
            <s.StatsItemText>KDR</s.StatsItemText>
            <s.StatsItemCount>{player.kdr}</s.StatsItemCount>
          </s.StatsItem>
        </s.Stats>
      </s.CardContent>
    </s.Card>
  );
}
