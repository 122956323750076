import {
  HeaderItem,
  ListContainer,
  SelectBtn,
  SPolygon,
  SubtitleText,
} from "components/Transfer/ClanMembers/ClanMembers.style";
import butter from "assets/butter_icon.png";
import mobButter from "assets/mobButter.png";
import orbs from "assets/Orbs.svg";
import modileEnOrb from "assets/mobileEnOrb.png";
import {
  ContainerBlock,
  ContainerHeader,
  ContainerItem,
  LeftConteinerItem,
  NameField,
  ScrollContainer,
  StyledLogo,
  StyledOrbLogo,
  Subtitle,
  WeaponConteinerItem,
  WeaponQuantityItem,
} from "components/Transfer/Currencies/Currencies.style";
import { useIsMobile, useIsTablet } from "hooks/useMediaQuery";
import { ClanMembersModal } from "components/Transfer/ClanMembers/ClanMembersModal";
import { useState } from "react";
import { ICurrencies, IgameData, IminiTable } from "types/transferTypes";
import { TokenImage } from "components/TieredLeaderboard/componetns/TokenImage";

export const CurrenciesTable = ({
  balance,
  onSelect,
  currentItem,
  setGdImage,
  setUpdate,
  setGdname,
}: ICurrencies) => {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);

  return (
    <ListContainer>
      <SubtitleText>Currencies:</SubtitleText>
      <ScrollContainer>
        <ContainerHeader>
          <HeaderItem style={{ opacity: "50%" }}>
            CURRENCIES
            <SPolygon />
          </HeaderItem>
          <HeaderItem>
            <span style={{ color: "#FFB60C" }}>QUANTITY</span>
            <SPolygon color="#FFB60C" />
          </HeaderItem>
        </ContainerHeader>
        <ContainerBlock
          selected={balance?.butter?.selected}
          onClick={() => onSelect("butter")}
        >
          <LeftConteinerItem>
            <StyledLogo src={isMobile ? mobButter : butter} alt="BIcon" />
            <div>
              <div>Butter</div>
            </div>
          </LeftConteinerItem>
          <ContainerItem>
            {balance?.butter?.total.toLocaleString("en") || "-"}
            {(isTablet || isMobile) && (
              <SelectBtn onClick={() => setOpen(true)}>Send</SelectBtn>
            )}
          </ContainerItem>
        </ContainerBlock>
        <ContainerBlock>
          <LeftConteinerItem>
            <StyledOrbLogo src={isMobile ? modileEnOrb : orbs} alt="EIcon" />
            <div>
              <div>Enriched Orbs</div>
              <Subtitle>cannot send</Subtitle>
            </div>
          </LeftConteinerItem>
          <ContainerItem>
            {balance?.enrichedOrbs?.total.toLocaleString("en") || "-"}
            {(isTablet || isMobile) && <SelectBtn>Send</SelectBtn>}
          </ContainerItem>
        </ContainerBlock>
        <SubtitleText>Items:</SubtitleText>
        <ContainerHeader>
          <HeaderItem style={{ opacity: "50%" }}>
            ITEMS
            <SPolygon />
          </HeaderItem>
          <HeaderItem>
            <span style={{ color: "#FFB60C" }}>QUANTITY</span>
            <SPolygon color="#FFB60C" />
          </HeaderItem>
        </ContainerHeader>
        {balance?.allItems?.map(
          (dataItem: IgameData | IminiTable, i: number) => (
            <ContainerBlock
              key={i}
              selected={dataItem.selected}
              onClick={() => onSelect(dataItem)}
            >
              <WeaponConteinerItem>
                <TokenImage info={dataItem} setItemImage={setGdImage} />
                <div>
                  <NameField>{setGdname(dataItem)}</NameField>
                </div>
              </WeaponConteinerItem>
              <WeaponQuantityItem>
                X{dataItem?.total}
                {(isTablet || isMobile) && (
                  <SelectBtn onClick={() => setOpen(true)}>Send</SelectBtn>
                )}
              </WeaponQuantityItem>
            </ContainerBlock>
          )
        )}
      </ScrollContainer>
      <ClanMembersModal
        open={open}
        setOpen={setOpen}
        currentItem={currentItem}
        setGdImage={setGdImage}
        setUpdate={setUpdate}
        setGdname={setGdname}
      />
    </ListContainer>
  );
};