import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { IGetClanAssets } from "api/api.types";
import { City } from "pages/WorldDomination/WDGridPanel/City";
import { ICity, IRow } from "pages/WorldDomination/WDGridPanel/WDGrid";
import * as s from "pages/WorldDomination/WDGridPanel/WDGrid.styled";
import {
  useGetEventQuery,
  useGetEventTurnQuery,
  useGetRoundQueueQuery,
} from "redux/rtkQuery/rtkApi";
import {
  actions,
  selectAttack,
  selectLastTurnEnded,
} from "redux/slices/worldDominationSlice";
import { useAppDispatch, useAppSelector } from "store";
import styled from "styled-components/macro";

export function WDGridRow({
  data: { clanName, totalPoints, vaultedPoints, cities, clanId },
  num,
  myClanName,
  assets,
}: {
  data: IRow;
  num: number;
  myClanName: string;
  assets: IGetClanAssets;
}) {
  return (
    <s.Row num={num}>
      <s.Num val={num} />
      <s.ClanName val={clanName} />
      <s.TotalPoints val={totalPoints} />
      <s.VaultedPoints val={vaultedPoints} />
      {cities.map((city) => (
        <CityItem
          city={city}
          key={city.id}
          clanName={clanName}
          clanId={clanId}
          myClanName={myClanName}
          assets={assets}
        />
      ))}

      {/*<s.CityEconomy val={cityEconomy} />*/}
    </s.Row>
  );
}

function CityItem({
  city,
  clanName,
  myClanName,
  assets,
  clanId,
}: {
  clanId: string;
  city: ICity;
  clanName: string;
  myClanName: string;
  assets: IGetClanAssets;
}) {
  const dispatch = useAppDispatch();
  
  const attack = useAppSelector(selectAttack);
  const lastTurnEnded = useAppSelector(selectLastTurnEnded);
  
  const { data: event } = useGetEventQuery();
  const { data: queue } = useGetRoundQueueQuery();
  const { data: turn } = useGetEventTurnQuery(event?.id ?? skipToken);

  if (!queue) return null;

  const isMyClan = clanName === myClanName;

  const nukesPlanned = queue.attackCityByNuke.filter(
    (i) => i.cityId === city.id
  ).reduce((acc,item)=>acc+item.count,0);

  const shields =
    clanName === myClanName &&
    assets.cityShieldsList.filter((i) => i.cityId === city.id).length;

  const shieldsPlanned =
    clanName === myClanName ?
    queue.cityShield.filter((i) => i.cityId === city.id).reduce((acc,item)=>acc+item.count,0) : 0;
  
  
  
  const handleCityClick = (city: ICity) => {
    if(lastTurnEnded) return
    if (clanName !== myClanName) dispatch(actions.setAttack({ city, clanId }));
    else {
      dispatch(actions.setBuildShieldCity(city));
    }
  };

  return (
    <City
      key={city.id}
      onClick={() => handleCityClick(city)}
      shields={shields}
      shieldsPlanned={shieldsPlanned}
      isMyClan={isMyClan}
      name={city.name}
      cityPoints={city.points}
      nukesPlanned={nukesPlanned}
      attack={attack}
      cityId={city.id}
      onClose={() => dispatch(actions.setAttack(undefined))}
    />
  );
}

export const NukesPlanned = styled.div<{ count: number }>`
  position: absolute;
  right: 6px;
  bottom: 6px;

  font-weight: 400;
  font-size: 14px;
  color: #ec2e00;
  &:after {
    content: "+${(props) => props.count}";
  }
`;

export const PercentsLost = styled.div<{ count: number }>`
  font-weight: 400;
  font-size: 14px;
  color: #ec2e00;
  &:after {
    content: "${(props) => props.count}% lost";
  }
`;
