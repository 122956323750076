import * as s from "./styled";
import { useNavigate } from "react-router";

type ITableContent = {
  data: IPlayer[];
};

export default function TableContent({ data }: ITableContent) {
  const navigate = useNavigate();

  if (data.length === 0) return <s.NoItems>Not found</s.NoItems>;

  return (
    <>
      <s.BodyWrapper>
        <s.Grid>
          <s.HeaderRow>
            <s.HeaderCell>RANK</s.HeaderCell>
            <s.HeaderCell>PLAYER</s.HeaderCell>
            <s.HeaderCell>KILLS</s.HeaderCell>
            <s.HeaderCell>DEATHS</s.HeaderCell>
            <s.HeaderCell>KDR</s.HeaderCell>
            <s.HeaderCell>SCORE</s.HeaderCell>
          </s.HeaderRow>
          {data.map((player, i) => (
            <s.GridRow $i={i} key={player.id}>
              <s.GridCell>{player.rank}</s.GridCell>
              <s.GridCellNav onClick={() => navigate(`/profile/${player.id}`)}>
                <s.GridImg src={player.icon} />
                {player.name}
              </s.GridCellNav>
              <s.GridCell>{player.kills}</s.GridCell>
              <s.GridCell>{player.deaths}</s.GridCell>
              <s.GridCell>{player.kdr}</s.GridCell>
              <s.GridCell>{player.score}</s.GridCell>
            </s.GridRow>
          ))}
        </s.Grid>
      </s.BodyWrapper>
      <s.BodyImg />
    </>
  );
}
