import * as s from "./styled";
import { TopThreeCardSkeleton } from "components/Skeleton/TopThreeCardSkeleton";
import { CardPlayer } from "components/TopThree/CardPlayer";
import { CardClan } from "components/TopThree/CardClan";

export default function TopThree({ data }: { data?: IPlayer[] | IClan[] }) {
  if (data === undefined)
    return (
      <s.Wrapper>
        <TopThreeCardSkeleton rank={1} />
        <TopThreeCardSkeleton rank={2} />
        <TopThreeCardSkeleton rank={3} />
      </s.Wrapper>
    );

  if (!data?.length) return null;

  let data_;
  if ("slug" in data[0]) {
    data_ = data as IClan[];
    return (
      <s.Wrapper>
        <CardClan {...data_[0]} />
        {data_[1] && <CardClan {...data_[1]} />}
        {data_[2] && <CardClan {...data_[2]} />}
        {/*  TODO: убрать на проде */}
      </s.Wrapper>
    );
  } else {
    data_ = data as IPlayer[];
    return (
      <s.Wrapper>
        <CardPlayer {...data_[0]} />
        <CardPlayer {...data_[1]} />
        <CardPlayer {...data_[2]} />
      </s.Wrapper>
    );
  }
}

