import styled, { css } from "styled-components/macro";
import tableBgTop from "assets/tableBgTop.png";
import tableBgMiddle from "assets/tableBgMiddle.png";
import filterArrow from "assets/filterArrow.svg";
import arrow from "assets/filterArrow.svg";

export const Wrapper = styled.div`
  max-width: 1170px;
  margin: 20px;

  @media (min-width: 768px) {
    margin: 24px;
  }

  @media (min-width: 1280px) {
    margin: 30px auto;
  }
`;
export const Inner = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 30px;
`;

export const TableHeader = styled.div`
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  row-gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 28px;
  padding-left: 20px;
  padding-bottom: 7px;
  flex-direction: column;
  height: 80px;
  background: url(${tableBgTop}) no-repeat;
  background-size: 100% 100%;
  @media (min-width: 768px) {
    align-items: center;
    padding-left: 30px;
    flex-direction: row;
  }
  @media (min-width: 1280px) {
    padding-top: 36px;
    padding-bottom: 7px;
  }
`;

export const TableName = styled.div`
  color: #ffffff;
  opacity: 0.8;
  font-size: 16px;
  //text-align: center;
  font-weight: 700;
  line-height: 19px;
  margin-right: 20px;
`;

export const HeadImg = styled.img.attrs({
  src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAA3CAYAAACGnvPUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJiSURBVHgB7ZldbtpAFIXPrU2pBFW9hHQHWQJ5TdUqrKCwgsYrSFhBYQVJV5CkVcIj7grCDpIdFKmJBIX49vqHYsAYe8w0ijTfAz9j8BzPHXvuPQMUhC8ch/v1E2iAoADf1Acgvse44lJzNMKOeAUVGD/B1EJ1essXb/awI9TE+PCiD7SHqn3LN7Vj7AClMAVIqH7Jm5No6tLhg4sSqI1MqAbfVlqOZYTuyoRNXYyPy/VGkrBZA/7+9ggKqIuZ2UN5HaUKsvlL8AhAQZTFxLf0cKXZwxMOZO4cqNzyNkrBVyKrEYvo0McHD89F+DS+rrVgMBgMhheOcnKVRbRijx1YlX1YvhOu5IR3kjdHn6fUo0+/11KQQgtlZic+7f/rDLP41Iyl6/Wphz92c9OKTouOJEPb2okyuVb1xci8tlvS6cnSlTDFX1WjKeUMi4gPj+d5fr3US5gu2v7XkqMQnwwdCUm3SJK1dsncl3CxNSghyMNk1qbm+B4FSR3/cKJWp105/Bm5kZA8UbtMtpc5Gfi6fiq/2FZXjyQkvaIhKSwmFJQ9jy4lJK5KSJTEhIJW5xFLVeDD3XUCnvueXcwjDOnwsQuDwWAwLAjcCf5RbxT5j7pztZ0hLAyKGNhaEvI5CUc014Kqc2SSjuhRaDxucUL1illyRCkwsO+ywqZXTJojyjjlfu0szQ3VKmaDI4pN+w56Rybq+Sq9naJ9h4RBqV/MpHKecdQB0dncUdcuJg6Vl340KPK4Pa+7S5rSOQn2pwiNRItUFOyuVpr/Yc4gsT8VljUdTOz3eUteLQQ1mMrmhiHgL7ui9sfgK2FSAAAAAElFTkSuQmCC",
})`
  top: 28px;
  left: -20px;
  position: absolute;
`;

export const BodyWrapper = styled.div`
  //min-height: 1266px;
  //max-height: 1530px;
  margin: 0 auto;
  padding-bottom: 30px;
  //@media (min-width: 768px) {
  background: url(${tableBgMiddle});
  background-repeat-x: no-repeat;
  background-size: 100% auto;
  //}
`;

export const BodyImg = styled.img.attrs({
  src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAA6CAYAAABIz4K8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJBSURBVHgB7ZhdbtpAEMf/g62CBJV8BHOD9gbwmqpSc4IkJ2hv4PYEHKHcgFZVoG/xDZIbJEfggUhBMZnsDhGJN/hj1zZ5iH8Swl6vNPuf2Y+ZBUrCi/4Jn/c/4dDwfDDhef8aNePnfeRFLwT7v9XjCIQpDmWc/w1GYNaGw21D5y9qprOvkReDCB4uAAp3jWsvRs2klPMsCNBNZmDl5jQxHS+XaMq4zGS6n6XUPg+rdpdrxO2imJBhWLHe/EED0MsXPh/8BPFJehB8Q0e3QzRAasLRl5UyvhmrmP/SRre2qRGX56LXuNpYprLkWlpaWlreDTxTOV4FfDgiqRY4VI9ncITgAM8/ftO65WWdDOn47gYOdCz7b9NpPEx2DV1JrZ2wNo4H30y3Rq5nvpVxiTPhdcnkIYIDpWOusttTEGW7eIMxfV3FsKCUcokz0SS3k4P6QuOSVrOnqhcEBV2tY1+svJtEmfm8iaX6XOP8v/9d/f1AeazUZ064p/LYoSYvX2Tsr1Kf4+wAhbIyXI2jdz8pHee99hGJAFvjspEwnaISFOJDUjhXjEJRl8l0iXpYYu0P8+p6o1C8vVJXIWe7IrEaQZH67Nku2ymiSrEvUJ+5zpUXprJkqnkiV73lweLkiUz1pY/UCp7IVO+URmksPaHUJ5/NdMs+k3nC0hMBet6rQ8dZuUkpTxjJprNyk7QnEO/tZKivTbmJHK3b832U+vAi3WrMeM4gYjpajXFI9CDUvf2F+vGbXa/JIPRNZ0tLy6F4BJpF6nbLv1GHAAAAAElFTkSuQmCC",
})`
  right: -13px;
  position: absolute;
  bottom: 20%;
`;

export const BodyInner = styled.div`
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  background: unset;
  margin: 20px auto 0;
  min-height: 1266px !important;
  max-height: 1530px !important;
  overflow-x: unset !important;
  min-width: 88.0597% !important;

  @media (min-width: 768px) {
    min-width: 100% !important;
    max-width: 100% !important;
  }
`;

export const Grid = styled.div`
  //margin: 20px auto 75px;
  padding: 0 20px;
  @media (min-width: 768px) {
    padding: 0 30px;
  }
  @media (min-width: 1280px) {
  }
`;

export const Row = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns:
    minmax(80px, 1fr)
    minmax(80px, 5fr)
    repeat(4, minmax(80px, 1fr));
  gap: 10px;
  align-items: center;
`;

export const ManagmentRow = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 10px;
  align-items: center;
  height: 60px;
  ${(props: { $i: number }) => {
    return (
      !(props.$i % 2) &&
      css`
        background: rgba(255, 255, 255, 0.03);
        border: 1px solid rgba(255, 255, 255, 0.12);
      `
    );
  }};
  padding-left: 20px;
  @media (min-width: 1280px) {
    padding-left: 25px;
  } 
`;

export const RowMobile = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 20px minmax(80px, 1fr) 60px 30px;
  gap: 10px;
  align-items: center;
`;

export const HeaderRowMobile = styled(RowMobile)`
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  background: #171717;
  margin-bottom: 20px;
  div:nth-child(2) {
    text-align: center;
  }
  div:nth-child(3) {
    text-align: end;
  }
`;

export const HeaderRow = styled(Row)`
  background: #171717;
  height: 40px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    padding-left: 20px;
  }
  @media (min-width: 1280px) {
    padding-left: 25px;
  }
`;

export const HeaderCell = styled.div`
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
`;

export const GridRow = styled(Row)`
  height: 60px;
  ${(props: { $i: number }) => {
    return (
      !(props.$i % 2) &&
      css`
        background: rgba(255, 255, 255, 0.03);
        border: 1px solid rgba(255, 255, 255, 0.12);
      `
    );
  }};
  padding-left: 20px;
  @media (min-width: 1280px) {
    padding-left: 25px;
  }
`;

export const GridRowMobile = styled(RowMobile)`
  height: 60px;
  padding-left: 20px;
  padding-right: 5px;
  ${(props: { $i: number }) => {
    return (
      !(props.$i % 2) &&
      css`
        background: rgba(255, 255, 255, 0.03);
        border: 1px solid rgba(255, 255, 255, 0.12);
      `
    );
  }};
  div:nth-child(4) {
    justify-content: center;
  }
  div:nth-child(3) {
    justify-content: flex-end;
  }
`;

export const GridCell = styled.div`
  display: flex;
  align-items: center;
  font-weight: 325;
  font-size: 12px;
  color: #ffffff;
`;

export const GridCellNav = styled(GridCell)`
  cursor: pointer;
`;

export const GridImg = styled.img.attrs<{ src: string }>((props) => ({
  src: props.src,
}))`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-self: flex-end;
  align-items: center;
  font-size: 14px;
  line-height: 17px;

  margin-right: 30px;
  @media (max-width: 768px) {
    align-self: stretch;
    margin-right: 20px;
  }
  span {
    color: #ffffff;
    opacity: 0.4;
    margin-right: 10px;
  }
`;

export const FilterItemWrapper = styled.div`
  margin-right: 10px;
  position: relative;
  display: flex;
`;

export const FilterInner = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  min-width: 100px;
  @media (min-width: 1280px) {
    min-width: 150px;;
  }
  margin-right: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 0.05);
  border-width: 1.2px;
  border-style: solid;
  border-color: initial;
  border-image: ${(props) =>
    props.open
      ? `linear-gradient(88.27deg, rgba(255, 255, 255, 0.27) 4.49%, rgba(255, 255, 255, 0.72) 62.01%) 1 / 1 / 0 stretch;`
      : `linear-gradient(88.27deg, rgba(255, 255, 255, 0.12) 4.49%, rgba(255, 255, 255, 0.32) 62.01%) 1 / 1 / 0 stretch;`};


  -webkit-box-align: center;
  padding-left: 16px;
  padding-right: 10px;
  position: relative;
  z-index: 100;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  //flex: 0 0 150px;
  align-items: center;
  transform: skew(-10deg);
`;

export const TableArrowMob = styled.img.attrs({
  src: arrow,
})<{ open: boolean }>`
  width: 20px;
  opacity: 0.6;
  cursor: pointer;
  transition: all 0.1s;
  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      transform: rotate(-180deg);
    `};
`;

export const HiddenStats = styled.div<{ open: boolean }>`
  height: ${({ open }) => (open ? `120px` : "0px")};
  overflow: hidden;
  display: grid;
  transition: all 0.1s;
  grid-template-columns: 1fr 1fr;
  //grid-template-rows: 1fr 2px 1fr;
`;

export const StatItem = styled.div`
  margin-top: 13px;
  margin-left: 15px;
  display: grid;
  grid-template-rows: 18px 17px;
  grid-template-columns: 26px auto;
`;

export const StatImg = styled.img.attrs((props) => ({
  src: props.src,
}))`
  grid-row: span 2;
`;
export const StatTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.6);
`;

export const StatText = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;

export const Separator = styled.div`
  grid-column: span 2;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.15) 49.83%,
    rgba(255, 255, 255, 0.06) 100%
  );
`;

export const ModalInner = styled.div`
  padding: 20px;
`;

export const ModalTitle = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 15px;
`;

export const MobFilterHeader = styled.div`
  height: 40px;
  background: rgb(55, 55, 55);
  margin-top: 10px;
  clip-path: polygon(3% 0%, 100% 0%, 97% 100%, 0% 100%);
  padding: 0 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const MobFilterTitle = styled.div<{ open: boolean }>`
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${({ open }) => (open ? 1 : 0.75)};
`;

export const MobFilterArrow = styled.img.attrs({
  src: filterArrow,
})<{ open: boolean }>`
  opacity: ${({ open }) => (open ? 1 : 0.75)};
  ${({ open }) => open && "transform: rotate(180deg);"}
`;
// export const MobFilterInner = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   flex: 1;
// `

export const MobFilterContent = styled.div<{ open: boolean }>`
  height: ${({ open }) => (open ? "200px" : "0px")};
  transition: all 0.1s;
  overflow-y: scroll;
  padding: ${({ open }) => (open ? "10px 0" : "0")};
  background: #121212;
  margin-top: ${({ open }) => (open ? "13px" : "0px")};
`;

export const MobFilterElementWrap = styled.div``;

export const NoItems = styled.div`
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
`;
