import styled from "styled-components/macro";

export const TimerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  background: #1d1d1c;
  border: 1px solid #333333;
  width: 410px;
  height: 52px;
  align-items: center;

  & .label {
    padding-left: 18px;
    padding-right: 20px;
    flex: 1 1 auto;
    font-weight: 700;
    font-size: 16px;
    color: white;
  }
`;
export const TimerField = styled.div`
  flex: 0 0 auto;
  height: 52px;
  margin: 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;

  & > img {
    height: 52px;
  }

  & .yellow {
    padding: 0 10px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1 1 auto;
    background: #ffb60c;
    border-bottom: 1px solid #333333;
  }

  & .time {
    font-weight: 700;
    font-size: 20px;
    color: #000000;
  }
`;
