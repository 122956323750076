import { stream } from "api/stream";
import { ErrorMessageModal } from "components/ErrorMessageModal/ErrorMessageModal";
import Menu from "components/Header/Menu";
import { StakingWrapper } from "components/StakingWrapper";
import AllTimeLeaderboard from "pages/AllTimeLeaderboard";
import ClanListPage from "pages/ClanListPage";
import DailyLeaderboard from "pages/DailyLeaderboard";
import { HistoryPage } from "pages/HistoryPage";
import IntraClanLBPage from "pages/IntraClanLBPage";
import NoMatchPage from "pages/NoMatchPage";
import ProfilePage from "pages/ProfilePage";
import { TransferPage } from "pages/TransferPage";
import { WDHomePage } from "pages/WorldDomination/WDHomePage";
import React, { Suspense, useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { useAppDispatch } from "store";
import TieredLBPage from "./pages/TieredLBPage";

function Stream() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    stream(dispatch);
  }, []);
  return null;
}

function App() {
  return (
    <HashRouter>
      <Menu />
      <Stream/>
      <Suspense fallback={<></>}>
        <Routes>
          <Route path="/" element={<DailyLeaderboard />} />
          <Route path="/daily" element={<DailyLeaderboard />} />
          <Route path="/seasonal" element={<AllTimeLeaderboard />} />
          <Route path="/clans" element={<ClanListPage />} />
          <Route path="/clans/:clanId" element={<ClanListPage />} />
          {/*<Route path="/tieredLB" element={<TieredLBPage />} />*/}
          {/*<Route path="/transfer" element={<TransferPage />} />*/}
          <Route path="/clanWars/intraClanLB" element={<IntraClanLBPage />} />
          <Route path="/clanWars/tieredLB" element={<TieredLBPage />} />
          <Route path="/clanWars/worldDomination" element={<WDHomePage />} />
          <Route path="/clanWars/transferItems" element={<TransferPage />} />
          <Route path="/clanWars/transferHistory" element={<HistoryPage />} />
          <Route path="/staking/*" element={<StakingWrapper />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/profile/:userId" element={<ProfilePage />} />

          <Route path="*" element={<NoMatchPage />} />
        </Routes>
      </Suspense>
      <ErrorMessageModal />
    </HashRouter>
  );
}

export default App;
