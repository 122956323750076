import * as s from "./staking.styled";
import bg from "assets/stakeForCheddar.png";

export function StakeForCheddarBlock() {
  return (
    <s.StakeLoginItem bgImg={bg} style={{ cursor: "auto" }}>
      <s.StakeForCheddarTitle>Stake for Butter</s.StakeForCheddarTitle>
      <s.StakeForCheddarCommingSoon />
    </s.StakeLoginItem>
  );
}
