import styled from "styled-components/macro";
import { HeaderItem } from "components/Transfer/ClanMembers/ClanMembers.style";
import { ContainerItem } from 'components/Transfer/Currencies/Currencies.style';
import TriangleIcon from 'assets/triangleIcon';

export const ContainerHeader = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 2.5fr 2.5fr 3fr 4fr 4fr;
    background-color: #1F1F1F;
    margin-bottom: 10px;
`;


export const SHeaderItem = styled(HeaderItem)`
    opacity: 0.5;
    &:not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    &:nth-child(2) {
        min-width: 102px;
    }

    @media (max-width: 650px) {
    font-size: 10px;
    padding: 5px 5px 5px 8px;
  }
`;

export const ContainerBlock = styled.div<{ selected?: boolean, isNegative?: boolean}>`
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 2.5fr 2.5fr 3fr 4fr 4fr;
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 700;
    background-color: ${({ isNegative }) => isNegative ? '#151515' : '#1F1F1F'};
    min-height: 100px;
    border: 1px solid #000;
`;

export const SContainerItem = styled(ContainerItem)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    &:not(:last-child) {
        border-right: 2px solid #000;
    }

    &:nth-child(2) {
        min-width: 102px;
    }

    @media (max-width: 768px) {
    flex-direction: column;

    & > div {
        display: flex;
        flex-direction: column;
    }
  }
`;

export const StatusText = styled.div`
    color: #FFB60C;
    opacity: 0.9;
    font-weight: 325;
    font-size: 12px;
`;

export const TimeText = styled.div`
    font-weight: 325;
    font-size: 12px;
    opacity: 0.9;
`;

export const SRedTriangle = styled(TriangleIcon)`
    margin-right: 19px;
    color: #EC2E00;
    transform: rotate(180deg);
`;

export const SGreenTriangle = styled(TriangleIcon)`
    margin-right: 19px;
    color: #5FB21D;
`;

export const ColoredAmount = styled.span<{ isNegative?: boolean }>`
    color: ${({ isNegative }) => isNegative ? '#EC2E00' : '#5FB21D'};
`;

export const SubtitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`