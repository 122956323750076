import * as s from "./sumbenu.styled";
import img from "../assets/submenu-selector.png";
import { useState } from "react";
import { useClickOutside } from "hooks/useClickOutside";
import { useLocation, useNavigate } from "react-router";

export function SubmenuMobile() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const clanWars = pathname.includes("clanWars");
  const tieredLB = pathname.includes("tieredLB");
  const transfer = pathname.includes("transferHistory");

  const ref = useClickOutside(open, () => {
    setOpen(false);
  });

  return (
    <s.Wrapper ref={ref}>
      <s.MobileSubmenuClickable onClick={() => setOpen(!open)}>
        Clan War Tiered Leaderboard
        <img src={img} alt="submenu" />
      </s.MobileSubmenuClickable>
      <s.ModalWrapper open={open}>
        <s.MenuitemMob onClick={() => navigate("/clanWars")} active={clanWars}>
          Intra-Clan Leaderboard
        </s.MenuitemMob>
        <s.MenuitemMob onClick={() => navigate("/tieredLB")} active={tieredLB}>
          Clan War Tiered Leaderboard
        </s.MenuitemMob>
        <s.MenuitemMob onClick={() => navigate("/unknown")} active={false}>
          Clan Treasury
        </s.MenuitemMob>
        <s.MenuitemMob onClick={() => navigate("/transferHistory")} active={transfer}>
          Treasury Transfer History
        </s.MenuitemMob>
      </s.ModalWrapper>
    </s.Wrapper>
  );
}
