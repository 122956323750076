import { useEffect } from "react";
import { createPortal } from "react-dom";

interface IProps {
  children: JSX.Element;
}

export default function Portal({ children }: IProps): JSX.Element | null {
  const mount = document.getElementById("modal")!;
  const el = document.createElement("div");

  useEffect(() => {
    mount.appendChild(el);
    return () => {
      mount.removeChild(el);
    };
  }, [el, mount]);
  return createPortal(children, el);
}
