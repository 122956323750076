import styled from "styled-components/macro";
import filterApplyBtn from "assets/filterApplyBtn.svg";

export default function ApplyBtn({
  onClick,
  label = "Apply",
}: {
  onClick: () => void;
  label?: string;
}) {
  return (
    <FilterApplyBtnWrapper>
      <FilterApplyBtn onClick={onClick} label={label} />
    </FilterApplyBtnWrapper>
  );
}

export const FilterApplyBtnWrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
`;

export const FilterApplyBtn = styled.div<{ label: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 40px;
  background: url(${filterApplyBtn});
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  &:after {
    //text-transform: uppercase;
    content: "${(props) => props.label}";
  }
`;
