import Box from "components/Box";
import { Spinner } from "components/Spinner";
import Text from "components/Text";
import { WDModalStyles } from "pages/WorldDomination/modalStyles";
import Modal from "react-modal";
import styled from "styled-components/macro";

export function ProcessingModal() {
  return (
    <Modal isOpen style={WDModalStyles} preventScroll>
      <>
        <Wrapper>
          <Box position="relative" h={88} mt={60} mb={20}>
            <Spinner />
          </Box>
          <Text w={350} s={20} center>
            Turn Processing Sequence is processing...
          </Text>
        </Wrapper>
      </>
    </Modal>
  );
}

export const Wrapper = styled.div`
  width: 606px;
  height: 250px;
`;
