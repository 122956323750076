import TopThree from "components/TopThree/TopThree";
import TableScores from "components/TableScores";
import { useSwrImmutable } from "hooks/useSwr";
import {
  fetchScores,
  idp_GetAccount,
  leaderboards_fetchRankAndHistory,
} from "api";
import { useSearchParams } from "react-router-dom";
import { getMergedFilters, initialFilters } from "components/Filters";
import { EmptyResults } from "components/Leaderboard/styled";
import { DelayWarning } from "components/DelayWarning";
import { DailyTimer } from "components/Leaderboard/DailyTimer";
import * as s from "./styled";
import { Rank } from "components/Rank";

export default function Leaderboard({ type }: { type: "seasonal" | "daily" }) {
  const [filtersFromUrl] = useSearchParams();
  const mergedFilters = getMergedFilters(filtersFromUrl, initialFilters);

  const [top3, , top3error] = useSwrImmutable(
    [type, initialFilters],
    fetchScores
  );

  let [data, loadingData, error] = useSwrImmutable(
    [type, mergedFilters],
    fetchScores
  );

  const [auth] = useSwrImmutable("auth", idp_GetAccount);
  const [rankAndMatchHistory] = useSwrImmutable(
    auth?.userId?.value ? [auth?.userId?.value, "rankAndHistory"] : null,
    leaderboards_fetchRankAndHistory
  );
  const rank = rankAndMatchHistory?.dailyRank;

  const title =
    type === "seasonal" ? "Seasonal Leaderboard" : "Daily Leaderboard";
  if (top3error?.message === "not found") return <EmptyResults />;
  if (error?.message === "not found") data = [];
  return (
    <div>
      <s.TopWrapper>
        {type === "daily" && <DailyTimer />}
        <DelayWarning />
        <s.Flex />
        {type === "daily" && rank !== undefined && (
          <Rank type={type} rank={rank} />
        )}
      </s.TopWrapper>
      <TopThree data={top3} />
      <TableScores
        loading={loadingData}
        data={data}
        title={title}
        mergedFilters={mergedFilters}
      />
    </div>
  );
}

