import {
  clanevents_getClanLeaderboard,
  clanevents_getLeaderboardViewEvent,
} from "api";
import { DeskTable } from "components/TieredLeaderboard/componetns/DeskTable";
import { TabletTable } from "components/TieredLeaderboard/componetns/TabletTable";
import { Title } from "components/TieredLeaderboard/componetns/Title";
import { useMediaAll } from "hooks/useMediaQuery";
import { useSwrImmutable } from "hooks/useSwr";
import React from "react";
import Box from "../Box";
import { EventEndTimer } from "../IntraClan/EventEndTimer";
import { useRewards } from "./componetns/hooks";
import { MobTable } from "./componetns/MobTable";

export function TieredLeaderboard() {
  const { isDesktop, isMobile, isTablet } = useMediaAll();

  const [event] = useSwrImmutable("event", clanevents_getLeaderboardViewEvent, {
    refreshInterval: 2_1000,
  });

  const [rewards, loading] = useRewards(event);

  const [clans, loadingClanLB] = useSwrImmutable(
    event ? [event, { clanSlots: true, allItems: true }, "clanLB"] : null,
    clanevents_getClanLeaderboard
  );

  if (!rewards || loading || loadingClanLB || !clans || !event) return null;
  const { items } = rewards;

  return (
    <>
      <Box alignItems="center" mt={20} mb={25} justifyContent="space-between">
        <Title text="Tiered Leaderboard" />
        <EventEndTimer />
      </Box>
      {isDesktop && <DeskTable clans={clans} event={event} items={items} />}
      {isTablet && <TabletTable clans={clans} event={event} items={items} />}
      {isMobile && <MobTable clans={clans} event={event} items={items} />}
    </>
  );
}
