import * as s from "./staking.styled";
import bg from "assets/stakeForBattlePass.png";
import { useSwrImmutable } from "hooks/useSwr";
import { idp_GetAccount } from "api";
import { useNavigate } from "react-router";

export function StakeForBattlePassBlock() {
  const [auth, loading] = useSwrImmutable("auth", idp_GetAccount);
  const navigate = useNavigate();
  const handle = () => {
    if (loading) return;
    else {
      if (auth) navigate("/staking/eventPoints");
      else window!.open("https://miniroyale.io/", "_blank")!.focus();
    }
  };

  return (
    <s.StakeLoginItem bgImg={bg} onClick={handle}>
      <div className="title">Stake for battle pass</div>
      <div className="small">Available In-Game during Season</div>
    </s.StakeLoginItem>
  );
}
