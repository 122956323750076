import styled from "styled-components/macro";
import tier1bg from "components/TieredLeaderboard/assets/tier1mob.png";
import tier2bg from "components/TieredLeaderboard/assets/tier2mob.png";
import tier3bg from "components/TieredLeaderboard/assets/tier3mob.png";

export const Tier = styled.div<{ tier: number }>`
  display: flex;
  flex-direction: column;
  background: rgba(23, 23, 23, 0.75);
  padding: 5px;
  align-items: stretch;
  margin-bottom: 20px;

  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ tier }) => (tier < 4 ? "black" : "white")};
    background: ${({ tier }) =>
      tier === 1
        ? `url(${tier1bg})`
        : tier === 2
        ? `url(${tier2bg})`
        : tier === 3
        ? `url(${tier3bg})`
        : "black"};
    background-size: cover;
    margin-bottom: 5px;
    padding: 0 38px 0 19px;
    height: 46px;

    & > div:nth-child(1) {
      font-weight: 700;
      font-size: 22px;
    }

    & > div:nth-child(2) {
      font-size: 14px;
    }
  }

  // Block with clans rows
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
    margin-bottom: 5px;
  }
`;

export const TierItem = styled.div`
  display: flex;
  flex: 1;
  min-height: 36px;

  & > div:nth-child(1) {
    flex: 1 1 201px;
  }

  & > div:nth-child(2) {
    flex: 0 0 70px;
  }

  & > div:nth-child(3) {
    flex: 0 0 41px;
  }

  //CLAN
  & > div:nth-child(1) {
    display: flex;
    align-items: stretch;
    margin-right: 1px;
    font-weight: 350;
    font-size: 12px;

    & > div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      color: black;
      background: #ffb60c;
    }

    & > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      color: white;
      background: black;
    }
  }

  //MEMBERS
  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    font-weight: 350;
    font-size: 12px;
    background: black;
    margin-right: 1px;
    color: white;
    column-gap: 3px;

    & > img {
      filter: opacity(0.5);
    }
  }

  //POINTS
  & > div:nth-child(3) {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    //align-content: center;
    align-items: center;
    width: 340px;
    font-weight: 350;
    font-size: 12px;
    background: black;
    margin-right: 1px;
    color: white;
    column-gap: 3px;
    padding: 0 16px;

    & > div {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }
  }
`;

export const Rewards = styled.div`
  display: flex;
  padding: 6px;
  font-weight: 350;
  font-size: 12px;
  background: black;
  color: white;
  gap: 6px;
  flex-direction: column;
  align-items: stretch;

  & > div:nth-child(1) {
    display: flex;
    flex-direction: row;
    column-gap: 5px;

    & > div {
      display: flex;
      flex: 1;
      column-gap: 8px;
      width: 113px;
      height: 44px;
      align-items: center;
      background: #171717;
      padding-left: 9px;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    justify-content: flex-start;
    gap: 6px;
    flex-wrap: wrap;
  }
`;
