import economic from "assets/economic-point-icon-grey.png";
import military from "assets/military-point-icon-grey.png";
import members from "components/TieredLeaderboard/assets/members.png";
import total from "components/TieredLeaderboard/assets/totalWhite.png";
import * as s from "components/TieredLeaderboard/componetns/Desk.styled";
import Tooltip from "rc-tooltip";

export function DeskTierClan({ clan }: { clan: IGetClanLeaderboard }) {
  // const [flags] = useSwrImmutable("fflags", fflags_getUserFeatures);
  const stakingEnabled = true;
  return (
    <s.TierItem>
      {/*clan*/}
      <div>
        <div>{clan.rank}</div>
        <div>{clan.name}</div>
      </div>
      {/*  members*/}
      <div>
        <img src={members} alt="members" />
        {clan.size}
      </div>
      {/*points*/}
      <div>
        <Tooltip placement="topLeft" overlay={<div>Military Points</div>}>
          <div>
            <img src={military} alt="military" />
            {clan.militaryScore}
          </div>
        </Tooltip>
        <Tooltip placement="topLeft" overlay={<div>Economic Points</div>}>
          <div>
            <img src={economic} alt="economic" />
            {stakingEnabled ? clan.economicScore : "0"}
          </div>
        </Tooltip>
        <Tooltip placement="topLeft" overlay={<div>Total Points</div>}>
          <div>
            <img src={total} alt="total" />
            {clan.totalScore}
          </div>
        </Tooltip>
      </div>
    </s.TierItem>
  );
}
