import React, { useEffect } from "react";
import * as s from "./styled";

export default function Drawer({
  open,
  children,
  onClose,
}: {
  open: boolean;
  children: JSX.Element | string;
  onClose: () => void;
}) {
  useEffect(() => {
    if (open) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);
  return (
    <div>
      <s.DrawerContent
        open={open}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </s.DrawerContent>
      <s.DrawerBg open={open} onClick={onClose} />
    </div>
  );
}
