import icon from "components/Staking/StakeForEventPoints/assets/notifyGreenInfo.png";
import Text from "components/Text/Text";
import styled from "styled-components/macro";

export const PointsInfoWrapper = styled.div`
  display: flex;
  background: rgba(31, 31, 31, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  padding: 0 40px 0 24px;
  align-items: center;
  height: 52px;
`;

export function PointsInfo() {
  return (
    <PointsInfoWrapper>
      <img src={icon} width={28} />
      <Text ml={16}>
        Points are awarded every hour
      </Text>
    </PointsInfoWrapper>
  );
}