import * as s from "./styled";
import { useIsMobile } from "hooks/useMediaQuery";
import Modal from "components/Modal";
import { useState, Dispatch, SetStateAction } from "react";
import ApplyBtn from "components/ApplyBtn";
import { IFilterItem, IOption } from "components/Filters";

export default function FiltersMobile({
  filters,
  handleApply,
}: {
  filters: IFilterItem[];
  handleApply: (filters: IFilterItem[]) => void;
}) {
  const [showModal, setShowModal] = useState(false);

  const isMobile = useIsMobile();

  if (!isMobile) return null;
  return (
    <s.MobFilterWrapper>
      <s.MobTitle onClick={() => setShowModal(!showModal)}>
        <div>Show Filters</div>
        <s.FilterIconMob />
      </s.MobTitle>
      <Modal open={showModal} onClose={() => setShowModal(!showModal)}>
        <ModalContent
          filters={filters}
          handleApply={handleApply}
          setShowModal={setShowModal}
        />
      </Modal>
    </s.MobFilterWrapper>
  );
}

function ModalContent(props: {
  filters: IFilterItem[];
  handleApply: (filters: IFilterItem[]) => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [filters, setFilters] = useState(props.filters);

  const handleSelect = (name: string, options: IOption[]) => {
    const newFilters = [...filters];
    const newFilterIdx = filters.findIndex((item) => item.name === name);
    newFilters[newFilterIdx].options = options;
    setFilters(newFilters);
  };

  const onApplyClick = () => {
    props.handleApply(filters);
    props.setShowModal(false);
  };
  return (
    <s.ModalInner>
      <s.ModalTitle>SELECT FILTERS</s.ModalTitle>
      <s.StatsSeparator />
      {props.filters.map((filter) => (
        <MobFilterElement
          key={filter.name}
          filter={filter}
          selected={selectedFilter}
          handleSelect={handleSelect}
          handleOpen={setSelectedFilter}
        />
      ))}
      <ApplyBtn onClick={onApplyClick} />
    </s.ModalInner>
  );
}

function MobFilterElement({
  selected,
  filter,
  handleOpen,
  handleSelect,
}: {
  selected: string;
  filter: IFilterItem;
  handleSelect: (name: string, options: IOption[]) => void;
  handleOpen: (name: string) => void;
}) {
  const open = selected === filter.name;
  const handleCheckOption = (item: IOption) => {
    const newOptions = filter.options.map((i) =>
      i.key === item.key ? { ...i, selected: !i.selected } : i
    );
    handleSelect(filter.name, newOptions);
  };

  const handleFilterNameClick = () => {
    if (open) {
      handleOpen("");
    } else {
      handleOpen(filter.name);
    }
  };
  return (
    <s.MobFilterElementWrap>
      <s.MobFilterHeader onClick={handleFilterNameClick}>
        {/*<s.MobFilterInner>*/}
        <s.MobFilterTitle open={open}>{filter.name}</s.MobFilterTitle>
        <s.MobFilterArrow open={open} />
        {/*</s.MobFilterInner>*/}
      </s.MobFilterHeader>
      <s.MobFilterContent open={open}>
        {filter.options.map((item) => (
          <s.FilterContentItem
            key={item.key}
            selected={item.selected}
            onClick={() => handleCheckOption(item)}
          >
            <s.FilterContentItemCheckbox selected={item.selected} />

            <span>{item.value}</span>
          </s.FilterContentItem>
        ))}
      </s.MobFilterContent>
    </s.MobFilterElementWrap>
  );
}
