import { SContainerItem, TimeText, StatusText, SRedTriangle, SGreenTriangle, ColoredAmount } from 'components/Transfer/TransferHistory/TransferHistory.style';
import { Container, DateItem, TitleText, Subtitle, TextContainer, MobileTextContainer } from 'components/Transfer/TransferHistory/TransferHistoryMobile.style';
import { IInfo, IHistoryItem } from 'types/transferTypes';
import { formatToMounthDay, getUTCTime } from 'utils/dateFormater';
import { TokenImage } from "components/TieredLeaderboard/componetns/TokenImage";
import { SCupIcon, SClanIcon} from "components/Transfer/ClanMembers/ClanMembers.style";

type HistoryProps = {
    data: IHistoryItem;
    setItemImage:  (info: IInfo) => string;
    getUserName: (id: string, type?: string) => string | undefined;
    getUserSuffix: (id: string) => string | undefined;
    getRole: (id: string) => string
}

export const MobileTransferTable = ({ data, setItemImage, getUserName, getUserSuffix, getRole }: HistoryProps) => {

    const isNegative = Math.sign(data?.item?.amount) === -1
    return (
    <Container>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '9px'}}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                <DateItem>
                {isNegative ? <SRedTriangle /> : <SGreenTriangle />}
                <div style={{ display: 'inline-block'}}>
                    {formatToMounthDay(data.createdAt?.seconds)}
                    <TimeText>{getUTCTime(data.createdAt?.seconds)} UTC</TimeText>
                </div>
                </DateItem>
                <div><TitleText>QUANTITY SENT:</TitleText><ColoredAmount isNegative={isNegative}>{`${!isNegative ? '+' : ''}${data?.item?.amount}`}</ColoredAmount></div>
            </div>
            <SContainerItem><TokenImage info={data?.item?.info} setItemImage={setItemImage} /></SContainerItem>
        </div>
        <TextContainer>
            <TitleText>RECIPIENT NAME:</TitleText>
            {data?.type === 'CLAN_EVENT_START_RAID' && <SCupIcon color="grey" />}
            {data?.type === 'CLAN_EVENT_TIER_REWARD' && <SClanIcon />}
            <Subtitle>{getUserName(data?.recipient?.userId, data.type)}<span style={{ opacity: '0.7'}}>{getUserSuffix(data?.recipient?.userId)}</span></Subtitle>
        </TextContainer>
        <MobileTextContainer>
            <TitleText style={{ minWidth: '103px'}}>SENT BY:</TitleText> 
            <div style={{ fontWeight: '700'}}>
            {getUserName(data?.by?.userId)}<span style={{ opacity: '0.7'}}>{getUserSuffix(data?.recipient?.userId)}</span>
            {data?.by?.userId && <StatusText>{getRole(data?.by?.userId)}</StatusText>}
            </div>
        </MobileTextContainer>
    </Container>
    )
}