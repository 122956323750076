import styled from "styled-components/macro";
import nuke from "../assets/nukeIcon.png";

export const Content = styled.div`
  width: 783px;
  position: relative;
  padding: 38px 30px 0 40px;
  display: flex;
  flex-direction: column;
`;

export const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 40;
  cursor: pointer;
  & > img {
    width: 16px;
    height: 16px;
  }
`;
export const Top = styled.div`
  display: flex;
  gap: 20px;
`;

export const TopLeft = styled.div`
  flex: 0 0 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const TopRight = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

export const Bottom = styled.div`
  display: flex;
`;
export const BottomLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex:0 0 370px;
`;
export const BottomRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Description = styled.ul`
  margin-top: 3px;
  margin-bottom: 25px;
  font-weight: 350;
  font-size: 16px;
  line-height: 22px;
  color: #838383;
  padding-inline-start: 16px;
`;

export const Img = styled.img`
  border-radius: 10px;
  margin-bottom: 6px;
`;
export const White = styled.span`
  color: white;
`;

export const Line = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #838383;
  display: flex;
  align-items: center;
`;

export const Item1 = styled.div`
  flex: 0 0 197px;
`;
export const Item2 = styled.div`
  flex: 0 0 140px;
`;

export const Count = styled.div<{ count: number }>`
  flex: 0 0 148px;
  &::before {
    color: ${({ count }) => (count === 0 ? "#838383" : "#59B015")};
    content: "${({ count }) => count}";
  }
`;
export const CountRed = styled.div<{ count: number }>`
  flex: 0 0 148px;
  &::before {
    color: ${({ count }) => (count === 0 ? "#838383" : "#EC2E00")};
    content: "${({ count }) => (count === 0 ? 0 : "+" + count)}";
  }
`;

export const Remove = styled.div`
  margin-top: 1px;
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #ec2e00;
  cursor: pointer;
  &::before {
    content: "Cancel Attack";
  }
`;

export const Line2 = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #838383;
  display: flex;
  align-items: center;
`;

export const NukesCost = styled.div`
  display: flex;
  color: white;
`;

export const ImgNuke = styled.img.attrs({
  src: nuke,
})`
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;
