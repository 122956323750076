import { numWithSufix } from "utils/numberWithComas";
import { useIsDesktop } from "hooks/useMediaQuery";
import { IEvent } from "api/api.types";

export function Place({ event, place }: { event: IEvent; place: number }) {
  const isDesktop = useIsDesktop();

  if (!event) return null;
  const start = event.rewardsList[place - 1].placeFromInclusive;
  const end = event.rewardsList[place - 1].placeToExclusive - 1;

  const placeText =
    end - start
      ? `${numWithSufix(start)} - ${numWithSufix(end)}`
      : numWithSufix(start);
  return (
    <div className="tier">
      <div>Tier {place}</div>
      {isDesktop ? (
        <>
          <div>{placeText}</div>
          <div>place</div>
        </>
      ) : (
        <div>{placeText} place</div>
      )}
    </div>
  );
}
