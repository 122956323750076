import * as s from "./styled";
import kill from "assets/killsMob.svg";
import deaths from "assets/deathsMob.svg";
import kdr from "assets/kdrMob.svg";

import { useState } from "react";
import { useNavigate } from "react-router";

type ITableContent = {
  data: IPlayer[];
};

export default function TableContentMobile({ data }: ITableContent) {
  return (
    <>
      <s.BodyWrapper>
        <s.Grid>
          <s.HeaderRowMobile>
            <s.HeaderCell>#</s.HeaderCell>
            <s.HeaderCell>PLAYER</s.HeaderCell>
            <s.HeaderCell>SCORE</s.HeaderCell>
          </s.HeaderRowMobile>
          {data.map((player, i) => (
            <GridRowMob key={player.id} i={i} player={player} />
          ))}
        </s.Grid>
      </s.BodyWrapper>
      <s.BodyImg />
    </>
  );
}

function GridRowMob(props: { i: number; player: IPlayer }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handlePlayerClick = () => navigate(`/profile/${props.player.id}`);
  return (
    <>
      <s.GridRowMobile $i={props.i}>
        <s.GridCell>{props.player.rank}</s.GridCell>
        <s.GridCell onClick={handlePlayerClick}>
          <s.GridImg src={props.player.icon} />
          {props.player.name}
        </s.GridCell>
        <s.GridCell>{props.player.score}</s.GridCell>
        <s.GridCell onClick={() => setOpen(!open)}>
          <s.TableArrowMob open={open} />
        </s.GridCell>
      </s.GridRowMobile>
      <s.HiddenStats open={open}>
        {!!(props.i % 2) && <s.Separator />}
        <s.StatItem>
          <s.StatImg src={kill} />
          <s.StatTitle>KILLS</s.StatTitle>
          <s.StatText>{props.player.kills}</s.StatText>
        </s.StatItem>

        <s.StatItem>
          <s.StatImg src={deaths} />
          <s.StatTitle>DEATHS</s.StatTitle>
          <s.StatText>{props.player.deaths}</s.StatText>
        </s.StatItem>
        <s.Separator />
        <s.StatItem>
          <s.StatImg src={kdr} />
          <s.StatTitle>KDR</s.StatTitle>
          <s.StatText>{props.player.kdr}</s.StatText>
        </s.StatItem>
        {!(props.i % 2) && <s.Separator />}
      </s.HiddenStats>
    </>
  );
}
