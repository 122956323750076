import basePperH from "components/Staking/StakeForEventPoints/assets/battlePperH.svg";
import bonusPperH from "components/Staking/StakeForEventPoints/assets/bonusPperH.svg";
import economyPointsEarned from "components/Staking/StakeForEventPoints/assets/economyPointsEarned.svg";
import stakedNFTs from "components/Staking/StakeForEventPoints/assets/stakedNfts.svg";
import * as s from "components/Staking/StakeForEventPoints/InfoBloc/StakeForEventPoints.styled";
import { InfoIcon } from "components/Staking/StakeForEventPoints/InfoBloc/StakeForEventPoints.styled";
import {
  selectBonuses,
  selectEconomicPoints,
  selectEventStatus,
  selectSlotsInfo,
  selectStakedItemsCount,
} from "redux/slices/eventPointsSlice";
import { useAppSelector } from "store";
import { MyTooltip } from "../../MyTooltip";

export function Contribution() {
  const ePoints = useAppSelector(selectEconomicPoints);
  const bonuses = useAppSelector(selectBonuses);
  const slotsInfo = useAppSelector(selectSlotsInfo);
  const eventStatus = useAppSelector(selectEventStatus);

  const levelUpBouses = bonuses
    .filter((bonus: any) => bonus.name.toLowerCase().includes("level"))
    .sort((a: any, b: any) => a.percent - b.percent);

  const baseHourPoints = slotsInfo.setsList.reduce(
    (acc: any, setListItem) =>
      acc + (setListItem?.details?.basePointsRate || 0),
    0
  );

  const bonusHourPoints = slotsInfo.setsList.reduce(
    (acc: any, setListItem) =>
      acc + (setListItem?.details?.bonusPointsRate || 0),
    0
  );
  const stakedItemsCount = useAppSelector(selectStakedItemsCount);
  return (
    <div>
      <div className="title">my contribution:</div>
      <s.Contribution>
        <div className="item">
          <img src={stakedNFTs} />
          <div className="count">{stakedItemsCount || "-"}</div>
          <div className="text">
            Staked NFTs <br />
            Towards Event
          </div>
        </div>
        <div className="item">
          <MyTooltip
            overlay={
              <s.TooltipContent>
                <div>
                  <div>
                    <span style={{ color: "#FFB60C" }}>
                      Special Character NFT
                    </span>{" "}
                    = {slotsInfo.specialCharacterRate} points/hr
                  </div>
                  <span style={{ color: "#FFB60C" }}>
                    Premium Character NFT
                  </span>{" "}
                  = {slotsInfo.premiumCharacterRate} points/hr
                </div>
                <div>
                  <span style={{ color: "#FFB60C" }}>Loot Character NFT</span> ={" "}
                  {slotsInfo.lootCharacterRate} points/hr
                </div>
                <div>
                  <span style={{ color: "#FFB60C" }}>Loot Weapon NFT</span> ={" "}
                  {slotsInfo.lootWeaponRate} points/hr
                </div>
              </s.TooltipContent>
            }
          >
            <InfoIcon />
          </MyTooltip>
          <img src={basePperH} />
          <div className="count">
            {(eventStatus?.status === "current" && baseHourPoints) || "-"}
          </div>
          <div className="text">
            Base Points <br />
            per Hour
          </div>
        </div>
        <div className="item">
          <img src={economyPointsEarned} />
          <div className="count">{ePoints || "-"}</div>
          <div className="text">
            Economic Points earned Towards Current Event
          </div>
        </div>
        <div className="item">
          <MyTooltip
            overlay={
              <s.TooltipContent>
                <div style={{ marginBottom: "16px" }}>
                  In Addition to the Event attribute bonuses, the following item
                  level bonuses apply:
                </div>
                {levelUpBouses?.map((item: any, i: number) => (
                  <div key={i}>
                    <span style={{ color: "#FFB60C" }}>{item.name}</span> ={" "}
                    {item.description}
                  </div>
                ))}
              </s.TooltipContent>
            }
          >
            <InfoIcon />
          </MyTooltip>
          <img src={bonusPperH} />
          <div className="count">
            {(eventStatus?.status === "current" && bonusHourPoints) || "-"}
          </div>
          <div className="text">
            Bonus Points <br />
            Per Hour
          </div>
        </div>
      </s.Contribution>
    </div>
  );
}
