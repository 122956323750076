import styled from "styled-components/macro";

export const Container = styled.div`
    background-color: #151515;
    padding: 9px 9px 9px 13px;
    margin-top: 5px;
`;

export const DateItem = styled.div`
    font-weight: 700;
    line-height: 19px;
    opacity: 0.9;
`;

export const TitleText = styled.span`
    display: inline-block;
    margin-right: 10px;
    opacity: 0.5;
    font-weight: 350;
    font-size: 12px;
    line-height: 14px;
`;

export const Subtitle = styled.span`
    display: contents;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
`;

export const TextContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.06);
    padding: 13px 11px;
    margin-top: 2px;
`;

export const MobileTextContainer = styled(TextContainer)`
    display: flex;
    align-items: center;
`;