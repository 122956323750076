import { SVGAttributes } from 'react';

const CloseIcon = (props: SVGAttributes<SVGElement>) => (
  <svg 
    width="44" 
    height="43" 
    viewBox="0 0 44 43"
    fill="currentColor"
    {...props}
   >
  <path d="M0 5L43.5 0V42.5L1.5 38.5L0 5Z" fill="#FFB60C"/>
  <path d="M22 19.1156L28.6 12.5156L30.4853 14.401L23.8853 21.001L30.4853 27.601L28.6 29.4863L22 22.8863L15.4 29.4863L13.5146 27.601L20.1146 21.001L13.5146 14.401L15.4 12.5156L22 19.1156Z" fill="black"/>
</svg>
);

export default CloseIcon;