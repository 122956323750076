import { Bonuses } from "components/Staking/StakeForEventPoints/InfoBloc/Bonuses";
import { Contribution } from "components/Staking/StakeForEventPoints/InfoBloc/Contribution";
import { PointsInfo } from "components/Staking/StakeForEventPoints/InfoBloc/PointsInfo";
import * as s from "components/Staking/StakeForEventPoints/InfoBloc/StakeForEventPoints.styled";
import {
  selectEventStatus,
  selectStakedItemsCount,
} from "redux/slices/eventPointsSlice";
import { useAppSelector } from "store";
import { StakeEPInfoBlock1 } from "./StakeEPInfoBlock1";
import { Timer } from "./Timer";

export function InfoBlock() {
  const stakedItemsCount = useAppSelector(selectStakedItemsCount);
  const eventStatus = useAppSelector(selectEventStatus);

  if (!eventStatus) return null;
  return (
    <s.HeadWrapper>
      <s.HeadTop>
        <div className="title">Stake for Event Points</div>
        {eventStatus.status === "current" && stakedItemsCount ? (
          <Timer />
        ) : (
          <PointsInfo />
        )}
      </s.HeadTop>
      <s.Blocks>
        <StakeEPInfoBlock1 />
        <Bonuses />
        <Contribution />
      </s.Blocks>
    </s.HeadWrapper>
  );
}

