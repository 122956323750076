import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";

const url = window.location.href;
export const wallets = [new PhantomWalletAdapter()];

const prod = "https://prod-server.miniroyale.io";
const staging = "https://staging-server.miniroyale.io";

let server: string;
let network



if (url.includes("social.miniroyale.io")) {
  server = prod;
  network = WalletAdapterNetwork.Mainnet;
} else if (url.includes("social-react.miniroyale.io")) {
  server = prod;
  network = WalletAdapterNetwork.Mainnet;
} else if (url.includes("social-react-test.miniroyale.io")) {
  server = staging;
  network = WalletAdapterNetwork.Devnet;
} else { // localhost
  server = staging;
  network = WalletAdapterNetwork.Devnet;
}

export { server };

export const endpoint = clusterApiUrl(network);
export const serverGW = server + "/miniroyale-gateway/";
