export function secondsToHMS(secs: number) {
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor(secs / 60) % 60;
  const seconds = secs % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
}

export function secondsToHM(secs: number) {
  let hours: number | string = Math.floor(secs / 3600);
  let minutes: number | string = Math.floor(secs / 60) % 60;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  const str = (hours !== 0 ? hours + " h " : "") + minutes + " min";
  return str;
}

export function secondsToDHM(seconds: number) {
  seconds = Number(Math.abs(seconds));
  let d = Math.floor(seconds / (3600 * 24));
  let h = Math.floor((seconds % (3600 * 24)) / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  // let s = Math.floor(seconds % 60);
  if(seconds < 60) return " < 1 min "
  let dDisplay = d > 0 ? d + (d === 1 ? "d " : "d ") : "";
  let hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
  let mDisplay = m > 0 ? m + (m === 1 ? "m " : "m ") : "";
  return dDisplay + hDisplay + mDisplay;
}

export function secondsToDHMSingleValue(seconds: number) {
  seconds = Number(Math.abs(seconds));
  let d = Math.floor(seconds / (3600 * 24)) ;
  let h = Math.floor((seconds % (3600 * 24)) / 3600) ;
  let m = Math.floor((seconds % 3600) / 60) ;
  // let s = Math.floor(seconds % 60);
  if (d > 0) return d + (d === 1 ? " day " : " days ");
  if (h > 0) return h + (h === 1 ? " hour " : " hours ");
  if (m > 0) return m + (m === 1 ? " min " : " min ");
  return " < 1 min ";
}

export function timeTo24UTC() {
  const date = new Date();
  const now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  const endOfDay = new Date();
  endOfDay.setUTCHours(23, 59, 59, 999);
  const dif = endOfDay.getTime() - now_utc;
  return secondsToHM(Math.floor(dif / 1000));
}
