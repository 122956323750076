import { EventEndTimer } from "components/IntraClan/EventEndTimer";
import { Submenu } from "components/IntraClan/Submenu/Submenu";
import React from "react";
import { Wrapper as StWrapper } from "components/Wrapper";
import { ClanWarsMembers } from "components/TieredLeaderboard/componetns/ClanWarsMembers";
import Box from "../components/Box";
import { Title } from "components/TieredLeaderboard/componetns/Title";

export default function IntraClanLBPage() {
  return (
    <StWrapper>
      <Submenu />
      <Box alignItems="center" mb={25} mt={20} justifyContent="space-between">
        <Title text="Intra-Clan Leaderboard" />
        <EventEndTimer />
      </Box>
      <ClanWarsMembers />
    </StWrapper>
  );
}
