import * as s from "components/IntraClan/styled";
import { PlayerCard } from "components/IntraClan/PlayerCard";
import TableClanWars from "components/IntraClan/TableClanWars/TableClanWars";
import React from "react";
import { clanevents_getIntraClanLB } from "api";
import { useSwrImmutable } from "hooks/useSwr";

export function ClanWarsMembers() {
  const [data] = useSwrImmutable("intraClanLB", clanevents_getIntraClanLB);
  if (!data) return null;
  return (
    <>
      <s.Top3Wrap>
        {data[0] && <PlayerCard player={data[0]} />}
        {data[1] && <PlayerCard player={data[1]} />}
        {data[2] && <PlayerCard player={data[2]} />}
      </s.Top3Wrap>
      <TableClanWars data={data} />
    </>
  );
}
