import styled from "styled-components/macro";
import modalClose from "assets/modalClose.svg";

export const Background = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 1000;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div<{ width?: number | string }>`
  position: relative;
  width: ${({ width }) => width || "100%"};
`;
export const Inner = styled.div`
  position: relative;
  clip-path: polygon(0px 1%, 100% 0px, 99% 100%, 1% 98%);
  background: rgb(36, 36, 36);

  box-sizing: border-box;
`;

export const CloseButton = styled.img.attrs({
  src: modalClose,
})`
  position: absolute;
  top: -8px;
  right: -4px;
  z-index: 1010;
`;
