import styled from "styled-components/macro";
import ClanBalanceBg from "../assets/ClanBalanceBg.png";
import donatePlus from "../assets/donate_plus.png";
import myBalanceBg from "../assets/MyBalanceBg.png";

export const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 40;
  cursor: pointer;
  & > img {
    width: 16px;
    height: 16px;
  }
`;

export const Content = styled.div`
  width: 680px;
  height: 466px;
  padding: 0 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: #ffb60c;
`;

export const Balance = styled.div`
  position: relative;
  display: flex;
  flex: 0 0 251px;
  height: 118px;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border: 1px solid #2c2c2c;
  
  border-radius: 4px;
`;

export const MyBalance = styled(Balance)`
  background: #131313 url("${myBalanceBg}");
  background-size: 100% 100%;
`;

export const ClanBalance = styled(Balance)`
  background: #131313 url("${ClanBalanceBg}");
  background-size: 100% 100%;
`;

export const PlusIcon = styled.img.attrs({
  src: donatePlus,
})`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
`;

export const Middle = styled.div`
  flex: 0 0 58px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BalanceText = styled.div`
  display: flex;
  gap: 4px;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #77dcff;
`;

export const Quantity = styled.div`
  width: 320px;
  margin: 24px auto 43px;
  
`;

export const QuantityText = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #838383;
  margin-bottom: 4px;
`;

export const Hint = styled.div`
  font-weight: 350;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  text-align: center;
  color: #ec2e00;
  position: absolute;
  bottom: 7px;
  left: 0;
  right: 0;
`;

