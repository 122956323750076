import styled, { css } from "styled-components/macro";
import { mapRarityToColor } from "utils/mappers/mapRarityToColor";
import { mapRarityToBgColor } from "utils/mappers/mapRarityToBgColor";

export const Wrapper = styled.div`
  //height: calc(100vh - 150px);
  max-width: 1110px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  margin: 40px auto;
  padding: 30px;
`;

export const Title = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  font-weight: 500;
  font-size: 35px;
  line-height: 42px;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 36px;
  & > img {
    cursor: pointer;
  }
`;

export const Body = styled.div`
  min-height: calc(100vh - 300px);
  display: flex;
  gap: 40px;
  max-height: 730px;
`;

export const LeftSide = styled.div`
  flex: 1 1 100px;
  display: flex;
  flex-direction: column;
`;

export const RightSide = styled.div`
  flex: 1 1 100px;
  display: flex;
  flex-direction: column;

  & > .block1 {
    display: flex;
    gap: 7px;

    & > div:nth-child(1) {
      flex: 0 0 180px;
      height:180px;
    }
    & > div:nth-child(2) {
      flex: 1 1 auto;
    }
  }
`;

export const RightTitle = styled.div`
  font-weight: 400;
  font-size: 27px;
  color: #ffffff;
  margin-bottom: 16px;
`;

export const FilterButton = styled.div<{ w?: number; active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  width: ${({ w }) => (w ? w + "px" : "auto")};
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

  ${({ active }) =>
    active
      ? css`
          color: #ffb60c;
          border: 1px solid #ffb60c;
        `
      : css`
          color: #ffffff;
          border: 1px solid #4e4e4e;
        `}
  & > img {
    transform: scale(0.5);
  }
`;

export const ItemListScrollable = styled.div`
  min-height: 300px;
  overflow-y: auto;
  padding: 3px 15px 3px 3px;
  &::-webkit-scrollbar-thumb {
    background-color: rgb(255, 138, 3);
    border-radius: 41px;
  }

  &::-webkit-scrollbar-track {
    background-color: #3d3d3d;
    border-radius: 41px;
  }

  &::backdrop {
    background-color: rgb(7, 17, 27);
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
`;

export const ItemListContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

`;

export const Info = styled.div`
  flex: 1 1 50%;
  display: grid;
  gap: 15px;
  grid-template-columns: min-content 1fr;
  align-content: center;

  & > img {
    width: 24px;
  }

  & > div {
    font-weight: 350;
    font-size: 14px;
    line-height: 130%;
    color: #ffffff;

    & > span {
      color: #ffb60c;
    }
  }

  & .sol {
    width: 18px;
  }
`;

export const Status = styled.div`
  display: flex;
  flex: 1 1 50%;
  align-items: center;
  gap: 7px;
  border: 1px solid #3f3f3f;
  background: rgba(63, 63, 63, 0.2);

  & > img {
    transform: scale(0.5);
  }

  & > div {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }

  & > span {
  }
`;

export const Rarity = styled.div<{ rarity: number }>`
  display: flex;
  flex: 1 1 50%;
  align-items: center;
  gap: 7px;
  background: ${({ rarity }) => mapRarityToBgColor(rarity)};
  border: 1px solid ${({ rarity }) => mapRarityToColor(rarity)};

  & > img {
    transform: scale(0.5);
  }
`;

export const CharacteristicListScrollable = styled.div`
  position: relative;
  margin-top: 25px;
  overflow-y: auto;
  padding-right: 5px;
  flex: 1;
  min-height: 200px;

  &::-webkit-scrollbar-thumb {
    background-color: rgb(255, 138, 3);
    border-radius: 41px;
  }

  &::-webkit-scrollbar-track {
    background-color: #3d3d3d;
    border-radius: 41px;
  }

  &::backdrop {
    background-color: rgb(7, 17, 27);
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
`;

export const CharacteristicListContent = styled.div`
  //min-height: ;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const CharListItem = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: auto auto auto;
  background: linear-gradient(
    90.01deg,
    rgba(40, 40, 40, 0) 0.01%,
    rgba(40, 40, 40, 0.25) 99.99%
  );
  height: 56px;

  & > .horizontal {
    height: 1px;
    grid-column: span 2;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 47.92%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  & > .img {
    max-width: 23px;
    max-height: 28px;
    align-self: center;
    margin-left: 12px;
    margin-right: 12px;
    grid-row: span 2;
  }
`;

export const ItemZoom = styled.div`
  transition: all 0.2s;
  &:hover {
    transform: scale(1.02);
  }
`;

export const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
`;
