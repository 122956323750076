import { getAccountDetails, idp_GetAccount } from "api";
import { useSwr } from "hooks/useSwr";
import * as s from "pages/WorldDomination/WDStatsPanel/StatsPanel.styled";

export function WDStatsPanelSignUpJoin() {
  const [auth, loadingAuth] = useSwr("auth", idp_GetAccount, {
    revalidateOnFocus: true,
  });
  const [player, loadingDetails] = useSwr(
    auth?.userId?.value ? [auth?.userId?.value, "user"] : null,
    getAccountDetails,
    { revalidateOnFocus: true }
  );
  const handleClick = () => window.open("https://miniroyale.io/", "_blank");
  return (
    <s.StatsPanelJoinWrapper>
      <s.Logo />
      <s.Text1>START NOW</s.Text1>
      <s.Text2>to manage the world!</s.Text2>
      {!auth && !loadingAuth && (
        <s.Button onClick={handleClick}>SIGN UP</s.Button>
      )}
      {auth && !player?.clan && !loadingDetails && (
        <s.Button onClick={handleClick}>JOIN CLAN</s.Button>
      )}
    </s.StatsPanelJoinWrapper>
  );
}