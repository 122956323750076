import {
  selectEvent,
  selectEventStatus,
  selectMembersCount,
  selectOrbs,
} from "redux/slices/eventPointsSlice";
import { useAppSelector } from "store";
import clanWar from "../assets/clanWar.png";
import eOrbs from "../assets/eOrbs.png";
import membersIcon from "../assets/membersIcon.png";
import timerIconYellow from "../assets/timerIconYellow.svg";
import * as s from "./StakeForEventPoints.styled";

export function StakeEPInfoBlock1() {
  const event = useAppSelector(selectEvent);
  const membersCount = useAppSelector(selectMembersCount);
  const enrichedOrbs = useAppSelector(selectOrbs);
  const eventStatus = useAppSelector(selectEventStatus);

  if (!event) return null;
  return (
    <div>
      <div className="title">
        {eventStatus?.status === "future"
          ? `upcoming event:`
          : `current event:`}
      </div>
      <s.Event status={eventStatus} bg={event.bannerUrl}>
        {/* <MyTooltip
          placement="topRight"
          overlay={<s.TooltipContent>Tooltip content</s.TooltipContent>}
        >
          <InfoIcon />
        </MyTooltip> */}
        <div className="top">
          <div className="name">{event?.name}</div>
          <div className="ends">
            {eventStatus?.status === "future" && `Starts In:`}
            {eventStatus?.status === "current" && `Ends In:`}
            {eventStatus?.status === "past" && `Event ended`}
            {eventStatus?.time && (
              <>
                <img src={timerIconYellow} alt="timer" />
                <div className="time">{eventStatus?.time}</div>
              </>
            )}
          </div>
        </div>

        <div className="stats">
          <div>
            <img src={membersIcon} />
            {membersCount}/100
          </div>
          <div>
            <img src={eOrbs} />
            {enrichedOrbs}
          </div>
        </div>
        <div className="clanWar">
          <img src={clanWar} />
        </div>
      </s.Event>
    </div>
  );
}
