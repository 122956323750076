import styled from "styled-components/macro";
import { numberWithCommas } from "utils/numberWithComas";

export const Content = styled.div`
  width: 1150px;
  position: relative;
  padding: 34px 16px 4px 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Scroll = styled.div`
  width: 100%;
  min-height: 200px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding: 0px 15px 0 0 ;
  &::-webkit-scrollbar-thumb {
    background-color: rgb(255, 138, 3);
    border-radius: 41px;
  }

  &::-webkit-scrollbar-track {
    background-color: #3d3d3d;
    border-radius: 41px;
  }

  &::backdrop {
    background-color: rgb(7, 17, 27);
  }

  &::-webkit-scrollbar {
    width: 4px;
  }
  & > div:not(:last-child) {
    margin-bottom: 35px;
  }
`;

export const DefenseGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(5,170px);
  grid-template-rows: repeat(4,41px);
  grid-auto-flow: column;
  row-gap:2px;
  column-gap: 4px;
`;

export const Close = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 40;
  cursor: pointer;
  & > img {
    width: 16px;
    height: 16px;
  }
`;

export const Yellow = styled.div`
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #FFB60C;
  line-height: 41px;
`;

export const White = styled.div`
  font-weight: 350;
  font-size: 14px;
  text-transform: capitalize;
  color: #FFFFFF;
  line-height: 41px;
`;

export const CityName = styled(White)`
  background-color: #323232;
  text-align: center;
`;


export const Green = styled.div<{val:number}>`
  font-weight: 400;
  font-size: 18px;
  line-height: 41px;
  text-align: center;
  text-transform: capitalize;
  color: ${({val})=> val ? "#59B015" : "#838383"};
  &:before {
    content: "${({val})=> val}";
  }
  background-color: #1F1F1F;
`;

export const Red = styled.div<{val:number}>`
  line-height: 41px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  text-transform: capitalize;
  color: ${({val})=> val ? "#EC2E00" : "#838383"};
  background-color: #1F1F1F;
  &:before {
    content: "${({val})=> val}";
  }
`;

export const Grey = styled.div<{val:number}>`
  font-weight: 400;
  font-size: 18px;
  line-height: 41px;
  text-align: center;
  text-transform: capitalize;
  color: #838383;
  &:before {
    content: "${({val})=> numberWithCommas(val)}";
  }
  background-color: #1F1F1F;
`;


export const PointsLost = styled(Red)<{val:number}>`
  &:before {
    content: "${({val})=> numberWithCommas(val)}";
  }
`;


// Offense
export const OffenseYellow = styled.div`
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #FFB60C;
  margin-bottom: 11px;
`;

export const PointsStolen = styled(Green)<{val:number}>`
  &:before {
    content: "${({val})=> numberWithCommas(val)}";
  }
`;

export const OffenseGrid = styled.div`
  display: grid;
  grid-template-columns: 170px 170px repeat(3,1fr);
  row-gap:2px;
  column-gap: 4px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 350;
  font-size: 14px;
  text-transform: capitalize;
  color: #FFFFFF;
  height: 76px;
  background-color: #323232;
  text-align: center;
  
`;

export const OffenceWhite = styled.div`
  background: #1F1F1F;
  padding-left: 10px;
  font-weight: 350;
  font-size: 14px;
  text-transform: capitalize;
  color: #FFFFFF;
  line-height: 41px;
`;


