import { idp_GetAccount } from "api";
import bg from "assets/stakeForEventPoints.png";
import Text from "components/Text/Text";
import { useSwrImmutable } from "hooks/useSwr";
import { useNavigate } from "react-router";
import { selectStakedItemsCount } from "redux/slices/eventPointsSlice";
import { useAppSelector } from "store";
import { plural } from "utils/plural";
import * as s from "./staking.styled";

export function StakeForEventPointsBlock() {
  const [auth, loading] = useSwrImmutable("auth", idp_GetAccount);
  const navigate = useNavigate();
  const handle = () => {
    if (loading) return;
    else {
      if (auth) navigate("/staking/eventPoints");
      else window!.open("https://miniroyale.io/", "_blank")!.focus();
    }
  };

  const stakedItemsCount = useAppSelector(selectStakedItemsCount);

  return (
    <s.StakeLoginItem bgImg={bg} onClick={handle}>
      <div className="title">Stake for event points</div>
      {!auth && !loading && <div className="small">Login to Access</div>}
      {auth && (
        <Text w={350}>
          {stakedItemsCount !== undefined && (
            <>
              {stakedItemsCount} {plural("item", stakedItemsCount)} staked
            </>
          )}
        </Text>
      )}
    </s.StakeLoginItem>
  );
}
