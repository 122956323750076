import styled, { css } from "styled-components/macro";
import clanIconBg from "assets/clanIconBg.svg";
import arrow from "assets/filterArrow.svg";
import { HeaderCell } from "components/TableScores/styled";
import tableBgTop from "assets/tableBgTop.png";
import tableBgMiddle from "assets/tableBgMiddle.png";

export { HeaderCell };

export const Wrapper = styled.div`
  max-width: 1170px;
  margin: 20px;

  @media (min-width: 768px) {
    margin: 24px;
  }

  @media (min-width: 1280px) {
    margin: 30px auto;
  }
`;
export const Inner = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 30px;
`;

export const TableHeader = styled.div`
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  row-gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 28px;
  padding-left: 20px;
  flex-direction: column;
  height: 80px;
  background: url(${tableBgTop}) no-repeat;
  background-size: 100% 100%;
  @media (min-width: 768px) {
    align-items: center;
    padding-left: 30px;
    flex-direction: row;
  }
  @media (min-width: 1280px) {
    padding-top: 36px;
    padding-bottom: 7px;
  }
`;

export const TableName = styled.div`
  color: #ffffff;
  opacity: 0.8;
  font-size: 16px;
  //text-align: center;
  font-weight: 700;
  line-height: 19px;
  margin-right: 20px;
`;

export const HeadImg = styled.img.attrs({
  src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAA3CAYAAACGnvPUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJiSURBVHgB7ZldbtpAFIXPrU2pBFW9hHQHWQJ5TdUqrKCwgsYrSFhBYQVJV5CkVcIj7grCDpIdFKmJBIX49vqHYsAYe8w0ijTfAz9j8BzPHXvuPQMUhC8ch/v1E2iAoADf1Acgvse44lJzNMKOeAUVGD/B1EJ1essXb/awI9TE+PCiD7SHqn3LN7Vj7AClMAVIqH7Jm5No6tLhg4sSqI1MqAbfVlqOZYTuyoRNXYyPy/VGkrBZA/7+9ggKqIuZ2UN5HaUKsvlL8AhAQZTFxLf0cKXZwxMOZO4cqNzyNkrBVyKrEYvo0McHD89F+DS+rrVgMBgMhheOcnKVRbRijx1YlX1YvhOu5IR3kjdHn6fUo0+/11KQQgtlZic+7f/rDLP41Iyl6/Wphz92c9OKTouOJEPb2okyuVb1xci8tlvS6cnSlTDFX1WjKeUMi4gPj+d5fr3US5gu2v7XkqMQnwwdCUm3SJK1dsncl3CxNSghyMNk1qbm+B4FSR3/cKJWp105/Bm5kZA8UbtMtpc5Gfi6fiq/2FZXjyQkvaIhKSwmFJQ9jy4lJK5KSJTEhIJW5xFLVeDD3XUCnvueXcwjDOnwsQuDwWAwLAjcCf5RbxT5j7pztZ0hLAyKGNhaEvI5CUc014Kqc2SSjuhRaDxucUL1illyRCkwsO+ywqZXTJojyjjlfu0szQ3VKmaDI4pN+w56Rybq+Sq9naJ9h4RBqV/MpHKecdQB0dncUdcuJg6Vl340KPK4Pa+7S5rSOQn2pwiNRItUFOyuVpr/Yc4gsT8VljUdTOz3eUteLQQ1mMrmhiHgL7ui9sfgK2FSAAAAAElFTkSuQmCC",
})`
  top: 28px;
  left: -20px;
  position: absolute;
`;

export const BodyWrapper = styled.div`
  margin: 0 auto;
  background: url(${tableBgMiddle});
  background-repeat-x: no-repeat;
  background-size: 100% auto;
`;

export const BodyImg = styled.img.attrs({
  src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAA6CAYAAABIz4K8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJBSURBVHgB7ZhdbtpAEMf/g62CBJV8BHOD9gbwmqpSc4IkJ2hv4PYEHKHcgFZVoG/xDZIbJEfggUhBMZnsDhGJN/hj1zZ5iH8Swl6vNPuf2Y+ZBUrCi/4Jn/c/4dDwfDDhef8aNePnfeRFLwT7v9XjCIQpDmWc/w1GYNaGw21D5y9qprOvkReDCB4uAAp3jWsvRs2klPMsCNBNZmDl5jQxHS+XaMq4zGS6n6XUPg+rdpdrxO2imJBhWLHe/EED0MsXPh/8BPFJehB8Q0e3QzRAasLRl5UyvhmrmP/SRre2qRGX56LXuNpYprLkWlpaWlreDTxTOV4FfDgiqRY4VI9ncITgAM8/ftO65WWdDOn47gYOdCz7b9NpPEx2DV1JrZ2wNo4H30y3Rq5nvpVxiTPhdcnkIYIDpWOusttTEGW7eIMxfV3FsKCUcokz0SS3k4P6QuOSVrOnqhcEBV2tY1+svJtEmfm8iaX6XOP8v/9d/f1AeazUZ064p/LYoSYvX2Tsr1Kf4+wAhbIyXI2jdz8pHee99hGJAFvjspEwnaISFOJDUjhXjEJRl8l0iXpYYu0P8+p6o1C8vVJXIWe7IrEaQZH67Nku2ymiSrEvUJ+5zpUXprJkqnkiV73lweLkiUz1pY/UCp7IVO+URmksPaHUJ5/NdMs+k3nC0hMBet6rQ8dZuUkpTxjJprNyk7QnEO/tZKivTbmJHK3b832U+vAi3WrMeM4gYjpajXFI9CDUvf2F+vGbXa/JIPRNZ0tLy6F4BJpF6nbLv1GHAAAAAElFTkSuQmCC",
})`
  right: -13px;
  bottom: 20%;
  position: absolute;
`;

export const Grid = styled.div`
  //margin: 20px auto 75px;
  padding: 0 20px;
  @media (min-width: 768px) {
    padding: 0 30px;
  }
  @media (min-width: 1280px) {
  }
`;

export const Row = styled.div`
  padding-right: 10px;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns:
    minmax(15px, 0.5fr)
    minmax(30px, 1.5fr)
    repeat(3, minmax(30px, 1fr))
    minmax(30px, 1.5fr);
  gap: 10px;
  align-items: center;
`;

export const RowMobile = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 20px minmax(80px, 1fr) 60px 30px;
  gap: 10px;
  align-items: center;
`;

export const HeaderRowMobile = styled(RowMobile)`
  height: 40px;
  padding-left: 20px;
  //padding-right: 20px;
  background: #171717;
  margin-bottom: 20px;
  div:nth-child(2) {
    text-align: left;
    padding-left: 40px;
  }
  div:nth-child(3) {
    text-align: end;
  }
`;

export const HeaderRow = styled(Row)`
  background: #171717;
  height: 40px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    padding-left: 20px;
  }
  @media (min-width: 1280px) {
    padding-left: 25px;
  }
`;

export const GridRow = styled(Row)`
  height: 60px;
  ${(props: { $i: number }) => {
    return (
      !(props.$i % 2) &&
      css`
        background: rgba(255, 255, 255, 0.03);
        border: 1px solid rgba(255, 255, 255, 0.12);
      `
    );
  }};
  padding-left: 20px;

  @media (min-width: 1280px) {
    padding-left: 25px;
  }
`;

export const GridRowMobile = styled(RowMobile)`
  height: 60px;
  padding-left: 20px;
  padding-right: 5px;
  ${(props: { $i: number }) => {
    return (
      !(props.$i % 2) &&
      css`
        background: rgba(255, 255, 255, 0.03);
        border: 1px solid rgba(255, 255, 255, 0.12);
      `
    );
  }};
  div:nth-child(4) {
    justify-content: center;
  }
  div:nth-child(3) {
    justify-content: flex-end;
  }
`;

export const GridCell = styled.div<{ pointer?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 325;
  font-size: 12px;
  color: #ffffff;
  ${({ pointer }) =>
    pointer &&
    css`
      cursor: pointer;
    `}//opacity: 0.9;
`;

export const ClanTag = styled.span`
  color: #666666;
  font-weight: bold;
`;

export const GridImg = styled.div<{ tag: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  background-image: url(${clanIconBg});

  &::after {
    font-size: 8px;
    content: "${(props) => props.tag}";
  }
`;

export const TableArrowMob = styled.img.attrs({
  src: arrow,
})<{ open: boolean }>`
  cursor: pointer;
  width: 20px;
  opacity: 0.6;
  transition: all 0.1s;
  ${({ open }) =>
    open &&
    css`
      opacity: 1;
      transform: rotate(-180deg);
    `};
`;

export const HiddenStats = styled.div<{ open: boolean }>`
  height: ${({ open }) => (open ? `142px` : "0px")};
  overflow: hidden;
  display: grid;
  transition: all 0.1s;
  grid-template-columns: 1fr 1fr;
`;

export const StatItem = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 20px;
  padding-top: 17px;
  height: 70px;
  box-sizing: border-box;
`;

export const StatTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.6);
`;

export const StatText = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
`;

export const StatsSeparator = styled.div`
  grid-column: span 2;
  margin: 0 20px;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(255, 255, 255, 0.15) 49.83%,
    rgba(255, 255, 255, 0.06) 100%
  );
`;

export const SkeletonFilterWrap = styled.div`
  display: flex;
  transform: skew(-10deg);
  align-items: center;
  border: 1px solid;
  height: 32px;
  margin-right: 10px;
  border-image: linear-gradient(
      88.27deg,
      rgba(255, 255, 255, 0.12) 4.49%,
      rgba(255, 255, 255, 0.32) 62.01%
    )
    1 / 1 / 0 stretch;

  div {
    transform: skew(10deg);
  }
`;


